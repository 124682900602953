/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Constants for the profile reducer & actions.
 */

import {
  supportCapital,
  supportCore,
  supportCapacity,
  PRManageMembersImage,
  PRManageMembersSelectedImage,
  PRProfileImage,
  PRProfileSelectedImage,
  PRPlanMeetingImage,
  PRPlanMeetingSelectedImage,
  editIcon,
  MessagesRed3x,
  BudgetsIconWhite,
  BudgetsIconCharcoal,
  ApprovalsRed3x,
  ClaimInvoiceRed3x,
  BudgetsIconRed,
  MyDocumentsRed4x,
  DocumentsRed3x,
  GreyClockIcon3x,
} from '../assets/images/vector-icons';
import * as images from '../assets/images/vector-icons';

import * as brand from '../Constants/BrandConstants';
import {BrandColors} from '../../src/Styles/Colours';
import * as defaultImages from '../assets/images';
import {BrandIcons} from '../Styles/BrandIcons';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {PM_SIGN_UP_URL, PM_DEMO_EMAIL, PR_DEMO_EMAIL} from '../environments';
import {
  DEMO_OPTIONS_CHOOSE_GUIDE_ME_TYPE_MG_BUDGET,
  DEMO_OPTIONS_CHOOSE_GUIDE_ME_TYPE_NDIS_PLANING,
  DEMO_OPTIONS_COLORS_WELCOME_EXPLORE,
  DEMO_OPTIONS_COLORS_WELCOME_LOGIN,
  DEMO_OPTIONS_COLORS_WELCOME_SCREEN_SIGN_UP_PROFILE,
  DEMO_OPTIONS_COLORS_WELCOME_SHOW_ME_AROUND,
  DEMO_OPTIONS_COLORS_WELCOME_SIGN_UP,
} from '../Styles/OnboardingStyles';

// Generic app info
export const HTTP = 'http:';
export const HTTPS = 'https:';
export const WEBSITE_HOME = 'www.leapin.com.au';
export const PLAY_STORE = `${HTTPS}//itunes.apple.com/au/app/leap-in-ndis-planning/id1399430949?mt=8`;
export const APP_STORE = `${HTTPS}//play.google.com/store/apps/details?id=com.planready`;
export const WEBSITE_TIPS_TRICKS = `${HTTP}//www.leapin.com.au`;
export const BUILD_ENVIROMENT_DISPLAY_ARRAY = ['dev', 'qa', 'uat'];


export const SELECT_WHEN_DO_YOU_USE = 'When do you use this service?';
export const SELECT_SUPPORT_DAYS = 'What days do you use this support?';
export const SELECT_SUPPORT_TIME = 'What time of day do you use this support?';
export const PLEASE_SELECT_VALUE = 'Please select an option';
export const SUPPORT_TITLE_INVALID_ERROR = 'Support title is required';
export const DATE_PICKER_PLACEHOLDER = {
  DATE_OF_BIRTH: 'Date of Birth',
  MEETING_DATE: 'DD/MM/YY',
  INVOICE_DATE: 'dd-mm-yy',
  PLAN_START_DATE: 'Plan start date',
};
export const WHO_IS_HELPING_YOU = 'Who is helping you?';
export const SUPPORT_TRAFFIC_LIGHT_QUESTION =
  'What do you think about this support?';
export const SUPPORT_TELL_MORE_ABOUT = 'Anything else you want to note?';
export const TOTAL_COST_PER_YEAR_QUESTION = 'Total cost per year (if known)';
export const COST_PER_VISIT_QUESTION = 'Cost per visit';
export const HOURS_PER_VIST_QUESTION = 'Hours per visit';
export const TIMES_PER_YEAR_QUESTION = 'Times per year?';
export const HOW_MANY_TIMES_A_YEAR = 'How many times a year (if relevent)';
export const CONFIRM_SUPPORT_DELETE = "Deleting this support can't be undone.";
export const SUPPORTS_ADD_TITLE = 'Add Support Detail';
export const SUPPORTS_EDIT_TITLE = 'Edit Support Detail';
export const SUPPORS_VIEW_TITLE = 'View Support Detail';
export const SUPPORT_CATEGORY_COLORS = [
  BrandColors.GOALS_INSPIRATION_COLORS.FIRE_ENGINE_RED,
  BrandColors.GOALS_INSPIRATION_COLORS.RED_ORANGE,
  BrandColors.GOALS_INSPIRATION_COLORS.RAZZMATAZZ,
  BrandColors.GOALS_INSPIRATION_COLORS.EGGPLANT,
  BrandColors.GOALS_INSPIRATION_COLORS.PERSIAN_ROSE,
  BrandColors.GOALS_INSPIRATION_COLORS.DARK_ORCHID,
  BrandColors.GOALS_INSPIRATION_COLORS.INDIGO,
  BrandColors.GOALS_INSPIRATION_COLORS.DARK_BLUE,
  BrandColors.GOALS_INSPIRATION_COLORS.COBALT,
  BrandColors.GOALS_INSPIRATION_COLORS.NAVY_BLUE,
  BrandColors.GOALS_INSPIRATION_COLORS.BONDI_BLUE,
  BrandColors.GOALS_INSPIRATION_COLORS.SHERPA_BLUE,
  BrandColors.GOALS_INSPIRATION_COLORS.TROPICAL_RAIN_FOREST,
  BrandColors.GOALS_INSPIRATION_COLORS.PIGMENT_GREEN,
  BrandColors.GOALS_INSPIRATION_COLORS.FUN_GREEN,
];
export const DEFUALT_SUPPORT_CAPTION = 'Upload an image of your support';
export const ADD_SUPPORT_PICTURE_TITLE = 'Add support picture';
export const ADD_SUPPORT_PICTURE_HEADER = 'Support Picture';
export const ADD_GOAL_PICTURE_TITLE = 'Add goal picture';
export const ADD_GOAL_PICTURE_HEADER = 'Goal Picture';

export const RELATIVE_MEETING_DATE = {
  FUTURE: 'future',
  TODAY: 'today',
  PAST: 'past',
};

export const USER_TYPE = {
  MEMBER: {
    TYPE: 'member',
    API_FILE_TYPE: 'profile',
  },
  CREW: {
    TYPE: 'crew',
    API_FILE_TYPE: 'crewProfile',
  },
  ORGANISATION: {
    TYPE: 'organisation',
    API_FILE_TYPE: 'organisationProfile',
  },
};

export const FILE_HEADER = 'file:/';


export const TAB_INDEXES = [
  'Profile', 'Budget', '', 'Linked'
]

export const CURRENT_PLAN_DOC_CATEGORY = 'Plannew';

export const DOC_UPLOAD = {
  CATEGORIES: [
    {
      type: 1,
      description: 'Plan - Current',
      value: CURRENT_PLAN_DOC_CATEGORY,
    },
    {type: 2, description: 'Plan - Previous', value: 'Planold'},
    {type: 3, description: 'Health', value: 'Health'},
    {
      type: 4,
      description: 'Assessments and Reports',
      value: 'Assessmentandreports',
    },
    {type: 5, description: 'Supports', value: 'Supports'},
    {type: 6, description: 'Quotes', value: 'Quotes'},
    {type: 7, description: 'Daily Living', value: 'Dailyliving'},
    {type: 8, description: 'Equipment', value: 'Equipment'},
    {type: 9, description: 'Consumables', value: 'Consumables'},
    {type: 10, description: 'General', value: 'General'},
  ],
};

export const MY_DETAILS = {
  STATES: [
    {type: '', description: 'State' /* value: '' */},
    {type: 'ACT', description: 'ACT' /* value: 'ACT' */},
    {type: 'NSW', description: 'NSW' /* value: 'NSW' */},
    {type: 'NT', description: 'NT' /* value: 'NT' */},
    {type: 'QLD', description: 'QLD' /* value: 'QLD' */},
    {type: 'SA', description: 'SA' /* value: 'SA' */},
    {type: 'TAS', description: 'TAS' /* value: 'TAS' */},
    {type: 'VIC', description: 'VIC' /* value: 'VIC' */},
    {type: 'WA', description: 'WA' /* value: 'WA' */},
  ],
};

export const PICKER = {
  YES_OR_NO: [
    {type: 'no', description: 'No'},
    {type: 'yes', description: 'Yes'},
  ],
  YES: 'yes',
  NO: 'no',
};
export const IMAGE_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
export const RESTRICTED_FILE_EXTENSIONS = [
  'exe',
  'pif',
  'com',
  'msp',
  'bat',
  'ws',
  'src',
  'js',
];
export const PDF = 'application/pdf';
export const DOC = 'binary/octet-stream';
export const ALLOWED_PHOTO_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'tif',
  'tiff',
  'gif',
];

export const allowedDocumentTypes = ["pdf","jpeg", "gif", "heic", "png", "webp"]

export const FILE_TYPE = {
  MEMBER_PROFILE: 'profile',
  CREW_PROFILE: 'crewProfile',
  DOCUMENT: 'document',
  PLAN_SUMMARY: 'planSummary',
  GOAL_PHOTO: 'goalPhoto',
  SUPPORT_PHOTO: 'supportPhoto',
  INVOICE: 'memberInvoice',
};
export const SORT = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  INITIAL: 'initial',
};

export const URI_MODE = {
  EXTERNAL_LINK: 'external_link',
  DEEP_LINK: 'deep_link',
  NO_LINK: 'no_link',
  PAYMENTS_HISTORY_LINK: 'payments_history',
  SERVICE_AGREEMENTS_LIST: 'service_agreements_list',
  PAY_ADHOC_INVOICE_LINK: 'pay_adhoc_invoice',
};

export const MARKETING_TEXT_TIME_TO_CHANGE = 2 * 1000; // X Seconds
export const MARKETING_MESSAGE_SET_INDEX = 'marketing_message_set_index';

export const REFERENCE_DATA = {
  GET_LIFE_STAGE: 'get_life_stage',
  GET_DISABILITY: 'get_disability_ref',
};

export const ROUTING_STATE = {
  LOGIN_SIGN_UP: 'loginScreen',
  HOME_SCREEN: 'homeScreen',
  LINKED_MEMBERS_SCREEN: 'linkedMemberScreen',
};

export const PLAN_MANAGER_DATA = {
  LEAP_IN: 'leap_in',
  SELF_MANAGED: 'self',
  PLAN_MANAGED: 'plan_managed',
  AGENCY: 'agency',
  OTHER: 'other',
  NONE: 'none',
};

export const MEMBER_STATUS_DATA = {
  PLAN_READY: 'plan_ready',
  ONBOARDING: 'onboarding',
  MANAGED: 'managed',
};

export const PLAN_STATUS_DATA = {
  DRAFT: 'draft',
  PM_REQUESTED: 'pm_requested',
  PM_CLIENT_VERIFIED: 'pm_client_verified',
  PM_LP_VERIFIED: 'pm_lp_verified',
  PM_ACTIVE: 'pm_active',
  PM_EXPIRED: 'pm_expired',
  COMPLETED: 'completed',
  CLAIMABLE: 'claimable',
  INACTIVE: 'inactive',
};

export const PDF_VIEW_TYPE = {
  VIEW: 'view',
  PRINT: 'print',
  SHARE: 'share',
  DOWNLOAD: 'download',
};

export const GOAL_SUMMARY_TILE_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
};

export const TRACKER_TYPE = {
  GOAL: 'goals',
  SUPPORT: 'support',
};

export const BUDGET_KEYS = {
  TOTAL: 'total',
  SPENT: 'spent',
  ALLOCATED: 'allocated',
  UNSPENT: 'unspent',
  UNRELEASED: 'unreleased'
};

export const SUPPORT_GROUPS = [
  {
    apiKey: 'core',
    displayText: 'Core Supports',
    icon: supportCore,
  },
  {
    apiKey: 'capitalSupports',
    displayText: 'Capital Supports',
    icon: supportCapital,
  },
  {
    apiKey: 'capacityBuilding',
    displayText: 'Capacity Building',
    icon: supportCapacity,
  },
];

export const SIDEBAR_MODE = {
  PLAN_READY: 'plan_ready',
  PLAN_READY_MANAGE_PARTICIPANT: 'plan_ready_manage_participants',
  PLAN_MANAGEMENT: 'plan_management',
  PLAN_PAY_INVOICE: 'plan_pay_invoice',
  EMPTY: 'empty',
  STEP_SELECT_PROVIDER: '1. Select provider',
  STEP_INVOICE_DETAILS: '2. Invoice details',
  STEP_SUPPORT_ITEM: '3. Support items details',
  STEP_REVIEW: '4. Review & submit',
};

export const TOPBAR_MODE = {
  DOCUMENTS: 'documents',
  PAYMENTS_HISTORY: 'paymentsHistory',
};

// The tolerance for a budget, if it is within this range it is on track
export const BUDGETS_TOLERENCE = 0.15;

export const PHOTOTILEGAP = 5;
export const PHOTOBORDERGAP = 15;

export const SORT_COLUMN = {
  FIRST: 'filename',
  SECOND: 'category',
};

export const APPROVAL_TYPE = {
  INVOICE: 'invoice',
  SERVICE: 'serviceAgreement',
  INVOICE_DISPLAY: 'Invoice',
  SERVICE_DISPLAY: 'Service Agreement',
  CLAIM_INVOICE: 'Claim Invoice',
};

export const APPROVAL = {
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const CHECKBOX = {
  RIGHT: 'right',
  LEFT: 'left',
};

export const INVOICE_REJECTION_EMAIL_OPTIONS = {
  SEND: 'SEND',
  NOT_SEND: 'NOT_SEND',
};

export const INVOICE_REJECT_REASONS = {
  REASONS: [
    {
      key: 'incorrect_amount',
      description: 'Details invoiced incorrectly',
      rejection: {
        email_popup: true,
        resolve_invoice: true,
      },
    },
    {
      key: 'wrong_details',
      description: 'Details entered incorrectly',
      rejection: {
        email_popup: false,
        resolve_invoice: false,
      },
    },
    {
      key: 'service_not_received',
      description: 'Did not receive this service',
      rejection: {
        email_popup: true,
        resolve_invoice: true,
      },
    },
    {
      key: 'service_not_authorized',
      description: 'Did not authorise this service',
      rejection: {
        email_popup: true,
        resolve_invoice: true,
      },
    },
    {
      key: 'incorrect_budget',
      description: 'Incorrect Budget',
      rejection: {
        email_popup: false,
        resolve_invoice: false,
      },
    },
    {
      key: 'other',
      description: 'Other',
      rejection: {
        email_popup: true,
        resolve_invoice: true,
      },
    },
  ],
};

export const SA_REJECT_REASONS = {
  REASONS: [
    {
      key: 'did_not_request',
      description: 'Did not ask provider for this',
    },
    {
      key: 'wrong_service_type',
      description: 'Wrong service type',
    },
    {
      key: 'incorrect_budget',
      description: 'Budget is wrong',
    },
    {
      key: 'wrong_agreement_date',
      description: 'Wrong start or stop date',
    },
    {
      key: 'more_more_services',
      description: 'I expected more services',
    },
    {
      key: 'more_less_services',
      description: 'I expected less services',
    },
    {
      key: 'other',
      description: 'Other',
    },
  ],
};

export const NOTIFICATION_TYPES = {
  GENERIC: 'GENERIC',
  BUDGETS: 'BUDGETS',
  AGREEMENT: 'AGREEMENT',
  DATE: 'DATE',
  CREW: 'CREW',
};

export const NOTIFICATION_DATE_FORMAT = 'D MMMM hh:mma';

export const CURRENCY_SYMBOL = '$';
export const DECIMAL_ACCURACY = 2;

export const CREW_ACCCESS_KEYS = {
  DECISION_MAKER: 'decisionMaker',
};

export const MARKETING_MESSAGE_LINK = {
  PLAN_MANAGEMENT: 'PlanManagement',
  PLAN_MEETING: 'PlanMeeting',
};

export const REVIEW_INVOICE_SUMMARY_SUPPORT_ITEM_DESCRIPTION_CHR_LIMIT = 20;

export const AMOUNT_DECIMAL_POINT = 2;

export const HOME_TYPES = {
  APARTMENT: 'apartment',
  HOUSE: 'house',
};

// Support Crew selection
export const ADD_A_NEW_CREW_MEMBER = 'Add a new crew member';
export const SELECT_OR_NEW_CREW_MEMBERS = 'Select or add crew members';

// Navigation constants
export const PROFILE = 'Profile';
export const PLAN_MEETING = 'PlanMeeting';
export const GOAL = 'Goal';
export const ABOUT_LEAPIN = 'AboutLeapIn';
export const TERMS_AND_COND = 'TermsAndConditions';
export const PLAN_MANAGED_TERMS_AND_COND = 'PlanManagementTC';
export const LINKED_MEMBER = 'Linked';
export const CREW_PROFILE = '1B';
export const NORMAL_PROFILE = '1A';
export const GUIDEME = 'GuideMe';
export const SUPPORTS_DETAILS = 'AddSupportDetails';

export const MEMBERS_TITLE = 'Members';

export const LINKED_MEMBERS = 'Linked members';
export const MY_PROFILE = 'My profile';
export const MY_BUDGETS = 'My budgets';

export const MEETING_CHECKLIST = 'MeetingChecklist';
export const PLAN_MANAGEMENT_SCREEN = 'PlanManagement';
export const FORGOT_PASSWORD = 'ForgotPassword';

export const BUDGETS = 'Budget';
export const GUIDE_ME = 'Guide me';
export const MEMBERS = 'Members';
export const BUDGET_PAYMENTS = 'BudgetsPayments';
export const GOAL_TRACKER = 'Goal tracker';
export const PLAN_MEETING_TAB = 'Plan meeting';
export const HOME = 'Home';

export const NAVIGATION_TEXT = {
  SIGN_UP_CREATE_ACCOUNT: 'Create an account to unlock my NDIS',
  SIGN_UP_MEMBER_SELECTION: 'Participant or crew selection',
  SIGN_UP_HELPING_PERSON: 'Setup the person I am helping',
  SIGN_UP_LOGIN: 'Login to my account',
  SIGN_UP_FORGOT_PASSWORD: 'Reset my password',
  SIGN_UP_FORGOT_PASSWORD_TOKEN: 'Enter temporary token',
  SIGN_UP_TEMPORARY_PASSWORD: 'Confirm password',
  MY_PROFILE: 'My profile',
  ABOUT_ME: 'About me',
  MY_DETAILS: 'My details',
  LIFE_STAGES: 'Life stage',
  DISABILITY: 'Disability',
  IMPACT_OF_MY_DISABILITY: 'Impact of my disability',
  CREW: 'My crew',
  ADD_CREW: 'Add crew',
  EDIT_CREW: 'Update crew',
  WHERE_I_LIVE: 'Where I live',
  HOME: 'My home',
  WHO_I_LIVE_WITH: 'Who I live with',
  HOME_MODIFICATION: 'Home modfications',
  FAVORITE_THINGS: 'Favourite things',
  TODAY_I_LIKE_TO: 'Today I like..',
  IN_THE_FUTURE_I_LIKE_TO: 'In the future..',
  HEALTH_AND_WELLBEING: 'Health and wellbeing',
  GOING_WELL: 'Going well',
  TO_IMPROVE: 'To improve',
  DOCUMENTS: 'Health documents',
  SUPPORTS: 'My supports',
  ADD_SUPPORTS: 'Add supports',
  EDIT_SUPPORTS: 'Edit supports',
  VIEW_SUPPORTS: 'View supports',
  MY_GOALS: 'My goals',
  ADD_GOALS: 'Add goal',
  GOAL_DETAIL: 'Goal detail',
  COMPLETED_GOAL: 'Completed goal',
  GOAL_FILTERS: 'Goal filters',
  GOAL_TRACKER: 'Goal tracker',
  MY_BUDGETS: 'My budgets',
  MY_BUDGET_DETAILS: 'Category budget',
  APPROVAL: 'Approvals',
  APPROVAL_SA: 'Service agreement approval',
  APPROVAL_INVOICE: 'Invoice approval',
  PAYMENTS_HISTORY: 'Payment history',
  DRAFT_CLAIMS_HISTORY: 'Draft invoices',
  REVIEW_DRAFT_CLAIM: 'Review draft invoice',
  PAYMENT_DETAILS: 'Payment detail',
  SERVICE_AGREEMENTS: 'Service agreements',
  SERVICE_AGREEMENT: 'Service agreement',
  CLAIM_INVOICES: 'Claim invoice',
  REVIEW_DRAFT_INVOICE: 'Review invoice',
  SETTINGS: 'Settings',
  MESSAGES: 'Messages',
  DOCUMENTS: 'My documents',
  HELP: 'Help',
  SIGN_UP_DETAILS: 'Plan management sign up',
  SIGN_UP_TERMS: 'Plan management terms',
  SIGN_UP_PLAN_DETAILS: 'Plan details',
  TERMS_AND_CONDITIONS: 'Plan management terms',
  PLAN_MANAGEMENT_OPTIONS: 'Plan management options',
  LINKED_MEMBERS: 'Members',
  PLAN_MEETING: 'Plan meeting',
  NEED_ASSISTANCE: 'Help',
  MEETING_CHECKLIST: 'NDIS meeting checklist',
  MEMBERS_MENU: 'Members menu',
  PROFILE_MENU: 'Profile menu',
  BUDGETS_MENU: 'Budgets menu',
  PREVIOUS_PLANS: 'Previous Plans',
  UPDATE_NOTE: 'Update Note',
  PROVIDER_SITES_NEAR_YOU: 'Provider Sites near You',
  ABOUT_PROVIDER: 'About',
  ENQUIRY_PROVIDER: 'Enquiry',
};

export const LIFE_STAGES = {
  HEADER_TITLE: 'Life Stage',
  DESCRIPTION:
    'How would you describe your life stage or current life transitions?',
};

export const TAB_BAR_HIDE = 'tabBarHide';
export const TAB_BAR_SHOW = 'tabBarShow';
export const ADD_SELECTED_TAB = 'addSelectedTab';

export const ABOUT_ME_PROFILE_PICTURE_PROMPT_TEXT = {
  ADD: 'Add a profile pic or\nchoose an avatar',
  EDIT: 'Update profile\npic or avatar',
};

// These are the list of screens where user needs to confirm the navigation before they navigate from
// These screens to a First level screen using the primary navigation
export const NAVIGATION_CONFIRMATION_REQUIRED_SCREENS = [
  'AboutMeMyDetailsForm',
  'AboutMeLifeStageForm',
  'AboutMeDisabilityForm',
  'AboutMeAdditionalInfoForm',
  'FavouriteThingsFutureForm',
  'FavouriteThingsTodayForm',
  'HealthWellbeingGoingWellForm',
  'HealthWellbeingToImproveForm',
  'WhereILiveHomeForm',
  'WhereILiveCrewForm',
  'WhereILiveModificationsForm',
  'SelectAddCrewSupport',
  'AddSupportDetails',
  'CrewForm',
  'AddSupportsByCrew',
  'AddSupportsByCategory',
  'CategorySupports',
  'AddGoals',
];

// Operating systems
export const IOS = 'ios';
export const ANDROID = 'android';
export const WEB = 'web';

// Device brands
export const GOOGLE = 'google';

export const CONNECTION_ERROR =
  'You must be connected to the inernet to use this feature!\nPlease reconnect and try again';
export const CONFIRM_ACCOUNT_CLOSE =
  'You have chosen to close your account. Please click "Confirm" to proceed further or click "Cancel" to stay on the same screen';
export const ALERT = 'Are you sure?';
export const CONFIRM = 'Confirm';
export const CANCEL = 'Cancel';
export const DELETE = 'Delete';
export const OK = 'OK';
export const YES = "Yes, That's right";
export const NO = "No, It's not";

export const BACK_BUTTON = 'back_button';

export const USER_STATE_CONFIRM =
  'User password cannot be reset in the current state.';

// Guide Me
export const GET_GUIDEME = 'getGuideMe';
export const GET_GUIDEME_MEMBER = 'getGuideMeMeMember';
export const OK_GOT_IT = 'Ok, got it!';
export const GUIDEME_SHOW = 'guideMeShow';
export const GUIDEME_HIDE = 'guideMeHide';
export const PREVIOUS_SCREEN_INFO = 'previousScreenInfo';
export const SHOW_WELCOME_GUIDE = 'showWelcomeGuide';
export const WELCOME_GUIDE_STEP = 'showWelcomeStep';
export const SHOW_WELCOME_MANAGED_GUIDE = 'showWelcomeGuideManaged';
export const OVERLAY_HIGHLIGHT_ONE = 'overlayHighlightOne';
export const OVERLAY_HIGHLIGHT_TWO = 'overlayHighlightTwo';
export const OFFSET_WIDTH = 4;
export const OFFSET_HEIGHT = 85;
export const GUIDE_ME_TITLE = 'Guide me';

export const NO_RECORDS = 'No Records';

export const GUIDEME_GENERAL_CONTENT = [
  {
    Id: 1,
    PrimaryMenu: 'General',
    SequenceNumber: 1,
    Title: `Welcome to ${brand.BRAND_FRIENDLY_APP_NAME}`,
    ContentType: 'html',
    Content: `<p>As you work through ${brand.BRAND_FRIENDLY_APP_NAME} you can always press the Guide Me button for help.</p>
      <p>Tap on My Profile to view and maintain information useful for your NDIS planning meetings and plan reviews.
      Tap on My Budgets to access features available for ${brand.BRAND_FRIENDLY_APP_NAME} members that are plan managed, to help you manage your NDIS money.
      Tap on "More" to view other menu options</p>`,
  },
];

export const WELCOME_GUIDE_THEMES = {
  Red: 'Red',
  Purple: 'Purple',
  Yellow: 'Yellow',
  RedPurple: 'Red-Purple',
  YellowBlack: 'Yellow-Black',
  SummerGreen: 'Summer-Green',
  Corn: 'Corn',
};

export const GUIDEME_AVATAR_ALIGNMENT = {
  Left: 'Left',
  Right: 'Right',
};

export const WELCOME_GUIDE_ME = [
  {
    TITLE: `Welcome to ${brand.BRAND_FRIENDLY_APP_NAME}`,
    Content: `As you work through the ${brand.BRAND_FRIENDLY_APP_NAME} app you can always press the Guide me tab below for help.\n\nTap on the My profile tab below to view and maintain information useful for your NDIS planning meetings and plan reviews.\n\nTap on the My budgets tab below to access features available for ${brand.BRAND_FRIENDLY_APP_NAME} plan managed members. Plan management helps you manage your NDIS money.\n\nTap on More in the top right-hand corner to view other menu options.`,
    THEME: WELCOME_GUIDE_THEMES.Red,
    IMAGE_ALIGNMENT: 'Left',
    ContentType: 'plain-text',
  },
];

export const WELCOME_GUIDE_ME_WEB = [
  {
    TITLE: 'Welcome to Leap in!',
    Content:
      '<p>As you work through the Leap in! app you can always press the <b>Guide me</b> tab below for help.</p><p>Tap on the <b>My profile</b> tab below to view and maintain information useful for your NDIS planning meetings and plan reviews.</p><p>Tap on the <b>My budgets</b> tab below to access features available for Leap in! plan managed members. Plan management helps you manage your NDIS money.</p>',
    THEME: WELCOME_GUIDE_THEMES.Red,
    IMAGE_ALIGNMENT: 'Left',
    ContentType: 'plain-text',
  },
];

export const ON_BOARDED_WELCOME_GUIDE_ME = [
  {
    TITLE: 'Welcome to Leap in!',
    SUB_TITLE: 'plan management',
    IMAGE: defaultImages.BudgetOnBoarding,
    IMAGE_STYLE: CommonStyles.imageGuideMeStepTwo,
    CONTENT:
      "<p>In <b>My Budgets</b> you see at a glance how you're tracking against each budget category.</p><p>Tap on a category to dig into more detail.</p><p>Let's get started!</p>",
    THEME: WELCOME_GUIDE_THEMES.RedPurple,
    IMAGE_ALIGNMENT: 'Left',
    SCREEN: 'BudgetsPayments',
    ContentType: 'html',
  },
  {
    TITLE: 'Look out for',
    SUB_TITLE: 'messages',
    IMAGE: defaultImages.AvatarLyingDownRight,
    IMAGE_STYLE: CommonStyles.imageGuideMeManagedStepTwo,
    CONTENT:
      "<p>Tap on <b>Messages</b> to keep up to date.</p><p>We'll let you know if something needs your approval or payments are being made.</p>",
    THEME: WELCOME_GUIDE_THEMES.YellowBlack,
    IMAGE_ALIGNMENT: 'Right',
    ContentType: 'html',
  },
  {
    TITLE: 'Approve provider invoices',
    SUB_TITLE: 'payments',
    IMAGE: defaultImages.AvatarArmsUp,
    IMAGE_STYLE: CommonStyles.imageGuideMeStepThree,
    CONTENT:
      '<p>Tap <b>Approvals</b> to review providers invoices so they get paid for services.</p><p>Or let us know if there are any issues.</p>',
    THEME: WELCOME_GUIDE_THEMES.Purple,
    IMAGE_ALIGNMENT: 'Right',
    ContentType: 'html',
  },
  // {
  //   TITLE: 'Snap and upload',
  //   SUB_TITLE: 'your invoices',
  //   IMAGE: defaultImages.AvatarLaying,
  //   IMAGE_STYLE: CommonStyles.imageGuideMeStepFour,
  //   CONTENT: '<p>If your provider send you invoices directly..</p><ul><li>Tap <b>Claim Invoice</b></li><li>Snap a photo, and</li><li>Submit your claim</li><ul>',
  //   THEME: WELCOME_GUIDE_THEMES.Red,
  //   IMAGE_ALIGNMENT: 'Right',
  //   ContentType: "html"
  // },
  {
    TITLE: 'Looking',
    SUB_TITLE: 'for detail?',
    IMAGE: defaultImages.AvatarArmsOutstretched,
    IMAGE_STYLE: CommonStyles.imageGuideMeStepFive,
    CONTENT:
      "Tap <b>Payment History</b> for details of your spend.</p><p>We'll also email you monthly statements.</p><p>Questions? Call on <b>1300 05 7878</b>, or <b>chat</b> to us online.",
    LINK: `${HTTPS}//www.leapin.com.au/`,
    THEME: WELCOME_GUIDE_THEMES.YellowBlack,
    IMAGE_ALIGNMENT: 'Right',
    ContentType: 'html',
  },
];

export const GUIDE_ME_CONTENT_TYPE = {
  PLAIN_TEXT: 'plain-text',
  HTML: 'html',
  IMAGE: 'image',
  CUSTOM_HTML: 'custom-html',
};

export const GUIDE_ME_APP_TITLES = {
  BLUE_CARE: 'Welcome to the BlueCare NDIS Planning App',
  UCQ: 'Welcome to the UCQ NDIS Planning App',
  WESLEY: 'Welcome to the Wesley Mission NDIS Planning App',
  UCN: 'Welcome to the Uniting Care NSW NDIS Planning App',
  YOORALLA: 'Welcome to the Yooralla NSW NDIS Planning App',
};

export const WELCOME_GUIDE_ME_DUAL_BRANDED = [
  {
    TITLE: `Welcome to ${brand.BRAND_FRIENDLY_APP_NAME}`,
    Content: `As you work through the ${brand.BRAND_FRIENDLY_APP_NAME} app you can always press the Guide me tab below for help.\n\nTap on the My profile tab below to view and maintain information useful for your NDIS planning meetings and plan reviews.\n\nTap on the My budgets tab below to access features available for ${brand.BRAND_FRIENDLY_APP_NAME} plan managed members. Plan management helps you manage your NDIS money.\n\nTap on More in the top right-hand corner to view other menu options.`,
    THEME: WELCOME_GUIDE_THEMES.Red,
    IMAGE_ALIGNMENT: 'Left',
    ContentType: 'plain-text',
  },
];

export const WELCOME_GUIDE_ME_YOORALLA = [
  {
    IS_TITLE_IMAGE: true,
    CONTENT: [
      'Capture your story in',
      ' My Profile',
      ' for everything you need for your NDIS meeting!',
      "It's FREE!",
    ],
  },
  {
    TITLE: 'Powered by',
    SUB_TITLE: 'Leap in!',
    TITLE_INFO: 'NDIS Registered Plan Manager',
    CONTENT: [
      "Choose Leap in! and you'll have one place to manage your NDIS money in",
      ' My Budgets.',
    ],
    THEME: WELCOME_GUIDE_THEMES.Red,
  },
  {
    TITLE: 'Help at your',
    SUB_TITLE: 'fingertips',
    IMAGE: defaultImages.MumWithGirl,
    IMAGE_STYLE: CommonStyles.imageGuideMeYooralla,
    CONTENT: ['Tap on', ' Guide Me', ' anytime for more information.'],
    THEME: WELCOME_GUIDE_THEMES.SummerGreen,
    IMAGE_ALIGNMENT: 'Left',
  },
  {
    TITLE: 'And lots more',
    SUB_TITLE: 'to discover!',
    IMAGE: defaultImages.BoyWithiPad,
    IMAGE_STYLE: CommonStyles.imageGuideMeYooralla,
    CONTENT: [
      'Tap on',
      ' More',
      ' to see the menu and explore what else you can do!',
    ],
    THEME: WELCOME_GUIDE_THEMES.Corn,
    IMAGE_ALIGNMENT: 'Left',
  },
];

// Side Menu
export const GET_SIDEMENU = 'getSideMenu';
export const SIDEMENU_IS_OPEN = 'sideMenuIsOpen';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CURRENT_SCREEN = 'set_current_screen';
export const SIDEMENU_SELECTED_SCREEN = 'sideMenuSelectedScreen';

export const NEED_HELP = {
  NEED_HELP_TEXT: 'Need help? Call us!',
  NEED_HELP_TP_NUM: '1300 05 78 78',
};

export const BUDGET_SCREENS_FOR_NOTIFICATIONS = [
  'PaymentsHistory',
  'ApprovalsSummary',
  'ServiceAgreement',
];

// Side Menu Items for Plan Ready
export const SIDEMENU_STATUS = 'sideMenuStatus';
export const MenuItemsPlanReady = [
  {
    screen: 'PlanMeeting',
    icon: images.PlanSummaryRed3x,
    label: 'My plan summary',
  },
  {
    screen: 'UploadPersonalDetailsPM',
    params: {screenCameFrom: {type: ROUTING_STATE.HOME_SCREEN}},
    icon: images.LISignUpPlanLogo3x,
    disableIcon: images.LISignUpPlanLogo3x,
    label: 'Sign up for plan management',
  },
  {
    screen: 'PlanManagement',
    icon: images.PlanManagementOptionsRed3x,
    label: 'Plan management options',
  },
  {
    screen: 'MeetingChecklist',
    params: {url: brand.APP_CHECKLIST_URL},
    icon: images.NDISCheckListRed3x,
    label: 'NDIS meeting checklist',
  },
  {
    screen: 'Documents',
    icon: images.DocumentsRed3x,
    disableIcon: images.DocumentsRed3x,
    label: 'Documents',
  },
  {
    screen: 'Notifications',
    icon: images.MessagesRed3x,
    disableIcon: images.MessagesRed3x,
    label: 'Messages',
  },
  {
    screen: 'Settings',
    icon: images.SettingsRed3x,
    label: 'Settings',
  },
  // temporary disabling help option in secondary nav
  // {
  //   screen: 'Help',
  //   params: { url: brand.APP_HELP_URL },
  //   icon: images.HelpRedIcon3x,
  //   label: 'Help'
  // },
  {
    params: {isLogout: true},
    icon: images.Logout3x,
    label: 'Log out',
  },
];

export const MenuItemsManagedOrOnBoarding = [
  {
    screen: 'Notifications',
    icon: images.MessagesRed3x,
    disableIcon: images.MessagesRed3x,
    label: 'Messages',
  },
  {
    screen: 'PlanMeeting',
    icon: images.PlanSummaryRed3x,
    label: 'My plan summary',
  },
  {
    screen: 'PlanManagement',
    icon: images.PlanManagementOptionsRed3x,
    label: 'Plan management options',
  },
  {
    screen: 'MeetingChecklist',
    params: {url: brand.APP_CHECKLIST_URL},
    icon: images.NDISCheckListRed3x,
    label: 'NDIS meeting checklist',
  },
  {
    screen: 'Documents',
    icon: images.DocumentsRed3x,
    disableIcon: images.DocumentsRed3x,
    label: 'Documents',
  },
  {
    screen: 'Settings',
    icon: images.SettingsRed3x,
    label: 'Settings',
  },
  {
    params: {isLogout: true},
    icon: images.Logout3x,
    label: 'Log out',
  },
];

export const MenuItemsNewPlanEnabled = [
  {
    screen: 'Notifications',
    icon: images.MessagesRed3x,
    disableIcon: images.MessagesRed3x,
    label: 'Messages',
  },
  {
    screen: 'PlanMeeting',
    icon: images.PlanSummaryRed3x,
    label: 'My plan summary',
  },
  {
    screen: 'UploadPlan',
    icon: images.LISignUpPlanLogo3x,
    label: 'Set up new plan',
  },
  {
    screen: 'PlanManagement',
    icon: images.PlanManagementOptionsRed3x,
    label: 'Plan management options',
  },
  {
    screen: 'MeetingChecklist',
    params: {url: brand.APP_CHECKLIST_URL},
    icon: images.NDISCheckListRed3x,
    label: 'NDIS meeting checklist',
  },
  {
    screen: 'Documents',
    icon: images.DocumentsRed3x,
    disableIcon: images.DocumentsRed3x,
    label: 'Documents',
  },
  {
    screen: 'Settings',
    icon: images.SettingsRed3x,
    label: 'Settings',
  },
  // temporary disabling help option in secondary nav
  // {
  //   screen: 'Help',
  //   params: { url: brand.APP_HELP_URL },
  //   icon: images.HelpRedIcon3x,
  //   label: 'Help'
  // },
  {
    params: {isLogout: true},
    icon: images.Logout3x,
    label: 'Log out',
  },
];

export const MenuItemsBudget = [
  {
    screen: 'Notifications',
    icon: images.MessagesRed3x,
    disableIcon: images.MessagesRed3x,
    label: 'Messages',
    guideMePosition: 2,
  },
  {
    screen: 'ApprovalsSummary',
    icon: images.ApprovalsRed3x,
    disableIcon: images.ApprovalsRed3x,
    label: 'Approvals',
    guideMePosition: 3,
  },
  {
    screen: 'ClaimInvoice',
    icon: images.ClaimInvoiceRed3x,
    disableIcon: images.ClaimInvoiceRed3x,
    label: 'Claim invoice',
    params: {isDraftEditing: false, invoiceId: null},
    guideMePosition: 4,
  },
  {
    screen: 'DraftClaimInvoices',
    icon: images.editIcon,
    disableIcon: images.editIcon,
    label: 'Draft claims',
  },
  {
    screen: 'PaymentsHistory',
    icon: images.PaymentHistoryRed3x,
    disableIcon: images.PaymentHistoryRed3x,
    label: 'Payments history',
    guideMePosition: 5,
  },
  {
    screen: 'ServiceAgreementsList',
    icon: images.ServiceAgreement3x,
    disableIcon: images.ServiceAgreement3x,
    label: 'Service agreements',
  },
  {
    screen: 'Plans',
    icon: images.GreyClockIcon3x,
    label: 'Previous Plans',
  },
  {
    screen: 'Settings',
    icon: images.SettingsRed3x,
    label: 'Settings',
  },
  // temporary disabling help option in secondary nav
  // {
  //   screen: 'Help',
  //   params: { url: brand.APP_HELP_URL },
  //   icon: images.HelpRedIcon3x,
  //   label: 'Help'
  // },
  {
    params: {isLogout: true},
    icon: images.Logout3x,
    label: 'Log out',
  },
];

export const MenuItemsDefault = [
  {
    screen: 'CrewCreateMember',
    params: {screenMode: 'ADD_MEMBER'},
    icon: images.AddMembersRed4x,
    label: 'Add a member',
  },
  {
    screen: 'Settings',
    icon: images.SettingsRed3x,
    label: 'Settings',
  },
  // temporary disabling help option in secondary nav
  // {
  //   screen: 'Help',
  //   params: { url: brand.APP_HELP_URL },
  //   icon: images.HelpRedIcon3x,
  //   label: 'Help'
  // },
  {
    params: {isLogout: true},
    icon: images.Logout3x,
    label: 'Log out',
  },
];

export const NOTIFICATIONS = 'Notifications';
export const SIGNUP_FOR_PLAN_MANAGEMENT = 'UploadPersonalDetailsPM';
export const DOCUMENTS = 'Documents';
export const ApprovalsSummary = 'ApprovalsSummary';
export const ClaimInvoice = 'ClaimInvoice';
export const DraftClaimInvoices = 'DraftClaimInvoices';
export const PaymentsHistory = 'PaymentsHistory';
export const ServiceAgreementsList = 'ServiceAgreementsList';
export const UPLOADPLAN = 'UploadPlan';
export const PLANS = 'Plans';

// General Labels & Text
export const UNDEFINED = undefined;
export const ZERO_DURATION_TWO = '00:00';
export const ZERO_DURATION_ONE = '0:00';
export const ZERO_STRING = '0';
export const SIGN_UP = 'Sign up';
export const LEARN_MORE = 'Learn more';
export const BACK = 'Back';
export const GO_BACK = 'Go Back';
export const CLOSE = 'Close';
export const NEXT = 'Next';
export const SKIP = 'Skip';
export const EMPTY_STRING = '';
export const CLICK_HERE = 'Click here';
export const REQUIRED = 'Required';
export const EDIT = 'Edit';
export const VIEW = 'View';
export const EXIT = 'Exit';
export const DOWNLOAD = 'Download';
export const VIEW_MORE = 'View More';
export const READ = 'Read';
export const READ_SIMPLE = 'read';
export const CREW = 'Crew';
export const DEMO_PROFILE_HEADER = 'This is a demonstration profile';
export const CREATE_OWN_ACCOUNT = 'Create your own account';
export const SAVE_EXIT_BACK_WARNING =
  'This support is already saved. Please click "Skip" to proceed to the next item';
export const SEARCH_MEMBERS = 'Search [by name or NDIS number]';
export const SEARCH_PROVIDERS = 'Search providers';
export const APP_BRAND = {
  LEAPIN: 'Leap in!',
  BLUE_CARE: 'BlueCare',
  UCQ: 'UCQ',
  WESLEY: 'WMQ',
  UCN: 'UCN',
  YOORALLA: 'Yooralla',
};
export const USER_FRIENDLY_APP_NAME = {
  LEAPIN: 'Leap in!',
  BLUE_CARE: 'BlueCare',
  UCQ: 'Uniting Care Queensland',
  WESLEY: 'Wesley Mission Queensland',
  UCN: 'Uniting Care NSW',
  YOORALLA: 'Yooralla',
};

export const APP_ID = {
  LEAPIN: 'Leap in',
  BLUE_CARE: 'Blue Care',
  UCQ: 'UCQ',
  WESLEY: 'WMQ',
  UCN: 'UCN',
  YOORALLA: 'Yooralla',
};
export const APP_NAME = {
  LEAPIN: 'Leap in!',
  BLUE_CARE: 'Bluecare',
  UCQ: 'UCQ',
  WESLEY: 'WMQ',
  UCN: 'UCN',
  YOORALLA: 'Yooralla',
};

export const CREW_NO_ACCESS_MESSAGE =
  'Please select a member from the Members tab to load up a member profile for this screen.';
export const CREW_PLEASE_SELECT_MEMBER_MESSAGE =
  'Select a member to get started.';

export const ORIENTATION = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
  PORTRAITUPSIDEDOWN: 'PORTRAITUPSIDEDOWN',
};
export const LIVE_WITH_NEW_CREW = 'live_with_crew_new';
export const REMOVE_LIVE_WITH_NEW_CREW = 'remove_live_with_crew_new';

export const OFFLINE_NOTICE_SHAKE = 'offline_notice_shake';
// Currency Formatters
export const DOLLOR = '$';
export const DOT = '.';
export const MINUS = '-';
export const MINUS_NAME = 'minus';

export const OFFLINE_NOTICE_SHAKE_VALUES = {
  inputRange: [0, 0.2, 0.4, 0.6, 0.8, 0.9, 1],
  outputRange: [0, -15, 15, -15, 15, -15, 0],
};

// add profile picture screen
export const ADD_PROFILE_PIC_TITLE = 'Add profile picture';
export const ADD_CREW_PIC_TITLE = 'Add Crew profile picture';
export const DISPLAY_AVATAR_TAB_LANDSCAPE = 6;
export const DISPLAY_AVATAR_TAB_PORTRAIT = 5;
export const DISPLAY_AVATAR__PHONE_PORTRAIT = 4;
export const TAKE_PHOTO = 'Take a photo';
export const CHOOSE_FROM_LIB = 'Choose from library';
export const CHOOSE_AVATAR = 'Choose an avatar';
export const CHOOSE_FILE = 'Choose file';

// Documents screens
export const MY_DOCUMENTS = 'My Documents';
export const EDIT_DOCUMENT = 'Edit Documents';
export const ADD_DOCUMENT = 'Add Document';
export const DOCUMENT_NAME = 'Document Name';
export const DOCUMENT_CATEGORY = 'Category';
export const NO_DOCUMENTS =
  'You have no documents added yet. Click “Add" button to add.';
export const GET_SEARCH_DOCUMENT_LIST = 'search_list';
export const DOCUMENT_UPLOADED_DATE = 'Uploaded Date';
export const DOCUMENT_DOWNLOAD = ' has been downloaded';
export const DOCUMENT_DOWNLOAD_DESCRIPTION = 'File downloaded by file manager';
export const DOCUMENT_WEEKDAY = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];
export const DOCUMENT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const SEARCH_DOCUMENTS = 'Search documents';

export const ERROR = 'Error';
export const CHECKING = 'Just checking..';
export const DOWNLOAD_ERROR = 'downloading error';
export const OFFLINE = 'offline';
export const NO_INTERNET = 'No Internet Connection';
export const CONFIRM_DELETE = 'Are you sure you want to delete this file?';
export const CREW_ALREADY_EXIST =
  'Someone with this email address is already part of your crew';
export const CONFIRM_DEL = 'Yes, Delete';
export const ALERT_TITLE = 'Alert';
export const MISSING_ERROR_HANDLER = 'You are missing the error handler';
export const MEDIA_QUERY = {
  WIDE: 1000,
  LARGE: 750,
  CUT_OFF: 600,
  MEDIUM: 500,
  SMALL: 450,
};
export const THEME_SWITCH_WIDTH = 700;
export const ITEM_DIMENSION = 400;
export const ONE_COLUMN = 1;
export const TWO_COLUMN = 2;
export const THREE_COLUMN = 3;
export const FOUR_COLUMN = 4;
export const FIVE_COLUMN = 5;
export const ADDED_VIEW_HEIGHT = 50;
export const VIEW_WIDTH_OFFSET = 50;
export const PHONE_VIEW_WIDTH_OFFSET = 20;
export const SIDEBAR_WIDTH = 250;
export const CONTENT_OFFSET = 305;
export const WIDTH_OFFSET_CHANGE = 20;
export const ITEM_DIMENSION_OFFSET_SA = 80;
export const ITEM_DIMENSION_OFFSET_PH = 80;

export const OPACITY1 = 1;
export const OPACITY04 = 0.4;

export const TERMS_LINKS = {
  LEAP_IN: `${HTTPS}//www.leapin.com.au/terms-of-use/`,
  BLUE_CARE: `${HTTP}//www.leapin.com.au/terms-of-use-ucq/`,
  UCQ: `${HTTP}//www.leapin.com.au/terms-of-use-ucq/`,
  WESLEY: `${HTTP}//www.leapin.com.au/wp-content/uploads/2018/09/Leap-in-Terms-of-Use-WMQ-FINAL-200918.pdf`,
  UCN: `${HTTP}//www.leapin.com.au/terms-of-use-unitingnsw`,
  YOORALLA: `${HTTP}//www.leapin.com.au/terms-of-use-yooralla`,
};

export const PLAN_SUMMARY_ASSETS = {
  POWERED_BY_HEADER: `${HTTPS}//s3-ap-southeast-2.amazonaws.com/public-leapin-icons/plan-summary/powered+by+leapin+header1.png`,
  POWERED_BY_FOOTER: `${HTTPS}//s3-ap-southeast-2.amazonaws.com/public-leapin-icons/plan-summary/powered+by+leapin+footer.png`,
  CONNECT_WITH_US: `${HTTPS}//s3-ap-southeast-2.amazonaws.com/public-leapin-icons/plan-summary/connect+with+us.png`,
};

export const USER_TRIM_FIELDS = ['firstName', 'lastName'];
export const USER_TRIM_FIELDS_ABOUT_ME = ['firstName', 'lastName', 'apartment'];
export const CREW_TRIM_FIELDS = [
  'crewFirstName',
  'crewLastName',
  'firstName',
  'lastName',
];

export const CREW_TYPE = {
  PERSON: 'person',
  ORGANISATION: 'organisation',
};

export const ACCESSIBILITY_LABELS = {
  TAP_TO_READ: 'tap to read',
  TAP: 'Tap',
  GUIDE_ME_INFO: 'guide me information',
  TITLE: 'title',
  SCREEN_LOADING: 'Screen loading in progress',
  GO_BACK: 'Go Back',
  OF: ' of ',
  SELECTED: ' is currently selected',
  PROFILE_TAB: 'Profile tab ',
  MEMBERS_TAB: 'Members tab ',
  GUIDEME_TAB: 'Guide Me tab ',
  BUDGETS_TAB: 'Budgets tab ',
  VIEW_HIDE_PW: 'View or hide password',

  MEMBER_FULLNAME_AND_EMAIL: 'Member Fullname and Email',
  CREW_FULLNAME_AND_EMAIL: 'Crew Fullname and Email',
  MEMBER_ADDRESS: 'Member Address',
  MEMBER_HOME_PHONE: 'Member Home Phone',
  MEMBER_MOBILE_PHONE: 'Member Mobile Phone',
  CREW_MOBILE_PHONE: 'Crew Mobile Phone',
  RELATIONSHIP: 'Relationship',
  MEMBER_DISABILITIES: 'Members disabilities',
  UPDATE_DETAILS: 'Update Details',
  SEND_ENQUIRY: 'Send Enquiry',

  SEARCH_AGAIN: 'Search again',
  TYPE_MY_OWN: 'Type my own',
  MORE: 'More',
  LESS_INFO: 'Less info',
};

export const LATEST_VERSION = 'Latest version : ';
export const STAR_RATING = {
  YOUR_RATING: 'Your rating',
  NOT_RATED_YET: 'Not rated yet',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  DISMISS: 'Dismiss',
  COMMENTS_PLACEHOLDER: 'Add your comments about this service.',
};

export const ONBOARDING_GUIDE_STATUS = {
  DEFAULT: null,
  DISPLAY: 1,
  COMPLETED: 0,
};

export const GUIDE_ME_STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
  STEP_5: 5,
  STEP_6: 6,
};

export const ATTACHMENTS = 'Attachments';

export const GOAL_SCREEN_LABELS = {
  ADD_GOAL: 'Add a new goal',
  GOALS: 'Goals',
  COMPLETED_GOALS: 'Completed goals',
  COMPLETED_GOAL: 'Completed Goal',
  COMPLETED: 'Completed ',
  NO_ACTIVE_GOALS: 'You currently have no active goals.',
  NO_COMPLETED_GOALS: 'You have not completed any goals yet.',
  PREVIEW_GOAL: 'Goal Detail',
  ADD_PHOTO: 'Add a photo',
  UPDATE_PROGRESS: 'Update progress',
  DONE: "I've done it!",
  DELETE_GOAL: 'Delete goal',
  DELETE_MESSAGE:
    'Are you sure you want to delete this goal? Once deleted,' +
    ' you will not be able to undo this action.',
  NEW_GOAL: 'New goal',
  GOAL_COMPLETE: 'Goal Complete',
  COMPLETE_MESSAGE:
    "We'll add this to your completed goals.\n\nTime to add a new goal!",
  MY_GOAL_WAS: 'My Goal was to...',
  LOGGED: 'Logged ',
  GOAL_PROGRESS: 'Goal Progress',
  GOAL_TRACKER: 'Goal Tracker',
  HOW_ARE_YOU_DOING: 'How are you going',
  GOAL_PROGRESS_COMMENT_TITLE:
    'Feel free to add some information about your progress so far.',
  GOAL_PROGRESS_COMMENT_PLACEHOLDER: 'Type your thoughts here',
  UNCOMPLETE: 'Set back to active',
  VIEW_PROGRESS: 'View progress',
};

// This constant values display the goal progress on Goal Progress Comments
// and mapping between LIBE stored value
export const GOAL_PROGRESS_TYPES = {
  GREAT: {
    KEY: 'Great',
    VALUE: 'Great!',
  },
  NOT_GREAT: {
    KEY: 'Not great',
    VALUE: 'Not great!',
  },
  OK: {
    KEY: 'Ok',
    VALUE: 'OK',
  },
};

// This array is using to manipulate selected goal progress to send to LIBE &
// display selected progress at a given time
export const GOAL_PROGRESS_ARRAY = [
  GOAL_PROGRESS_TYPES.GREAT,
  GOAL_PROGRESS_TYPES.OK,
  GOAL_PROGRESS_TYPES.NOT_GREAT,
];

export const CLAIM_UNTIL = 'Claim until';
export const NO_PLANS_ERROR_CODE = '404';

export const DATE_FORMATS = {
  TYPE_ONE: 'DDD d MMM yyyy',
  TYPE_TWO: 'd MMM yyyy',
};

export const RESULT_COUNT = {
  SHOW: 'Showing ',
  OF: ' of ',
  RESULTS: ' result(s)',
};

export const COMPONENT_TYPES = {
  LINK: 'link',
  BUTTON: 'button',
};

export const MEMBER_INVOICE_PATH = 'member-invoices';

export const IS_SERVICE_AGREEMENTS_ENABLED = 'isServiceAgreementsEnabled';

export const HTTP_TYPES = ['https:', 'http:'];

export const DEEP_LINK_NAV_TYPES = {
  SA: 'ServiceAgreement',
  INVOICE: 'Invoice',
  MY_PROFILE_DETAILS: 'MyProfileDetail',
  PLAN_SUMMARY: 'PlanSummary',
  GOAL: 'Goals',
  MY_PROFILE_GUIDE_ME: 'MyProfileGuideMe',
  PLAN_MEETING_CHECKLIST: 'MeetingChecklist',
  DOCUMENTS: 'Documents',
  PLAN_MANAGEMENT_OPTIONS: 'PlanManagement',
  PROFILE_BUDGETS: 'ProfileBudgets',
  DRAFT_INVOICE: 'DraftInvoice',
  INVOICE_PAYMENT_HISTORY: 'InvoicePaymentHistory',
  PAYMENT_HISTORY: 'PaymentsHistory',
  SIGNUP_FOR_PLAN_MANAGEMENT: 'SignupForPlanManagement',
  LOGIN_PAGE: 'LoginPage',
  PREVIOUS_PLANS: 'PreviousPlans',
  UPLOAD_NEW_PLAN: 'UploadNewPlan',
  FORGOT_PASSWORD: 'ForgotPassword',
};

export const DEEP_LINK_NAV_TYPES_ARRAY = [
  'MyProfileGuideMe',
  'MyProfileDetail',
  'PlanSummary',
  'Goal',
  'MeetingChecklist',
  'Documents',
  'PlanManagement',
  'SignupForPlanManagement',
  'LoginPage',
  'ForgotPassword',
  'UploadNewPlan',
];

export const DEEP_LINK_SCREENS = {
  MY_PROFILE: 'MyProfile',
  ABOUT_ME_DETAILS_FORM: 'AboutMeMyDetailsForm',
  PLAN_MEETING: 'PlanMeeting',
  APPROVAL_SUMMARY: 'ApprovalsSummary',
  HOME: 'Home',
  GOAL: 'Goals',
  PLAN_MEETING_CHECKLIST: 'MeetingChecklist',
  FORGOT_PASSWORD: 'ForgotPassword',
  DOCUMENTS: 'Documents',
  PLAN_MANAGEMENT_OPTIONS: 'PlanManagement',
  BUDGETS: 'BudgetsPayments',
  DRAFT_INVOICE: 'DraftClaimInvoices',
  INVOICE_HISTORY: 'PaymentDetails',
  PAYMENT_HISTORY: 'PaymentsHistory',
  SIGNUP_FOR_PLAN_MANAGEMENT: 'UploadPersonalDetailsPM',
  LOGIN_PAGE: 'LoginSignUp',
  PREVIOUS_PLANS: 'Plans',
  UPLOAD_NEW_PLAN: 'UploadPlan',
};

//Web menu item lists
export const PRIMARY_TABS = {
  crew: [
    {
      id: 'Members',
      title: 'Members',
      image: PRManageMembersImage,
      selectedImage: PRManageMembersSelectedImage,
    },
    {
      id: 'Profile',
      title: 'My profile',
      image: PRProfileImage,
      selectedImage: PRProfileSelectedImage,
    },
    {
      id: 'BudgetsPayments',
      title: 'My budgets',
      image: BudgetsIconWhite,
      selectedImage: BudgetsIconRed,
    },
  ],
  members: [
    {
      id: 'Profile',
      title: 'My profile',
      image: PRProfileImage,
      selectedImage: PRProfileSelectedImage,
      guideMePosition: 3,
    },
    {
      id: 'BudgetsPayments',
      title: 'My budgets',
      image: BudgetsIconWhite,
      selectedImage: BudgetsIconRed,
      guideMePosition: 2,
    },
  ],
};

export const SECONDARY_TABS = {
  Profile: [
    {
      id: 'Notifications',
      title: 'Messages',
      image: MessagesRed3x,
      disabledForReadOnly: false,
    },
    {
      id: 'PlanMeeting',
      image: images.PlanSummaryRed3x,
      title: 'My plan summary',
      disabledForReadOnly: false,
    },
    {
      id: 'UploadPersonalDetailsPM',
      image: images.LISignUpPlanLogo3x,
      title: 'Sign up for plan management',
      disabledForReadOnly: true,
    },
    {
      id: 'PlanManagement',
      image: images.PlanManagementOptionsRed3x,
      title: 'Plan management options',
      disabledForReadOnly: false,
    },
    {
      id: 'MeetingChecklist',
      params: {url: brand.APP_CHECKLIST_URL},
      image: images.NDISCheckListRed3x,
      title: 'NDIS meeting checklist',
      disabledForReadOnly: false,
    },
    {
      id: 'Documents',
      image: images.DocumentsRed3x,
      disableIcon: images.DocumentsRed3x,
      title: 'Documents',
      disabledForReadOnly: false,
    },
  ],
  ProfileOnBoarding: [
    {
      id: 'Notifications',
      title: 'Messages',
      image: MessagesRed3x,
      disabledForReadOnly: false,
    },
    {
      id: 'PlanMeeting',
      image: images.PlanSummaryRed3x,
      title: 'My plan summary',
      disabledForReadOnly: false,
    },
    {
      id: 'PlanManagement',
      image: images.PlanManagementOptionsRed3x,
      title: 'Plan management options',
      disabledForReadOnly: false,
    },
    {
      id: 'MeetingChecklist',
      params: {url: brand.APP_CHECKLIST_URL},
      image: images.NDISCheckListRed3x,
      title: 'NDIS meeting checklist',
      disabledForReadOnly: false,
    },
    {
      id: 'Documents',
      image: images.DocumentsRed3x,
      disableIcon: images.DocumentsRed3x,
      title: 'Documents',
      disabledForReadOnly: false,
    },
  ],
  ProfilePlanEnabled: [
    {
      id: 'Notifications',
      title: 'Messages',
      image: MessagesRed3x,
      disabledForReadOnly: false,
    },
    {
      id: 'PlanMeeting',
      image: images.PlanSummaryRed3x,
      title: 'My plan summary',
      disabledForReadOnly: false,
    },
    {
      id: 'UploadPlan',
      image: images.LISignUpPlanLogo3x,
      title: 'Set up new plan',
      disabledForReadOnly: true,
    },
    {
      id: 'PlanManagement',
      image: images.PlanManagementOptionsRed3x,
      title: 'Plan management options',
      disabledForReadOnly: false,
    },
    {
      id: 'MeetingChecklist',
      params: {url: brand.APP_CHECKLIST_URL},
      image: images.NDISCheckListRed3x,
      title: 'NDIS meeting checklist',
      disabledForReadOnly: false,
    },
    {
      id: 'Documents',
      image: images.DocumentsRed3x,
      disableIcon: images.DocumentsRed3x,
      title: 'Documents',
      disabledForReadOnly: false,
    },
  ],
  BudgetsPayments: [
    {
      id: 'Notifications',
      title: 'Messages',
      image: MessagesRed3x,
      disabledForReadOnly: false,
    },
    {
      id: 'ApprovalsSummary',
      title: 'Approvals',
      image: ApprovalsRed3x,
      disabledForReadOnly: false,
    },
    {
      id: 'ClaimInvoice',
      title: 'Claim invoice',
      image: ClaimInvoiceRed3x,
      disabledForReadOnly: true,
    },
    {
      id: 'DraftClaimInvoices',
      title: 'Draft Invoice',
      image: editIcon,
      disabledForReadOnly: false,
    },
    {
      id: 'PaymentsHistory',
      title: 'Payment history',
      image: BudgetsIconRed,
      disabledForReadOnly: false,
    },
    {
      id: 'ServiceAgreementsList',
      title: 'Service agreements',
      image: MyDocumentsRed4x,
      disabledForReadOnly: false,
    },
    {
      id: 'Plans',
      title: 'Previous plans',
      image: GreyClockIcon3x,
      disabledForReadOnly: false,
    },
  ],
};

export const BUDGET_ONBOARD_DISABLED_MENU = [
  ApprovalsSummary,
  ClaimInvoice,
  PaymentsHistory,
  DraftClaimInvoices,
  ServiceAgreementsList,
  PLANS,
];

export const PROFILE_DISABLED_MENU = [
  'Notifications',
  'PlanMeeting',
  'UploadPersonalDetailsPM',
  'PlanManagement',
  'MeetingChecklist',
  'Documents',
];

export const WEB_STORE = {
  LOADED_MEMBER_ID: 'loadedMemberId',
  PREV_SCREEN: 'prevScreen',
  CREW_FORM: 'CrewForm',
  CREW_NAVIGATION_PARAMS: 'crewNavigationParams',
  SELECTE_CREW_SUPPORT: 'selectedCrewSupport',
  SUPPORT_CREW: 'supportCrew',
  SERVICE_AGREEMENTS_ENABLED: 'isServiceAgreementsEnabled',
  CREW_TYPE: 'crewType',
  CREW_STATE: 'crewState',
  CURRENT_MENU: 'currentMenu',
  NAVIGATION_PARAMS: 'navigationParams',
  AVATAR_LISTOBJ: 'avatarlistObj',
  SUPPORTS: 'Supports',
  GOALS: 'Goals',
};

export const NAV_SCREENS = {
  CREW_FORM: 'CrewForm',
};

//Navigation screen paths for web
export const routePaths = {
  Splash: {
    path: '/',
  },
  LoginSignUp: {
    path: '/LoginSignUp/:screenMode?/:enableBack?',
  },
  Welcome: {
    path: '/Welcome',
  },
  ChooseDemoProfile: {
    path: '/ChooseDemoProfile',
  },
  ChooseGuideMeType: {
    path: '/ChooseGuideMeType',
  },
  SignUp: {
    path: '/Signup',
  },
  Login: {
    path: '/login',
  },
  ChooseParticipantOrCrew: {
    path: '/ChooseParticipantOrCrew',
  },
  TermsAndConditions: {
    path: '/TermsAndConditions',
  },
  ForgotPassword: {
    path: '/ForgotPassword',
  },
  Home: {
    path: '/Home',
  },
  ProfilePictureForm: {
    path: '/Home/ProfilePictureForm',
  },
  Settings: {
    path: '/Home/Settings',
  },
  PlanMeeting: {
    path: '/Home/PlanMeeting',
  },
  PlanSummaryPdfViewer: {
    path: '/Home/PlanSummaryPdfViewer',
  },
  BudgetsPayments: {
    path: '/Home/BudgetsPayments/:navigateFrom?',
  },
  SpendingSupportItemDetail: {
    path: '/Home/SpendingSupportItemDetail/:group?/:index?',
  },
  AboutMe: {
    path: '/Home/AboutMe',
  },
  FavouriteThings: {
    path: '/Home/FavouriteThings',
  },
  HealthWellbeing: {
    path: '/Home/HealthWellbeing',
  },
  WhereILive: {
    path: '/Home/WhereILive',
  },
  AboutMeAdditionalInfoForm: {
    path: '/Home/AboutMeAdditionalInfoForm',
  },
  AboutMeLifeStageForm: {
    path: '/Home/AboutMeLifeStageForm',
  },
  AboutMeDisabilityForm: {
    path: '/Home/AboutMeDisabilityForm',
  },
  AboutMeMyDetailsForm: {
    path: '/Home/AboutMeMyDetailsForm',
  },
  FavouriteThingsFutureForm: {
    path: '/Home/FavouriteThingsFutureForm',
  },
  FavouriteThingsTodayForm: {
    path: '/Home/FavouriteThingsTodayForm',
  },
  HealthWellbeingGoingWellForm: {
    path: '/Home/HealthWellbeingGoingWellForm',
  },
  HealthWellbeingToImproveForm: {
    path: '/Home/HealthWellbeingToImproveForm',
  },
  PlanManagement: {
    path: '/Home/PlanManagement',
  },
  LearnMoreOptions: {
    path: '/Home/LearnMoreOptions',
  },
  WhereILiveHomeForm: {
    path: '/Home/WhereILiveHomeForm',
  },
  WhereILiveCrewForm: {
    path: '/Home/WhereILiveCrewForm',
  },
  WhereILiveModificationsForm: {
    path: '/Home/WhereILiveModificationsForm',
  },
  Crew: {
    path: '/Home/Crew',
  },
  CrewForm: {
    path: '/Home/CrewForm',
  },
  Documents: {
    path: '/Home/Documents',
  },
  DocumentEditForm: {
    path: '/Home/DocumentEditForm',
  },
  DocumentAddForm: {
    path: '/Home/DocumentAddForm',
  },
  ApprovalsDetailsInvoice: {
    path: '/Home/ApprovalsDetailsInvoice',
  },
  ApprovalsDetailsInvoiceReject: {
    path: '/Home/ApprovalsDetailsInvoiceReject',
  },
  ApprovalsDetailsServAgreement: {
    path: '/Home/ApprovalsDetailsServAgreement/:group?/:index?',
  },
  PaymentsHistory: {
    path: '/Home/PaymentsHistory',
  },
  PaymentDetails: {
    path: '/Home/PaymentDetails',
  },
  ApprovalsSummary: {
    path: '/Home/ApprovalsSummary',
  },
  Plans: {
    path: '/Home/Plans',
  },
  ChangePassword: {
    path: '/Home/ChangePassword',
  },
  CrewCreateMember: {
    path: '/CrewCreateMember/:screenMode?/:planType?/:signUpType?',
  },
  ReviewInvoiceSummary: {
    path: '/Home/ReviewInvoiceSummary/:isDraftInvoice?/:invoiceId?/:saveAsDraft?/:itemTotal?',
  },
  AddProvider: {
    path: '/Home/AddProvider',
  },
  ClaimInvoice: {
    path: '/Home/ClaimInvoice/:isDraftEditing?/:invoiceId?/:itemTotal?',
  },
  Notifications: {
    path: '/Home/Notifications',
  },
  ServiceAgreementsDetails: {
    path: '/Home/ServiceAgreementsDetails',
  },
  DraftClaimInvoices: {
    path: '/Home/DraftClaimInvoices',
  },
  ServiceAgreementsList: {
    path: '/Home/ServiceAgreementsList',
  },
  Members: {
    path: '/Home',
  },
  Profile: {
    path: '/Home/MyProfile/:screenCameFrom?',
  },
  AddSupportsBy: {
    path: '/Home/AddSupportsBy',
  },
  Supports: {
    path: '/Home/Supports',
  },
  Goal: {
    path: '/Home/Goals',
  },
  AddGoals: {
    path: '/Home/AddGoals/:screenMode?',
  },
  GoalPreview: {
    path: '/Home/GoalPreviewSummary',
  },
  CompletedGoal: {
    path: '/Home/CompletedGoal',
  },
  GoalProgress: {
    path: '/Home/GoalProgress',
  },
  GoalFiltersForm: {
    path: '/Home/GoalFiltersForm',
  },
  AddSupportsByCategory: {
    path: '/Home/AddSupportsByCategory',
  },
  SelectAddCrewSupport: {
    path: '/Home/SelectAddCrewSupport',
  },
  CategorySupports: {
    path: '/Home/CategorySupports/:selectedCategory?/:selectedColor?',
  },
  AddSupportDetails: {
    path: '/Home/AddSupportDetails/:edit?/:prevScreen?',
  },
  AddSupportsByCrew: {
    path: '/Home/AddSupportsByCrew/:fromScreen?/:crewSelected?',
  },
  UploadPlan: {
    path: '/Home/UploadPlan',
  },
  UploadPersonalDetailsPM: {
    path: '/Home/UploadPersonalDetailsPM',
  },
  TermsAndConditionsPM: {
    path: '/Home/TermsAndConditionsPM',
  },
  TermsAndConditionsFullPM: {
    path: '/Home/TermsAndConditionsFullPM',
  },
  MeetingChecklist: {
    path: '/Home/MeetingChecklist',
  },
  ReferralProviders: {
    path: '/Home/ReferralProviders/:category?/:region?/:saregion?',
  },
  AboutProvider: {
    path: '/Home/AboutProvider/:providerid?',
  },
  ProviderEnquiry: {
    path: '/Home/ProviderEnquiry/:providerid?',
  },
  UnderMaintenance: {
    path: '/under-maintenance',
  },
};

// Web Navigation Styles
export const WEB_LEFT_NAVIGATION_WIDTH = 280;

//Guide me web heighlight index
export const WEB_HEIGHLIGHT = {
  PLAN_READY: {
    STEP_2: 490,
    STEP_3: 110,
    STEP_4: 560,
  },
  PLAN_MANAGED: {
    STEP_1: 250,
    STEP_2: 320,
    STEP_3: 370,
    STEP_4: 410,
    STEP_5: 495,
  },
};

export const PLAN_MEETING_ACCESSIBILITY = {
  LEAPIN_LOGO: 'Leapin logo image',
  CONNECT: 'Connect With Us image',
};

export const BOOK_MARK_PARAMS = 'bookMarkParams';
export const BOOK_MARK_EXTRA_ID = 'bookMarkExtraId';
export const BOOK_MARK_INVOICE_URI = 'bookMarkInvoiceUri';
export const SHOW_BOOK_MARK_GUIDE_ME = 'showBookMarkGuideMe';
export const BOOK_MARK_PREVIOUS_PLANS = 'bookMarkPreviousPlans';
export const BOOK_MARK_SIGNUP_PM = 'bookMarkSignUpPM';
export const BOOK_MARK_SETUP_NEW_PLAN = 'bookMarkSetUpNewPlan';
export const MENUE_ID = 'menuId';
export const DEEPLINK_NAVIGATION = 'deepLinkNavigation';
export const DEEPLINK_URL = 'deepLinkUrl';

export const ENVIRONMENTS = {
  DEV: 'dev',
  QA: 'qa',
  UAT: 'uat',
};

export const DEEPLINK_URLS = {
  DEV: `${HTTPS}//leapindev.page.link/ViewOnApp`,
  QA: `${HTTPS}//leapinqa.page.link/ViewOnApp`,
  UAT: `${HTTPS}//leapinuat.page.link/ViewOnApp`,
  PROD: `${HTTPS}//leapin.page.link/ViewOnApp`,
};

export const INVOICE_NOTES = {
  MEMBER_NOTE: 'member_note',
  WORK_NOTE: 'work_note',
};

export const NAVIGATION_PRIMARY_HEADERS = ['BudgetsPayments', 'Profile'];
export const CHANGE_MY_PASSWORD_HEADER = 'ChangePassword';

export const INVOICE_ACCORDIAN_SECTIONS = {
  'Claim Approved': {
    TextColor: 'TextColor4',
    Expand: 'ExpandPurple3x',
    Collapse: 'CollapsePurple3x',
    IsExpand: true,
  },
  'Partially Approved': {
    TextColor: 'TextColor4',
    Expand: 'ExpandPurple3x',
    Collapse: 'CollapsePurple3x',
    IsExpand: true,
  },
  'Agency Rejected': {
    TextColor: 'TextColor5',
    Expand: 'ExpandCharcoal3x',
    Collapse: 'CollapseCharcoal3x',
    IsExpand: false,
  },
  Submitted: {
    TextColor: 'TextColor4',
    Expand: 'ExpandPurple3x',
    Collapse: 'CollapsePurple3x',
    IsExpand: false,
  },
  'Claim Rejected': {
    TextColor: 'TextColor5',
    Expand: 'ExpandCharcoal3x',
    Collapse: 'CollapseCharcoal3x',
    IsExpand: false,
  },
  'Member Rejected': {
    TextColor: 'TextColor5',
    Expand: 'ExpandCharcoal3x',
    Collapse: 'CollapseCharcoal3x',
    IsExpand: false,
  },
  'Needs Approval': {
    TextColor: 'TextColor3',
    Expand: 'ExpandRed3x',
    Collapse: 'CollapseRed3x',
    IsExpand: false,
  },
  Cancelled: {
    TextColor: 'TextColor5',
    Expand: 'ExpandCharcoal3x',
    Collapse: 'CollapseCharcoal3x',
    IsExpand: false,
  },
  Rejected: {
    TextColor: 'TextColor5',
    Expand: 'ExpandCharcoal3x',
    Collapse: 'CollapseCharcoal3x',
    IsExpand: false,
  },
  Refunded: {
    TextColor: 'TextColor5',
    Expand: 'ExpandCharcoal3x',
    Collapse: 'CollapseCharcoal3x',
    IsExpand: false,
  },
  ActualInvoiced: {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Pending Review': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Rejected by Operator': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Authorised by Operator': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Not Claimable': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Authorised by Scheme': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Rejected by Scheme': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Pending Disbursement': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  Paid: {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  Pending: {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  Refund: {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Pending Refund': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
  'Pending Cancellation': {
    TextColor: 'TextColor10',
    Expand: 'arrowExpandCharcoal60',
    Collapse: 'arrowCollapseCharcoal60',
    IsExpand: false,
  },
};

export const ACTUAL_INVOICED = 'ActualInvoiced';
export const BASE64_PREFIX = 'data:image/png;base64,';
export const UTCSuffix = '+0000';
export const DARK_MODE = 'dark';
export const WELCOME_SCREEN_SIGN_UP_OPTIONS = [
  {
    ...DEMO_OPTIONS_COLORS_WELCOME_SCREEN_SIGN_UP_PROFILE,
    title: 'Create an account.',
    subtitle: {
      portrait: 'Start using the app to build your profile.',
      landscape: 'Start using the app to build your profile.',
    },
    image: BrandIcons.WELCOME_PROFILE, //crew_image
    screen: 'ChooseParticipantOrCrew',
  },
  {
    ...DEMO_OPTIONS_COLORS_WELCOME_SIGN_UP,
    title: 'Sign up for plan management.',
    subtitle: {
      portrait: "I'm ready to sign up for plan management.",
      landscape: "I'm ready to sign up for plan management.",
    },
    image: BrandIcons.WELCOME_SIGN_UP, //participant_image
    link: PM_SIGN_UP_URL,
    isIcon: true,
  },
];
export const WELCOME_SCREEN_DEMO_OPTIONS = [
  // Show Me Round option Commented to enable if needed
  // {
  //   ...DEMO_OPTIONS_COLORS_WELCOME_SHOW_ME_AROUND,
  //   title: 'Show me round.',
  //   subtitle: {
  //     portrait: "I'd like to know what the Leap in! app can do.",
  //     landscape: "I'd like to know what the Leap in! app can do."
  //   },
  //   image: BrandIcons.WELCOME_SHOW_ME_AROUND, //participant_image
  //   screen: 'ChooseGuideMeType',
  //   isIcon: true
  // },
  {
    ...DEMO_OPTIONS_COLORS_WELCOME_EXPLORE,
    title: 'Explore the app.',
    subtitle: {
      portrait: 'Show me demo profiles so I can explore.',
      landscape: 'Show me demo profiles so I can explore.',
    },
    image: BrandIcons.WELCOME_EXPLORE, //participant_image
    screen: 'ChooseDemoProfile',
    isIcon: true,
  },
];

export const WELCOME_SCREEN_LOGIN_OPTION = {
  ...DEMO_OPTIONS_COLORS_WELCOME_LOGIN,
  title: 'Log in.',
  screen: 'Login',
  image: BrandIcons.WELCOME_LOGIN, //participant_image
  isSmallIcon: true,
};

export const CHOOSE_GUIDE_ME_TYPE = [
  {
    ...DEMO_OPTIONS_CHOOSE_GUIDE_ME_TYPE_NDIS_PLANING,
    username: PR_DEMO_EMAIL,
    title: 'NDIS Planning.',
    subtitle: {
      portrait: 'Show me how to prepare for my NDIS meeting with Leap in!',
      landscape: 'Show me how to prepare for my NDIS meeting with Leap in!',
    },
    image: BrandIcons.WELCOME_PROFILE, //participant_image
    content: PROFILE,
  },
  {
    ...DEMO_OPTIONS_CHOOSE_GUIDE_ME_TYPE_MG_BUDGET,
    username: PM_DEMO_EMAIL,
    title: 'Managing Budgets.',
    subtitle: {
      portrait: 'Show me how to manage my NDIS plan with Leap in!',
      landscape: 'Show me how to manage my NDIS plan with Leap in!',
    },
    image: defaultImages.BudgetsIconCharcoal, //participant_image
    content: BUDGETS,
  },
];

export const SET_DEMO_GUIDE_ME = 'setDemoGuideMe';
export const DEMO_APPROVAL_FAIL = {
  TITLE:
    'Great work, this would normally confirm submitting the claim to the NDIS',
  BODY: "But because this is a demo profile, this approval won't go anywhere. \n\nDid you know, when you sign up with Leap in! we can set you up to review or auto approve all or just some provider's claims? Just let us know your preference!",
};
export const DEMO_REFERRAL_FAIL = {
  TITLE: 'Great work, this would normally send an enquiry to the provider',
  BODY: "But because this is a demo profile, this enquiry won't go anywhere. \n\nDid you know, when you sign up with Leap in! you can get in touch with providers in your area",
};
export const IS_DEMO_PROFILE_FIRST_TIME = 'demoFirstTime';
export const SET_HIGHLIGHTED_MENU_ITEM = 'setHighlightedMenuItem';

export const About_Provider = {
  NO_IMAGE: 'No image',
  WHAT_WE_DO: 'What we do',
  EMAIL: 'Email',
  CONTACT: 'Contact',
  LOCATION: 'Location',
  WE_SUPPORT: 'We support',
  DIAGNOSES_AND_CONDITIONS_WE_SUPPORT: 'Diagnoses and conditions we support',
  SERVICE_TYPES: 'Service types',
  REGISTERED_NDIS_PROVIDER: 'Registered NDIS provider',
};

export const ABOUT_ME_MY_DETAILS_FORM = {
  MANUAL_ADDRESS_POPUP: {
    TITLE: "Couldn't find your address?",
    MESSAGE:
      'When you set your address with our auto-complete feature, we can use it to find and suggest providers near you.',
    MESSAGE2:
      "Can't find your address? No problem. You can type your own. This just means we won't be able to show you local supports.",
  },
  SEARCH_AGAIN: 'Search again',
  TYPE_MY_OWN: 'Type my own',
};

export const REFERRAL_BUTTON = {
  UPDATE_ADDRESS: 'Update Address',
  ADDRESS: 'address',
  SPENDING_SUPPORT_ITEM_DETAIL: 'SpendingSupportItemDetail',
};

export const Provider_Enquiry = {
  ENQUIRY_SENT: 'Enquiry sent',
  INCLUDE_THIS_INFO_IN_YOUR_ENQUIRY: "We'll include this info in your enquiry?",
  SELECT_EXTRA_INFORMATION_YOU_WOULD_LIKE_TO_SHARE:
    'Select extra information you would like to share',
  ENQUIRY_FROM: 'Enquiry from',
  FULL_NAME: 'Full name',
  EMAIL: 'Email',
  ON_BEHALF_OF: 'On behalf of',
  ADDRESS: 'Address',
  HOME_PHONE: 'Home Phone',
  NOT_RECORDED: 'Not recorded',
  MOBILE_PHONE: 'Mobile Phone',
  CREW: '(Crew)',
  PARTICIPANT: '(Participant)',
  RELATIONSHIP_TO: 'Relationship to',
  DISABILITIES: 'Disability',
  UPDATE_DETAILS: 'Update Details',
  SEND_ENQUIRY: 'Send Enquiry',
};

export const REFERRAL_LIST_ITEM = {
  LESS_INFO: 'Less info',
  NO_IMAGE: 'No image',
  MORE: 'More...',
  MORE_INFO: 'More info',
  EMAIL: 'Email',
  CONTACT: 'Contact',
  LOCATION: 'Location',
  WE_SUPPORT: 'We support',
  DIAGNOSES_AND_CONDITIONS_WE_SUPPORT: 'Diagnoses and conditions we support',
  SERVICE_TYPES: 'Service types',
  REGISTERED_NDIS_PROVIDER: 'Registered NDIS provider',
  ENQUIRE: 'Enquire',
};

export const XML = 'xml';

export const AVERAGE_WIDTH_PER_CHARACTER = 11.5;

export const SIGNUP_LOGIN_FLOW = {
  DEMO_PROFILE: 'Choose a demo profile.',
  WELCOME: 'Welcome',
  WELCOME_LI: 'Welcome to Leap in!',
  EXPLORE: 'I would like to explore...',
  WELCOME_TITLE: 'Welcome.',
};

export const PLAN_NEW = 'Plannew';
export const PLAN_OLD = 'Planold';

export const RETURN_KEY_TYPE = {
  NEXT: 'next',
};

export const STATUS_CODES = {
  SUCCESS: '200',
  SYSTEM_UNDER_MAINTAINED: '503',
};

export const DELETE_ACCOUNT = {
  SETTINGS_AND_INFO: 'Settings and info',
  DELETE_ALL_DATA: 'Delete all data associated with this account.',
};
