/*
 * Authour: Andrew Lee
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Crew information that has been obtained.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function GeneralReducer(
  state = {
    skipUnwantedStack: false,
    isSystemUnderMaintained: false,
    nextRef: {},
    systemUnderMaintainedMessage: {
      title: 'We’ll be back soon!',
      message:
        'Sorry for the inconvenience - we are performing some scheduled maintenance on the Leap in! app at the moment.',
      footerMessage: 'Need help now? \nCall us on',
      phoneNumber: '1300 05 78 78',
    },
  },
  action: any,
) {
  switch (action.type) {
    case types2.MARKETING_MESSAGE_SET_INDEX:
      return {
        ...state,
        marketingIndex: action.newIndex,
      };
    case types2.REFERENCE_DATA.GET_LIFE_STAGE:
      return {
        ...state,
        lifeStageReference: {
          ...state.lifeStageReference,
          lifeStages: action.lifeStageData,
          selectedLifeStages: action.selectedLifeStages,
          selectedLifeStageNames: action.selectedLifeStageNames,
          apiCalled: new Date(),
        },
      };
    case types2.REFERENCE_DATA.GET_DISABILITY:
      return {
        ...state,
        disabilitiesReference: {
          ...state.disabilitiesReference,
          disabilities: action.disabilityReference,
          apiCalled: new Date(),
        },
      };
    case types.NAVIGATE_BACK_TO_PREVIOUS_SCREEN:
      return {
        ...state,
        screenName: action.screenName,
      };
    case types.SET_CATEGORY_REFERRAL:
      return {
        ...state,
        category: action.category,
      };
    case types.SKIP_UNWANTED_STACK_SCREEN:
      return {
        ...state,
        skipUnwantedStack: action.payload,
      };
    case types.SET_FORM_NEXT_REF: 
    return {
        ...state,
        nextRef: action.ref,
     };
    case types.SYSTEM_UNDER_MAINTAINED:
      return {
        ...state,
        isSystemUnderMaintained: action.isSystemUnderMaintained,
        systemUnderMaintainedMessage: action.systemUnderMaintainedMessage
          ? action.systemUnderMaintainedMessage
          : state.systemUnderMaintainedMessage,
      };

    default:
      return state;
  }
}
