/**
 * Author: Andrew Seeley
 * Date: 14/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This shows the details for an individaul support item spend.
 */

import React, {Component} from 'react';
import {
  View,
  ScrollView,
  Text,
  Image,
  StyleSheet,
  Alert,
  Linking,
  Button,
} from 'react-native';
import {connect} from 'react-redux';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {bindActionCreators} from 'redux';

import {callAPIs} from '../API/APICaller';
import {getSupportsForBudgetCategory} from '../API/PlanDataAPI';
import {getProvidersByCategoryRegion} from '../API/ProviderAPI';
import {
  getSupportCategories,
  getSupportGroupItems,
  getBudgetCategories,
} from '../API/ReferenceAPI';

import {
  getTransactionHistory,
  getBudget,
  getDraftInvoices,
  getPlans,
} from '../API/PlanDataAPI';

import {
  StandardText,
  PlanDate,
  StandardTouchableOpacity,
  TipsAndTricks,
  StandardAlert,
  ReferralBtn,
} from '../Components/Atoms';
import {
  Container,
  SpendingPieChart,
  SimpleSpendingBar,
  SpendingBar,
  SpendingBarTitleMain,
  SpendingBarTitleSupports,
  SummaryTile,
  SupportTileItem,
  CurrencyText,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import {isTablet} from '../Helpers/PlatformSynchronizer';

import {SupportCategory} from '../Components/Organisms';
import {
  formatDDMMMYYYYDate,
  parseFormattedNumber,
  setServiceAgreementFeature,
} from '../Helpers/Utils';
import {checkFlag} from '../Helpers/featureFlagsHelpers';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  BUDGETS_AND_PAYMENTS_SCREEN as messages,
  BUDGET_SPEND_GRAPHS as graphMessages,
  REFERRAL_POPUP as referralPopup,
  MESSAGES,
  CORE_FLEXIBLE_CATEGORIES_TXT,
} from '../Constants/Messages';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';
import {RED_BUDGET, BLACK_BUDGET_TITLE} from '../Styles/SpendingBarColors';
import {
  formatAsCurrency,
  getScreenTitle,
  DifferenceInDays,
} from '../Helpers/Utils';

import {
  supportsIconCharcoal,
  viewIconCharcoal60,
  LILogo,
  InformationIconx3,
} from '../assets/images';

import {Tracking} from '../assets/images/vector-icons';

import * as SupportsActions from '../Actions/SupportsActions';
import * as BudgetActions from '../Actions/BudgetActions';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as MemberActions from '../Actions/MemberActions';
import * as UserActions from '../Actions/UserActions';
import * as ProviderActions from '../Actions/ProviderActions';
import * as GeneralActions from '../Actions/GeneralActions';
import * as EnquiryActions from '../Actions/EnquiryActions';
import * as AdminActions from '../Actions/AdminActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

// import Orientation from 'react-native-orientation-enhanced';
import DeviceInfo from 'react-native-device-info';
import {
  filterBudgetCategories,
  hasBudgetAccess,
} from '../Helpers/determineAnyBudgetAccess';
import {sumBy} from 'lodash';

const OTHER_CATEGORY = 'Other';
const CURRENT_SCREEN = 'SpendingSupportItemDetail';

const PLAN_RESULT_INDEX = {
  BUDGETS: 0,
  BUDGET_CATEGORIES: 1,
  DRAFT_INVOICES: 2,
  PLANS: 3,
};

class SpendingSupportItemDetail extends Component {
  state = {
    loading: true,
    listedProviderSupports: [],
    unlistedProviderSupports: [],
    adhocInvoicesSpentAmounts: [],
    listReferrals: [],
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    refreshing: false,
    showPopupAlert: false,
    upcomingBudgetModalVisible: false,
  };

  constructor(props: any) {
    super(props);
    this._createMainContents = this._createMainContents.bind(this);
    this._getSpendBarColors = this._getSpendBarColors.bind(this);
    this._getTitleColors = this._getTitleColors.bind(this);
    this._renderSupportSpend = this._renderSupportSpend.bind(this);
    this._renderTrackingSpend = this._renderTrackingSpend.bind(this);
    this._budgetInTolerance = this._budgetInTolerance.bind(this);
    this._debitsExists = this._debitsExists.bind(this);
    this._navigateToEdit = this._navigateToEdit.bind(this);
    this.onRefresh = this.onRefresh.bind();
    this._createAllModals = this._createAllModals.bind(this);
  }

  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({orientation});
  //     });
  //   }
  // }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({orientation});
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  componentDidMount() {
    this.setState({loading: true});
    this.props.actions.EnquiryActions.actionSetEnquiryDescription('');
    this.manipulatedBudgetWS();
  }

  componentDidUpdate(prevProps: any) {
    if (!prevProps.budget?.budget && this.props.budget?.budget) {
      this.fetchData();
    }
    if (prevProps.member !== this.props.member) {
      this.fetchProviders();
      return true;
    }
  }

  manipulatedBudgetWS() {
    // We removed the async function since its not working properly
    // so all the other function calls are nested
    if (!this.props.budget.budget) {
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      this.fetchBudgetData();
    } else {
      this.fetchData();
    }
  }

  cleanAndJoinStrings = (stringsArray?: string[]) => {
    if (!stringsArray) return '';
    const cleanedStrings = stringsArray.map(str => str.replace(/,/g, ''));
    return cleanedStrings.join(',');
  };

  fetchProviders = () => {
    let item = this._getSelectedItem();
    callAPIs(
      [
        getProvidersByCategoryRegion(
          `${this.props.member.boundries.SA3}`,
          item?.name === types.CORE_FLEXIBLE
            ? this.cleanAndJoinStrings(item?.categories)
            : item?.name,
        ),
      ],
      (data: any) => {
        if (data) {
          const obj = JSON.parse(data[0].content.body);
          this.props.actions.ProviderActions.actionSetProviderList(obj.data);
          this.setState({
            loading: false,
            refreshing: false,
          });
          if (types.isWeb) {
            this.props.setLoading(false);
          }
        }
      },
      null,
      (err: any) => {
        this.setState({
          loading: false,
          refreshing: false,
        });
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
      },
    );

    const APIArray = [];

    //Do checks for budgets first and then push to APIArray if needed
    if (this._fetchBudgets()) {
      const planKey = null;
      APIArray.push(
        getBudget(
          this.props.member.id,
          this.props.demoProfile,
          planKey,
          true,
          checkFlag(
            types.FEATURE_FLAG_KEYS.requestMultiTranche,
            this.props.admin.featureFlags,
          ),
          checkFlag(
            types.FEATURE_FLAG_KEYS.requestFlexCore,
            this.props.admin.featureFlags,
          ),
        ),
      );
      APIArray.push(getBudgetCategories());
      // TODO: Pagitionation for draft invoices
      APIArray.push(
        getDraftInvoices(
          this.props.loadedMemberId.loadedMemberId,
          // searchQuery,
          // pageNumber,
          // pageSize,
          false,
        ),
      );
    }
  };
  fetchBudgetData = () => {
    let callBudgets = false;
    // Call API to get transaction history
    const callbackFunction = (results: any) => {
      if (
        results[PLAN_RESULT_INDEX.BUDGETS].statusCode ==
        types2.NO_PLANS_ERROR_CODE
      ) {
        callBudgets = false;
      }
      if (callBudgets) {
        const budgetsPayload = results[PLAN_RESULT_INDEX.BUDGETS].content;
        // Check if Service Agreement feature is disabled from the LIBE
        if (budgetsPayload.isServiceAgreementsEnabled != null) {
          if (types.isWeb) {
            setServiceAgreementFeature(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          } else {
            this.props.actions.MemberActions.actionSetServiceAgreementEnabled(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          }
        }

        const budgetsCategories =
          results[PLAN_RESULT_INDEX.BUDGET_CATEGORIES].content;
        this.props.actions.BudgetActions.actionGetBudgets(budgetsPayload);
        this.props.actions.BudgetActions.actionGetBudgetCategories(
          filterBudgetCategories(budgetsCategories),
        );
        const draftInvoicesPayload =
          results[PLAN_RESULT_INDEX.DRAFT_INVOICES].content;
        this.props.actions.PayInvoiceActions.setDraftInvoicesCount(
          draftInvoicesPayload.length,
        );
      }

      if (results[PLAN_RESULT_INDEX.PLANS]) {
        const plans = results[PLAN_RESULT_INDEX.PLANS].content;
        this.props.actions.BudgetActions.actionAddPreviousPlans(plans);
      }
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
      this.fetchData();
    };

    const APIArray = [];

    //Do checks for budgets first and then push to APIArray if needed
    if (this._fetchBudgets()) {
      const planKey = null;
      APIArray.push(
        getBudget(
          this.props.member.id,
          this.props.demoProfile,
          planKey,
          true,
          checkFlag(
            types.FEATURE_FLAG_KEYS.requestMultiTranche,
            this.props.admin.featureFlags,
          ),
          checkFlag(
            types.FEATURE_FLAG_KEYS.requestFlexCore,
            this.props.admin.featureFlags,
          ),
        ),
      );
      APIArray.push(getBudgetCategories());
      // TODO: Pagitionation for draft invoices
      APIArray.push(
        getDraftInvoices(
          this.props.loadedMemberId.loadedMemberId,
          // searchQuery,
          // pageNumber,
          // pageSize,
          false,
        ),
      );
      APIArray.push(getPlans(this.props.member.id));

      callBudgets = true;
    }

    callAPIs(APIArray, callbackFunction, null, (error: any) => {
      this.setState({loading: false, refreshing: false});
      if (types.isWeb) {
        this.props.setLoading(false);
      }
    });
  };

  _fetchBudgets = () => {
    if (hasBudgetAccess(this.props.member)) {
      const memberObj = this.props.member;
      const userObj = this.props.user;

      // fetch budget of the selected person if logged in user is a employee
      if (
        memberObj.pmExitDate != '' &&
        (userObj.isEmployee === true || userObj.isFinancialStaff === true)
      ) {
        return true;
      }

      if (
        memberObj.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED &&
        (memberObj.planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE ||
          memberObj.planStatus === types2.PLAN_STATUS_DATA.COMPLETED)
      ) {
        return true;
      }
    }
  };

  fetchData = () => {
    const selectedItem = this._getSelectedItem();
    this.fetchProviders();
    if (!selectedItem) {
      return;
    }
    this.setState({
      providerLoading: true,
    });
    const callbackFunction = (results: any) => {
      this.setState({
        listedProviderSupports: results[0].content.supports.filter(
          (s: any) => s.description !== OTHER_CATEGORY,
        ),
        unlistedProviderSupports: results[0].content.supports.filter(
          (s: any) => s.description === OTHER_CATEGORY,
        ),
        adhocInvoicesSpentAmounts: results[0].content.adhocInvoicesSpentAmounts,
      });
      this.setState({
        loading: false,
        refreshing: false,
        providerLoading: false,
      });
      if (types.isWeb) {
        this.props.setLoading(false);
      }
    };
    this.props.actions.GeneralActions.setCategory(selectedItem?.name);
    callAPIs(
      [
        getSupportsForBudgetCategory(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          selectedItem?.categoryId,
          this.props.budget.plansObject.filter(
            (plan: any) => (plan.endDate = this.props.budget.endDate),
          )[0].planKey,
          this.props.user.demoProfile,
          true,
          selectedItem?.name === types.CORE_FLEXIBLE
            ? selectedItem?.categories
            : undefined,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({
          loading: false,
          refreshing: false,
          providerLoading: false,
        });
        if (types.isWeb) {
          this.props.setLoading(false);
        }
      },
    );
  };

  onRefresh = () => {
    this.setState({refreshing: true});
    this.manipulatedBudgetWS();
  };

  _getParams = (key: any) => {
    if (types.isWeb) {
      return this.props.navigation.getParam(key, undefined) || undefined;
    }

    const {route} = this.props;
    const params = route?.params || {};
    if (params) {
      return params[key];
    }
    return undefined;
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        modals={this._createAllModals}
        sidebarMode={types2.SIDEBAR_MODE.PLAN_MANAGEMENT}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.MY_BUDGET_DETAILS}
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  messageBody = () => {
    return (
      <View>
        <View>
          <StandardText
            style={[
              CommonStyles.font20,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
              CommonStyles.marginBottom10,
            ]}>
            {referralPopup.MESSAGE1}
          </StandardText>
        </View>
        <View>
          <StandardText
            style={[
              CommonStyles.font20,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
            ]}>
            {referralPopup.MESSAGE2}
          </StandardText>
        </View>
      </View>
    );
  };
  _renderEmptyProvidersPopup = () => {
    return (
      this.state.showPopupAlert && (
        <StandardAlert
          modalTitle={referralPopup.TITLE}
          headerStyle={[
            !types.isWeb
              ? DeviceInfo.isTablet()
                ? CommonStyles.font25
                : CommonStyles.font26
              : CommonStyles.font23,
            BrandStyles.TextColor10,
            CommonStyles.customFont,
          ]}
          close={() => this.togglePopupState(false)}
          messageComponent={this.messageBody()}
          mobileStyle={CommonStyles.emptyProviderPopup}
          tabletStyle={CommonStyles.emptyProviderPopupTablet}
          webStyle={CommonStyles.emptyProviderPopup}
          cancelBtnStyle={CommonStyles.font22}
        />
      )
    );
  };

  togglePopupState = (shown: any) => {
    this.setState({showPopupAlert: shown});
  };

  _isNdisPacePlan = () => {
    const planKey = this.props.budget?.planKey;
    const plans = this.props.budget?.plansObject;
    if (planKey && plans?.length > 0) {
      const plan = plans.find(plan => plan.planKey === planKey);
      if (plan) {
        return plan.claimingSystem === types.CLAIMING_SYSTEMS_VALUES.NDIS_PACE;
      }
    }
    return false;
  };

  getTrancheUnreleased(tranches: any) {
    if (tranches?.length) {
      return sumBy(tranches, tranche =>
        tranche.released ? 0 : parseFormattedNumber(tranche.amount),
      );
    }
    return 0;
  }

  _getSelectedItem = () => {
    const group = this._getParams('group', undefined);
    const index = this._getParams('index', undefined);

    if (this.props.budget.budget && group && index !== undefined) {
      const selectedMainCategory = this.props.budget[group];
      const selectedItem = selectedMainCategory.find((category: any) => {
        return category?.categoryId == index;
      });
      return selectedItem;
    }
    return null;
  };
  _createMainContents = () => {
    const isMultiTranche =
      checkFlag(
        types.FEATURE_FLAG_KEYS.requestMultiTranche,
        this.props.admin.featureFlags,
      ) && this._isNdisPacePlan();
    const requestFlexCore =
      checkFlag(
        types.FEATURE_FLAG_KEYS.requestFlexCore,
        this.props.admin.featureFlags,
      ) && this._isNdisPacePlan();

    let budget = this.props.budget;
    let item = this._getSelectedItem();
    if (!item) {
      return null;
    }

    if (item.budget.allocated && item.budget.allocated != null) {
      item.budget.unspent += item.budget.allocated;
      delete item.budget.allocated;
    }

    const itemBudget = item.budget;
    const sortOrder = [types2.BUDGET_KEYS.UNSPENT, types2.BUDGET_KEYS.SPENT];
    if (isMultiTranche) {
      itemBudget.unreleased = this.getTrancheUnreleased(item.tranches);
      sortOrder.push(types2.BUDGET_KEYS.UNRELEASED);
    }

    let planStartDate = budget.startDate;
    let planEndDate = budget.endDate;
    let total = parseFloat(item.budget.spent) + parseFloat(item.budget.unspent);
    if (isMultiTranche) {
      total += parseFloat(itemBudget.unreleased);
    }
    let spendBarColors = this._getSpendBarColors(item);
    let spentTitleColors = this._getTitleColors(item);
    let spendTitle = {
      totalSpendPrefix: item?.name,
      totalSpend: total,
      title: graphMessages.TOTAL_FUNDING,
      icon: item.icon.url,
      textColors: spentTitleColors,
    };
    const group = this._getParams('group', undefined);
    const index = this._getParams('index', undefined);
    const showBudgetSpendingTracker = checkFlag(
      'budgetSpendingTracker ',
      this.props.admin.featureFlags,
    );

    return (
      <ScrollView style={[CommonStyles.content, BrandStyles.primaryBgColour]}>
        <View style={[CommonStyles.spendingBarContainer]}>
          <SpendingPieChart
            isMobile={this.props.isMobile}
            viewWidth={this.props.viewWidth}
            budget={itemBudget}
            budgetColors={spendBarColors}
            titleComponentProps={spendTitle}
            titleComponentShow={true}
            displayText={true}
            showSummaryTitle={true}
            orientation={this.state.orientation}
            isTablet={isTablet}
            sortOrder={sortOrder}
            pieChartSortOrder={[
              types2.BUDGET_KEYS.SPENT,
              types2.BUDGET_KEYS.UNSPENT,
              types2.BUDGET_KEYS.UNRELEASED,
            ]}
            isMultiTranche={isMultiTranche}
            showUpcomingBudgetPopUp={this._showUpcomingBudgetPopUp}
          />
        </View>

        {requestFlexCore && item.categories?.length
          ? this._renderCoreFlexibleCategories(item)
          : null}

        {showBudgetSpendingTracker ? (
          <SummaryTile
            headerDisplayString={messages.TRACKING}
            headerIconImage={Tracking}
            screenType={types.SCREEN_TYPE_MAIN}
            containsData={false} // This will always be charcol with no button
            HeaderTextColor={BrandStyles.TextColor2}
            HeaderBoarderColor={BrandStyles.borderColor2}
            showButton={false}
            hideYellowStar={true}
            alignItemsCenter={true}
            imageIcon={false}>
            {this._renderTrackingSpend(total, item.budget.spent, planStartDate)}
          </SummaryTile>
        ) : null}

        {this._renderSupportSpend(item)}
        <ScrollView>
          {this._renderNoContentMessage()}
          {this._renderListedProviderSupports()}
          {this._renderUnlistedProviderSupportsAndAdhocInvoiceSpentAmounts()}
          {checkFlag('providerReferral', this.props.admin.featureFlags) ? (
            <React.Fragment>
              <View style={[CommonStyles.flex1]}>
                <View style={CommonStyles.dividerStylePopupModal} />
              </View>
              <ReferralBtn
                onPress={async () => {
                  await CommonActions.reset({
                    key: null,
                    index: 0,
                    routes: [{name: 'BudgetPayments'}],
                  });
                  await this.props.navigation.navigate('ReferralProviders', {
                    category: item?.name,
                    region: this.props.member.city,
                    saregion: this.props.member.boundries.SA3,
                  });
                }}
                emptyProviders={
                  this.props.providers.providerList
                    ? this.props.providers.providerList.length
                    : 0
                }
                group={group}
                index={index}
                memberData={this.props.member}
                popUp={this._renderEmptyProvidersPopup}
                togglePopup={this.togglePopupState}
                navigation={this.props.navigation}
                navigateBackToPreviousScreen={
                  this.props.actions.GeneralActions.navigateBackToPreviousScreen
                }
              />
            </React.Fragment>
          ) : null}
          {this._renderEmptyProvidersPopup()}
          {types.isWeb ? this._createUpcomingBudgetPopUp(isTablet()) : null}
        </ScrollView>
      </ScrollView>
    );
  };

  _renderCoreFlexibleCategories = (item: any) => {
    return (
      <View
        style={[
          CommonStyles.containerImportantNotification,
          CommonStyles.paddingBottom25,
        ]}>
        <Image
          style={[CommonStyles.changePasswordButtonImage]}
          source={InformationIconx3}
          accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
          accessible={true}
        />
        <View
          style={[
            BrandStyles.secondaryBgColor3,
            CommonStyles.PlanSummaryActionBox,
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.containerRoundCornersBorderless,
            CommonStyles.marginBottom10,
          ]}>
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.spendingItemSpacing,
            ]}>
            {CORE_FLEXIBLE_CATEGORIES_TXT}
          </StandardText>
          {item.categories.map((category: string, index: any) => {
            return (
              <StandardText
                key={index}
                style={[
                  CommonStyles.SummaryTileText,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                  CommonStyles.spendingItemSpacing,
                ]}>
                {`\u2022 ${category}`}
              </StandardText>
            );
          })}
        </View>
      </View>
    );
  };

  _renderNoContentMessage() {
    let unlistedProviderSupports = this.state.unlistedProviderSupports;
    let listedProviderSupports = this.state.listedProviderSupports;
    let adhocInvoicesSpentAmounts = this.state.adhocInvoicesSpentAmounts;

    if (
      listedProviderSupports.length ||
      unlistedProviderSupports.length ||
      adhocInvoicesSpentAmounts.length ||
      this.state.loading ||
      this.state.providerLoading
    ) {
      return null;
    }

    return (
      <View
        style={[
          CommonStyles.alignItemsCenter,
          CommonStyles.justifyContentCentre,
        ]}>
        <StandardText
          style={[
            CommonStyles.flex1,
            CommonStyles.font20,
            BrandStyles.TextColor5,
          ]}>
          {messages.NO_CONTENT_MESSAGE}
        </StandardText>
      </View>
    );
  }

  _renderListedProviderSupports() {
    let listedProviderSupports = this.state.listedProviderSupports;
    if (!listedProviderSupports && !listedProviderSupports.length) {
      return null;
    }

    // SA bar and SA related views commented as they are no longer in use
    return listedProviderSupports.map((provider, i) => (
      <View key={i} style={CommonStyles.ProviderSupports}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.SummaryTileHeader,
            BrandStyles.borderColor2,
          ]}>
          <StandardText
            style={[
              CommonStyles.flex07,
              CommonStyles.customFontBold,
              CommonStyles.SummaryTileHeaderText,
              CommonStyles.textAlignVerticalBottom,
              BrandStyles.TextColor5,
            ]}>
            {provider.description}
          </StandardText>
          {provider.hasOwnProperty('budget')
            ? this._renderSpent(provider.budget)
            : null}
        </View>
        {/* {provider.hasOwnProperty('budget')
          ? this._renderUsedRemaining(provider.budget)
          : null}
        {provider.hasOwnProperty('budget')
          ? this._renderSABar(provider.budget.budget)
          : null}
        {provider.hasOwnProperty('budget') && provider.budget.isSABudget
          ? this._renderViewServiceAgreementsLable()
          : null}
        {this._renderSupports(provider.supportItems)} */}
        {/* {this._renderProviderBorder(listedProviderSupports.length, i)} */}
      </View>
    ));
  }

  _createAllModals = () => {
    let modalJSX = [this._createUpcomingBudgetPopUp(isTablet())];
    return modalJSX;
  };

  _createUpcomingBudgetPopUp = (isTablet: any) => {
    const item = this._getSelectedItem();
    const tranches = item?.tranches?.filter(tranche => !tranche.released) || [];
    return (
      <BasicOverlayPopupModal
        visible={this.state.upcomingBudgetModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closeUpcomingBudgetPopUp}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}>
        <View style={[CommonStyles.flexDirectionColumn]}>
          <View>
            <StandardText
              style={[
                CommonStyles.buttonPopupOk,
                CommonStyles.customFontBold,
                CommonStyles.marginBottom10,
                CommonStyles.paddingBottom3,
              ]}>
              {graphMessages.UPCOMING_FUND_RELEASES}
            </StandardText>
          </View>
          <View
            style={[CommonStyles.flexDirectionColumn, CommonStyles.margin10]}>
            <StandardText
              accessible={true}
              accessibilityLabel={graphMessages.RELEASE_SCHEDULE}
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}>
              {`${graphMessages.RELEASE_SCHEDULE}:`}
            </StandardText>
            {tranches.length ? (
              <>
                {tranches.map((tranche: any, index: any) => {
                  return (
                    <View
                      key={index + 2}
                      accessible={true}
                      accessibilityLabel={`${formatDDMMMYYYYDate(
                        tranche.start_date,
                      )} : ${tranche.amount}`}
                      style={[
                        CommonStyles.flexDirectionRow,
                        CommonStyles.alignItemsCenter,
                        CommonStyles.marginTop5,
                        CommonStyles.marginLeft5,
                      ]}>
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          BrandStyles.TextColor5,
                          CommonStyles.customFont,
                          CommonStyles.UnreleasedBudgetPopupDate,
                        ]}>
                        {`\u2022 ${formatDDMMMYYYYDate(tranche.start_date)}:`}
                      </StandardText>
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          BrandStyles.TextColor5,
                          CommonStyles.customFont,
                        ]}>
                        {` ${tranche.amount}`}
                      </StandardText>
                    </View>
                  );
                })}
              </>
            ) : (
              <StandardText
                key={1}
                accessible={true}
                accessibilityLabel={graphMessages.ALL_BUDGETS_RELEASED}
                style={[
                  CommonStyles.SummaryTileText,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                  CommonStyles.marginTop5,
                  CommonStyles.marginLeft5,
                ]}>
                {`\u2022 ${graphMessages.ALL_BUDGETS_RELEASED}`}
              </StandardText>
            )}
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  _showUpcomingBudgetPopUp = () => {
    this.setState({upcomingBudgetModalVisible: true});
  };

  _closeUpcomingBudgetPopUp = () => {
    this.setState({upcomingBudgetModalVisible: false});
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerPMPopUpWeb]);
    } else if (isTablet) {
      styles.push([CommonStyles.containerBudgetReleasePopUpTablet]);
    } else {
      styles.push([CommonStyles.containerBudgetReleasePopUpMobile]);
    }
    return styles;
  };

  _renderUnlistedProviderSupportsAndAdhocInvoiceSpentAmounts() {
    const i = undefined;
    let unlistedProviderSupports = this.state.unlistedProviderSupports;
    let listedProviderSupports = this.state.listedProviderSupports;
    let adhocInvoicesSpentAmounts = this.state.adhocInvoicesSpentAmounts;

    if (!unlistedProviderSupports.length && !adhocInvoicesSpentAmounts.length) {
      return null;
    }

    // SA bar and SA related views commented as they are no longer in use
    return (
      <View style={[CommonStyles.ProviderSupports]}>
        {/* <View
          style={[CommonStyles.SummaryTileHeader, BrandStyles.borderColor2]}
        >
          <StandardText
            style={[
              CommonStyles.flex1,
              CommonStyles.customFontBold,
              CommonStyles.SummaryTileHeaderText,
              CommonStyles.textAlignVerticalBottom,
              BrandStyles.TextColor5
            ]}
          >
            {messages.OTHER_PROVIDERS}
          </StandardText>
        </View> */}
        {this._renderUnlistedProviderSupports(unlistedProviderSupports)}
        {this._renderAdhocInvoiceSpentAmounts(adhocInvoicesSpentAmounts)}
      </View>
    );
  }

  _renderUnlistedProviderSupports(unlistedProviderSupports: any) {
    if (!unlistedProviderSupports.length) {
      return null;
    }
    return unlistedProviderSupports[0].supportItems.map(
      (support: any, i: any) => {
        return (
          <SupportTileItem
            key={support.CurrentSupportId}
            navigateToEdit={this._navigateToEdit}
            navigation={this.props.navigation}
            support={support}
            readOnly={this.props.member.access.profile}
          />
        );
      },
    );
  }

  _renderAdhocInvoiceSpentAmounts(adhocInvoicesSpentAmounts: any) {
    const i = undefined;
    if (!adhocInvoicesSpentAmounts.length) {
      return null;
    }
    return (
      <View style={CommonStyles.ProviderSupports}>
        <View
          style={[
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.content,
            CommonStyles.AdhocInvoiceSpentAmountsLine,
            CommonStyles.flexRow,
            BrandStyles.borderColor8,
          ]}
        />
        {adhocInvoicesSpentAmounts.map((x: any) =>
          this._renderAdhocInvoiceProviderNameAndSpentAmount(x),
        )}
      </View>
    );
  }

  _renderAdhocInvoiceProviderNameAndSpentAmount(detail: any) {
    return (
      <View>
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.SABarStylesUsedRemainingStyles,
          ]}>
          <View style={[CommonStyles.justifyFlexRowStart]}>
            <View>
              <StandardText
                style={[
                  CommonStyles.flex1,
                  CommonStyles.spaceAround,
                  CommonStyles.customFontBold,
                  CommonStyles.SummaryTileHeaderText,
                  CommonStyles.textAlignVerticalBottom,
                  BrandStyles.TextColor5,
                ]}>
                {detail.SupportProvider}
              </StandardText>
            </View>
          </View>
          <View style={[CommonStyles.justifyFlexRowEnd]}>
            <View style={[CommonStyles.alignItemsFlexEnd]}>
              <StandardText
                style={[
                  CommonStyles.flex1,
                  CommonStyles.spaceAround,
                  CommonStyles.SummaryTileHeaderText,
                  CommonStyles.textAlignVerticalBottom,
                  BrandStyles.TextColor5,
                ]}>
                {`${messages.SPENT_PREFIX}  `}
                <CurrencyText
                  value={detail.Total}
                  isPositive={true}
                  noContainer={true}
                  integerStyle={[
                    CommonStyles.customFontBold,
                    BrandStyles.TextColor5,
                    CommonStyles.font20,
                  ]}
                  decimalStyle={[
                    CommonStyles.font18,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                  containerStyle={[CommonStyles.flexDirectionRow]}
                />
              </StandardText>
            </View>
          </View>
        </View>
        <View
          style={[
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.content,
            CommonStyles.AdhocInvoiceSpentAmountsLine,
            CommonStyles.flexRow,
            BrandStyles.borderColor8,
          ]}
        />
      </View>
    );
  }

  _renderProviderBorder(length: any, index: any) {
    if (index != length - 1) {
      return (
        <View
          style={[
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.content,
            CommonStyles.ProviderCategorySection,
            CommonStyles.flexRow,
            BrandStyles.borderColor8,
          ]}
        />
      );
    }
  }

  _renderSupports(supports: any) {
    if (supports.length > 0) {
      return supports.map((support: any) => {
        return (
          <SupportTileItem
            key={support.CurrentSupportId}
            navigateToEdit={this._navigateToEdit}
            navigation={this.props.navigation}
            support={support}
            readOnly={this.props.member.access.profile}
          />
        );
      });
    } else {
      return null;
    }
  }

  _renderSABar = (budget: any) => {
    return (
      <View style={[CommonStyles.SABarStyles]}>
        <SimpleSpendingBar
          budget={budget}
          budgetColors={RED_BUDGET}
          displayText={false}
          sortOrder={[types2.BUDGET_KEYS.SPENT, types2.BUDGET_KEYS.UNSPENT]}
        />
      </View>
    );
  };

  _renderSpent = (budget: any) => {
    return (
      <View style={[CommonStyles.flexDirectionRow, CommonStyles.flex03]}>
        <View style={[CommonStyles.justifyFlexRowEnd]}>
          <View style={[CommonStyles.alignItemsFlexEnd]}>
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor5,
                CommonStyles.font18,
                CommonStyles.marginRight10,
              ]}>
              {`${messages.SPENT_PREFIX}  `}
              <CurrencyText
                value={budget.budget.spent}
                isPositive={true}
                noContainer={true}
                integerStyle={[
                  CommonStyles.customFontBold,
                  BrandStyles.TextColor5,
                  CommonStyles.font20,
                ]}
                decimalStyle={[
                  CommonStyles.font18,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
                containerStyle={[CommonStyles.flexDirectionRow]}
              />
            </StandardText>
          </View>
        </View>
      </View>
    );
  };

  _renderUsedRemaining = (budget: any) => {
    return (
      <View>
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.SABarStylesUsedRemainingStyles,
          ]}>
          {budget.isSABudget && (
            <View
              style={[
                CommonStyles.justifyFlexRowEnd,
                CommonStyles.alignItemsFlexEnd,
                CommonStyles.flexRow,
              ]}>
              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  CommonStyles.font17,
                ]}>
                {`${messages.SERVICE_AGREEMENTS_ALLOCATED_TOTAL_MESSAGE}  `}
              </StandardText>
              <CurrencyText
                value={budget.budget.total}
                isPositive={true}
                integerStyle={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  CommonStyles.font22,
                ]}
                decimalStyle={[
                  CommonStyles.font15,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
                containerStyle={[CommonStyles.flexDirectionRow]}
              />
            </View>
          )}
        </View>
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.SABarStylesUsedRemainingStyles,
          ]}>
          <View
            style={[
              CommonStyles.justifyFlexColumnStart,
              CommonStyles.alignItemsFlexStart,
            ]}>
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor5,
                CommonStyles.font18,
              ]}>
              {messages.SPENT_PREFIX}
            </StandardText>
            <CurrencyText
              value={budget.budget.spent}
              isPositive={true}
              integerStyle={[
                CommonStyles.title_ListContainer,
                CommonStyles.marginRight0,
                CommonStyles.customFontSemiBold,
              ]}
              decimalStyle={[
                CommonStyles.title_ListContainerSmall,
                CommonStyles.marginRight0,
                CommonStyles.customFont,
              ]}
              containerStyle={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.customFontSemiBold,
              ]}
            />
          </View>
          {budget.isSABudget && (
            <View
              style={[
                CommonStyles.justifyFlexColumnEnd,
                CommonStyles.alignItemsFlexEnd,
              ]}>
              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  CommonStyles.font18,
                ]}>
                {messages.REMAINING}
              </StandardText>
              <CurrencyText
                value={budget.budget.unspent}
                isPositive={true}
                integerStyle={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.marginRight0,
                  CommonStyles.customFontSemiBold,
                ]}
                decimalStyle={[
                  CommonStyles.title_ListContainerSmall,
                  CommonStyles.marginRight0,
                  CommonStyles.customFont,
                ]}
                containerStyle={[
                  CommonStyles.justifyFlexRowStart,
                  CommonStyles.customFontSemiBold,
                ]}
              />
            </View>
          )}
        </View>
      </View>
    );
  };

  _renderViewServiceAgreementsLable = () => {
    return (
      <StandardTouchableOpacity
        style={[CommonStyles.justifyFlexRowEnd]}
        accessibilityLabel={messages.VIEW_SERVICE_AGREEMENTS}
        onPress={() => this.props.navigation.navigate('ServiceAgreementsList')}>
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.justifyFlexRowEnd,
            CommonStyles.spendingItemSpacing,
          ]}>
          <StandardText
            style={[
              CommonStyles.spendSupportViewText,
              CommonStyles.alignCenter,
              CommonStyles.fontWeight400,
              BrandStyles.TextColor5,
              CommonStyles.textAlignCentre,
            ]}>
            {messages.VIEW_SERVICE_AGREEMENTS}
          </StandardText>
          <Image
            style={[
              CommonStyles.spendingSupportIconView,
              CommonStyles.marginLeft5,
            ]}
            source={viewIconCharcoal60}
          />
        </View>
      </StandardTouchableOpacity>
    );
  };

  /**
   * Render the tracking spend
   * @param {Number} total The total allocated to the plan ready budget for the year
   * @param {Number} spend How much has been spent up until today
   * @param {String} planStartDate The plan start date
   */
  _renderTrackingSpend(total: any, spend: any, planStartDate: any) {
    // Calculate tracking spend
    let percentageSpent = ((spend / total) * 100).toFixed(0);
    let inTolerance = this._budgetInTolerance(total, spend, planStartDate);

    let trackingTextStyle = [
      CommonStyles.trackingTitle,
      CommonStyles.customFontBold,
      CommonStyles.fontWeight400,
    ];
    let trackingText = '';
    if (inTolerance) {
      trackingTextStyle.push(BrandStyles.textColorBudgetOnTrack);
      trackingText = messages.SPENDING_ON_TRACK;
    } else {
      trackingTextStyle.push(BrandStyles.textColorBudgetOffTrack);
      trackingText = messages.SPENDING_OFF_TRACK;
    }

    return (
      <View>
        <View
          style={[
            CommonStyles.alignFlexStart,
            CommonStyles.justifyContentFlexStart,
            CommonStyles.contentFlexWrap,
          ]}>
          <StandardText style={trackingTextStyle}>
            {percentageSpent + trackingText}
          </StandardText>
        </View>
        <TipsAndTricks showTipsAndTricks={false} />
      </View>
    );
  }

  /**
   * Calculates if the budget is within tolerence based on what is spent up until today
   * @param {Number} total The total allocated to the plan ready budget for the year
   * @param {Number} spend How much has been spent up until today
   * @param {String} planStartDate The plan start date
   */
  _budgetInTolerance(total: any, spend: any, planStartDate: any) {
    // Calculate how many days into the current plan the user is
    let startDate = new Date(planStartDate);
    let today = new Date();
    let days = DifferenceInDays(startDate, today);

    // Calculate how much budget the user has up until today, based on how much they can spend per day
    let spentPerDay = total / 365;
    let budgetSpendUntilToday = spentPerDay * days;

    // Calcualte if spend up until today is with range (Currently 15% tolerance)
    let tolerencePercent = types2.BUDGETS_TOLERENCE;
    let budgetLowerRange =
      budgetSpendUntilToday - budgetSpendUntilToday * tolerencePercent;
    let budgetUpperRange =
      budgetSpendUntilToday + budgetSpendUntilToday * tolerencePercent;

    // Return true if budget is on track, false if not
    if (spend < budgetLowerRange || spend > budgetUpperRange) {
      return false;
    } else {
      return true;
    }
  }

  _renderSupportSpend(item: any) {
    let sectionTitle = (
      <SpendingBarTitleSupports
        total={10000}
        unspent={2000}
        title={'BlueCare'}
      />
    );
    let transactions = item.transactions;
    let jsx = [];

    if (transactions != undefined) {
      for (var key in transactions) {
        if (transactions.hasOwnProperty(key)) {
          let groupTransactions = transactions[key];
          if (key != 'Other') {
            // let sectionTitle = <SpendingBarTitleSupports total={10000} unspent={2000} title={key}/>
            // jsx.push(
            //   <View key={key}>
            //     <View style={[CommonStyles.spendingBarContainer]}>
            //       <SpendingBar budget={item.budget}
            //                     budgetColors={this._getSpendBarColors(item)}
            //                     displayText={false}
            //                     titleComponent={sectionTitle}
            //         />
            //     </View>
            //     <StandardTouchableOpacity accessibilityLabel={messages.VIEW_SERVICE_AGREEMENTS} onPress={() => this._openTipsTricks()}>
            //       <View style={[CommonStyles.flexRow, CommonStyles.justifyEnd, {flex: 1}]}>
            //           <Image style={[CommonStyles.spendingSupportIconView]} source={viewIconCharcoal60} />
            //           <StandardText style={[CommonStyles.spendSupportViewText, CommonStyles.fontWeight400, BrandStyles.TextColor5]}>
            //           {messages.VIEW_SERVICE_AGREEMENTS}
            //           </StandardText>
            //       </View>
            //     </StandardTouchableOpacity>
            //   </View>
            // )
          } else {
            if (
              groupTransactions.length != undefined &&
              groupTransactions.length != 0 &&
              this._debitsExists(groupTransactions)
            ) {
              jsx.push(
                <View key={key + 'title'}>
                  <StandardText
                    style={[
                      BrandStyles.TextColor5,
                      CommonStyles.spendBarTitleSupports,
                      CommonStyles.customFontSemiBold,
                      CommonStyles.fontWeight400,
                      CommonStyles.SSIDMB10,
                    ]}>
                    {key}
                  </StandardText>
                </View>,
              );
            }
            for (let i = 0; i < groupTransactions.length; i++) {
              let transaction = groupTransactions[i];
              if (transaction.debit != undefined) {
                jsx.push(
                  <View
                    key={key}
                    style={[
                      BrandStyles.borderColor5,
                      CommonStyles.supportOtherItem,
                      CommonStyles.alignFlexStart,
                      CommonStyles.justifyContentFlexStart,
                      CommonStyles.contentFlexWrap,
                    ]}>
                    <View style={[CommonStyles.SSIDF8]}>
                      <StandardText
                        style={[
                          BrandStyles.TextColor5,
                          CommonStyles.spendSupportOtherItem,
                          CommonStyles.customFontMedium,
                          CommonStyles.fontWeight400,
                        ]}>
                        {transaction.accountName}
                      </StandardText>
                    </View>
                    <View style={[CommonStyles.SSIDF2]}>
                      <StandardText
                        accessible={true}
                        accessibilityLabel={
                          messages.SPENT_PREFIX +
                          ' ' +
                          formatAsCurrency(transaction.debit, '$')
                        }
                        style={[CommonStyles.alignFlexEnd]}>
                        <StandardText
                          style={[
                            BrandStyles.TextColor5,
                            CommonStyles.spendSupportOtherItem,
                            CommonStyles.customFont,
                            CommonStyles.fontWeight400,
                          ]}>
                          {messages.SPENT_PREFIX + ' '}
                        </StandardText>
                        <StandardText
                          style={[
                            BrandStyles.TextColor5,
                            CommonStyles.spendSupportOtherItem,
                            CommonStyles.customFontMedium,
                            CommonStyles.fontWeight400,
                          ]}>
                          {formatAsCurrency(
                            transaction.debit,
                            '$',
                            types2.DECIMAL_ACCURACY,
                          )}
                        </StandardText>
                      </StandardText>
                    </View>
                  </View>,
                );
              }
            }
          }
        }
      }
    }
    return jsx;
  }

  _debitsExists(groupTransactions: any) {
    return groupTransactions.some(function (el: any) {
      return 'Debit' in el;
    });
  }

  _openTipsTricks() {
    Linking.openURL(types2.WEBSITE_TIPS_TRICKS);
  }

  _getTitleColors(item: any) {
    return {
      budget: item.color,
      funding: item.secondaryColor,
    };
  }

  _getSpendBarColors(item: any) {
    return [
      {
        key: 'unspent',
        backgroundColor: item.color,
        textColor: BrandColors.WHITE,
      },
      {
        key: 'allocated',
        backgroundColor: item.secondaryColor,
        textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
      },
      {
        key: 'spent',
        backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
        textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
      },
      {
        key: 'unreleased',
        backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.TANGERINE,
        textColor: BrandColors.WHITE,
      },
    ];
  }

  _navigateToEdit(categoryId: any, groupId: any, support: any) {
    this.setState({isLoading: true});
    let callback = (data: any) => {
      const groups = data[0].content;
      this.props.actions.SupportsActions.actionGetSupportGroups(groups);
      this.setState({isLoading: false});
      this.props.navigation.navigate('AddSupportDetails', {
        item: support,
        edit: true,
        prevScreen: CURRENT_SCREEN,
      });
    };
    let requestBody = {
      groupId: groupId,
      categoryId: categoryId,
      searchTerm: '',
      page: 1,
      itemsPerPage: 50,
    };
    if (groupId && categoryId) {
      callAPIs([getSupportGroupItems(requestBody)], callback, null, () => {
        this.setState({loading: false});
      });
    } else {
      this.setState({isLoading: false});
      this.props.navigation.navigate('AddSupportDetails', {
        item: support,
        edit: true,
        prevScreen: CURRENT_SCREEN,
      });
    }
  }

  _getTitle() {
    return getScreenTitle(this.props.member, messages.MAIN_TITLE);
  }
}

// Warn the user if props are not passed
SpendingSupportItemDetail.propTypes = {};

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  budget: state.BudgetReducer,
  loadedMemberId: state.LoadedMemberReducer,
  supports: state.SupportsReducer,
  providers: state.ProviderReducer,
  admin: state.AdminReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    UserActions: bindActionCreators(UserActions, dispatch),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    ProviderActions: bindActionCreators(ProviderActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    EnquiryActions: bindActionCreators(EnquiryActions, dispatch),
    AdminActions: bindActionCreators(AdminActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpendingSupportItemDetail);
