/*
 * Author: Brad D'Costa
 * Date: 11/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Member API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';

//* ********************************
//*          M E M B E R
//* ********************************

/*
 * Returns a user with a given member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The user object
 */
export const getMember = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getMemberById =  await configuredAWSLamda();
      const requestBody = {
        isDemoProfile,
        id: memberId,
      };
      const functionName = 'getMemberByMemberId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getMemberById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data, true);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Updates details of the member with a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {object} requestObj The object containing the updated member details
 * @returns {Promise<object>} The member object if successful, otherwise an error object
 */
export const updateMember = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putMember =  await configuredAWSLamda();
      const requestBody = requestObj;
      requestBody.isDemoProfile = isDemoProfile;
      const functionName = 'putMember';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);

      putMember.invoke(params, (err, data) => {
        if (err) {
          logger.log('The updateMember error is:', err);
          const endTime = new Date().getTime();
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
// Get Documents

export const getDocumentList = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getDocumentList =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        isDemoProfile,
      };
      const functionName = 'getDocumentList';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getDocumentList.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

// Get upload url
/**
 * Returns a signed url that will allow the upload of a file to the storage location.
 * @async
 * @param {string} memberId The member ID of the user that the file is to be attached to.
 * @param {object} requestObj The request object that will also be sent to lambda for file upload
 * @returns {Promise<object>} The file upload url if successful, otherwise an error object
 */
export const getUploadURL = (
  memberId: any,
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    // There is an error with fetching the credentials
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getUploadURL =  await configuredAWSLamda();
      const requestBody = {id: memberId, isDemoProfile};
      for (const k in requestObj) requestBody[k] = requestObj[k];
      const functionName = 'getUploadURL';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getUploadURL.invoke(params, (err, data) => {
        if (err) {
          logger.log('The getUploadURL error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Creates a new crew member, or modifies an existing one if the object has the crew id
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {requestObj} Object that matches the Swagger definition to create or modify a crew
 * @returns {Promise<object>} The user object
 */
export const putCrewByPersonId = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // ID is an optional argument, if passed it will overwrite the exising member
      const putCrewById =  await configuredAWSLamda();
      requestObj.isDemoProfile = isDemoProfile;
      const requestBody = requestObj;
      const functionName = 'putCrewByPersonId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      putCrewById.invoke(params, (err, data) => {
        if (err) {
          logger.log('The putCrew error is:', err);
          const endTime = new Date().getTime();
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const putFileData = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      const putFileData =  await configuredAWSLamda();
      const requestBody = requestObj;
      const functionName = 'putFileInfo';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      putFileData.invoke(params, (err, data) => {
        if (err) {
          logger.log('The putFileData error is:', err);
          const endTime = new Date().getTime();
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Deletes a crew from a given object
 * @async
 * @param {string} memberId
 * @param {requestOb} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The user object
 */
export const deleteCrew = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // ID is an optional argument, if passed it will overwrite the exising member
      const deleteCrewRlnByPersonId =  await configuredAWSLamda();
      const functionName = 'deleteCrewRlnByPersonId';
      logger.log('Lambda::', functionName);
      requestObj.isDemoProfile = isDemoProfile;
      const requestBody = requestObj;
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      deleteCrewRlnByPersonId.invoke(params, (err, data) => {
        if (err) {
          logger.log('The deleteCrew error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const deleteFile = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // ID or KEY
      const deleteFile =  await configuredAWSLamda();
      const functionName = 'deleteFile';
      logger.log('Lambda::', functionName);
      const requestBody = requestObj;
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      deleteFile.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

// Get Avatar Images

export const getAvatarImages = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getAvatarImages =  await configuredAWSLamda();
      const requestBody = {id: memberId};
      const functionName = 'getAvatarImages';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getAvatarImages.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getClaimableItemsSearchRes = (
  memberId: any,
  searchQuery: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getClaimableItems =  await configuredAWSLamda();
      const requestBody = {personId: memberId, searchQuery}; // todo: change personID
      const functionName = 'getClaimableItems';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getClaimableItems.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const postMemberInvoice = (
  memberId: any,
  invoiceDetails: any,
  isDraftSubmission: any,
  invoiceId: any,
  preApproved: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const postMemberInvoice =  await configuredAWSLamda(false);
      const requestBody = {
        personId: memberId,
        isDraftSubmission: isDraftSubmission,
        invoiceId: invoiceId,
        invoiceDetails,
        preApproved,
      }; // todo: change personID
      const functionName = 'postMemberInvoice';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      postMemberInvoice.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const postMemberBiller = (
  memberId: any,
  memberBiller: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const postMemberBiller =  await configuredAWSLamda();
      const requestBody = {personId: memberId, provider: memberBiller}; // todo: change personID
      const functionName = 'postMemberBiller';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      postMemberBiller.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Send Plan Meeting html to LIBE for PDF generation
 * @param {*} payload html payload
 * @param {*} autoHandleError
 */
export const generatePDF = (
  payload: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      const generatePdfLambda =  await configuredAWSLamda();
      const functionName = 'generatePDF';
      logger.log('Lambda::', functionName);
      const requestBody = payload;
      const serviceName = AWS_LAMBDA_SERVICES.PDFGENERATION;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = new Date().getTime();

      generatePdfLambda.invoke(params, (err, data) => {
        if (err) {
          logger.log('Error occurred while generating PDF');
          reject();
        } else {
          const result = parseResult(data);
          const endTime = new Date().getTime();
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

export const getMostUsedClaimableItems = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getClaimableItems =  await configuredAWSLamda();
      const requestBody = {personId: memberId}; // todo: change personID
      const functionName = 'getMostUsedClaimableItems';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getClaimableItems.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getExistingOrganisations = (
  searchQuery: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getClaimableItems =  await configuredAWSLamda();
      const requestBody = {searchTerm: searchQuery};
      const functionName = 'getOrganizations';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getClaimableItems.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getExistingSupportItems = (
  searchQuery: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getClaimableItems =  await configuredAWSLamda();
      const requestBody = {searchTerm: searchQuery}; // todo: change personID
      const functionName = 'searchSupportItems';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getClaimableItems.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const putCrewOrganization = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // ID is an optional argument, if passed it will overwrite the exising member
      const putCrewByOrg =  await configuredAWSLamda();
      const requestBody = requestObj;
      const functionName = 'putCrewOrganization';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      putCrewByOrg.invoke(params, (err, data) => {
        if (err) {
          logger.log('The putCrew Organization error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const updateCrewOrganization = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // ID is an optional argument, if passed it will overwrite the exising member
      const putCrewByOrg =  await configuredAWSLamda();
      const requestBody = requestObj;
      const functionName = 'updateCrewOrganization';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      putCrewByOrg.invoke(params, (err, data) => {
        if (err) {
          logger.log('The updateOrg error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getFileBase64Content = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // ID is an optional argument, if passed it will overwrite the exising member
      const getFileBase64 =  await configuredAWSLamda();
      const requestBody = requestObj;
      const functionName = 'getFileBase64Content';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getFileBase64.invoke(params, (err, data) => {
        if (err) {
          logger.log('The updateOrg error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const authenticateMember = (autoHandleError = false) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const requestAuth =  await configuredAWSLamda();
      const requestBody = {};
      const functionName = 'requestAuth';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.LOYALTY;
      const params = buildLambda(requestBody, functionName, serviceName);
      requestAuth.invoke(params, (err, data) => {
        if (err) {
          logger.log('The authenticateMember error is:', err);
          const endTime = new Date().getTime();
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
