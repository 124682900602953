import React, {Component, useEffect} from 'react';
import {View} from 'react-native';
import {StandardInput, SecureStandardInput} from '../Atoms';
import {DataSharingConsentModal, FormSubtitle} from '../Molecules';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {connect} from 'react-redux';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {isTablet} from '../../Helpers/PlatformSynchronizer';
import * as GeneralActions from '../../Actions/GeneralActions';
import {bindActionCreators} from 'redux';
import logger from 'helpers/Logger';

const LoginFields = (props: any) => {
  let passwordInputRef;

  useEffect(() => {
    return () => {
      props?.actions?.GeneralActions.commonNextActionForm({})
    };
  }, []);

  return (
    <View
      style={
        (CommonStyles.flex1, CommonStyles.width100, CommonStyles.alignCenter)
      }
    >
      <View key="emailContainer" style={[CommonStyles.textInputLoginSignUp]}>
        <FormSubtitle text={['Email']} />
        <StandardInput
          onFocus={() => {
            if (isTablet()) {
              props.scrollToPosition(0, 280);
            }
          }}
          applyWrapper={false}
          key="email"
          accessibilityLabel="Email"
          value={props.email}
          onChangeText={(email: any) => {
            var emailTrimmed = email.trim();
            props.saveField(types.EMAIL, emailTrimmed);
          }}
          onSubmitEditing={() => {
            passwordInputRef?.focus();
          }}
          onRef={(ref: any) => {
           if(!props.general?.nextRef['email']){
            props?.actions?.GeneralActions.commonNextActionForm({...props.general.nextRef, email: ref });
           }
          }}
          blurOnSubmit={false}
          autoCapitalize={'none'}
          keyboardType={'email-address'}
          returnKeyType={'next'}
          style={[
            CommonStyles.marginBottom10,
            CommonStyles.ModalTextInputWrapper,
            CommonStyles.loginSignUpFieldWidth,
          ]}
          noMargin={true}
          underlineColorAndroid="transparent"
          errorMessage={props.emailError}
          textContentType= "username"
        />
      </View>
      <View key="passwordContainer" style={CommonStyles.textInputLoginSignUp}>
        <FormSubtitle text={['Password']} />
        <SecureStandardInput
          onFocus={() => {
            if (isTablet()) {
              props.scrollToPosition(0, 280);
            }
          }}
          textContentType="password"
          applyWrapper={false}
          onRef={(ref: any) => {
            passwordInputRef = ref;
          }}
          key="password"
          accessibilityLabel="Password"
          value={props.password}
          onChangeText={(password: any) =>
            props.saveField(types.PASSWORD, password)
          }
          secureTextEntry={true}
          style={[
            CommonStyles.ModalTextInputWrapper,
            props.passwordError ? CommonStyles.LoginSignUpMB10 : null,
            CommonStyles.loginSignUpFieldWidth,
          ]}
          noMargin={true}
          underlineColorAndroid="transparent"
          errorMessage={props.passwordError}
          onSubmitEditing={() =>
          props.onEditSubmit ? props.onEditSubmit() : null
          }
        />
      </View>
    </View>
  );
};
const mapStateToProps = (state: any) => ({
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
  
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFields);
