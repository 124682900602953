/*
 * Authour: Andrew Seeley
 * Date: 21/02/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the action for the general reducer, where stuff does not belong anywhere else.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionSetMarketingMessageIndex = (newIndex: any) => ({
  type: types2.MARKETING_MESSAGE_SET_INDEX,
  newIndex,
});

export const getLifeStageReferenceData = (
  lifeStageData,
  selectedLifeStages,
  selectedLifeStageNames,
) => ({
  type: types2.REFERENCE_DATA.GET_LIFE_STAGE,
  lifeStageData,
  selectedLifeStages,
  selectedLifeStageNames,
});

export const getDisabilityReferenceData = (disabilityReference: any) => ({
  type: types2.REFERENCE_DATA.GET_DISABILITY,
  disabilityReference,
});

export const navigateBackToPreviousScreen = (screenName: any) => ({
  type: types.NAVIGATE_BACK_TO_PREVIOUS_SCREEN,
  screenName,
});

export const skipUnwantedStackScreen = (payload: any) => ({
  type: types.SKIP_UNWANTED_STACK_SCREEN,
  payload,
});

export const setCategory = (category: any) => ({
  type: types.SET_CATEGORY_REFERRAL,
  category,
});

export const commonNextActionForm = (ref: any) => ({
  type: types.SET_FORM_NEXT_REF,
  ref,
});


export const systemUnderMaintain = (
  isSystemUnderMaintained,
  systemUnderMaintainedMessage,
) => ({
  type: types.SYSTEM_UNDER_MAINTAINED,
  isSystemUnderMaintained,
  systemUnderMaintainedMessage,
});
