import React, {Component} from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Image,
  Platform,
  Alert,
} from 'react-native';
import {
  RedArrow,
  arrowExpandWhite,
  UploadPurple3x,
  closeIconCharcoal60,
} from '../assets/images';
import {
  StandardText,
  StandardAlert,
  StandardInput,
  StandardTouchableOpacity,
  StandardButton,
} from '../Components/Atoms';

import {
  Container,
  CrewNoAccess,
  BottomMessageBar,
  SummaryTile,
  SummaryList,
  DatePicker,
  GridView,
  FormTitle,
} from '../Components/Molecules';
import {DocumentRestrictedPopUp} from '../Components/Organisms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as PayInvoiceActions from '../Actions/PayInvoiceActions';

import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getServiceAgreementsList} from '../API/PlanDataAPI';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  INVOICE_DETAILS_SCREEN,
  ERRORS,
  INPUT_CONTROL_ACCESSIBILITY,
} from '../Constants/Messages';
import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import {fileUploadRestrictCheck} from '../Helpers/FileHelper';

import {formatDate} from '../Helpers/Utils';


// import Orientation from 'react-native-orientation-enhanced';

import {
  RNFetchBlobSingleton,
  DocumentPickerSingleton,
  FilePickerSingleton,
  FileDownloaderSingleton,
  isTablet,
} from '../Helpers/PlatformSynchronizer';

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  INVOICE_NUMBER: 'INVOICE_NUMBER',
  INVOICE_DATE: 'INVOICE_DATE',
};

const TOP = 'top';
class InvoiceDetails extends Component {
  filePickerRef: any;
  inputs: any;
  state = {
    invoiceDetails: {
      invoiceDocPath: '',
      invoiceDate: '',
      invoiceNumber: '',
    },
    permissionAlertMessage: '',
    validInvoiceNumber: '',
    validInvoiceDate: '',
    invoiceDateBorderWidth: 0,
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    documentRestrictPoupVisible: false,
  };

  constructor(props: any) {
    super(props);
    this.inputs = {};
    this.validateAdding = this.validateAdding.bind(this);
  }
  focusNextField(nextField: any) {
    if (this.inputs[nextField].focus) {
      this.inputs[nextField].focus();
    }
  }
  render() {
    return this._createMainContents();
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      isOpen: nextProps.sideMenu ? nextProps.sideMenu.sideMenuIsOpen : false,
    });
  }
  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };
  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({orientation: orientation});
  //     });
  //   }
  // }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }
  componentDidMount() {
    this.props.onRef(this);
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
    //const { route } = this.props;
    let param; // = route.params || {};
    if(types.isWeb) {
      param = this.props.navigation.getParam('isDraftEditing', undefined) ;
     }else {
      param = this.props.route.params['isDraftEditing'] ? this.props.route.params['isDraftEditing'] : undefined;
     }
    if (param) {
      this.setState(prevState => ({
        invoiceDetails: {
          ...prevState.invoiceDetails,
          invoiceNumber: this.props.payInvoice.invoice.invoiceNumber,
          invoiceDate: this.props.payInvoice.invoice.invoiceDate,
          invoiceDocPath: this.props.payInvoice.invoice.invoiceDocPath,
        },
      }));
    }
  }

  _renderInvoiceNumber = () => {
    return (
      <View
        style={[
          CommonStyles.content,
          types.isWeb ? CommonStyles.maxWidth400 : null,
        ]}
      >
        <View style={[CommonStyles.subHeadingContainerMandotory]}>
          <FormTitle
            hasError={this.state.validInvoiceNumber}
            containsData={this.state.invoiceDetails.invoiceNumber}
            text={'Invoice Number'}
            style={[
              BrandStyles.TextColor5,
              types.isWeb
                ? CommonStyles.customFont
                : CommonStyles.customFontBold,
              CommonStyles.fontWeight400,
              CommonStyles.font20,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
          />
        </View>
        <View>
          <StandardInput
            multiline={false}
            key={'invoiceNumber'}
            accessibilityLabel="Invoice Number"
            mandatory={true}
            onSubmitEditing={() => {
              focusNextField(
                this.inputs,
                INPUTS.SCROLL_VIEW,
                INPUTS.INVOICE_DATE,
              );
            }}
            value={this.state.invoiceDetails.invoiceNumber}
            onChangeText={(invoice: any) =>
              this.setState(
                prevState => ({
                  invoiceDetails: {
                    ...prevState.invoiceDetails,
                    invoiceNumber: invoice.replace(/^\s+|\s+$/g, ''),
                  },
                }),
                () => {
                  this.props.actions.payInvoiceActions.addInvoiceAction(
                    this.state.invoiceDetails,
                  );
                },
              )
            }
            onRef={(ref: any) => {
              this.inputs[INPUTS.INVOICE_NUMBER] = ref;
            }}
            readOnly={this.props.member?.access?.finances}
            returnKeyType={'next'}
            blurOnSubmit={true}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
            defaultValue={
              this.props.payInvoice.invoice != undefined
                ? this.props.payInvoice.invoice.invoiceNumber
                : ''
            }
            errorMessage={this.state.validInvoiceNumber}
          />
        </View>
      </View>
    );
  };

  _renderInvoiceDate = () => {
    return (
      <View
        style={[
          CommonStyles.content,
          types.isWeb ? CommonStyles.zIndex02 : null,
        ]}
      >
        <View style={[CommonStyles.subHeadingContainerMandotory]}>
          <FormTitle
            text={'Invoice date'}
            hasError={this.state.validInvoiceDate}
            containsData={this.state.invoiceDetails.invoiceDate}
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              CommonStyles.fontWeight400,
              CommonStyles.font20,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
          />
        </View>
        <DatePicker
          maxDate={new Date()}
          accessibilityLabel={'invoiceDate'}
          errorBorder={this.state.invoiceDateBorderWidth}
          onRef={(ref: any) => {
            this.inputs[INPUTS.INVOICE_DATE] = ref;
          }}
          mandatory={true}
          value={this.state.invoiceDetails.invoiceDate}
          readOnly={this.props.member?.access?.finances}
          popUpPlacement={TOP}
          errorMessage={this.state.validInvoiceDate}
          inputs={this.inputs}
          //nextFocus={INPUTS.INVOICE_DATE} TODO: focus crash the
          save={(invoiceDate: any) => {
            if (
              invoiceDate.displayText !=
              types2.DATE_PICKER_PLACEHOLDER.INVOICE_DATE
            ) {
              this.setState(
                prevState => ({
                  invoiceDetails: {
                    ...prevState.invoiceDetails,
                    invoiceDate: invoiceDate,
                  },
                }),
                () =>
                  this.props.actions.payInvoiceActions.addInvoiceAction(
                    this.state.invoiceDetails,
                  ),
              );
            }
          }}
        />
      </View>
    );
  };

  _webFileUploadOnChange = (fileObject: any) => {
    const responseModified = fileObject;
    if (fileUploadRestrictCheck(fileObject.name)) {
      this.setState({documentRestrictPoupVisible: true});
    } else {
      responseModified.fileName = fileObject.name;
      responseModified.isChanged = true;
      this.setState(prevState => ({
        invoiceDetails: {
          ...prevState.invoiceDetails,
          invoiceDocPath: responseModified,
        },
      }));
      this.props.actions.payInvoiceActions.setInvoiceDoc(responseModified);
    }
  };

  _renderInvoiceUpload = () => {
    //Provide inline styles for file picker
    return (
      <FilePickerSingleton
        onRef={(ref: any) => {
          if (types.isWeb && ref) {
            this.filePickerRef = ref;
          }
        }}
        extensions={types.VALID_DOCUMENT_TYPES}
        maxSize={1000}
        style={{
          marginLeft: 10,
          backgroundColor: BrandColors.WHITE,
        }}
        onChange={(event: any) => {
          if (types.isWeb) {
            event.persist();
            if (event.target && event.target.files && event.target.files.length)
              this._webFileUploadOnChange(event.target.files[0]);
          }
        }}
        onError={(errMsg: any) => {
          this.props.actions.payInvoiceActions.setInvoiceDoc(null);
          this.props.reloading();
        }}
      >
        <StandardTouchableOpacity
          accessibilityLabel={INPUT_CONTROL_ACCESSIBILITY.UPLOAD_INVOICE}
          mandatory={true}
          style={[BrandStyles.primaryBgColor1]}
          onPress={(event: any) => {
            if (types.isWeb && this.filePickerRef) {
              this.filePickerRef.click();
            }
            this.onPick(event);
          }}
        >
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.alignCenter,
            ]}
          >
            <View
              style={[
                CommonStyles.subHeadingContainerMandotory,
                CommonStyles.flexDirectionRow,
              ]}
            >
              <View style={[CommonStyles.elevation10]}>
                <Image
                  style={[CommonStyles.UploadButtonIcon]}
                  source={UploadPurple3x}
                />
              </View>
              <FormTitle
                text={INVOICE_DETAILS_SCREEN.UPLOAD_INVOICE}
                hasError={this.state.validInvoiceUpload}
                containsData={this.state.invoiceDetails.invoiceDocPath}
                style={[
                  BrandStyles.TextColor5,
                  CommonStyles.customFontBold,
                  CommonStyles.fontWeight400,
                  CommonStyles.font20,
                  CommonStyles.marginLeft10,
                  types.isWeb
                    ? [CommonStyles.font15, CommonStyles.customFontBold]
                    : null,
                ]}
              />
            </View>
          </View>
        </StandardTouchableOpacity>
      </FilePickerSingleton>
    );
  };

  _renderUploadedDoc = () => {
    const showInLeftSide =
      (this.state.isOpen &&
        (this.state.orientation === types2.ORIENTATION.PORTRAIT ||
          this.state.orientation === types2.ORIENTATION.PORTRAITUPSIDEDOWN)) ||
      !isTablet;
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionRow,
          showInLeftSide ? CommonStyles.AlignCenter : null,
          CommonStyles.marginTop10,
          CommonStyles.paddingRight5,
          CommonStyles.alignItemsFlexStart,
          CommonStyles.uploadFileTextContainer,
        ]}
      >
        <Text
          style={[CommonStyles.uploadFileText]}
          onPress={() =>
            this._openDocument(this.state.invoiceDetails.invoiceDocPath)
          }
        >
          {this.state.invoiceDetails.invoiceDocPath != undefined
            ? this.state.invoiceDetails.invoiceDocPath.fileName
            : ''}
        </Text>
        {this._renderRemoveButton()}
      </View>
    );
  };
  _createMainContents = () => {
    return (
      <View>
        {this._renderInvoiceNumber()}
        {this._renderInvoiceDate()}
        <View style={types.isWeb ? CommonStyles.justifyFlexRowStart : null}>
          {this._renderInvoiceUpload()}
        </View>
        {this._renderUploadedDoc()}
        <View>{this._renderErrorMessage(this.state.validInvoiceUpload)}</View>
        {this._renderPermisionPopup()}
        <DocumentRestrictedPopUp
          visible={this.state.documentRestrictPoupVisible}
          close={this._closeDocumentAlert}
        />
      </View>
    );
  };

  _renderRemoveButton() {
    if (
      this.state.invoiceDetails.invoiceDocPath != undefined &&
      this.state.invoiceDetails.invoiceDocPath != ''
    ) {
      return (
        <View style={[CommonStyles.paddingLeft15, CommonStyles.paddingTop5]}>
          <StandardButton
            accessibilityLabel="Remove File"
            onPress={this.removeFile.bind(this)}
          >
            <Image
              source={closeIconCharcoal60}
              style={CommonStyles.closeIconCharcoal60Image}
            />
          </StandardButton>
        </View>
      );
    } else {
      return null;
    }
  }

  removeFile() {
    this.setState(prevState => ({
      invoiceDetails: {
        ...prevState.invoiceDetails,
        invoiceDocPath: '',
      },
    }));
    this.props.actions.payInvoiceActions.setInvoiceDoc('');
  }

  _closeDocumentAlert = () => {
    this.setState({
      documentRestrictPoupVisible: false,
    });
  };

  timeoutPicture = null;
  onPick(event: any) {
    if (Platform.OS === types2.ANDROID) {
      if (this.timeoutPicture) clearTimeout(this.timeoutPicture);
      this.timeoutPicture = setTimeout(() => this._uploadInvoice(event), 500);
    } else if (Platform.OS === types2.IOS) {
      this._uploadInvoice(event);
    }
  }

  _uploadInvoice(event: any) {
    if (this.props.member?.access?.finances == types.ACCESS_READ_ONLY) {
      return;
    }
    const pageX = event.nativeEvent ? event.nativeEvent.pageX : 0;
    const pageY = event.nativeEvent ? event.nativeEvent.pageY : 0;

    DocumentPickerSingleton(pageX, pageY).then(response => {
      response.isChanged = true;
      this.setState(prevState => ({
        invoiceDetails: {
          ...prevState.invoiceDetails,
          invoiceDocPath: response,
        },
      }));
      this.props.actions.payInvoiceActions.setInvoiceDoc(response);
    });
  }
  _closeAlertMessage = () => {
    this.setState({permissionAlertMessage: ''});
  };
  _renderPermisionPopup() {
    return (
      <StandardAlert
        modalTitle={ERRORS.GENERIC_ISSUE_ALERT_TITLE}
        close={this._closeAlertMessage}
        message={this.state.permissionAlertMessage}
      />
    );
  }
  _openDocument(document: any) {
    const fileType = document.type;
    if (Platform.OS === types2.IOS) {
      const cleanUri = decodeURI(document.uri.replace('file://', ''));
      const ios = RNFetchBlobSingleton.ios;
      ios.openDocument(cleanUri);
    } else if (Platform.OS === types2.ANDROID) {
      if (fileType === types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.uri,
          fileName: document.fileName,
        });
      } else if (types2.IMAGE_FILE_TYPES.includes(fileType)) {
        this.props.navigation.navigate('WebViewer', {
          url: document.data
            ? types2.BASE64_PREFIX + document.data
            : document.uri,
          fileName: document.fileName,
        });
      }
    } else if (types.isWeb) {
      if (document.uri) {
        window.open(document.uri);
      } else {
        FileDownloaderSingleton(document, document.fileName);
      }
    }
  }

  validateAdding = () => {
    // Need to vlidate with filed vlaidation rules
    if (this.validateFields()) {
      this.props.actions.payInvoiceActions.addInvoiceAction(
        this.state.invoiceDetails,
      );
      return true;
    }
    return false;
  };

  validateFields = () => {
    // Used to set the state for failed fields - we build it up as to only set the state once
    // If the error string is blank it means it passed validation :)
    // Additionaly it all sets them to passed if the user fixed previous validation issues
    var validateErrors = {
      invoiceNumberError: '',
      invoiceDateError: '',
      invoiceUploadError: '',
    };

    if (
      this.state.invoiceDetails.invoiceNumber == '' ||
      this.state.invoiceDetails.invoiceNumber == undefined
    ) {
      validateErrors.invoiceNumberError = types.INVOICENUMBER_NOT_ENTERED;
    }

    if (
      this.state.invoiceDetails.invoiceDate == '' ||
      this.state.invoiceDetails.invoiceDate == undefined
    ) {
      validateErrors.invoiceDateError = types.INVOICEDATE_NOT_ENTERED;
      this.setState({
        invoiceDateBorderWidth: 3,
      });
    } else {
      this.setState({
        invoiceDateBorderWidth: 0,
      });
    }

    var reLengthWithSpecialChar = /^.{1,30}$/;
    if (
      validateErrors.invoiceNumberError == '' &&
      !reLengthWithSpecialChar.test(this.state.invoiceDetails.invoiceNumber)
    ) {
      validateErrors.invoiceNumberError = types.INVOICENUMBER_INVALID_FORMAT;
    }
    if (validateErrors.invoiceDateError == '') {
      var invoiceDate = this.getParsedDate(
        this.state.invoiceDetails.invoiceDate,
      );
      var invoiceDateValue = new Date(
        invoiceDate[0],
        invoiceDate[1],
        invoiceDate[2],
      );
      var now = new Date();
      if (invoiceDateValue > now) {
        validateErrors.invoiceDateError = types.INVOICEDATE_INVALID_FORMAT;
      }
    }

    if (
      this.state.invoiceDetails.invoiceDocPath == '' ||
      this.state.invoiceDetails.invoiceDocPath == undefined
    ) {
      validateErrors.invoiceUploadError = types.INVOICEUPLOAD_NOT_ENTERED;
    }

    // Set the state to show the validation on the components
    this.setState({
      validInvoiceNumber: validateErrors.invoiceNumberError,
      validInvoiceDate: validateErrors.invoiceDateError,
      validInvoiceUpload: validateErrors.invoiceUploadError,
    });

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  getParsedDate = (date: any) => {
    var days = String(date).split('-');
    return [parseInt(days[0]), parseInt(days[1]) - 1, parseInt(days[2])];
  };

  _renderErrorMessage = (errorMessage: any) => {
    if (errorMessage != undefined) {
      if (errorMessage != '') {
        return (
          <StandardText
            accessibilityLabel={errorMessage}
            style={[
              CommonStyles.inputErrorMessageText,
              CommonStyles.customFont,
            ]}
          >
            {errorMessage}
          </StandardText>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}

const mapStateToProps = (state: any) => ({
  payInvoice: state.PayInvoiceReducer,
  sideMenu: state.SideMenuReducer,
  member: state.MemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
