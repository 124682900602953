/*
 * Author: Brad D'Costa
 * Date: 03/04/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Payments Details Screen
 */

import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import * as Images from '../assets/images';
import {InvoiceDollarPurple2x} from '../assets/images/vector-icons';
import {StandardText} from '../Components/Atoms';

import {
  Container,
  SummaryTile,
  InvoiceHeader,
  InvoiceHeaderHH,
  InvoiceList,
  InvoiceListHH,
  PaymentList,
  PaymentListHH,
  AccordianPaymentTile,
  StandardStarRating,
} from '../Components/Molecules';
import {BasicForm} from '../Components/Organisms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {callAPIs} from '../API/APICaller';
import {getInvoiceDetails, putInvoice} from '../API/PlanDataAPI';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {SCREEN_TYPE_MAIN} from '../Constants/Constants';
import {
  NAVIGATION_TEXT,
  EMPTY_STRING,
  DOLLOR,
  INVOICE_ACCORDIAN_SECTIONS,
} from '../Constants/Constants2';
import {
  PAYMENTS_DETAILS_SCREEN,
  INVOICE_DETAILS_SCREEN,
  APPROVALS_INVOICE_DETAILS,
} from '../Constants/Messages';
import {formatAsCurrency} from '../Helpers/Utils';
import {isTablet} from '../Helpers/PlatformSynchronizer';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {getValue, removeItem} from '../API/WebPersistenceStore';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

const INVOICE_DETAILS = 0;
const TYPE = '1';

class PaymentDetails extends Component {
  _closeAlert: any;
  _showAlert: any;
  state = {
    loading: true,
    loadedInvoice: {},
    invoiceDetails: {},
    showRating: false,
  };

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._renderStandardRating.bind(this);
    this._updateInvoice.bind(this);
    this._updateInvoiceNotes.bind(this);
  }

  UNSAFE_componentWillMount = () => {
    if (
      this.props.navigationParams.params === undefined &&
      getValue(types2.BOOK_MARK_INVOICE_URI) === undefined
    ) {
      this.props.navigation.goBack();
    } else {
      if (types.isWeb) {
        this.props.setLoading(true);
      }

      this._fetchInvoiceData();
    }
  };

  _fetchInvoiceData = () => {
    let loadedInvoice = this.props.navigationParams.params;
    let loadedInvoiceLink = loadedInvoice ? loadedInvoice.invoiceLink : '';

    if (getValue(types2.BOOK_MARK_INVOICE_URI)) {
      loadedInvoiceLink = getValue(types2.BOOK_MARK_INVOICE_URI);
      removeItem(types2.BOOK_MARK_INVOICE_URI);
    }

    // Get the invoice details
    const dataCallback = (data: any) => {
      const invoiceDetails = data[INVOICE_DETAILS].content;

      // This is to cater invoice details load via deeplinking.
      // Because at this time navigationParams are not avaialable
      if (types.isWeb && loadedInvoice === undefined) {
        loadedInvoice = {
          biller: invoiceDetails.biller,
          invoiceDate: invoiceDetails.invoiceDate,
          invoiceKey: invoiceDetails.invoiceKey,
          invoiceLink: loadedInvoiceLink,
          invoiceNumber: invoiceDetails.invoiceNumber,
          providerFriendlyName: invoiceDetails.providerFriendlyName,
          submittedDate: invoiceDetails.createdDate,
          total: invoiceDetails.total,
        };
      }

      if (types.isWeb) {
        this.props.setLoading(false);
      }

      this.setState({
        loading: false,
        invoiceDetails,
        loadedInvoice,
        ratingComment: invoiceDetails.ratingInfo.comment,
        ratingValue: invoiceDetails.ratingInfo.rating,
        ratingId: invoiceDetails.ratingInfo.id,
        showRating: invoiceDetails.showRating,
      });
    };

    callAPIs(
      [
        getInvoiceDetails(
          this.props.loadedMemberId.loadedMemberId,
          loadedInvoiceLink,
          this.props.user.demoProfile,
        ),
      ],
      dataCallback,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false});
      },
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        screenType={SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={NAVIGATION_TEXT.PAYMENT_DETAILS}
        needsSideBar={false}
        toggleMenu={
          this.props.screenProps ? this.props.screenProps?.toggleMenu : null
        }
        getInitialMenuState={
          this.props.screenProps
            ? this.props.screenProps?.getInitialMenuState
            : null
        }
        ignorePadding={false}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _renderVerifiedName = (friendlyName: any, billerName: any) => {
    if (friendlyName && friendlyName !== billerName) {
      return (
        <View style={[CommonStyles.endContent, CommonStyles.paddingBottom10]}>
          <StandardText
            style={[CommonStyles.textProvider, BrandStyles.TextColor10]}
          >
            {PAYMENTS_DETAILS_SCREEN.BILL_TO + billerName}
          </StandardText>
        </View>
      );
    }
  };

  _renderInvoiceHeader = () => {
    if (this.props.member.isHedgehog) {
      return (
        <InvoiceHeaderHH
          isMobile={this.props.isMobile}
          viewWidth={this.props.viewWidth}
          memberReducer={this.props.member}
          loadedMemberId={this.props.loadedMemberId}
          userReducer={this.props.user}
          invoiceDetails={this.state.invoiceDetails}
          updateNotes={(invoiceNotes: any, noteType: any) =>
            this._updateInvoiceNotes(invoiceNotes, noteType)
          }
          loadedInvoice={this.state.loadedInvoice}
          loading={(load: any) => {
            if (types.isWeb) {
              this.props.setLoading(load);
            }
            this.setState({loading: load});
          }}
          navigation={this.props.navigation}
          fetchInvoiceData={this._fetchInvoiceData}
        />
      );
    } else {
      return (
        <InvoiceHeader
          isMobile={this.props.isMobile}
          viewWidth={this.props.viewWidth}
          memberReducer={this.props.member}
          loadedMemberId={this.props.loadedMemberId}
          userReducer={this.props.user}
          invoiceDetails={this.state.invoiceDetails}
          updateNotes={(invoiceNotes: any, noteType: any) =>
            this._updateInvoiceNotes(invoiceNotes, noteType)
          }
          loadedInvoice={this.state.loadedInvoice}
          loading={(load: any) => {
            if (types.isWeb) {
              this.props.setLoading(load);
            }
            this.setState({loading: load});
          }}
          navigation={this.props.navigation}
          fetchInvoiceData={this._fetchInvoiceData}
        />
      );
    }
  };

  getBillerName() {
    if (
      this.state.invoiceDetails.reimbursementAccount &&
      this.state.invoiceDetails.reimbursementAccount.friendlyName
    ) {
      return this.state.invoiceDetails.reimbursementAccount.friendlyName;
    } else if (
      this.state.invoiceDetails.biller &&
      this.state.invoiceDetails.biller.name
    ) {
      return this.state.invoiceDetails.biller.name;
    }
    return EMPTY_STRING;
  }

  _createMainContents = () => {
    const billerName = this.getBillerName();
    const providerFriendlyName = this.state.invoiceDetails.providerFriendlyName;
    return (
      <View style={CommonStyles.content}>
        <BasicForm
          hideTitle={true}
          disableMore={true}
          readOnly={this.props.member?.access?.finances}
          contentWidthFull={true}
        >
          <View style={CommonStyles.containerInvoice_Summary}>
            <SummaryTile
              headerDisplayString={
                providerFriendlyName ? providerFriendlyName : billerName
              }
              headerIconImage={InvoiceDollarPurple2x}
              HeaderTextColor={BrandStyles.TextColor4}
              HeaderBoarderColor={BrandStyles.borderColor4}
              hideYellowStar={true}
              showButton={true}
              showButtonTitle={types.ACTION_BUTTON.UPDATE_NOTES}
              modal={this._updateInvoice}
              fontSize={CommonStyles.rpfont20}
              disableMore={true}
              containsData={true}
            />
          </View>

          <View style={[CommonStyles.containerInvoice_Summary]}>
            {this._renderVerifiedName(providerFriendlyName, billerName)}
            {this._renderStandardRating()}
            {this._renderInvoiceHeader()}
          </View>

          {/* Rendering the accordian section */}
          {this._renderAccordians(
            this.state.invoiceDetails.groupedInvoiceClaims,
          )}
        </BasicForm>
      </View>
    );
  };

  _renderAccordians = (groupedInvoiceClaims: any) => {
    if (groupedInvoiceClaims) {
      return Object.keys(groupedInvoiceClaims).map(key => {
        return this._renderAccordian(
          this._generateAccordianTitle(key),
          groupedInvoiceClaims[key],
          INVOICE_ACCORDIAN_SECTIONS[key].IsExpand,
          BrandStyles[INVOICE_ACCORDIAN_SECTIONS[key].TextColor],
          {
            expand: Images[INVOICE_ACCORDIAN_SECTIONS[key].Expand],
            collapse: Images[INVOICE_ACCORDIAN_SECTIONS[key].Collapse],
          },
        );
      });
    }
  };

  _renderStandardRating = () => {
    if (this.state.loadedInvoice !== undefined && this.state.showRating) {
      var item = this.state.invoiceDetails;
      return (
        <StandardStarRating
          type={TYPE}
          providerKey={item.biller.billerKey}
          providerName={item.biller.name}
          itemKey={item.invoiceKey}
          itemNumber={item.invoiceNumber}
          ratingComment={this.state.ratingComment}
          ratingValue={this.state.ratingValue}
          showRating={this.state.showRating}
          ratingId={this.state.ratingId}
          setLoading={(loading: any) => {
            if (types.isWeb) {
              this.props.setLoading(loading);
            }
          }}
        />
      );
    }
  };

  _renderAccordian = (
    title: any,
    data: any,
    expandedDefault: any,
    buttonColor: any,
    icons: any,
  ) => {
    if (data && data.length > 0) {
      return (
        <AccordianPaymentTile
          title={title}
          openByDefault={expandedDefault}
          buttonColor={buttonColor}
          icons={icons}
        >
          <View
            style={
              types.isWeb
                ? CommonStyles.containerInvoice_CardsWeb
                : CommonStyles.containerInvoice_Cards
            }
          >
            {this._renderDetailList(data, title)}
          </View>
        </AccordianPaymentTile>
      );
    }

    return null;
  };

  _renderDetailList = (data: any, title: any) => {
    let viewTablet = false;
    //Change detail view format based on view width
    if (types.isWeb) {
      if (this.props.viewWidth > types2.THEME_SWITCH_WIDTH + 200) {
        viewTablet = true;
      }
    } else {
      if (isTablet()) {
        viewTablet = true;
      }
    }
    if (viewTablet) {
      return this.props.member.isHedgehog ? (
        <PaymentListHH
          itemCode={PAYMENTS_DETAILS_SCREEN.INVOICE_COL1}
          itemDesc={PAYMENTS_DETAILS_SCREEN.COLUMNS.DESCRIPTION}
          itemCategory={PAYMENTS_DETAILS_SCREEN.COLUMNS.CATEGORY}
          itemQty={PAYMENTS_DETAILS_SCREEN.COLUMNS.QUANTITY}
          itemUnitPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.UNIT_PRICE}
          itemTotalPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_REQUESTED}
          itemFundedPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_FUNDED}
          itemDate={PAYMENTS_DETAILS_SCREEN.COLUMNS.DATE}
          dateRange={PAYMENTS_DETAILS_SCREEN.DATE_RANGE}
          data={data}
          section={title}
          actualInvoiced={APPROVALS_INVOICE_DETAILS.ACTUAL_INVOICED.show}
          actualInvoicedHide={APPROVALS_INVOICE_DETAILS.ACTUAL_INVOICED.hide}
          itemClaimedQty={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.CLAIMED_QUANTITY}
          itemFundedTotal={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.FUNDED_TOTAL}
          itemClaimedPrice={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.CLAIMED_PRICE}
          itemClaimedTotal={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.CLAIMED_TOTAL}
          itemInvoicedQuantity={
            APPROVALS_INVOICE_DETAILS.COLUMNS_HH.INVOICED_QUANTITY
          }
          itemInvoicedPrice={
            APPROVALS_INVOICE_DETAILS.COLUMNS_HH.INVOICED_PRICE
          }
          itemInvoicedTotal={
            APPROVALS_INVOICE_DETAILS.COLUMNS_HH.INVOICED_TOTAL
          }
        />
      ) : (
        <PaymentList
          itemCode={PAYMENTS_DETAILS_SCREEN.INVOICE_COL1}
          itemDesc={PAYMENTS_DETAILS_SCREEN.COLUMNS.DESCRIPTION}
          itemCategory={PAYMENTS_DETAILS_SCREEN.COLUMNS.CATEGORY}
          itemQty={PAYMENTS_DETAILS_SCREEN.COLUMNS.QUANTITY}
          itemUnitPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.UNIT_PRICE}
          itemTotalPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_REQUESTED}
          itemFundedPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_FUNDED}
          itemDate={PAYMENTS_DETAILS_SCREEN.COLUMNS.DATE}
          dateRange={PAYMENTS_DETAILS_SCREEN.DATE_RANGE}
          data={data}
          section={title}
        />
      );
    } else {
      return this.props.member.isHedgehog ? (
        <InvoiceListHH
          itemCode={PAYMENTS_DETAILS_SCREEN.COLUMNS.ITEM_CODE}
          itemDesc={PAYMENTS_DETAILS_SCREEN.COLUMNS.DESCRIPTION}
          itemCategory={PAYMENTS_DETAILS_SCREEN.COLUMNS.CATEGORY}
          itemQty={PAYMENTS_DETAILS_SCREEN.COLUMNS.QUANTITY}
          itemUnitPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.UNIT_PRICE}
          itemTotalPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_REQUESTED}
          itemFundedPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_FUNDED}
          itemDate={PAYMENTS_DETAILS_SCREEN.COLUMNS.DATE}
          data={data}
          section={title}
          actualInvoiced={APPROVALS_INVOICE_DETAILS.ACTUAL_INVOICED.show}
          actualInvoicedHide={APPROVALS_INVOICE_DETAILS.ACTUAL_INVOICED.hide}
          itemClaimedQty={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.CLAIMED_QUANTITY}
          itemFundedTotal={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.FUNDED_TOTAL}
          itemClaimedPrice={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.CLAIMED_PRICE}
          itemClaimedTotal={APPROVALS_INVOICE_DETAILS.COLUMNS_HH.CLAIMED_TOTAL}
          itemInvoicedQuantity={
            APPROVALS_INVOICE_DETAILS.COLUMNS_HH.INVOICED_QUANTITY
          }
          itemInvoicedPrice={
            APPROVALS_INVOICE_DETAILS.COLUMNS_HH.INVOICED_PRICE
          }
          itemInvoicedTotal={
            APPROVALS_INVOICE_DETAILS.COLUMNS_HH.INVOICED_TOTAL
          }
        />
      ) : (
        <InvoiceList
          itemCode={PAYMENTS_DETAILS_SCREEN.COLUMNS.ITEM_CODE}
          itemDesc={PAYMENTS_DETAILS_SCREEN.COLUMNS.DESCRIPTION}
          itemCategory={PAYMENTS_DETAILS_SCREEN.COLUMNS.CATEGORY}
          itemQty={PAYMENTS_DETAILS_SCREEN.COLUMNS.QUANTITY}
          itemUnitPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.UNIT_PRICE}
          itemTotalPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_REQUESTED}
          itemFundedPrice={PAYMENTS_DETAILS_SCREEN.COLUMNS.TOTAL_FUNDED}
          itemDate={PAYMENTS_DETAILS_SCREEN.COLUMNS.DATE}
          data={data}
          section={title}
        />
      );
    }
  };

  _generateAccordianTitle(titleText: any) {
    return `${titleText}`;
  }

  _calculateClaimsTotal(claims: any) {
    if (!claims || !claims.length) {
      return '';
    }
    const claimTotal = claims
      .map((x: any) => x.total)
      .reduce((acc: any, val: any) => acc + val);
    return `${DOLLOR}${formatAsCurrency(claimTotal, EMPTY_STRING, 2)}`;
  }

  _updateInvoice = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }
    const {invoiceDetails} = this.state;
    const {loadedMemberId} = this.props;
    const invoiceKey = this.props.navigationParams.params.invoiceKey;
    const putInvoiceCallbackFunction = (data: any) => {
      if (parseInt(data[0].statusCode) == 200) {
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
      }
    };

    const putInvoiceErrorCallbackFunction = (err: any, reject: any) => {
      if (err) {
        this._showAlert(
          types2.ERROR,
          INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
          types2.OK,
          this._closeAlert,
          true,
        );
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
        this.setState({
          saveApiErrorCode: err.code,
          saveApiError: true,
        });
      }
    };

    callAPIs(
      [
        putInvoice(
          loadedMemberId.loadedMemberId,
          invoiceKey,
          invoiceDetails,
          false,
        ),
      ],
      putInvoiceCallbackFunction,
      putInvoiceErrorCallbackFunction,
    );
  };

  _updateInvoiceNotes(note: any, type: any) {
    const {invoiceDetails} = this.state;
    if (type === types2.INVOICE_NOTES.MEMBER_NOTE) {
      invoiceDetails.memberNote = note;
    } else if (type === types2.INVOICE_NOTES.WORK_NOTE) {
      invoiceDetails.workNote = note;
    }
    this.setState({
      invoiceDetails,
    });
  }
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
