import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import CommonStyles from '../../Styles/CommonStyles';

const CustomInputAccessoryView = ({ onNext, onDone }) => {
    return (
      <View style={CommonStyles.InputAccessoryViewContainer}>
        <TouchableOpacity onPress={onNext} style={CommonStyles.InputAccessoryViewButton}>
          <Text style={CommonStyles.InputAccessoryViewButtonText}>Next</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onDone} style={CommonStyles.InputAccessoryViewButton}>
          <Text style={CommonStyles.InputAccessoryViewButtonText}>Done</Text>
        </TouchableOpacity>
      </View>
    );
  };
  
  
  export default CustomInputAccessoryView;