/*
Author: Josh Merkas
Date: 26/02/18
Copyright © 2017 Leap In All rights reserved.

Screen for Crew to sign up a member.
*/

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Animated,
  Easing,
  Image,
  Dimensions,
  ScrollView,
  TouchableOpacity,
  Keyboard,
  Alert,
} from 'react-native';
import logger from 'helpers/Logger';
import {Picker} from '@react-native-picker/picker';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {MemoryRouter as Redirect} from 'react-router-dom';
import {
  StandardButton,
  StandardText,
  StandardInput,
  StandardPicker,
  BackButton,
  StandardTouchableOpacity,
  WebReturnButton,
} from '../Components/Atoms';
import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import {
  DatePicker,
  BasicOverlayPopupModal,
  FormSubtitle,
  BrandActivityIndicator,
  WebHeaderBar,
  LogoutConfirmationModal,
} from '../Components/Molecules';
import FitImage from 'react-native-fit-image';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  OfflineNotice,
  BasicPopupAlert,
  DeepLinkBanner,
} from '../Components/Organisms';
import * as messages from '../Constants/Messages';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getDataLoggedIn} from '../API/StorageHelper';
import {logUserOut} from '../API/PreauthenticationHelper';
import {callAPIs} from '../API/APICaller';
import {updateMember} from '../API/MemberAPI';
import {getCrewCategories} from '../API/ReferenceAPI';

import {trimObjectProperties} from '../Helpers/Utils';

import * as MemberActions from '../Actions/MemberActions';
import * as CrewActions from '../Actions/CrewActions';
import * as UserActions from '../Actions/UserActions';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as SideMenuActions from '../Actions/SideMenuActions';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';
import * as HealthWellbeingActions from '../Actions/HealthWellbeingActions';
import * as SupportsActions from '../Actions/SupportsActions';
import * as GoalActions from '../Actions/GoalActions';
import * as NotificationActions from '../Actions/NotificationActions';
import * as BudgetActions from '../Actions/BudgetActions';
import * as NavigationConfirmationActions from '../Actions/NavigationActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {navigateMember} from '../Helpers/SignupNavigationHelper';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';
import CustomIcon from '../assets/images/CustomIcon';
import {
  CREW_CREATE_MEMBER as crewMessages,
  ERRORS,
  POPUP_TITLES,
  LOGIN_SIGNUP_SCREEN,
} from '../Constants/Messages';
import * as brand from '../Constants/BrandConstants';

import {
  SignUpOptionsImage,
  InformationIconx3,
  LILogoWhiteText,
} from '../assets/images';
import {RefreshIcon} from '../assets/images/vector-icons';

import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';

import {APP_BRAND} from '../Constants/BrandConstants';

// import Orientation from 'react-native-orientation-enhanced';
import {
  isTablet,
  firebaseAnalysisLogEvent,
} from '../Helpers/PlatformSynchronizer';
import {setValue} from '../API/WebPersistenceStore';

// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;
const LOADED_MEMBERID = 'loadedMemberId';
// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  FIRST_NAME: 'firstname',
  PREFERRED_NAME: 'knownas',
  LAST_NAME: 'lastname',
  EMAIL: 'email',
  DATE_OF_BIRTH: 'dateofbirth',
  POSTCODE: 'postcode',
  RELATIONSHIPTOYOU: 'relationshiptoyou',
  ORGANISATIONNAME: 'organiationname',
};

class CrewCreateMember extends Component {
  inputs: any;
  state = {
    firstname: '',
    preferredname: '',
    lastname: '',
    dateofbirth: '',
    postcode: '',
    email: '',
    dateofbirthInput: '',
    category: 'family',
    categoryDescription: 'Family and friends',
    relationship: '',
    organisationname: '',
    firstnameError: '',
    preferrednameError: '',
    lastnameError: '',
    dateofbirthError: '',
    displayDOBError: true,
    dobErrorBorderWidth: 0,
    validPostCode: '',
    emailError: '',
    dateofbirthInputError: '',
    relationshipPicker: [],
    participantDetails: {},
    myDetails: {},
    loading: true,
    orientation: '',
    instructionModalVisible: false,
    loggoutOut: false,
    errorPopUpVisible: false,
  };

  constructor(props: any) {
    super(props);
    this._addMemberSkipButton = this._addMemberSkipButton.bind(this);
    this._renderInstructionPopUp = this._renderInstructionPopUp.bind(this);
    this.inputs = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.logout.logUserOut) {
      this.props.actions.LogoutConfirmationActions.hideConfirmLogout({
        logUserOut: false,
      });
      logUserOut().then(() => {
        this.navigateToLoginSignUpScreen();
      });
    }
  }

  UNSAFE_componentWillMount() {
    //Load the categories if needed
    if (
      this.props.crew &&
      this.props.crew.crewCategories &&
      this.props.crew.crewCategories.length > 0
    ) {
      this.setState({
        relationshipPicker: this.props.crew.crewCategories,
        loading: false,
      });
    } else {
      // Need to download the crew categories
      const crewCategoryCallback = (data: any) => {
        let crewCategoryPayload = data[0].content.categories;
        this.props.actions.CrewActions.actionGetCrewCategories(
          crewCategoryPayload,
        );
        this.setState({
          relationshipPicker: crewCategoryPayload,
          loading: false,
        });
      };

      callAPIs([getCrewCategories()], crewCategoryCallback, null, () => {
        this.setState({loading: false});
      });
    }
    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation,
    //     });
    //   });
    // }
  }

  componentDidMount() {
    this.setState({loading: false});
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  navigateToLoginSignUpScreen() {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      this.setState({loggoutOut: true});
    });
  }

  _renderPreferdName() {
    //const screenMode = this.props.navigation.getParam('screenMode', undefined);
    const { route } = this.props;
    const { screenMode } = route.params || {};
    if (screenMode == types.ADD_MEMBER) {
      return (
        <StandardInput
          applyWrapper={false}
          accessibilityLabel="Preferred Name"
          key="knownas"
          onRef={(ref: any) => {
            this.inputs[INPUTS.PREFERRED_NAME] = ref;
          }}
          value={this.state.preferredname}
          placeholder="Preferred name"
          onChangeText={(preferredname: any) => this.setState({preferredname})}
          onSubmitEditing={() => {
            focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.LAST_NAME);
          }}
          blurOnSubmit={false}
          style={[
            CommonStyles.CrewCreateMemberML20,
            CommonStyles.ModalTextInputWrapper,
          ]}
          underlineColorAndroid="transparent"
          returnKeyType={'next'}
          errorMessage={this.state.preferrednameError}
        />
      );
    } else {
      return null;
    }
  }

  renderPickers() {
    //const screenMode = this.props.navigation.getParam('screenMode', undefined);
    const { route } = this.props;
    const { screenMode } = route.params || {};
    if (screenMode === types.ADD_MEMBER) {
      return (
        <View>
          <View>
            {this._renderDatePicker(
              this.state.dateofbirth,
              this.state.dateofbirthInput,
            )}
            {this._showDOBError(this.state.displayDOBError)}
          </View>
          <View>
            <StandardInput
              applyWrapper={false}
              key="postcode"
              onRef={(ref: any) => {
                this.inputs[INPUTS.POSTCODE] = ref;
              }}
              keyboardType="numeric"
              maxLength={4}
              value={this.state.postcode}
              accessibilityLabel={types.POST_CODE_ACCESSIBILITY}
              placeholder="Postcode"
              onChangeText={(postcode: any) =>
                this.setState({postcode: postcode.replace(/[^0-9]/g, '')})
              }
              onSubmitEditing={() => {
                focusNextField(
                  this.inputs,
                  INPUTS.SCROLL_VIEW,
                  INPUTS.RELATIONSHIPTOYOU,
                );
              }}
              blurOnSubmit={false}
              style={[
                CommonStyles.CrewCreateMemberMR200,
                CommonStyles.ModalTextInputWrapper,
              ]}
              underlineColorAndroid="transparent"
              returnKeyType={'next'}
              errorMessage={this.state.validPostCode}
            />
            <StandardInput
              applyWrapper={false}
              accessibilityLabel="Your relationship to the member"
              key="relationshiptoyou"
              onRef={(ref: any) => {
                this.inputs[INPUTS.RELATIONSHIPTOYOU] = ref;
              }}
              value={this.state.relationship}
              placeholder="Your relationship to the member"
              onChangeText={(relationship: any) =>
                this.setState({relationship})
              }
              onSubmitEditing={() => {
                focusNextField(
                  this.inputs,
                  INPUTS.SCROLL_VIEW,
                  INPUTS.ORGANISATIONNAME,
                );
              }}
              blurOnSubmit={false}
              style={[CommonStyles.ModalTextInputWrapper]}
              underlineColorAndroid="transparent"
              returnKeyType={'next'}
              errorMessage={this.state.relationshiptoyouError}
            />
          </View>
        </View>
      );
    } else {
      return null;
    }
  }

  _renderOrg() {
    //const screenMode = this.props.navigation.getParam('screenMode', undefined);
    const { route } = this.props;
    const { screenMode } = route.params || {};
    if (screenMode === types.ADD_MEMBER) {
      return (
        <StandardInput
          applyWrapper={false}
          accessibilityLabel="organisation name"
          key="organisationname"
          onRef={(ref: any) => {
            this.inputs[INPUTS.ORGANISATIONNAME] = ref;
          }}
          value={this.state.organisationname}
          placeholder={"Crew member's organisation (if relevant)"}
          onChangeText={(organisationname: any) =>
            this.setState({organisationname})
          }
          blurOnSubmit={true}
          style={[CommonStyles.ModalTextInputWrapper]}
          underlineColorAndroid="transparent"
          errorMessage={this.state.organisationnameError}
        />
      );
    } else {
      return null;
    }
  }

  _renderSkipButton() {
    const { route } = this.props;
    const { screenMode } = route.params || {};
    if (screenMode !== types.ADD_MEMBER) {
      return (
        <TouchableOpacity onPress={this._addMemberSkipButton}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <View>
              <CustomIcon
                style={[
                  CommonStyles.skipButtonwh30,
                  BrandStyles.bottomTabIconInactiveColor,
                ]}
                name={BrandIcons.SKIP_BUTTON}
              />
            </View>
            <View>
              <StandardText
                allowFontScaling={false}
                style={[
                  CommonStyles.customFont,
                  BrandStyles.bottomTabIconInactiveColor,
                ]}
              >
                Skip
              </StandardText>
            </View>
          </View>
        </TouchableOpacity>
      );
    } else {
      return null;
    }
  }

  _addMemberSkipButton() {
    let callbackFunction = (routingObj: any) => {
      if (!types.isWeb) {
        firebaseAnalysisLogEvent(
          types.FIREBASE_ANALYTICS_EVENTS.CREW_CREATE_PARTICIPANT_SKIP,
          {},
        );
      }

      //Update the redux store with the ID we want to load the initial data for
      if (routingObj.params.userObj.managedMemberId != undefined) {
        this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
          routingObj.params.userObj.managedMemberId,
        );
        //Persist the selected person ID in order to retrive on refresh
        setValue(
          LOADED_MEMBERID,
          routingObj.params.userObj.managedMemberId,
        );
      } else {
        this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
          routingObj.params.userObj.user.id,
        );
        //Persist the selected person ID in order to retrive on refresh
        setValue(LOADED_MEMBERID, routingObj.params.userObj.user.id);
      }

      // Update the redux store with the User Object of the logged in user
      this.props.actions.UserActions.actionGetUser(routingObj.params.userObj);
      this.props.actions.UserActions.setFirstTimeSignUp(true, false);

      if (!types.isWeb) {
        firebaseAnalysisLogEvent(
          types.FIREBASE_ANALYTICS_EVENTS.LOGIN_SUCCESS,
          {},
        );
      }

      if (types.isWeb) {
        this.props.navigation.navigate(routingObj.routeName);
      } else {
        const actionToDispatch = CommonActions.reset({
          index: 0,
          routes: [
            {name: routingObj.routeName,
              params:routingObj?.params
            }
          ]
        });
        this.setState({loading: false});
        this.props.navigation.dispatch(actionToDispatch);
      }
    };

    routeTolanding(this.props.auth.awstoken.IdentityId, callbackFunction);
  }

  _renderLogo() {
    if (APP_BRAND != types2.APP_BRAND.LEAPIN) {
      return (
        <View style={[CommonStyles.flex1]}>
          <View
            style={[
              CommonStyles.alignFlexEnd,
              CommonStyles.crewcreatememberl10,
            ]}
          >
            <Image
              style={[CommonStyles.loginsingupLogowh60]}
              source={BrandIcons.LOGO_ICON}
            />
          </View>
        </View>
      );
    }
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      if (this.props.isMobile) {
        styles.push([CommonStyles.containerInstructionPopUpWebMobileLarge]);
        return styles;
      }
      styles.push([CommonStyles.containerInstructionPopUpWeb]);
    } else if (isTablet()) {
      styles.push(CommonStyles.containerInstructionPopUpTablet);
    } else {
      styles.push(CommonStyles.containerInstructionPopUpMobile);
    }
    return styles;
  };

  _closePlanManagedModal = () => {
    this.setState({instructionModalVisible: false});
  };

  _renderInstructionPopUp = (isTablet: any) => {
    let fontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (isTablet()) {
      fontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font25;
    } else if (types.isWeb) {
      fontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    }

    return (
      <BasicOverlayPopupModal
        visible={this.state.instructionModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closePlanManagedModal}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>
          <View
            style={
              types.isWeb
                ? [CommonStyles.marginBottom10, CommonStyles.textAlignCentre]
                : [
                    CommonStyles.flexDirectionRow,
                    CommonStyles.scrollView_subContentCenter,
                    CommonStyles.paddingBottom20,
                    CommonStyles.marginLeft20,
                    CommonStyles.marginRight20,
                  ]
            }
          >
            <View>
              <Image
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
              />
            </View>
            <View style={[CommonStyles.scrollView_subContentCenter]}>
              <StandardText
                style={[
                  headerFontSize,
                  BrandStyles.TextColor10,
                  CommonStyles.customFontBold,
                ]}
              >
                {POPUP_TITLES.PERSON_YOU_ARE_HELPING}
              </StandardText>
            </View>
          </View>
          <StandardText
            style={[
              fontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.paddingBottom20,
            ]}
          >
            {crewMessages.EMAIL_INFO1}
          </StandardText>
          <StandardText
            style={[
              fontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.paddingBottom10 : null,
            ]}
          >
            {crewMessages.EMAIL_INFO2}
          </StandardText>
          <View style={[CommonStyles.containerInstructionDevider]} />
        </View>
      </BasicOverlayPopupModal>
    );
  };

  _renderLeapinLogo = () => {
    if (types.isWeb) {
      return (
        <StandardTouchableOpacity
          activeOpacity={0.8}
          onPress={() => window.open(brand.LEAP_IN_URL)}
          accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
          style={[CommonStyles.loginTileWrapper]}
        >
          <View style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}>
            <Image
              source={LILogoWhiteText}
              style={[CommonStyles.imageBrandLogoText]}
            />
          </View>
        </StandardTouchableOpacity>
      );
    }
  };

  _renderReturnToLeapin = () => {
    if (types.isWeb) {
      return (
        <WebReturnButton
          onPress={() => window.open(brand.LEAP_IN_URL)}
          accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
          buttonLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
          buttonIcon={RefreshIcon}
        />
      );
    }
  };

  _renderAppHeader = () => {
    const { route } = this.props;
    //onst { screenMode } = route.params || {};
    let screenMode; 
    if(types.isWeb) {
      screenMode = this.props.navigation.getParam(screenMode, undefined) || undefined;
     }else {
      screenMode = this.props.route.params[screenMode] ? this.props.route.params[screenMode] : undefined;
     }
     

    if (types.isWeb && screenMode !== types.LOGINSIGNUP_CREW_CREATE_MEMBER) {
      return (
        <WebHeaderBar
          isMobile={this.props.isMobile}
          onLayout={(event: any) => {
            this.setDimesions(event.nativeEvent.layout);
          }}
          name={this.props.user.firstName}
          user={this.props.user}
          nav={this.props.navigation}
          member={this.props.member}
          logout={() => {
            this.props.actions.LogoutConfirmationActions.showConfirmLogout();
          }}
        />
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.headerWrapper,
            BrandStyles.ContainerBackgroundColor,
          ]}
        >
          {this._renderLeapinLogo()}
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.alignFlexStart,
              CommonStyles.justifyCenter,
              this._getMarginTop(),
            ]}
          >
            {this._renderBackButtonForIOS()}
          </View>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.alignItemsFlexEnd,
              CommonStyles.justifyCenter,
              this._getMarginTop(),
            ]}
          >
            {this._renderSkipButton()}
          </View>
          {this._renderReturnToLeapin()}
        </View>
      );
    }
  };

  _renderWebLogutConfirmationModal = () => {
    if (types.isWeb) {
      return (
        <LogoutConfirmationModal
          navigation={this.props.navigation}
          isMobile={this.props.isMobile}
        />
      );
    }
  };

  _getMarginTop() {
    if (Platform.OS === types2.IOS) {
      return CommonStyles.button_moreImage;
    }
  }

  setDimesions(layout: any) {
    const {height} = layout;
    this.setState({
      menuViewHeight: height,
    });
  }

  render() {
    if (types.isWeb && this.state.loggoutOut) {
      this.setState({loggoutOut: false});
      this.props.navigation.navigate('Home');
      return null;
    }
    return (
      <View style={[CommonStyles.screenWrapperContainer]}>
        {this._renderInstructionPopUp(isTablet)}
        <View style={[CommonStyles.flex1]}>
          {this._renderAppHeader()}
          {this._renderWebLogutConfirmationModal()}
          <OfflineNotice />
          {types.isWeb && <DeepLinkBanner />}
          <KeyboardAwareScrollView
            style={[CommonStyles.flex1, BrandStyles.primaryBgColor1]}
            keyboardShouldPersistTaps="always"
            ref={ref => (this.inputs[INPUTS.SCROLL_VIEW] = ref)}
          >
            <View style={[CommonStyles.flex1, CommonStyles.alignCenter]}>
              <View
                accessible={true}
                accessibilityLabel={
                  crewMessages.HEADER1 + ' ' + crewMessages.HEADER2
                }
                style={[
                  CommonStyles.flex1,
                  CommonStyles.rpMarginTop25p,
                  this.state.orientation === types2.ORIENTATION.LANDSCAPE
                    ? CommonStyles.rpMarginBottom20p
                    : CommonStyles.rpMarginBottom40p,
                  types.isWeb
                    ? [CommonStyles.marginRight10, CommonStyles.marginLeft10]
                    : null,
                ]}
              >
                <View
                  style={[
                    CommonStyles.flex1,
                    CommonStyles.viewWhiteBorder,
                    BrandStyles.borderColor4,
                    BrandStyles.brandBlockBgColor6,
                    CommonStyles.alignSelfFlexStart,
                    CommonStyles.alignCenter,
                    CommonStyles.zIndex02,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.customFontBold,
                      BrandStyles.TextColor1,
                      CommonStyles.loginSignUpHeadingPadding,
                      CommonStyles.borderColor4,
                      types.isWeb ? CommonStyles.font25 : CommonStyles.rpfont22,
                      CommonStyles.fontWeightBold,
                    ]}
                  >
                    {crewMessages.HEADER1}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.flex1,
                    BrandStyles.primaryBgColor1,
                    CommonStyles.alignSelfFlexStart,
                    CommonStyles.alignCenter,
                    CommonStyles.zIndex01,
                    CommonStyles.secondHeaderTop,
                    CommonStyles.rpMarginLeft70,
                    CommonStyles.viewWhiteBorder,
                    BrandStyles.borderColor4,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.customFontBold,
                      BrandStyles.brandBlockTxtColor6,
                      CommonStyles.loginSignUpHeadingPadding,
                      types.isWeb ? CommonStyles.font25 : CommonStyles.rpfont22,
                      CommonStyles.fontWeightBold,
                    ]}
                  >
                    {crewMessages.HEADER2}
                  </StandardText>
                </View>
              </View>
              <View style={[CommonStyles.flex1, CommonStyles.rpWidth270]}>
                <FormSubtitle
                  text={[messages.MESSAGES.PLACEHOLDER.FIRST_NAME]}
                />
                <StandardInput
                  applyWrapper={false}
                  accessibilityLabel="First Name"
                  key="firstname"
                  value={this.state.firstname}
                  onChangeText={(firstname: any) => this.setState({firstname})}
                  onSubmitEditing={() => {
                    focusNextField(
                      this.inputs,
                      INPUTS.SCROLL_VIEW,
                      INPUTS.LAST_NAME,
                    );
                  }}
                  onSubmitEditing={() => {
                    this?.lastNameInputRef?.focus();
                  }}
                  blurOnSubmit={false}
                  style={[CommonStyles.ModalTextInputWrapper]}
                  underlineColorAndroid="transparent"
                  returnKeyType={'next'}
                  errorMessage={this.state.firstnameError}
                />
                <FormSubtitle
                  text={[messages.MESSAGES.PLACEHOLDER.LAST_NAME]}
                />
                <StandardInput
                  applyWrapper={false}
                  accessibilityLabel="Last Name"
                  key="lastname"
                  onRef={(ref: any) => {
                    this.lastNameInputRef = ref;
                  }}
                  value={this.state.lastname}
                  onChangeText={(lastname: any) => this.setState({lastname})}
                  onSubmitEditing={() => {
                    this?.emailInputRef?.focus();
                  }}
                  blurOnSubmit={false}
                  style={[CommonStyles.ModalTextInputWrapper]}
                  underlineColorAndroid="transparent"
                  returnKeyType={'next'}
                  errorMessage={this.state.lastnameError}
                />
                <FormSubtitle text={[messages.MESSAGES.PLACEHOLDER.EMAIL]} />
                <StandardInput
                  applyWrapper={false}
                  accessibilityLabel="email"
                  key="email"
                  onRef={(ref: any) => {
                    this.inputs[INPUTS.EMAIL] = ref;
                  }}
                  onRef={(ref: any) => {
                    this.emailInputRef = ref;
                  }}
                  value={this.state.email}
                  onChangeText={(email: any) => {
                    let emailTrimmed = email.trim();
                    this.setState({email: emailTrimmed});
                  }}
                  onSubmitEditing={() => {
                    focusNextField(
                      this.inputs,
                      INPUTS.SCROLL_VIEW,
                      INPUTS.POSTCODE,
                    );
                  }}
                  blurOnSubmit={true}
                  style={[CommonStyles.ModalTextInputWrapper]}
                  underlineColorAndroid="transparent"
                  returnKeyType={'next'}
                  errorMessage={this.state.emailError}
                />
                <View>
                  <StandardText
                    style={[
                      BrandStyles.TextColor5,
                      CommonStyles.CrewCreateMemberP10,
                      types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                      CommonStyles.customFont,
                    ]}
                  >
                    {crewMessages.INFO_TEXT}
                  </StandardText>
                </View>
                <View
                  style={[
                    this.state.orientation === types2.ORIENTATION.LANDSCAPE
                      ? CommonStyles.rpMarginTop10p
                      : CommonStyles.rpMarginTop20p,
                  ]}
                >
                  <FormSubtitle text={[crewMessages.CATEGORY_CREW]} />
                </View>
                <View
                  style={[
                    CommonStyles.ModalPickerInputContainer,
                    BrandStyles.errorBorder,
                    {borderWidth: this.state.stateErrorBorderWidth},
                    this._getMargin(),
                  ]}
                >
                  <View
                    style={[
                      BrandStyles.secondaryBgColor3,
                      CommonStyles.borderRadius10,
                    ]}
                  >
                    {this.state.relationshipPicker.length > 0 ? (
                      <StandardPicker
                        textStyle={[
                          CommonStyles.customFont,
                          CommonStyles.rpfont11,
                          CommonStyles.textAlignCentre,
                        ]}
                        style={[
                          CommonStyles.standardPicker,
                          CommonStyles.standardPickerBackgroundColorGray,
                        ]}
                        selectedValue={this.state.category}
                        onValueChange={(itemValue: any, itemIndex: any) =>
                          this.setState({
                            categoryDescription:
                              this.state.relationshipPicker[itemIndex]
                                .description,
                            category: itemValue,
                          })
                        }
                        pickerOptions={this.state.relationshipPicker}
                      />
                    ) : null}
                  </View>
                </View>
                <View
                  style={[
                    CommonStyles.loginsingupmt20,
                    CommonStyles.flex1,
                    CommonStyles.LoginSignUpMB20,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  <StandardButton
                    style={[
                      types.isWeb
                        ? CommonStyles.font18
                        : CommonStyles.rpfont22MS,
                      CommonStyles.customFont,
                      CommonStyles.textAlignCentre,
                      CommonStyles.fontWeightBold,
                      BrandStyles.brandBlockTxtColor6,
                    ]}
                    containerStyle={[
                      BrandStyles.primaryBgColor1,
                      CommonStyles.elevation3,
                      BrandStyles.imageBorderColor,
                      CommonStyles.borderRadius5,
                      CommonStyles.borderWidth1,
                      CommonStyles.loginsingupW150,
                      CommonStyles.padding10,
                    ]}
                    onPress={() => this._addMemberNextButton()}
                  >
                    {crewMessages.BUTTON}
                  </StandardButton>
                </View>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </View>
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this._closeAlert}
          cancelLabel={types2.OK}
          headerText={types2.ERROR}
          messageText={this.state.alertMessage}
          isMobile={this.props.isMobile}
        />
      </View>
    );
  }

  _closeAlert = () => {
    this.setState({
      errorPopUpVisible: false,
    });
  };

  _showAlert = (alertMessage: any, errorPopUpVisible: any) => {
    this.setState({
      alertMessage,
      errorPopUpVisible,
    });
  };

  _getMargin() {
    if (isTablet) {
      return [CommonStyles.rpMarginLeft, CommonStyles.rpMarginRight];
    }
  }

  _categoryPickerOptions = () => {
    let pickerOptions: any = [];
    let counter = 0;
    this.state.relationshipPicker.map(relationshipCat => {
      pickerOptions.push(
        <Picker.Item
          textStyle={CommonStyles.font20}
          key={counter}
          label={relationshipCat.description}
          value={relationshipCat.type}
        />,
      );
      counter++;
    });

    return pickerOptions;
  };

  _renderDatePicker = (dateOfBirth: any, dateofbirthInput: any) => {
    return (
      <DatePicker
        fontSize={CommonStyles.font17}
        value={dateOfBirth}
        onRef={(ref: any) => {
          this.inputs[INPUTS.DATE_OF_BIRTH] = ref;
        }}
        restricted={true}
        errorBorder={this.state.dobErrorBorderWidth}
        accessibilityLabel={types2.DATE_PICKER_PLACEHOLDER.DATE_OF_BIRTH}
        save={(dateofbirthInput: any) => {
          let tempParsedDate = new Date(dateofbirthInput);
          if (
            !isNaN(tempParsedDate.valueOf()) &&
            tempParsedDate instanceof Date
          ) {
            focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.POSTCODE);
            this.setState({dateofbirth: dateofbirthInput});
          }
        }}
      />
    );
  };

  _renderBackButtonForIOS() {
    const { route } = this.props;
    const { shouldntRenderBackButton } = route.params || {};
    if (
      Platform.OS === types2.IOS &&
      !shouldntRenderBackButton
    ) {
      if (APP_BRAND == types2.APP_BRAND.LEAPIN) {
        return (
          <BackButton
            isBlack={false}
            goBack={() => {
              this.props.navigation.goBack();
            }}
          />
        );
      } else {
        return (
          <BackButton
            isBlack={false}
            goBack={() => {
              this.props.navigation.goBack();
            }}
          />
        );
      }
    }
  }

  _heroImage = () => {
    let heroImageHeight = ScreenHeight * 1.8;
    return (
      <FitImage style={[styles.heroImage]} source={SignUpOptionsImage}>
        <View style={CommonStyles.alignBackButton}>
          {this._renderBackButtonForIOS()}
        </View>
      </FitImage>
    );
  };

  _addMemberNextButton() {
    Keyboard.dismiss();
    const validateFields = this._validateFields();
    if (!validateFields) {
      return;
    }

    if (this.props.user.demoProfile) {
      this.setState({
        errorPopUpVisible: true,
        alertMessage: crewMessages.DEMO_PROFILE_WARNING,
      });
      return;
    }

    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.CREW_CREATE_PARTICIPANT,
        {},
      );
    }

    let participantDetails = {
      crewId: this.props.user.id,
      crewFirstName: this.props.user.firstName,
      crewLastName: this.props.user.lastName,
      firstName: this.state.firstname,
      lastName: this.state.lastname,
      knownAs: this.state.preferredname,
      dateOfBirth: this.state.dateofbirth,
      postcode: this.state.postcode,
      email: this.state.email,
      category: this.state.category,
      relationship: this.state.relationship,
      organisationName: this.state.organisationname,
    };

    this.setState({
      loading: true,
    });

    participantDetails = trimObjectProperties(
      participantDetails,
      types2.CREW_TRIM_FIELDS,
    );

    this._saveNewParticipant(participantDetails);
  }

  _navigateTo(routingObj: any) {
    if (types.isWeb) {
      this.props.navigation.navigate(routingObj.routeName);
    } else {
      const actionToDispatch = CommonActions.navigate(
          {name:routingObj.routeName,params:routingObj.params }
      );
      this.props.navigation.dispatch(actionToDispatch);
    }

    this.setState({loading: false});
  }

  _saveNewParticipant = (participantObj: any, err: any) => {
    let updateMemberCallback = (data: any) => {
      const responseContent = data[0].content;
      // const { route } = this.props;
      // const { screenMode, planType, signUpType } = route.params || {};
      let screenMode;
      let planType;
      let signUpType;
      if(types.isWeb) {
        screenMode =   this.props.navigation.getParam('screenMode', undefined) ;
        planType =   this.props.navigation.getParam('planType', undefined) ;
        signUpType =   this.props.navigation.getParam('signUpType', undefined) ;
       }else {
        //return this.props.route.params['signUpType'] ? this.props.route.params['signUpType'] : undefined;
        screenMode = this.props.route.params['screenMode'] ? this.props.route.params['screenMode'] : undefined;
        planType = this.props.route.params['planType'] ? this.props.route.params['planType'] : undefined;
        signUpType  = this.props.route.params['signUpType'] ? this.props.route.params['signUpType'] : undefined;
       }
      const completionHandler = () => {
        var memberNumber = this.props.user.numberOfMembers;
        var numberofmembers = memberNumber + 1;
        this.props.actions.UserActions.actionUpdateNumberOfMembers(
          numberofmembers,
        );
        this.setState({
          firstname: '',
          preferredname: '',
          lastname: '',
          dateofbirth: '',
          postcode: '',
          email: '',
          dateofbirthInput: '',
          relationship: '',
          relationshiptoyou: '',
          organisationname: '',
          relationshipPicker: this.props.crew.crewCategories,
        });

        let callbackFunction = (routingObj: any) => {
          //Update the redux store with the ID we want to load the initial data for
          if (routingObj.params.userObj.managedMemberId != undefined) {
            this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
              routingObj.params.userObj.managedMemberId,
            );
            //Persist the selected person ID in order to retrive on refresh
            setValue(
              LOADED_MEMBERID,
              routingObj.params.userObj.managedMemberId,
            );
          } else {
            this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
              routingObj.params.userObj.user.id,
            );
            //Persist the selected person ID in order to retrive on refresh
            setValue(LOADED_MEMBERID, routingObj.params.userObj.user.id);
          }

          // Update the redux store with the User Object of the logged in user
          this.props.actions.UserActions.actionGetUser(
            routingObj.params.userObj,
          );

          if (screenMode === types.LOGINSIGNUP_CREW_CREATE_MEMBER) {
            this.props.actions.UserActions.setFirstTimeSignUp(true, false);
          } else {
            this.props.actions.UserActions.setFirstTimeSignUp(false, false);
          }

          if (!types.isWeb) {
            firebaseAnalysisLogEvent(
              types.FIREBASE_ANALYTICS_EVENTS.LOGIN_SUCCESS,
              {},
            );
          }
          if (this.props.user.isMember && this.props.user.isCrew) {
            this.props.actions.SideMenuActions.setCurrentScreen(types2.PROFILE);
            this.setState({loading: false}, () => {
              navigateMember(responseContent.MemberId, this.props, true);
            });
          } else if (screenMode === types.ADD_MEMBER) {
            this.props.navigation.navigate('Home', {
              screenMode: screenMode,
              planType: planType,
              signUpType,
            });
          } else {
            this._navigateTo(routingObj);
          }
        };

        if (screenMode !== types.LOGINSIGNUP_CREW_CREATE_MEMBER) {
          if (memberNumber === 0 && this.props.user.isMember) {
            this.props.actions.loadedMemberActions.actionNewlyAddedMemberId(
              responseContent.MemberId,
            );
            routeTolanding(
              this.props.auth.awstoken.IdentityId,
              callbackFunction,
            );
          } else {
            this.props.actions.SideMenuActions.setCurrentScreen(types2.PROFILE);
            this.setState({loading: false}, () => {
              navigateMember(responseContent.MemberId, this.props, true);
            });
          }
        } else {
          routeTolanding(this.props.auth.awstoken.IdentityId, callbackFunction);
        }
      };

      completionHandler();
    };

    const updateMemberErrorCallback = (err: any) => {
      if (err.code == 409) {
        if (types.isWeb) {
          this._showAlert(ERRORS.GENERIC_CREW_USER_EXISTING_ALERT, true);
        } else {
          Alert.alert(types2.ERROR, ERRORS.GENERIC_CREW_USER_EXISTING_ALERT);
        }
      }
      this.setState({loading: false});
    };

    callAPIs(
      [updateMember(participantObj, false)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };

  _validateFields = () => {
    //Checks required fields of the before saving to backend.
    var validateErrors = {
      firstnameError: '',
      lastnameError: '',
      dateofbirthError: '',
      validPostCode: '',
      emailError: '',
    };

    validateErrors.firstnameError = this._validateFirstName();
    validateErrors.lastnameError = this._validateLastName();
    validateErrors.emailError = this._validateEmail();

    //const screenMode = this.props.navigation.getParam('screenMode', undefined);
    // if(screenMode === types.ADD_MEMBER){
    //     validateErrors.dateofbirthError = this._validateDOB();
    //     validateErrors.validPostCode = this._validatePostCode();
    // }

    this.setState({
      firstnameError: validateErrors.firstnameError,
      lastnameError: validateErrors.lastnameError,
      emailError: validateErrors.emailError,
      dateofbirthError: validateErrors.dateofbirthError,
      validPostCode: validateErrors.validPostCode,
    });

    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  _validateFirstName() {
    if (this.state.firstname == '') {
      return types.FIRSTNAME_NOT_ENTERED;
    } else {
      return '';
    }
  }

  _validateLastName() {
    if (this.state.lastname == '') {
      return types.LASTNAME_NOT_ENTERED;
    } else {
      return '';
    }
  }

  _validateEmail() {
    if (this.state.email != '') {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.email)) {
        return types.EMAIL_INVALID_FORMAT;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  _validatePostCode() {
    if (isNaN(this.state.postcode)) {
      return types.INVALID_POSTCODE_NAN;
    } else if (this.state.postcode == '') {
      return types.INVALID_POSTCODE_LENGTH;
    } else {
      return '';
    }
  }

  _validateDOB() {
    if (this.state.dateofbirth === '') {
      this.setState({displayDOBError: false, dobErrorBorderWidth: 3});
      return types.DOB_NOT_SELECTED;
    } else {
      this.setState({displayDOBError: true, dobErrorBorderWidth: 0});
      return '';
    }
  }

  _showDOBError = (DOBCheck: any) => {
    if (DOBCheck != false) {
      return null;
    } else {
      return (
        <StandardText
          accessibilityLabel="Date of Birth is Required"
          style={[
            CommonStyles.inputErrorMessageText,
            CommonStyles.CrewCreateMemberMTm20,
            CommonStyles.customFont,
          ]}
        >
          {types.DOB_NOT_SELECTED}
        </StandardText>
      );
    }
  };
}

const styles = StyleSheet.create({
  // Animations for the class
  //heroBanner: {
  //  transform: [{ translateY: animateTopToBottom }]
  //},
  heroImage: {
    height: ScreenHeight,
    width: ScreenWidth * 0.34,
  },
  /* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                This enables the Leap Into text to start on top of the */
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: '25%',
    zIndex: 100,
    //transform: [{ translateY: animateBottomToTop }]
  },
});

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  auth: state.AuthenticationReducer,
  crew: state.CrewReducer,
  logout: state.LogoutReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    FavouriteThingsActions: bindActionCreators(
      FavouriteThingsActions,
      dispatch,
    ),
    HealthWellbeingActions: bindActionCreators(
      HealthWellbeingActions,
      dispatch,
    ),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    notificationActions: bindActionCreators(NotificationActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CrewCreateMember);
