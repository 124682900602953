/*
 * Author: Shelan Cooray
 * Date: 23/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the detail view for approving or rejecting the service agreement.
 *
 */

import React, {Component} from 'react';
import {View, Image, Platform} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {CommonActions} from '@react-navigation/native';
import {requestWritePermission} from '../Helpers/FileHelper';

import {StandardText, StandardTouchableOpacity} from '../Components/Atoms';
import {BasicForm} from '../Components/Organisms';
import {
  SummaryTile,
  ServiceAgreementList,
  Container,
  CurrencyText,
  BasicOverlayPopupModal,
} from '../Components/Molecules';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  APPROVALS_AGREEMENT_DETAILS as messages,
  APPROVALS_SUMMARY as summaryMessages,
  COMMON_BUTTONS as buttonMessages,
} from '../Constants/Messages';
import {SADetail3x, GreyClockIcon3x, EyeGray4} from '../assets/images';

import {callAPIs} from '../API/APICaller';
import {
  putAgreementApproval,
  getServiceAgreementApproval,
} from '../API/PlanDataAPI';

import {formatDDMMMYYDate} from '../Helpers/Utils';
// import DeviceInfo from 'react-native-device-info';
import {isTablet, RNFetchBlobSingleton} from '../Helpers/PlatformSynchronizer';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {OfflineNotice} from '../Components/Organisms';
import {ServiceAgreementAttachmentsPopup} from '../Components/Organisms';
import logger from 'helpers/Logger';

class ApprovalsDetailsServAgreement extends Component {
  refreshSummary: any;
  selectedItem: any;
  state = {
    loading: false,
    agreementData: {},
    agreementSummary: {},
    approveModalVisible: false,
    attachmentVisible: false,
  };

  constructor(props: any) {
    super(props);

    this.selectedItem = this.props.navigationParams.params.selectedItem;
    this.refreshSummary = this.props.navigationParams.params.refreshSummary;

    this._renderDetails.bind(this);
    this._renderDateRange.bind(this);
    this._renderAgreementValue.bind(this);
    this._renderAgreementItems = this._renderAgreementItems.bind(this);
    this._formatAgreementTableData.bind(this);
    this._renderApprovePopUp.bind(this);

    this._renderMain.bind(this);
  }

  componentDidMount() {
    const {loadedMemberId} = this.props;
    if (types.isWeb) {
      this.props.setLoading(false);
    }

    const dataCallback = (data: any) => {
      let agreementData = data[0].content;
      let agreementSummary = this._formatAgreementTableData(agreementData);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        loading: false,
        agreementData,
        agreementSummary,
      });
    };

    callAPIs(
      [
        getServiceAgreementApproval(
          loadedMemberId.loadedMemberId,
          this.selectedItem.id,
        ),
      ],
      dataCallback,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
      },
    );
  }

  /**
   * Format the data for the ServiceAgreementTable component
   * @param {Object} agreementSummary The agreement data from the API call
   * @returns {Object} Agreement data formatted to be used in the Service agreement table component
   */
  _formatAgreementTableData = (agreementSummary: any) => {
    for (let i = 0; i < agreementSummary.agreementItems.length; i++) {
      const agreement = agreementSummary.agreementItems[i];
      agreement['description'] = agreement.itemDescription;
      agreement['code'] = agreement.itemCode;
      agreement['customDescription'] = agreement.shortDescription;
      agreement['initialBudget'] = agreement.budget;
    }
    return agreementSummary;
  };

  render() {
    if (types.isWeb) {
      return this._renderMain();
    }

    return (
      <Container
        contents={this._renderMain}
        loading={this.state.loading}
        ignorePadding={true}
        customStyle={CommonStyles.containerInvoiceDetails}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.APPROVAL_SA}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _renderMain = () => {
    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <View>
          <BasicForm
            hideTitle={true}
            save={this._showApprovalPopUp}
            saveLabel={buttonMessages.APPROVE}
            saveStyle={this._renderSaveStyles()}
            delete={this._rejectItem}
            deleteText={buttonMessages.DECLINE}
            deleteStyle={this._renderDeleteStyles()}
            buttonContainerStyle={CommonStyles.containerInvoiceDetails_Buttons}
            disableMore={true}
            readOnly={this.props.member?.access?.finances}
            saveApiError={this.state.saveApiError}
            saveApiErrorCode={this.state.saveApiErrorCode}
            buttonsPositionBottom={true}
            contentWidthFull={true}
          >
            <View style={[CommonStyles.containerInvoice_Summary]}>
              <View>
                <SummaryTile
                  headerDisplayString={messages.TITLE}
                  headerIconImage={{
                    uri: this.selectedItem.budgetCategoryData
                      ? this.selectedItem.budgetCategoryData.icon.url
                      : '',
                  }}
                  HeaderTextColor={{
                    color: this.selectedItem.budgetCategoryData
                      ? this.selectedItem.budgetCategoryData.color
                      : '',
                  }}
                  HeaderBoarderColor={{
                    borderColor: this.selectedItem.budgetCategoryData
                      ? this.selectedItem.budgetCategoryData.color
                      : '',
                  }}
                  hideYellowStar={true}
                  showButton={false}
                  fontSize={CommonStyles.rpfont20}
                  disableMore={true}
                  imageIcon={true}
                />
              </View>
              {this._renderDetails()}
            </View>
          </BasicForm>
          {types.isWeb
            ? this._renderApprovePopUp(false)
            : this._renderApprovePopUp(isTablet)}
        </View>
      </View>
    );
  };

  _renderDetails = () => {
    return (
      <View>
        <View style={[CommonStyles.containerSA_Summary]}>
          <View style={[CommonStyles.justifyFlexRowStart]}>
            <View
              style={[CommonStyles.justifyFlexColumnStart, CommonStyles.flex06]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontSemiBold,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
                  BrandStyles.TextColor5,
                ]}
              >
                {this.selectedItem.biller.name}
              </StandardText>
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.marginTop10,
                  CommonStyles.customFont,
                ]}
              >
                {this.selectedItem.budgetCategory}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                CommonStyles.flex04,
                CommonStyles.alignItemsFlexEnd,
              ]}
            >
              <View>
                <CurrencyText
                  value={this._renderAgreementValue()}
                  isPositive={true}
                  noContainer={true}
                  integerStyle={[
                    types.isWeb ? CommonStyles.font30 : CommonStyles.font40,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                />
              </View>
              <View>
                <StandardText
                  style={[
                    CommonStyles.title_ListContainer,
                    CommonStyles.marginRight0,
                    CommonStyles.customFont,
                  ]}
                >
                  {messages.TOTAL}
                </StandardText>
              </View>
            </View>
          </View>
          <View
            style={[CommonStyles.justifyFlexRowStart, CommonStyles.marginTop5]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainer,
                CommonStyles.marginTop5,
                CommonStyles.customFont,
              ]}
            >
              {this.selectedItem.invoiceNumber}
            </StandardText>
            <View
              style={[
                CommonStyles.justifyFlexRowEnd,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <Image
                style={CommonStyles.containerBudgetApprovalCard_ImageDate}
                source={GreyClockIcon3x}
              />
              <StandardText
                style={[
                  CommonStyles.font15,
                  CommonStyles.customFont,
                  BrandStyles.TextColor10,
                ]}
              >
                {this._renderDateRange()}
              </StandardText>
            </View>
          </View>
          {this.state.agreementData.attachments
            ? this.state.agreementData.attachments.length != 0
              ? this._renderShowAttachments()
              : null
            : null}
        </View>
        {this._renderAttachmentPopup()}
        {this._renderAgreementItems()}
      </View>
    );
  };

  _renderAgreementItems = () => {
    // If data not loaded, or there are no agreement items don't render the table
    if (this.state.agreementData == null) {
      return null;
    }

    return (
      <ServiceAgreementList
        titles={messages.TABLE_TITLES}
        data={this.state.agreementData.agreementItems}
      />
    );
  };

  _renderDeleteStyles = () => {
    let styles = {};
    return (styles = {
      style: [
        BrandStyles.brandBlockTxtColor6,
        CommonStyles.buttonBudgetAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    });
  };

  _renderSaveStyles = () => {
    let styles = {};
    return (styles = {
      style: [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonBudgetAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    });
  };

  _renderDateRange = () => {
    const {startDate, endDate} = this.selectedItem;
    return formatDDMMMYYDate(startDate) + ' - ' + formatDDMMMYYDate(endDate);
  };

  _renderAgreementValue = () => {
    let totalValue = 0;
    if (this.state.agreementSummary.budget) {
      totalValue = this.state.agreementSummary.budget;
    }
    return totalValue;
  };

  _closeForm = () => {
    this.props.navigation.dispatch(CommonActions.goBack());
  };

  _approveItem = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }

    const approveCallback = (data: any) => {
      this._closeForm();
      this.refreshSummary();
    };

    const approveErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    // Call the API to approve the invoice
    callAPIs(
      [
        putAgreementApproval(
          this.props.loadedMemberId.loadedMemberId,
          this.selectedItem.id,
          types2.APPROVAL.APPROVE,
        ),
      ],
      approveCallback,
      approveErrorCallback,
    );
  };

  _rejectItem = () => {
    let notificationData = null;
    if (
      this.props.navigationParams.params &&
      this.props.navigationParams.params.notificationData
    ) {
      notificationData = this.props.navigationParams.params.notificationData;
    }
    this.props.navigation.navigate('ApprovalsDetailsInvoiceReject', {
      invoiceDetails: this.state.invoiceDetails,
      selectedItem: this.selectedItem,
      refreshSummary: this.refreshSummary,
      notificationData: notificationData,
    });
  };

  _renderApprovePopUp = (isTablet: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.approveModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closeApprovePopUp}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.font20,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
                CommonStyles.paddingBottom20,
              ]}
            >
              {summaryMessages.APPROVED_SA}
            </StandardText>
          </View>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.paddingBottom20,
                CommonStyles.textAlignCentre,
              ]}
            >
              {summaryMessages.APPROVED_MESSAGE}
            </StandardText>
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.containerSAApprovedPopUp,
      CommonStyles.alignSelfCenter,
    ];
    return styles;
  };

  _showApprovalPopUp = () => {
    this.setState({approveModalVisible: true});
  };

  _closeApprovePopUp = () => {
    this._approveItem();
    this.setState({approveModalVisible: false});
    this.props.navigation.goBack();
  };

  _showAttachmentModal = () => {
    this.setState({
      attachmentVisible: true,
    });
  };

  _closeAttachmentModal = () => {
    this.setState({
      attachmentVisible: false,
    });
  };

  _renderAttachmentPopup = () => {
    return (
      <ServiceAgreementAttachmentsPopup
        attachmentVisible={this.state.attachmentVisible}
        close={this._closeAttachmentModal}
        _openDocument={this._openDocument}
        attachments={this.state.agreementData.attachments}
      />
    );
  };

  _renderShowAttachments = () => {
    return (
      <StandardTouchableOpacity
        accessible
        activeOpacity={0.6}
        onPress={this._showAttachmentModal}
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.alignItemsCenter,
            CommonStyles.marginTop10,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.title_ListContainer,
              CommonStyles.customFontSemiBold,
              CommonStyles.underlineText,
              BrandStyles.TextColor10,
            ]}
          >
            {types2.ATTACHMENTS}
          </StandardText>
          <Image
            style={[CommonStyles.viewAttachments, CommonStyles.marginLeft5]}
            source={EyeGray4}
          />
        </View>
      </StandardTouchableOpacity>
    );
  };

  _openDocument = async (document: any, forceDownload: any) => {
    this.setState({
      attachmentVisible: false,
    });
    if (types.isWeb) {
      window.open(document.signedUrl);
    }
    // this.setState({ loading: true });
    if (Platform.OS === types2.IOS) {
      const ios = RNFetchBlobSingleton.ios;
      const dirs = RNFetchBlobSingleton.fs.dirs;
      RNFetchBlobSingleton.config({
        path: dirs.DocumentDir + '/LeapIn/' + document.name,
        fileCache: true,
      })
        .fetch('GET', document.signedUrl, {
          // Headers here
        })
        .then(res => {
          // this.setState({ loading: false });
          ios.openDocument(res.path()).catch((err: any) => logger.log(err));
        })
        .catch(err => {
          logger.log(err);
        });
    } else if (Platform.OS === types2.ANDROID) {
      let fileType = this.getFileExtension(document.name)[0];

      if (fileType == types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.signedUrl,
          fileName: document.name,
        });
        // this.setState({ loading: false });
      } else if (
        types2.IMAGE_FILE_TYPES.includes(fileType) &&
        forceDownload != true
      ) {
        this.props.navigation.navigate('WebViewer', {
          url: document.signedUrl,
          fileName: document.name,
        });
        // this.setState({ loading: false });
      } else {
        const granted = await requestWritePermission();
        if (granted === true) {
          const {config, fs} = RNFetchBlobSingleton;
          const DownloadDir = fs.dirs.DownloadDir;
          const options = {
            fileCache: true,
            addAndroidDownloads: {
              useDownloadManager: true,
              notification: true,
              mime: document.contentType,
              title: document.name + ' has been downloaded',
              description: 'File downloaded by file manager',
              mediaScannable: true,
              path: DownloadDir + '/LeapIn/' + document.name,
            },
          };
          config(options)
            .fetch('GET', document.signedUrl)
            .then(res => {});
        }

        // this.setState({ loading: false });
      }
    }
  };

  getFileExtension = (filename: any) => {
    let fileType: any = [];
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : fileType;
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovalsDetailsServAgreement);
