/**
 * Author: Nirodha Perera
 * Date: 31/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 *
 */

import React, {Component} from 'react';
import {View, Dimensions} from 'react-native';
import PropTypes from 'prop-types';
import {StandardText, StandardButton} from '../Atoms';
import {SpendingBarTitleMain, CurrencyText, GridView} from './';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {
  capitalize,
  formatAsCurrency,
  getPostive,
  calculatePieChartArcPercentages,
} from '../../Helpers/Utils';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {PieChartSVG} from '../../Helpers/PlatformSynchronizer';

import {BrandColors} from '../../Styles/Colours';
import {BUDGET_SPEND_GRAPHS} from '../../Constants/Messages';

const OUTER_RADIOUS = 64;
const AVAILABLE = 'Available';
const AVAILABLE_NOW = 'Available now';
const ALLOCATED_REMAINING = 'Allocated Remaining';

class SpendingPieChart extends Component {
  constructor(props: any) {
    super(props);
    this._renderSection.bind(this);
    this._getColorForKey.bind(this);
    this._getBarTotal.bind(this);
    this._renderBarChart.bind(this);
    this._determineStyle.bind(this);
  }

  // Need a min width based on the text size
  render() {
    return (
      <View style={{flex: 1}}>
        {/* Will only render a title if one passed, otherwise renders null */}
        {this._renderTitleComponent()}
        {this._renderBarChart()}
      </View>
    );
  }

  _setupChartData(budget: any, showTotal: any) {
    const data = [];

    if (budget.allocated && budget.allocated != null) {
      budget.unspent += budget.allocated;
      delete budget.allocated;
    }

    // Go over all the budget values
    for (const key in budget) {
      if (budget.hasOwnProperty(key)) {
        if (key != 'total' && !showTotal) {
          const value = parseInt(budget[key]);

          // Setup the values for a 'section' of a pie graph
          const graphSection = {
            key: key,
            value: getPostive(value),
          };
          // Set the fill color for the section, default is grey (For unassigned)
          const backgroundColor = this._getColorForKey(key, 'backgroundColor');

          // Add the fill color and outline for the pie slice
          if (types.isWeb) {
            graphSection.stroke = backgroundColor;
            graphSection.strokeWidth = 10;
          } else {
            graphSection.svg = {
              fill: backgroundColor,
              stroke: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
              strokeWidth: '1',
            };
          }

          data.push(graphSection);
        }
      }
    }
    return data;
  }

  _renderTitleComponent = () => {
    if (this.props.titleComponentShow && this.props.titleComponentProps) {
      return (
        <SpendingBarTitleMain
          orientation={this.props.orientation}
          isOpen={this.props.isOpen}
          isTablet={this.props.isTablet}
          showSummaryTitle={this.props.showSummaryTitle}
          totalSpendPrefix={this.props.titleComponentProps.totalSpendPrefix}
          totalSpend={this.props.titleComponentProps.totalSpend}
          title={this.props.titleComponentProps.title}
          icon={this.props.titleComponentProps.icon}
          textColors={this.props.titleComponentProps.textColors}
          hideTotal={this.props.isMultiTranche}
        />
      );
    }
  };

  _renderBarChart = () => {
    const {budget, showTotal, pieChartSortOrder, isMultiTranche} = this.props;

    const pieData = this._setupChartData(budget, showTotal);
    const pieCharSortOrder = JSON.parse(JSON.stringify(pieChartSortOrder));
    const percentageBasedPieData = calculatePieChartArcPercentages(
      pieData,
      'value',
      pieCharSortOrder,
    );
    const viewOuterPadding = 150;
    const isSmallScreen = this._isSmallScreen();
    const total = this.props.titleComponentProps.totalSpend;
    return (
      <View style={[CommonStyles.flexRow, CommonStyles.flex1]}>
        <View>
          <View
            style={[
              CommonStyles.paddingRight20,
              CommonStyles.paddingBottom20,
              CommonStyles.paddingTop20,
              types.isWeb ? CommonStyles.pieChartSpending : null,
            ]}>
            <PieChartSVG
              style={CommonStyles.pieChartSpending}
              data={percentageBasedPieData}
              sort={() => {}}
              outerRadius={OUTER_RADIOUS}
            />
          </View>
          {isMultiTranche && isSmallScreen
            ? this._renderTotalBudget(total)
            : null}
        </View>
        <View style={[CommonStyles.flex1, CommonStyles.padding10]}>
          <GridView
            viewWidth={this.props.viewWidth - viewOuterPadding}
            isMobile={this.props.isMobile}
            style={CommonStyles.justifyCenter}
            itemDimension={350}>
            {this._renderSection(budget, showTotal)}
          </GridView>
        </View>
      </View>
    );
  };

  _renderTotalBudget = (total?: string) => {
    return (
      <View
        accessible={true}
        accessibilityLabel={BUDGET_SPEND_GRAPHS.TOTAL_BUDGET + ' ' + total}
        style={[CommonStyles.containerPieChart]}>
        <StandardText
          style={[
            CommonStyles.customFont,
            CommonStyles.spendBarSectionTitle,
            CommonStyles.fontWeight400,
          ]}>
          {BUDGET_SPEND_GRAPHS.TOTAL_BUDGET}
        </StandardText>
        <CurrencyText
          value={total || '0'}
          isPositive={true}
          integerStyle={[
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            this._determineStyle(
              CommonStyles.font20,
              CommonStyles.font20,
              CommonStyles.font25,
              CommonStyles.font20,
            ),
          ]}
          decimalStyle={[
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            this._determineStyle(
              CommonStyles.font15,
              CommonStyles.font15,
              CommonStyles.font18,
              CommonStyles.font15,
            ),
          ]}
          containerStyle={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}
        />
      </View>
    );
  };

  _renderUpcomingBudgets = () => {
    return (
      <View style={CommonStyles.marginLeft24}>
        <StandardButton
          accessibilityLabel={BUDGET_SPEND_GRAPHS.SHOW_UPCOMING_FUND_RELEASES}
          buttonType={types2.COMPONENT_TYPES.BUTTON}
          style={[CommonStyles.textAlignCentre]}
          onPress={this.props.showUpcomingBudgetPopUp}>
          <View>
            <StandardText
              style={[
                CommonStyles.settingsLinkButton,
                CommonStyles.customFont,
                CommonStyles.spendBarSectionTitle,
                CommonStyles.padding0,
              ]}>
              {BUDGET_SPEND_GRAPHS.SHOW_UPCOMING_FUND_RELEASES}
            </StandardText>
          </View>
        </StandardButton>
      </View>
    );
  };

  _isSmallScreen = () => {
    const GRID_VIEW_WIDTH = 850;
    if (!types.isWeb) return true;
    const {width} = Dimensions.get('window');
    return width <= GRID_VIEW_WIDTH;
  };

  /**
   * This will return the total for the graph sections
   * @param {Object} budget The budget object
   * @param {Bool} showTotal If the total is included on the graph or not
   */
  _getBarTotal(budget: any, showTotal: any) {
    let total = budget['total'];
    // If no total key, manually build
    if (total === undefined) {
      total = 0;
      // Loop over the key value pairs for the budget
      for (const key in budget) {
        if (budget.hasOwnProperty(key)) {
          if (key != 'total' && !showTotal) {
            total += budget[key];
          }
        }
      }
    }
    return total;
  }

  /**
   * Return jsx for a section of the bar
   * @param {String} title The title to display in the section
   * @param {String} value The value of the section to display, also used to size the section
   * @param {String} total The total value of the whole bar (All section values added together)
   * @param {String} backgroundColor The background color for the section
   * @param {String} textColor The text color for the section
   */
  _renderSection = (budget: any, showTotal: any) => {
    const jsx: any = [];
    const isSmallScreen = this._isSmallScreen();
    const total = this.props.titleComponentProps.totalSpend;
    const isMultiTranche = this.props.isMultiTranche;
    const sortOrder = this.props.sortOrder;
    sortOrder.forEach((budgetState: any) => {
      jsx.push(
        <View
          style={
            types.isWeb
              ? [CommonStyles.paddingTop10, CommonStyles.minHeight60]
              : null
          }>
          {this._renderSectionValue(budgetState, budget[budgetState])}
        </View>,
      );
    });

    if (isMultiTranche && !isSmallScreen) {
      jsx.push(
        <View style={[CommonStyles.marginLeft13, CommonStyles.paddingTop10]}>
          {this._renderTotalBudget(total)}
        </View>,
      );
    }

    return jsx;
  };

  /**
   * Returns the text for a section. If the prop display text is set to false return null instead
   * @param {String} title The title to display in the section
   * @param {String} value The value of the section to display, also used to size the section
   * @param {String} textColor The text color for the section
   */
  _renderSectionValue(title: any, value: any) {
    const keyContainerColor = this._getColorForKey(title, 'backgroundColor');
    let displayTitle = title;
    // Changing summary bar titles here since titles are come along with the API payload.
    if (title === 'unspent') {
      displayTitle = this.props.isMultiTranche ? AVAILABLE_NOW : AVAILABLE;
    }
    if (title === 'allocated') {
      displayTitle = ALLOCATED_REMAINING;
    }

    const displayUpcomingBudgets =
      this.props.isMultiTranche &&
      this.props.showUpcomingBudgetPopUp &&
      title === 'unreleased';

    const displayText = this.props.displayText;
    if (displayText) {
      return (
        <View>
          <View
            style={[CommonStyles.flexDirectionRow, CommonStyles.marginBottom5]}>
            <View
              style={[
                CommonStyles.containerPieChartKeys,
                {backgroundColor: keyContainerColor},
              ]}
            />
            <View
              accessible={true}
              accessibilityLabel={displayTitle + ' ' + value}
              style={[CommonStyles.containerPieChart]}>
              <StandardText
                style={[
                  CommonStyles.customFont,
                  CommonStyles.spendBarSectionTitle,
                  CommonStyles.fontWeight400,
                ]}>
                {capitalize(displayTitle)}
              </StandardText>
              <CurrencyText
                value={value}
                isPositive={true}
                integerStyle={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,

                  this._determineStyle(
                    CommonStyles.font20,
                    CommonStyles.font20,
                    CommonStyles.font25,
                    CommonStyles.font20,
                  ),
                ]}
                decimalStyle={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  this._determineStyle(
                    CommonStyles.font15,
                    CommonStyles.font15,
                    CommonStyles.font18,
                    CommonStyles.font15,
                  ),
                ]}
                containerStyle={[
                  CommonStyles.flex1,
                  CommonStyles.flexDirectionRow,
                ]}
              />
            </View>
          </View>
          {displayUpcomingBudgets ? this._renderUpcomingBudgets() : null}
        </View>
      );
    } else {
      return null;
    }
  }

  /**
   * Get a color for a given key and the colour type. If not found will return white.
   * @param {String} key The key for the budget color you are looking for
   * @param {String} colorType The color type to return. E.g backgroundColor, textColor
   */
  _getColorForKey(key: any, colorType: any) {
    const budgetColors = this.props.budgetColors;
    // In the array of colour objects, find the one that matches the given key
    const keyColor = budgetColors.find((c: any) => c.key === key);
    // So long as we found the colour return ut
    if (keyColor != undefined) {
      // Return the colour for our given colour type
      return keyColor[colorType];
    }

    // If the colour is not found return white
    return 'white';
  }

  _determineStyle = (
    portraitOpen: any,
    portraitClosed: any,
    horizontal: any,
    normal: any,
  ) => {
    const {orientation, isOpen, isTablet} = this.props;

    return isTablet
      ? orientation === types2.ORIENTATION.LANDSCAPE
        ? horizontal
        : isOpen
        ? portraitOpen
        : portraitClosed
      : normal;
  };
}

// Warn the user if props are not passed
SpendingPieChart.propTypes = {
  budget: PropTypes.object.isRequired,
  budgetColors: PropTypes.array.isRequired,
  displayText: PropTypes.bool.isRequired,
  showTotal: PropTypes.bool,
  titleComponent: PropTypes.element,
  sortOrder: PropTypes.array,
  pieChartSortOrder: PropTypes.array,
  isMultiTranche: PropTypes.bool,
  showUpcomingBudgetPopUp: PropTypes.func,
};

export default SpendingPieChart;
