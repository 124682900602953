/*
Author: Roshan Piumal
Date: 22/08/18
Copyright © 2018 Leap in!. All rights reserved.

The Upload Personal Details for Plan Management Sign-up Screen.
*/

import React, { Component, createRef }  from 'react';
//import {View, Image, Dimensions} from 'react-native';
import { View, Image, Dimensions, StyleSheet, TouchableOpacity, Text, TextInput, Keyboard } from 'react-native';
//import CustomInputAccessoryView from './CustomInputAccessoryView'; // Import the custom accessory view


import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import DeviceInfo from 'react-native-device-info';
// import Orientation from 'react-native-orientation-enhanced';

import {StandardText, StandardInput, StandardButton, CustomInputAccessoryView} from '../Components/Atoms';
import {Container, DatePicker, FormTitle} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {getMember, updateMember} from '../API/MemberAPI'; // For T&C acceptance - to move l8r
import {callAPIs} from '../API/APICaller';
import * as MemberActions from '../Actions/MemberActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {trimObjectProperties, getContentLayout} from '../Helpers/Utils';

// Import assets
import {SignUpForPMAvatar3x, ApprovalsRed3x} from '../assets/images';
import CustomIcon from '../assets/images/CustomIcon';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {UPLOAD_PERSONAL_DETAILS_SCREEN} from '../Constants/Messages';
import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import {formatDateToString} from '../Helpers/Utils';
import {RNInputAccessoryView} from '../Helpers/InputAccessoryView'
import {
  isTablet,
  firebaseAnalysisLogEvent,
} from '../Helpers/PlatformSynchronizer';
import {getValue, removeItem} from '../API/WebPersistenceStore';
import logger from 'helpers/Logger';

const {height, width} = Dimensions.get('window');

const INPUTS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  DATE_PICKER: 'datePicker'
};

class UploadPersonalDetailsPM extends Component {
  inputs: any;
  constructor(props: any) {
    super(props);
    this.inputs = {};
    this.datePickerRef = createRef();
    this.previousInputRef = createRef();
    this.state = {
      loading: false,
      saveApiError: false,
      dob: '',
      displayDOBError: false,
      dobErrorBorderWidth: 0,
      phoneNumber: '',
      displayPhoneNumberError: false,
      isPhoneNumberInvalid: false,
      firstName: '',
      displayFirstNameError: false,
      lastName: '',
      displayLastNameError: false,
      orientation: '',
    };
  }
  focusDatePicker = () => {
    if (this.datePickerRef.current) {
      this.datePickerRef.current._showDateTimePicker();
    }
  };

  handleNextPress = () => {
    this.setState({ currentInput: 'datePicker' }, () => {
      this.focusDatePicker();
    });
  };

  handleDonePress = () => {
    Keyboard.dismiss();
  };

  UNSAFE_componentWillMount() {
    if (
      types.isWeb &&
      this.props.member &&
      JSON.parse(getValue(types2.BOOK_MARK_SIGNUP_PM))
    ) {
      removeItem(types2.BOOK_MARK_SIGNUP_PM);
      this._deepLinkNavigator();
    }

    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation,
    //     });
    //   });
    // }
  }

  componentDidMount = () => {
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_FOR_PLAN_MANAGEMENT_CLICKED,
        {},
      );
    }

    if (
      Object.getOwnPropertyNames(this.props.member).length === 0 ||
      !this.props.member.planId
    ) {
      // reducer is empty, let's trigger the APIcall first
      this.setState({loading: true});
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      const callbackFunction = (data: any) => {
        const member = data[0].content;
        this.props.actions.MemberActions.actionGetMember(
          member,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
        const reduxMember = this.props.member;
        this.initializeDataInState(reduxMember);
      };
      callAPIs(
        [getMember(this.props.loadedMemberId.loadedMemberId)],
        callbackFunction,
        null,
        () => {
          this.setState({loading: false});
          if (types.isWeb) {
            this.props.setLoading(false);
          }
        },
      );
    } else {
      const reduxMember = this.props.member;
      this.initializeDataInState(reduxMember);
    }
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  };

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  _deepLinkNavigator = () => {
    if (this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED) {
      return this.props.navigation.navigate(
        types2.DEEP_LINK_SCREENS.UPLOAD_NEW_PLAN,
      );
    }

    if (
      this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.PLAN_READY &&
      this.props.member.pmtnCAccepted
    ) {
      return this.props.navigation.navigate(
        types2.DEEP_LINK_SCREENS.UPLOAD_NEW_PLAN,
      );
    }

    if (
      this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING &&
      this.props.member.pmtnCAccepted
    ) {
      return this.props.navigation.navigate(
        types2.DEEP_LINK_SCREENS.MY_PROFILE,
      );
    }
  };

  initializeDataInState = (data: any) => {
    this.setState({
      loading: false,
      firstName: data.firstName,
      lastName: data.lastName,
      dob: data.dateOfBirth,
      phoneNumber: data.phoneNumber,
    });
    if (types.isWeb) {
      this.props.setLoading(false);
    }
  };

  createMainContents = () => (
    <View
      style={[CommonStyles.content, BrandStyles.primaryBgColour4]}
      contentContainerStyle={[
        CommonStyles.alignItemsCenter,
        CommonStyles.justifyContentCentre,
        CommonStyles.scrollViewVerticalCentre,
      ]}
    >
      <View
        style={[
          CommonStyles.mainContentContainer,
          BrandStyles.primaryBgColour1,
        ]}
      >
        <View style={[CommonStyles.singleMainContentContainer]}>
          <View style={[CommonStyles.SummaryTileContainer]}>
            <View
              style={[
                CommonStyles.justifyContentSpaceBetween,
                BrandStyles.borderColor3,
                CommonStyles.content,
                CommonStyles.SummaryTileHeaderContainer,
                CommonStyles.flexRow,
                CommonStyles.rpMarginBottom10p,
              ]}
            >
              <Image
                style={[
                  CommonStyles.leapInReadPlanManagedTnCIcon,
                  CommonStyles.rpMarginBottom5p,
                  CommonStyles.titleBoxIconMargin,
                ]}
                source={ApprovalsRed3x}
              />
              <View
                style={[
                  CommonStyles.flex1,
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignItemsFlexEnd,
                  CommonStyles.rpMarginBottom5p,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.font20,
                    CommonStyles.customFontSemiBold,
                    BrandStyles.TextColor3,
                  ]}
                >
                  {UPLOAD_PERSONAL_DETAILS_SCREEN.SIGN_UP_LEAP_IN_TEXT}
                </StandardText>
              </View>
            </View>
          </View>
          <View style={[CommonStyles.flexDirectionRow, CommonStyles.zIndex04]}>
            <View
              style={[
                CommonStyles.flex1,
                types.isWeb ? CommonStyles.marginLeft15 : null,
              ]}
            >
              <Image
                style={[
                  [
                    isTablet
                      ? [
                          CommonStyles.signUpForPlanManagementAvatarIconLarge,
                          this._getLandscapeScale(),
                        ]
                      : [CommonStyles.signUpForPlanManagementAvatarIcon],
                  ],
                  CommonStyles.zIndex05,
                ]}
                source={SignUpForPMAvatar3x}
              />
            </View>
            <View
              style={[
                CommonStyles.zIndex02,
                CommonStyles.flex1,
                CommonStyles.paddingLeft10,
                this._getLandscapeMargin(),
              ]}
            >
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                  CommonStyles.textAlignCentre,
                  CommonStyles.paddingLeft10,
                  CommonStyles.zIndex03,
                ]}
              >
                {UPLOAD_PERSONAL_DETAILS_SCREEN.CONFIRM_DETAILS_TEXT}
              </StandardText>
            </View>
          </View>
          <View style={CommonStyles.marginTop20}>
            <FormTitle
              text={UPLOAD_PERSONAL_DETAILS_SCREEN.FIRST_NAME}
              containsData={this.state.firstName}
              hasError={this.state.displayFirstNameError}
            />
            <StandardInput
              accessibilityLabel={UPLOAD_PERSONAL_DETAILS_SCREEN.FIRST_NAME}
              key="first_name"
              value={this.state.firstName}
              blurOnSubmit={true}
              applyWrapper={false}
              errorMessage={this.checkError(
                this.state.displayFirstNameError,
                types.FIRSTNAME_NOT_ENTERED,
              )}
              onChangeText={(firstName: any) => this.setState({firstName})}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColour1,
              ]}
              returnKeyType={'next'}
              onRef={(ref: any) => {
                this.inputs[INPUTS.FIRST_NAME] = ref;
              }}
              onSubmitEditing={() => {
                this.inputs[INPUTS.LAST_NAME].focus();
              }}
              readOnly={this.props.member.access.profile}
            />
          </View>
          <View>
            <FormTitle
              text={UPLOAD_PERSONAL_DETAILS_SCREEN.LAST_NAME}
              containsData={this.state.lastName}
              hasError={this.state.displayLastNameError}
            />
            <StandardInput
              accessibilityLabel={UPLOAD_PERSONAL_DETAILS_SCREEN.LAST_NAME}
              key="last_name"
              value={this.state.lastName}
              blurOnSubmit={true}
              applyWrapper={false}
              errorMessage={this.checkError(
                this.state.displayLastNameError,
                types.LASTNAME_NOT_ENTERED,
              )}
              onChangeText={(lastName: any) => this.setState({lastName})}
              underlineColorAndroid="transparent"
              returnKeyType={'next'}
              onRef={(ref: any) => {
                this.inputs[INPUTS.LAST_NAME] = ref;
              }}
              onSubmitEditing={() => {
                this.inputs[INPUTS.PHONE_NUMBER].focus()
              }}
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColour1,
              ]}
              readOnly={this.props.member.access.profile}
            />
          </View>
          <View>
            <FormTitle
              text={UPLOAD_PERSONAL_DETAILS_SCREEN.PHONE_NUMBER}
              containsData={this.state.phoneNumber}
              hasError={this.state.displayPhoneNumberError}
            />
            <StandardInput
              keyboardType="phone-pad"
              ref={this.previousInputRef}
              inputAccessoryViewID="inputAccessoryView1"
              accessibilityLabel={UPLOAD_PERSONAL_DETAILS_SCREEN.PHONE_NUMBER}
              key="phone_number"
              value={this.state.phoneNumber}
              blurOnSubmit={true}
              applyWrapper={false}
              returnKeyType={'next'}
              onRef={(ref: any) => {
                this.inputs[INPUTS.PHONE_NUMBER] = ref;
              }}
              onSubmitEditing={() => {
               this.focusDatePicker()
              }}
              errorMessage={this.checkError(
                this.state.displayPhoneNumberError,
                this.state.isPhoneNumberInvalid
                  ? types.PHONE_NUMBER_SHOULD_VALID_TEXT
                  : types.PHONE_NUMBER_IS_MANDATORY_ERROR_TEXT,
              )}
              onChangeText={(phoneNumber: any) => this.setState({phoneNumber})}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColour1,
              ]}
              readOnly={this.props.member.access.profile}
            />
          </View>
          <View>
          <RNInputAccessoryView nativeID="inputAccessoryView1">
            <CustomInputAccessoryView
            onNext={this.handleNextPress}
            onDone={this.handleDonePress}
          />
          </RNInputAccessoryView>
          </View>
          <View>
            <FormTitle
              text={types2.DATE_PICKER_PLACEHOLDER.DATE_OF_BIRTH}
              containsData={this.state.dob}
              hasError={this.state.displayDOBError}
            />
            <DatePicker
              value={this.state.dob}
              errorBorder={this.state.dobErrorBorderWidth}
              marginRight={false}
              ref={this.datePickerRef}
              accessibilityLabel={types2.DATE_PICKER_PLACEHOLDER.DATE_OF_BIRTH}
              save={(dob: any) => {
                if (dob != types2.DATE_PICKER_PLACEHOLDER.DATE_OF_BIRTH) {
                  this.setState(prevState => ({dob: dob}));
                }
             
              }}
              readOnly={this.props.member.access.profile}
            />
            {this._showError(
              this.state.displayDOBError,
              types.DOB_NOT_SELECTED,
              types.DOB_IS_MANDATORY_ACCESSIBLE_ERROR_TEXT,
            )}
          </View>
          <View
            style={[
              CommonStyles.ModalActionButtons,
              types.isWeb
                ? [CommonStyles.zIndexN1, CommonStyles.paddingBottom100]
                : null,
            ]}
          >
            <View style={CommonStyles.ModalFooterLeft}>
              <StandardButton
                onPress={() => this.props.navigation.navigate('Profile')}
                style={[
                  BrandStyles.brandBlockTxtColor6,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor1,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={
                  UPLOAD_PERSONAL_DETAILS_SCREEN.CANCEL_BUTTON_TEXT
                }
              >
                {UPLOAD_PERSONAL_DETAILS_SCREEN.CANCEL_BUTTON_TEXT}
              </StandardButton>
            </View>
            <View  style={CommonStyles.ModalFooterRight}>
              <StandardButton
                onPress={() => this.goNext(true)}
                style={[
                  BrandStyles.brandBlockTxtColor2,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor4,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={
                  UPLOAD_PERSONAL_DETAILS_SCREEN.SAVE_BUTTON_TEXT
                }
                readOnly={this.props.member.access.profile}
              >
                {UPLOAD_PERSONAL_DETAILS_SCREEN.SAVE_BUTTON_TEXT}
              </StandardButton>
            </View>
          </View>
          <View style={[CommonStyles.ModalSingleRow]} />
        </View>
      </View>
    </View>
  );

  _getLandscapeMargin() {
    if (isTablet && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return [CommonStyles.signUpForPlanManagementAvatarCloseMarginLC];
    }
  }

  _getLandscapeScale() {
    if (getContentLayout(isTablet, width, height)) {
      return [CommonStyles.signUpForPMAvatarScale];
    } else {
      return [CommonStyles.signUpForPMAvatarScaleSmall];
    }
  }

  checkError(check: any, text: any) {
    if (check) {
      return text;
    }
    return null;
  }

  _showError = (check: any, text: any, accessibilityLabel: any) => {
    if (!check) {
      return null;
    } else {
      if (!accessibilityLabel) {
        accessibilityLabel = text;
      }
      return (
        <StandardText
          accessibilityLabel={accessibilityLabel}
          style={[
            CommonStyles.inputErrorMessageText,
            CommonStyles.UploadPlanMTm20,
            CommonStyles.customFont,
          ]}
        >
          {text}
        </StandardText>
      );
    }
  };

  determineDOBEntered() {
    if (
      this.state.dob &&
      this.state.dob !== types2.DATE_PICKER_PLACEHOLDER.DATE_OF_BIRTH
    ) {
      return true;
    }
    return false;
  }

  /* Validate the screen.
   * @return Boolean if valid or not.
   */
  valid = () => {
    var valid = true;

    let trimmedFirstName = this.state.firstName.trim();
    let trimmedLastName = this.state.lastName.trim();

    if (trimmedFirstName == '' || this.state.firstName == undefined) {
      this.setState({displayFirstNameError: true});
      valid = false;
    } else {
      this.setState({displayFirstNameError: false});
    }

    if (trimmedLastName == '' || this.state.lastName == undefined) {
      this.setState({displayLastNameError: true});
      valid = false;
    } else {
      this.setState({displayLastNameError: false});
    }

    if (!this.state.phoneNumber || this.state.phoneNumber.length == 0) {
      this.setState({displayPhoneNumberError: true});
      valid = false;
    } else if (isNaN(this.state.phoneNumber)) {
      this.setState({
        displayPhoneNumberError: true,
        isPhoneNumberInvalid: true,
      });
      valid = false;
    } else {
      this.setState({
        displayPhoneNumberError: false,
        isPhoneNumberInvalid: false,
      });
    }

    if (!this.state.dob || this.state.dob.length == 0) {
      this.setState({displayDOBError: true, dobErrorBorderWidth: 3});
      valid = false;
    } else {
      this.setState({displayDOBError: false, dobErrorBorderWidth: 0});
    }

    return valid;
  };

  /* Go next action, if the screen is valid or not (depending on applyValidation input).
   */
  goNext(applyValidation: any) {
    if (!applyValidation || this.valid()) {
      if (!types.isWeb) {
        firebaseAnalysisLogEvent(
          types.FIREBASE_ANALYTICS_EVENTS.PLAN_MANAGEMENT_FORM_FILLED,
          {},
        );
      }

      // Go to the next screen
      let data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        dateOfBirth: formatDateToString(this.state.dob),
        phoneNumber: this.state.phoneNumber,
        id: this.props.loadedMemberId.loadedMemberId,
      };
      data = trimObjectProperties(data, types2.USER_TRIM_FIELDS);
      this.libeSave(data);
    }
  }

  libeSave(saveObj: any) {
    this.setState({loading: true});
    if (types.isWeb) {
      this.props.setLoading(true);
    }

    const callback = (data: any) => {
      this.setState({loading: false});
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.success(saveObj);
    };
    callAPIs(
      [updateMember(saveObj, this.props.member.isDemoProfile, true)],
      callback,
      null,
      () => {
        this.setState({loading: false});
        if (types.isWeb) {
          this.props.setLoading(false);
        }
      },
    );
  }

  success(libeObj: any) {
    this.props.actions.MemberActions.actionUpdateMember(libeObj);
    this.props.navigation.navigate('TermsAndConditionsPM');
  }

  render() {
    if (types.isWeb) {
      return this.createMainContents();
    } else {
      return (
        <Container
          contents={this.createMainContents}
          loading={this.state.loading}
          sidebarMode={types2.SIDEBAR_MODE.EMPTY}
          needsSidebar
          customSidebar
          screenType={types.SCREEN_TYPE_MAIN}
          activeScreen={types.SCREEN_UPLOAD_PLAN}
          nav={this.props.navigation}
          headerTitle={types2.NAVIGATION_TEXT.SIGN_UP_DETAILS}
          toggleMenu={this.props.screenProps?.toggleMenu}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  sideMenu: state.SideMenuReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadPersonalDetailsPM);
