/*
 * Author: Andrew Lee
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Messages object for both static strings and error strings.
 */

import {LEAP_IN_COMPANY_NAME} from './BrandConstants';
import {URI_MODE} from './Constants2';

// Static messages
export const MESSAGES = {
  PLACEHOLDER: {
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password',
    RE_ENTER_PASSWORD: 'Re-enter Password',
    START_TYPING: 'Start Typing',
    ENTER_RESPONSE: 'Enter your response',
    ADDRESS: 'Address',
    CITY: 'City',
    APARTMENT: 'Apartment/unit number',
    POSTCODE: 'Postcode',
    STATE: 'State',
    SHOW_PASSWORD: 'Tap on the eye icon to show or hide your password',
    CREW_SUBTEXT: 'E.g. My important friends or support workers',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL: 'Email',
  },
  // Accessibility
  ACCESSIBILITY: {
    IMPORTANT_INFORMATION: 'Important Information',
    INFO_NOT_ADDED_YET: 'Info not added yet',
    RADIO_BUTTON: {
      SELECTED: 'Selected',
      NOT_SELECTED: 'Not Selected',
    },
  },

  GO_BACK: {
    CANCEL: 'Cancel',
  },

  PHOTO_CAPTION_TITLE: 'Caption for screen readers',

  PASSWORD: {
    LENGTH: 'Password must be 8 or more characters long',
  },

  BANNER: {
    PASSWORD_CHANGE: 'Your password has been changed.',
  },

  HEALTH_WELLBEING_SCREEN: {
    DOCUMENTS_TILE_TITLE: 'Health related documents',
    GOING_WELL_TILE_TITLE: 'Going well',
    MAIN_TITLE: 'My health & wellbeing',
    NO_HEALTH_DOCUMENTS:
      'Do you have documents from your GP or Allied Health Professional? Upload them here.',
    GOING_WELL_MODAL_DESCRIPTION_TITLE: 'Feeling great!',
    GOING_WELL_SUB_HEADING:
      'E.g. Regular massage is maintaining my flexibility',
    GOING_WELL_MODAL_DESCRIPTION_PLACEHOLDER:
      'Tell us about what is going well with your health and why\u2026',
    TO_IMPROVE_SUB_HEADING:
      'E.g. Assistance with my diet could increase my energy levels',
    TO_IMPROVE_MODAL_DESCRIPTION_TITLE: 'What can be improved?',
    TO_IMPROVE_MODAL_DESCRIPTION_PLACEHOLDER: 'Start typing\u2026',
    TO_IMPROVE_MODAL_DIFFICULTY_TITLE:
      'How does your disability make it harder to manage?',
    TO_IMPROVE_MODAL_DIFFICULTY_PLACEHOLDER: 'Start typing\u2026',
    TO_IMPROVE_MODAL_SUPPORT_TITLE:
      'What help/support do you need to improve or manage your health?',
    TO_IMPROVE_MODAL_SUPPORT_PLACEHOLDER: 'Start typing\u2026',
    TO_IMPROVE_TILE_TITLE: 'To improve',
    SELECT_DOCUMENT: 'Select Document',
  },

  WHERE_I_LIVE_SCREEN: {
    MAIN_TITLE: 'Where I live',
    HOME_TILE_TITLE: 'Home',
    LIVE_WITH_TILE_TITLE: 'Who I live with',
    MODIFICATIONS_TILE_TITLE: 'Home modification',
    MODIFICATIONS_TILE_YES: 'Yes',
    MODIFICATIONS_TILE_NO: 'No',
    MODIFICATIONS_TILE_YES_SUBTITLE:
      ' , I currently have modifications to my home.',
    MODIFICATIONS_TILE_TITLE_NO_SUBTITLE:
      " , I currently don't have modifications to my home.",
    HOME_MODAL_HOME_TYPE_TITLE: 'What kind of home do you live in?',
    HOME_MODAL_HOME_TYPE_SUBTITLE: 'Please choose one',
    HOME_MODAL_LIVE_WITH_TITLE: 'Who do you live with?',
    HOME_MODAL_LIVE_WITH_SUBTITLE: 'Please select all that apply',
    HOME_MODAL_NUMBER_OF_PEOPLE_TITLE: 'How many people do you live with?',
    CREW_MODAL_LIVE_WITH_TITLE: 'Who I live with?',
    CREW_MODAL_LIVE_WITH_SUBTITLE: 'Select all that apply',
    MODIFICATIONS_MODAL_MODIFIED_TITLE:
      'Does your home need changes to cater for your disability?',
    HAS_MODIFICATIONS_MODAL_MODIFIED_TITLE:
      'Has your home been modified for your disability?',
    MODIFICATIONS_MODAL_MODIFIED_SUBTITLE: 'Select an option',
    MODIFICATIONS_MODAL_MODIFIED_HOW_TITLE: 'How has your home been modified?',
    MODIFICATIONS_MODAL_MODIFIED_HOW_SUBTITLE:
      'And does anything need to change?(for example, a ramp has been fitted at the front of my house)',
    MODIFICATIONS_MODAL_MODIFIED_HOW_PLACEHOLDER:
      'For example, a ramp has been fitted at the front of my house',
  },

  PLAN_MEETING_LANDING_SCREEN: {
    MAIN_TITLE: 'plan meeting',
    SUMMARY_TILE_TITLE: 'Plan ready summary',
    MANAGEMENT_TILE_TITLE: 'Plan management',
    CHECKLIST_TILE_TITLE: 'NDIS planning meeting checklist',
    SUMMARY_TILE_DETAILS:
      'This is a summary of all the information you’ve entered ready for your planning meeting with the NDIS.',
    MANAGEMENT_TILE_NO_SELECTION:
      'One of the biggest decisions you will need to make is how you would like your NDIS plan and supports managed. Explore the management options here and find out why being plan managed by Leap in! is ideal.',
    MANAGEMENT_TILE_SELF_SELECTED: "You've chosen Option 1 - Self managed",
    MANAGEMENT_TILE_AGENCY_SELECTED: "You've chosen Option 3 - Agency managed",
    MANAGEMENT_TILE_LEAP_IN_SELECTED:
      "You've chosen Leap in! as your Plan Manager",
    MANAGEMENT_TILE_PLAN_MANAGED_SELECTED:
      "You've chosen Option 2 - Plan Managed",
    CHECKLIST_TILE_DETAILS:
      'Make sure that you have everything you need for your planning meeting with this quick checklist.',
  },

  PLAN_MANAGEMENT_SCREEN: {
    CONTENT_TITLE1: 'Already got your plan and ready to leap in?',
    CONTENT_TITLE2_PM_ONBOARDING: 'Plan management options',
    CONTENT_TITLE2_PR:
      'Still getting plan ready? Explore how you can manage your plan.',
    CONTENT_TITLE3: 'Questions? Contact us to find out more.',
    QUESTIONS_DESC:
      'Call us or book a time with one of our Leap in! crew to call you. We can answer your questions and help you to explore your options.',
    QUESTIONS_TEL_TEXT: 'Ph:  ',
    QUESTIONS_TEL_NUMBER: '1300 05 78 78',
    QUESTIONS_EMAIL_TEXT: 'Email:  ',
    QUESTIONS_EMAIL:"crew@leapin.com.au",
    EMAIL_URL:'mailto:crew@leapin.com.au?subject=Enquiry from Member App',
    CONTENT_SIGNUP_DESC:
      "I'd like to sign up for plan management with Leap in!",
    MAIN_TITLE: 'plan management',
    CONTENT_TITLE: 'Choose how to manage your plan',
    SELECT_LEAP_IN: 'Select Leap in! as my Plan Manager',
    CHECKBOX_SELECTED: 'Selected',
    CHECKBOX_UNSELECTED: 'Select',
  },

  HELP_SCREEN: {
    MAIN_TITLE: 'Need assistance?',
  },

  GOALS_SCREEN: {
    ADD_STEPS_TITLE: 'Add steps to achieve your goal.',
    ADD_STEPS_SUBTITLE:
      'Thinking about how you can reach your goal, what do you need to do?',
    ADD_STEPS_INPUT_PLACEHOLDER: 'Add a name for your step: I need to...',
    SUGGESTED_STEPS_TITLE:
      'Here are some suggested steps that can help you achieve it.',
  },

  NODATA: {
    SUPPORTS: 'Select add supports to start adding your current supports',
    CATEGORIES: 'Please select a category above to continue',
    NOT_AVALIABLE: 'N/A',
  },

  ABOUT_ME_SCREEN: {
    MAIN_TITLE: 'About me',
  },

  FAVOURITE_THINGS_SCREEN: {
    MAIN_TITLE: 'My favourite things',
    TODAY_TILE_TITLE: 'Today\u2026',
    FUTURE_TILE_TITLE: 'In the future\u2026',
    TODAY_MODAL_TITLE: 'Today, I like to\u2026',
    TODAY_MODAL_SUBTITLE:
      'Add the things you enjoy and would like to continue doing. eg. Play soccer',
    TODAY_MODAL_PLACEHOLDER: 'e.g. Play soccer',
    FUTURE_MODAL_TITLE: 'In the future, I would like to\u2026',
    FUTURE_MODAL_SUBTITLE:
      'Add the things you would like to do that you aren’t doing yet and the things you would like to change in your life',
    FUTURE_MODAL_PLACEHOLDER: 'e.g. Go to a music festival',
  },

  MORE_ABOUT_ME: {
    TITLE: 'Impact of my disability',
    MODAL_SUBTEXT:
      'An important part of your planning meeting will be discussing how your disability impacts areas of your life.',
    SUB_TITLE: 'What areas of your life are impacted by your condition?',
    CHALLENGE_TEXT1:
      'Think about your best days and your most challenging days.',
    CHALLENGE_TEXT2: '\u2022 What can you do independently?',
    CHALLENGE_TEXT3: '\u2022 What do you need assistance with and why?',
    CHALLENGE_TEXT4: '\u2022 If you need assistance, what kind of assistance?',
    CHALLENGE_TEXT5:
      '\u2022 Is there anything that would change or reduce the need for assistance?',
    DAILY_LIVING_TITLE: 'Daily living/self Care',
    DAILY_LIVING_SUBTITLE:
      'Activities like getting out of bed and moving in the home, bathing, dressing, eating, doing my household chores, shopping, cleaning and preparing meals, moving outside the home etc.',
    COMMUNICATION_TITLE: 'Communication',
    COMMUNICATION_SUBTITLE:
      'Understanding and being understood by other people.',
    CONNECTING_TITLE: 'Connecting with Others',
    CONNECTING_SUBTITLE:
      'Getting on with people, joining in activities, making friends, having conversations, coping with feelings and emotions.',
    LEARNING_TITLE: 'Learning',
    LEARNING_SUBTITLE: 'Understanding, remembering and learning new things.',
    INDEPENDENCE_TITLE: 'Independence',
    INDEPENDENCE_SUBTITLE:
      'Activities such as doing daily job, handling money and making decisions.',
    NO_INFORMATION: 'No Information yet.',
  },

  NOTIFICATIONS_SCREEN: {
    MAIN_TITLE: 'notifications',
  },

  MY_DETAILS: {
    TITLE: 'My details',
    SUB_TITLE: 'Add some information about yourself',
    FIRST_NAME: 'First name',
    CALL_ME: 'Preferred name',
    LIKE_TO_BE_CALLED: "I'd like to be called...",
    LAST_NAME: 'Last name',
    DATE_OF_BIRTH: 'Date of birth',
    ADDRESS: 'Home address',
    HOME_PHONE: 'Home phone',
    NDIS_PLAN_DATE: 'NDIS plan meeting date',
    CALL_ME_PLACEHOLDER: 'Enter your response',
    NDIS_NUMBER: 'NDIS number',
    MOBILE_PHONE: 'Mobile phone',
  },
  REFERRAL_SCREEN: {
    SHOW_ME_PROVIDERS_THAT_SERVICE_MY_REGION:
      'Show me providers that service my region',
    SHOW_ME_PROVIDERS_THAT_CAN_HELP: 'Show me providers that can help',
    NO_PROVIDERS: "Hmm. We can't seem to find your address..",
    UPDATE_ADDRESS: 'If you update it we can recommend providers near you.',
  },
};

export const MY_PLAN_SUMMARY_SCREEN = {
  MAIN_TITLE: 'My Plan Summary',
  SHARE_TILE_TITLE: 'Share',
  SHARE_TILE_DESCRIPTION:
    'Email or export your summary to share with your planner or others in your support circle.',
  VIEW_TILE_TITLE: 'View',
  VIEW_TILE_DESCRIPTION:
    'Check how your summary is looking. It might take a few seconds to pop up!',
  PRINT_TILE_TITLE: 'Print',
  PRINT_TILE_DESCRIPTION:
    "Print your summary. Don't forget to take a copy to your NDIS planning meeting!",
};

export const PROFILE_SCREEN = {
  BUDGET_PENDING1:
    'Your NDIS Plan details are being confirmed... check back soon!',
  BUDGET_PENDING2:
    "When your plan details are confirmed with the NDIA, you'll see a great summary of your budget here.",
};

export const ERRORS = {
  LIMIT_EXCEEDED:
    'Incorrect password entered too many times. Please try again after 30 minutes or reset your password.',
  INCORRECT_PASSWORD: 'Incorrect password entered',
  FAILED_CLOSE_ACCOUNT:
    "We were unable to close your account right now. Please try again later or contact us via email and we'll close your account for you.",
  NETWORK_FAILURE:
    "Your device couldn't connect to our system. Please reconnect to the internet and try again.",
  UNAUTHORISED:
    "Your profile isn't set up correctly to make that change. Please contact us and we'll guide you through it.",
  GENERIC: 'We were unable to save your information. Please try again.',
  GENERIC_LOGIN:
    'There was a problem when trying to sign in. Please try again.',
  GENERIC_GET:
    "Sorry, it seems we can't get your information right now. Please try again.",
  GENERIC_CONNECTIVITY_ALERT_TITLE: 'Error: could not connect',
  GENERIC_ISSUE_ALERT_TITLE: 'Error',
  GENERIC_ISSUE_ALERT_MSG:
    "It seems something has gone wrong. Please try again or contact us and we'll guide you through it.",
  VERSION_ERROR:
    'Your current app version is no longer supported. Please update the app to the latest version.',
  NO_ACCOUNT:
    "You're trying sign in with an account that hasn't been created yet. Please go to the Sign up page to create an account",
  INACTIVE_ACCOUNT: `You're trying to log in to an account which is inactive. Please sign up or contact ${LEAP_IN_COMPANY_NAME} for support`,
  MISSING_MANDATORY_CHECKBOX: 'Please select a checkbox, and then try again',
  GENERIC_CREW_USER_EXISTING_ALERT:
    'An account with this email already exists. Ask them to add you as their crew through their Leap in! account.',
  GENERIC_GUIDE_ME_ERROR_ALERT:
    "Sorry, it seems we can't get Guide Me instruction right now",
  NO_INTERNET: 'Network Failure',
  OFFLINE_NOTICE:
    'No internet connection. Please reconnect and try again soon.',
  USER_DOES_NOT_EXIST: 'User does not exist',
  TRY_AGAIN: 'Try again?',
  TRY_AGAIN_MESSAGE: "We can't login with that username and password.",
  UPDATE_AVAILABLE: 'Update Available',
  FORCE_UPDATE_TEXT:
    'Your current app version is no longer supported. Please update the app to the latest version.',
  MEMBER_PROFILE:
    'Please download and log in to our native app at the Playstore or AppStore to view your profile. This page is currently only for Leap in! plan managed members.',
  LINKED_MEMBER:
    "Please download and log in to our native app at the Playstore or AppStore to view your linked members' profile. This page is currently only for Leap in! plan managed members.",
  INVALID_SIGNATURE_TEXT:
    "It looks like your device time may be incorrect, please correct this and try again.\n\nIf you're having trouble, please contact us and we'll guide you through it.",
  UNABLE_TO_LOGIN_HEADER: 'Unable to log in',
  UNABLE_TO_LOGIN_MESSAGE:
    'We were unable to log you in. Please check your details and try again.',
};

export const MORE_ABOUT_ME_SUMMARY_TITLES = {
  DAILY_LIVING: '\u2022 Daily living/self care',
  COMMUNICATION: '\u2022 Communication',
  CONNECTING: '\u2022 Connecting with others',
  LEARNING: '\u2022 Learning',
  INDEPENDENCE: '\u2022 Independence',
};

export const FORGOT_PASSWORD = {
  BUTTON_TEXT_1: 'I forgot my ',
  BUTTON_TEXT_2: 'password',
  HEADER: 'Reset password',
  EMAIL: 'Email',
  CODE: 'Token code',
  PASSWORD: 'New password',
  ACCOUNT_INFO: 'Account password being reset: ',
  SUBMIT_BTN_EMAIL: 'Confirm',
  SUBMIT_BTN_PWD: 'Submit',
  INSTRUCTIONS:
    'To reset your password, enter your email that you have forgotten the password.\nYou will receive an email with a token that will need to be entered.',
  SUCCESS_TITLE: 'Update success',
  SUCCESS_MESSAGE: 'Your password was updated successfully.',
  RESET_ALERT_TITLE: 'Password reset',
  RESET_ALERT_MESSAGE: 'Please check your email for the token code.',
  CHECK_INBOX_TITLE: 'Check your inbox!',
  CHECK_INBOX_MESSAGE:
    "Please check your inbox for your temporary password and instructions to access your Leap in! account. This will have been sent when your account was created by another member. Can't find the email? Contact us on help@leapin.com.au",
  TEMPORARY_PASSWORD_SENT_MESSAGE:
    "We've sent you a new temporary password and instructions to access your Leap i! account",
  HEADER1: 'Reset my',
  HEADER2: 'password',
  INFO_MESSAGE1: 'Enter your account email below.',
  INFO_MESSAGE2: 'You will be sent a code to reset your password.',
  INFO_MESSAGE3:
    'Please enter this into the Leap in! App to finalise the password reset process.',
  INFO_MESSAGE4:
    'Please enter the token code you received to your email to finalise the password reset process.',
  INFO_ONE: '1. ',
  INFO_TWO: '2. ',
  INFO_THREE: '3. ',
  CODE_VALIDATION_ERROR:
    "That's not the right token. Please check the token we emailed you and try again.",
  VALIDATION_TITLE: 'Whoops',
  INVALID_EMAIL:
    'We can not recognise the email you provided. Please enter a valid email account.',
};

export const DISABILITY = {
  SUMMARY_TITLE: 'Disability',
  MODAL_TITLE: 'My disability is\u2026',
  MORE_INFO_TITLE: 'Further information',
  MORE_INFO_SUB_TITLE:
    'Provide any other information you think might be useful for others to know about your disability.',
  INPUT_PLACEHOLDER: 'Start typing here\u2026',
  CANT_FIND: "Can't find? Add a new one",
  MY_DISABILITY: `Type and select from the drop-down list e.g. 'Intellectual disability', or type your disability in your own words, e.g. 'Anxiety'`,
};

export const CLAIM_INVOICES = {
  MAIN_TITLE: 'Upload your invoices',
  // select or add providers
  SELECT_OR_ADDPROVIDERS: '1. Select or add a provider',
  ENTER_INVOICE_DETAILS: '2. Enter invoice details',
  ENTERSUPPORT_ITEM_DETAILS: '3. Enter support item details',
  SUPPORT_ITEM_NUMBER: 'Support Item ',
  ADD_PROVIDER: 'Add provider',
  ADD_NEW_PROVIDER: 'Add new provider',
  ACTION_ADD: 'Add',
  REVIEW_AND_SUBMIT: 'Review and submit',
};

export const MP_MY_PROFILE = {
  TITLE: 'My Profile',
};

export const MY_DETAILS = {
  TITLE: 'My Details',
};

export const LIFE_STAGE = {
  TITLE: 'Life Stage',
};

export const COMMON_BUTTONS = {
  TRY_AGAIN: 'Try again',
  CLOSE: 'Close',
  CLEAR: 'Clear',
  APPROVE: 'Approve',
  DECLINE: 'Decline',
  CONFIRM_DECLINE: 'Confirm Decline',
  UPDATE: 'Update now',
  OK: 'Ok',
};

export const ADD_CREW = {
  TITLE: 'Add to your crew',
  CREW_PROFILE: 'Crew profile',
  UPDATE_YOUR_CREW: 'Update your crew',
  CREW_INFO:
    'Is your crew a particular person, or an organisation where more than one person supports you?',
  ORGANISATION_PROFILE: 'Organisation Profile',
  CHANGE_PHOTO: 'Change photo',
  CONTACT_INFO: 'Contact information',
  ACCESS_SECTION: 'Access rights',
  ACCESS_INFO:
    'If you are giving this person access, you’ll need to ensure that their email is provided and ' +
    'select which parts of your account they will access.',
  ACCESS_INFO_LINK: 'Why would I do this?',
  WHY_INVITE_CREW_TITLE: 'Why invite crew?',
  WHY_INVITE_MESSAGE1:
    'You may want to give your trusted crew, like family, access to update your profile and ' +
    'help you get plan ready. You can even choose them to be a decision-maker for you. You might choose to give a ' +
    'trusted support worker read access to your profile so they have access to your latest care plans. Whatever access you give, Leap in! ' +
    'keeps a record of each time your crew access or change your information.',
  WHY_INVITE_MESSAGE2:
    'We want to keep your information safe. Think carefully before you give your crew members access. ' +
    'If you choose Leap in! to manage your plan budgets, we will verify anyone you tell us is a decision-maker to make sure ' +
    'they can act on your behalf.',
  ACCESS: {
    GIVE_ACCESS: 'Give this person access to my account?',
    PROFILE: 'Access to Profile',
    DOCUMENTS: 'Access to Documents',
    FINANCES: 'Access to Finances',
    NOTIFICATIONS: 'Access to Notifications',
    DECISION_TITLE: 'Allow this person to make decisions on my behalf?',
    DECISION_DESCRIPTION:
      "If 'Yes' is selected, Leap in! may contact this person about your account and this person can invite other people to have access to your account.",
    ACCESS_DESCRIPTION:
      'Select which parts of your account this person will have access to:',
  },
  PROFILE: {
    FIRST_NAME: 'First name',
    PREFERRED_NAME: 'Preferred name',
    LAST_NAME: 'Last name',
    CATEGORY: 'Category',
    RELATIONSHIP: 'Relationship to you',
    ORG_NAME: 'Organisation name',
    ORG_NAME_IF_APPLICABLE: 'Organisation (Type to search or add new)',
    SUPPORT: 'How does this person support you?',
    PHONE_NUMBER: 'Phone number',
    EMAIL: 'Email address',
    EXTRA_INFO: 'Extra information',
    NOTES: 'Notes',
  },
  DELETE_CONFIRMATION: {
    HEADER: 'Confirm crew deletion',
    GENERIC_DELETE_DESCRIPTION:
      'You have chosen to delete a crew member. Click "Yes" to confirm delete or click "No" to cancel.',
    SELF_DELETE_DESCRIPTION: (firstName: any, lastName: any) =>
      `You are logged in as the crew member you're trying to delete. If you continue, you'll lose access to ${firstName} ${lastName}'s profile. Do you want to remove yourself as a crew member from their profile?`,
    CANCEL_TEXT: 'No',
    CONFIRM_TEXT: 'Yes',
  },
};

export const PLAN_MANAGEMENT_HTML = {
  LEAP_IN_SELECTED: 'Plan managed by Leap in!',
  SELF_MANAGED_SELECTED: 'Self managed',
  PLAN_MANAGED_SELECTED: 'Plan managed',
  AGENCY_SELECTED: 'Agency managed',
  NOTHING_SELECTED: "I'm still deciding how I want to manage my plan",
};

export const ACCORDIAN = {
  COLLAPSE: 'Close',
  EXPAND: 'Expand',
};

export const BUDGETS_AND_PAYMENTS_SCREEN = {
  MAIN_TITLE: 'budgets',
  UNLOCK_MESSAGE_1: 'Leap in! is a registered NDIS Plan Manager.',
  UNLOCK_MESSAGE_2: 'You could manage your NDIS money here.',
  UNLOCK_MESSAGE_3: 'Still not sure?   Call us on 1300 05 78 78',
  ONBOARD_MESSAGE: "Please note, this is not your budget.\n\nYour budget will appear here once your NDIS Plan details have been confirmed.\n\nPlease check back soon!",
  PLAN_EXPIRED:
    'Your plan is temporarily unavailable.\nPlease check back later, or contact us if you have a question.\n1300 05 78 78',
  ACCESSIBILITY_MESSAGE:
    'Select for more information about benefits of being managed by Leap in!',
  SUPPORTS: 'Supports',
  VIEW_SERVICE_AGREEMENTS: 'View agreements',
  TRACKING: 'Tracking',
  SPENDING_ON_TRACK: '% Spent - Spending on track',
  SPENDING_OFF_TRACK: '% Spent - Spending off track',
  TRACK_HELP_1: 'For tips to make the most out of your NDIS funding visit',
  TRACK_HELP_2: 'Tips & Tricks',
  SPENT_PREFIX: 'Spent',
  SERVICE_AGREEMENTS_ALLOCATED_TOTAL_MESSAGE: 'Agreed Services',
  NO_CONTENT_MESSAGE: 'No related information to show',
  OTHER_PROVIDERS: 'Other Providers',
  REMAINING: 'Remaining',
  LEARN_MORE: 'Learn more',
  SIGN_ME_UP: 'Sign up for plan management',
  ONBOARDING_BANNER_TITLE: 'You rock!',
  ONBOARDING_BANNER_TEXT1: 'Thanks for choosing Leap in! as your plan manager.',
  ONBOARDING_BANNER_TEXT2: "We'll let you know when you're good to go.",
};

export const PAYMENTS_HISTORY_SCREEN = {
  MAIN_TITLE: 'payments history',
  BALANCE: 'Remaining Plan Balance',
  PLAN_NUMBER: 'NDIS plan #',
  COLUMN1: 'Date',
  COLUMN2: 'Supports Provider',
  COLUMN3: 'Invoice No.',
  COLUMN4: 'Value',
  SEARCH_PAYMENTS: 'Search payments',
  SEARCH_PREVIOUS_PAYMENTS: 'Search previous payments',
};

export const SERVICE_AGREEMENTS_LIST_SCREEN = {
  MAIN_TITLE: 'Service Agreements',
  SUPPORT_CATEGORY: 'Support Category',
  DATE: 'Start - End Date',
  USED: 'Spent',
  REMAINING: 'Remaining',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const SERVICE_AGREEMENTS_DETAILS_SCREEN = {
  MAIN_TITLE: 'Service Agreement',
  SUPPORT_PROVIDER: 'Supports Provider',
  PROVIDER_REF_NUM: 'Provider Reference Number',
  SUPPORT_CATEGORY_NUM: 'Support Category Number',
  AGREEMENT_NUMBER: 'Service Agreement ID',
  DATE: 'Start - End Date',
  SUPPORT_CATEGORY: 'Support Category',
  TOTAL: 'Total',
  TOTAL_ALLOCATED: 'Total Allocated',
  USED: 'Used',
  SPENT: 'Spent',
  REMAINING: 'Remaining',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  TABLE_TITLES: ['Description', 'Code', 'Units', 'Unit Price', 'Budget'],
};

export const PAY_ADHOC_INVOICE_SCREEN = {
  MAIN_TITLE: 'Pay new invoice',
  SELECT_PROVIDER_TITLE: 'Load invoices here',
};

export const PAYMENTS_DETAILS_SCREEN = {
  MAIN_TITLE: 'payments history',
  INVOICE_NUMBER: 'Invoice Number ',
  PROVIDER: 'Supports provider',
  INVOICE_DATE: 'Invoice date',
  DOCUMENT: 'Document',
  RATING: 'Rating',
  PLAN_NUMBER: 'NDIS plan no.',
  DATE_RANGE: 'Date range',
  INVOICE_TOTAL: 'Invoice Total',
  CLAIMED_TOTAL: 'Claimed Total',
  FUNDED_TOTAL: 'Funded Total',
  INVOICED_TOTAL: 'Invoiced Total',
  ACCORDIAN1: 'Pending approval',
  ACCORDIAN2: 'Approved',
  ACCORDIAN3: 'Not claimable',
  INVOICE_COL1: 'Item code',
  INVOICE_COL2: 'Description',
  INVOICE_COL3: 'Support category',
  INVOICE_COL4: 'Qty',
  INVOICE_COL5: 'Unit price',
  INVOICE_COL6: 'Total price',
  INVOICE_COL7: 'Date',
  OPEN_DOC: 'Open Invoice Document',
  SECTIONS: {
    CLAIM_APPROVED: 'Claim Approved',
    PARTIALLY_APPROVED: 'Partially Approved',
    AGENCY_REJECTED: 'Agency Rejected',
    SUBMITTED: 'Submitted',
    CLAIM_REJECTED: 'Claim Rejected',
    MEMBER_REJECTED: 'Member Rejected',
    NEEDS_APPROVAL: 'Needs Approval',
    CANCELLED: 'Cancelled',
    REJECTED: 'Rejected',
    REFUNDED: 'Refunded',
  },
  COLUMNS: {
    ITEM_CODE: 'NDIS Code',
    DESCRIPTION: 'Description',
    CATEGORY: 'Support category',
    QUANTITY: 'Quantity',
    UNIT_PRICE: 'Unit price',
    TOTAL_PRICE: 'Total price',
    TOTAL_REQUESTED: 'Total requested',
    TOTAL_FUNDED: 'Total funded',
    DATE: 'Service dates',
  },
  UPLOAD_INVOICE_POPUP: {
    TITLE: 'Confirm to replace attachment',
    MESSAGE:
      'Are you sure you want to replace the invoice attachment with a different file?',
    YES: 'Yes',
    NO: 'No',
  },
  UPLOAD_INVOICE_POPUP_EMPTY: {
    TITLE: 'Confirm to upload attachment',
    MESSAGE:
      'Are you sure you want to upload the invoice attachment against this invoice?',
    YES: 'Yes',
    NO: 'No',
  },
  BILL_TO: 'Leap in! paid to ',
};

export const DOCUMENTS_SCREEN = {
  MAIN_TITLE: 'documents',
  COLUMN1: 'Title',
  COLUMN2: 'Category',
  COLUMN3: 'Date',
  COLUMN4: 'Add',
  SELECT_DOCUMENT: 'Select Document',
  DOCUMENT_RESTRICT_TITLE: 'Oops! File type not supported',
  DOCUMENT_RESTRICT_MESSAGE:
    'The file type you are trying to upload is not supported.',
};

export const BENEFIT_OF_LI_SCREEN = {
  TITLE_1: 'Looking for choice and control without the stress?',
  TITLE_2: 'Ask to be plan managed by Leap in!',
  POINT_1: 'See your NDIS funds in real time',
  POINT_2: 'Choose any provider to help you meet your goals',
  POINT_3:
    'Get help to find and book supports and make the most out of your plan',
  POINT_4: 'Enjoy making decisions without the paperwork hassle',
  POINT_5: 'Choose to track and share your goals',
  QUESTION: 'Which one best describes you?',
  PLAN_READY_BTN: "I'm getting plan ready",
  NDIS_PLAN_BTN: "I've got my NDIS Plan",
  PLAN_DELIVERY_TITLE: 'Coming soon!',
  PLAN_DELIVERY_MESSAGE:
    'Contact us to be the first to know when this new feature is ready!',
};

export const SIDEBAR_PLAN_MANAGEMENT = {
  MAIN_TITLE: "You're ready to create the life you want to lead.",
  MAIN_TITLE_PAST_DATE:
    "Have you already had your NDIS plan meeting and now you're ready for plan management?",
  BODY_TEXT1:
    "You've had your plan meeting and now you're ready to start finding and booking supports, setting up your goals and managing how your money is being spent.",
  BODY_TEXT2: "We're here to help you every step of the way",
  BODY_TEXT3:
    "If so, tap below, or give us a call on 07 3724 0367 if you want to chat to one of our Plan Managers about what's next",
  BUTTON: "Let's get started!",
  SIGNUP_NOW: 'I want to be\n plan managed',
};

export const SIDEBAR_MEETING_DATE = {
  MEETING_DATE_NONE: 'Enter your plan meeting date',
  MEETING_DATE_TOMORROW: 'Day to your NDIS meeting',
  MEETING_DATE_FUTURE: 'Days to your NDIS meeting',
  MEETING_DATE_TODAY: 'Today is your NDIS Meeting',
  MEETING_DATE_PAST:
    'Enter your new plan meeting or a plan review date, or get started with Leap in! plan manager',
};

export const SPLASH_CONTENT = {
  BUTTON: "Let's go!",
  GOALS: {
    DESCRIPTION:
      "Goals describe what you want to achieve, develop, learn or do.\n\nThe easiest way to think about goals is to think about what's important to you.",
    ADDITIONAL_INFO: 'Add your own or get inspired by Leap in! ideas.',
    TITLE: 'Time to add your goals',
  },
  SUPPORTS: {
    TITLE: 'Time to add supports',
    DESCRIPTION:
      'Here you can add and review the supports and services that you currently receive.',
    ADDITIONAL_INFO:
      'Add your own or get help using a Leap in! list of supports.',
  },
};

export const CREW_CREATE_MEMBER = {
  HEADER1: 'Tell us about the',
  HEADER2: 'person you are helping',
  CATEGORY_CREW: "How do you fit into this person's life?",
  EMAIL_INFO1:
    'An email will be sent to the participant, asking them to set a password so that they can login to access their profile.',
  EMAIL_INFO2:
    "If you want this person to have access, add their email address here. They won't be able to access their profile themselves without one.",
  BUTTON: 'Next',
  BACK_BUTTON: 'Back',
  INFO_TEXT:
    'Does the person you are helping want to login to their own profile?  If so, enter their email here, and they will get details on how to access the app. \n\nIf you leave the email blank, only you will be able to maintain their profile.',
  DEMO_PROFILE_WARNING:
    "Because this is a demo profile, you're unable to add a new member at this time.",
};

export const BUDGET_SPEND_GRAPHS = {
  SPENT: 'Spent',
  TOTAL_ALLOCATED: 'Total allocated by service agreeements',
  TOTAL_FUNDING: 'Total funding',
  TOTAL_FUNDING_NDIS: 'Total funding for NDIS Plan #',
  TOTAL_SUPPORT: 'Total',
  UNASSIGNED_SUPPORT: 'left',
  VIEW: 'View',
  TOTAL_BUDGET: 'Total funds',
  AVAILABLE_NOW: 'Available now',
  NEXT_BUDGET_RELEASE: 'Next fund release',
  ALL_BUDGETS_RELEASED: 'All funds released',
  SHOW_UPCOMING_FUND_RELEASES: 'Show upcoming fund releases',
  UPCOMING_FUND_RELEASES: 'Upcoming fund releases',
  RELEASE_SCHEDULE: 'Release schedule',
};

export const CORE_FLEXIBLE_CATEGORIES_TXT = 'Your Core flexible budget categories are:'

export const PLAN_DATE_TEXT = {
  START_DATE: 'Plan starts - ',
  END_DATE: 'and ends - ',
};

export const SIGNUP_WELCOME_SCREEN = {
  HEADER: 'Welcome!',
  PR_PARTICIPANT1:
    'An important part of the NDIS is preparing for your planning meeting.',
  PM_PARTICIPANT1:
    'You’ve got your NDIS plan and you’re ready to start finding and booking your supports.',
  PM_PARTICIPANT2: 'We’re here to help every step of the way.',
  PR_CREW1:
    'An important part of the NDIS is preparing for the NDIS plan meeting.',
  PR_CREW2: "We're here to help make sure it is the best it can be.",
  PM_CREW1:
    'The person you are helping has their NDIS plan and together, you’re both ready to start finding and booking their supports.',
  PM_CREW2: 'We’re here to help every step of the way.',
  BUTTON: "Let's get started",
};

export const SIGNUP_CREW_INSTRUCTIONS_SCREEN = {
  HEADER1: 'Does the person you are helping',
  HEADER2: 'already have a Leap in! account?',
  YES: 'Yes',
  YES_DESCRIPTION: 'I would like to help!',
  SEPARATOR: 'or',
  NO: 'No',
  NO_DESCRIPTION: "I would like to create an account and have the person's ok.",
  CHOOSE_OPTION: 'Please choose an option',
};

export const SIGNUP_OPTIONS_NEW_EXISTING_SCREEN = {
  PARTICIPANT_HEADER: 'What best describes you\u2026',
  PARTICIPANT_OPTION1A: "I'm new to NDIS",
  PARTICIPANT_OPTION1B: 'Help me get ready',
  PARTICIPANT_OPTION2A: "I've got my plan",
  PARTICIPANT_OPTION2B: "Let's go!",
  CREW_HEADER: 'What best describes the person',
  CREW_HEADER_SECONDARY: 'you are helping\u2026',
  CREW_OPTION1A: 'They are new to the NDIS',
  CREW_OPTION1B: 'Help them get ready',
  CREW_OPTION2A: 'They have their NDIS plan',
  CREW_OPTION2B: "Let's go!",
  SEPARATOR: 'or',
  CHOOSE_OPTION: 'Please choose an option',
};

export const PREAUTHENTICATION_SCREEN = {
  HEADER1: 'Leap into',
  HEADER2: 'your NDIS journey',
  DESCRIPTION:
    'Prepare you for your NDIS planning meeting and get the most out of your plan.',
  BUTTON_SIGNUP: 'Sign me up!',
  BUTTON_LOGIN: 'Log in',
};

export const SIGNUP_OPTIONS_SCREEN = {
  HEADER: 'Are you\u2026',
  PARTICIPANT: 'participant',
  SEPARATOR: 'or',
  PARTICIPANT_SUBTEXT:
    "If you're about to, or already receiving, disability supports - you'll become a Leap in! Member",
  CREW: 'crew',
  CREW_SUBTEXT:
    "If you're a parent, sibling, child, friend, support worker or another person who provides support",
  CHOOSE_OPTION: 'Please choose an option',
};

export const SIGNUP_OPTIONS_PLAN_MANAGER_SCREEN = {
  HEADER: 'How are you managing your NDIS plan?',
  LEAP_IN_MANAGED: 'I’ve selected Leap in! as my plan manager',
  SEPARATOR: 'or',
  OTHER: 'I’ve selected another way of managing my finances',
  CHOOSE_OPTION: 'Please choose an option',
};

export const UPLOAD_PERSONAL_DETAILS_SCREEN = {
  SIGN_UP_LEAP_IN_TEXT: 'Sign up Leap in! as my Plan Manager',
  CONFIRM_DETAILS_TEXT: 'Awesome! Please confirm a few details to get started.',
  MAIN_TITLE: 'Upload your NDIS Plan',
  BODY_TEXT1: 'So we can get started, please help us with some details.',
  SAVE_BUTTON_TEXT: 'Next',
  CANCEL_BUTTON_TEXT: 'Cancel',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  PHONE_NUMBER: 'Phone number',
};

export const TERMS_CONDITION_PM_SCREEN = {
  HEADER: 'Terms & Conditions',
  BODY_TEXT1:
    "By agreeing to the terms & conditions of signing up Leap in! as your Plan Manager, you're confirming that you have Plan Management budget in your plan, and you allow us to:",
  BODY_TEXT2: 'Manage NDIS finances on your behalf',
  BODY_TEXT3:
    'Share your details with providers that may provide you with supports',
  BUTTON: 'Read full Terms & Conditions',
  CANCEL_BUTTON_TEXT: 'Cancel',
};

export const TERMS_CONDITIONS_FULL_PM_SCREEN = {
  MAIN_TITLE: 'Li! Terms & Conditions',
  BUTTON: 'I agree',
  CANCEL_BUTTON_TEXT: 'Cancel',
};

export const UPLOAD_PLAN_SCREEN = {
  MODAL_WELCOME_TEXT: 'Welcome aboard',
  MODAL_WE_WILL_BE_IN_TOUCH_TEXT:
    "We'll be in touch once we've confirmed your details with the NDIA!",
  MODAL_GOT_IT_TEXT: 'OK, got it!',
  LETS_GET_STARTED_TEXT: "Let's get started!",
  GUIDE_ME_TEXT:
    'We need to know your Participant ID and plan start date to set you up. Not sure? Call us on 1300 05 7878 or tap Guide Me',
  MAIN_TITLE: 'Upload your NDIS Plan',
  BODY_TEXT1: 'So we can get started, please help us with some details.',
  SAVE_BUTTON_TEXT: 'Save',
  CANCEL_BUTTON_TEXT: 'Cancel',
  COULDNT_SAVE_PLAN_ERROR_TEXT:
    "Error: Couldn't save your plan. Please try again.",
  COULDNT_SAVE_PLAN_NETWORK_ERROR_TEXT:
    "Error: Couldn't save your plan because of a network issue. Please try again.",
  UPLOAD_FILE_TEXT: 'Upload Plan',
  MESSAGE_TEXT_1_FORM_INITIAL:
    'Thanks for signing up! Now we need your NDIS Plan details to get you up and running.',
  MESSAGE_TEXT_1_FORM_VALIDATION_FAILED:
    'We need to know your Participant ID and plan start date to set you up.',
  MESSAGE_TEXT_2: 'Not sure? Call us on 1300 05 7878 or tap Guide Me',
  NDIS_PARTICIPANT_ID: 'NDIS Participant ID',
  NDIS_PARTICIPANT_ID_ACCESSIBILITY: 'N D I S Participant ID',
  PLAN_START_DATE: 'Plan Start Date',
  SELECT_DOCUMENT: 'Select Document',
};

export const LOGIN_SIGNUP_SCREEN = {
  CREW_SIGNUP1: "Here, we're creating an account for you.",
  CREW_SIGNUP2:
    "We'll ask you about an account for the person you are supporting in just a moment.",
  PASSWORD_REQUIREMENT: 'Password must have at least 8 characters.',
  LOGIN_TITLE_1: 'Log in to',
  LOGIN_TITLE_2: 'my NDIS planning app',
  SIGNUP_TITLE_1: 'Create an account',
  SIGNUP_TITLE_2: 'to unlock your NDIS',
  CREW_SIGNUP_TITLE_1: 'Create a crew account',
  CREW_SIGNUP_TITLE_2: 'for yourself first',
  REQUESTACCESS_TITLE_1: 'Request access to \n their ',
  REQUESTACCESS_TITLE_2: ' account',
  TERMS_OF_USE_TEXT_1: 'I have read and agreed to the ',
  TERMS_OF_USE_TEXT_2: 'Terms of Use ',
  DONT_HAVE_ONE: "Don't have an account? Create one ",
  HERE: 'here.',
  LOGIN_TO: "I 'd like to login to",
  MY_ACCOUNT: 'My Account',
  TEMP_PASSWORD_EXPIRED_MODAL_CANCEL: 'NO',
  TEMP_PASSWORD_EXPIRED_MODAL_OK: 'YES',
  TEMP_PASSWORD_EXPIRED_MODAL_HEADER: 'Sign in failed',
  TEMP_PASSWORD_EXPIRED_MODAL_MESSAGE:
    'Your old temporary password has expired. Would you like us to send you a temporary password?',
  TEMP_PASSWORD_SENT_MODAL_HEADER: 'Check your inbox',
  TEMP_PASSWORD_SENT_MODAL_MESSAGE:
    "We've sent you a new temporary password and instructions to access your Leap in! account",
  RETURN_TO_LEAPIN: 'Return to Leap in! website',
  RETURN_TO_WELCOME: {
    BUTTON_TEXT_1: 'Return to the ',
    BUTTON_TEXT_2: 'Welcome page',
  },
};

export const HTTP = 'http:';

export const SIDEBAR_PLAN_MANAGEMENT_ACTIONS = {
  TIPS_TRICKS: {
    DISPLAY_TEXT: 'Tips & Tricks',
    URI: `${HTTP}//www.leapin.com.au`,
    LINK_TYPE: URI_MODE.EXTERNAL_LINK,
  },
  APPROVALS_SUMMARY: {
    DISPLAY_TEXT: 'Approvals',
    URI: 'ApprovalsSummary',
    LINK_TYPE: URI_MODE.DEEP_LINK,
  },
  PAYMENTS_HISTORY: {
    DISPLAY_TEXT: 'Payments history',
    LINK_TYPE: URI_MODE.PAYMENTS_HISTORY_LINK,
  },

  SERVICE_AGREEMENTS_LIST: {
    DISPLAY_TEXT: 'Service agreements',
    LINK_TYPE: URI_MODE.SERVICE_AGREEMENTS_LIST_LINK,
  },

  PAY_ADHOC_INVOICE: {
    DISPLAY_TEXT: 'Pay adhoc invoice',
    LINK_TYPE: URI_MODE.PAY_ADHOC_INVOICE_LINK,
  },
};

export const APPROVALS_SUMMARY = {
  TITLE: 'approvals',
  HEADINGS: {
    FIRST: 'Date',
    SECOND: 'Supports Provider',
    THIRD: 'Type',
    FOURTH: 'Value',
  },
  TYPE: {
    INVOICE: 'Invoice',
    SERVICE: 'Service agreement',
  },
  SERVICE_AGREEMENT: 'Service agreement',
  NO_APPROVAL_MESSAGE: 'Nice work!! You have nothing to approve!',
  APPROVED: 'Approved',
  APPROVED_INVOICE: 'Invoice approval recorded confirmation',
  APPROVED_SA: 'Service agreement recorded confirmation',
  APPROVAL_FAILED: 'Approval Failed',
  APPROVED_MESSAGE:
    'Thanks for approving this agreement. Once its processed we’ll let your provider know its good to go.',
  APPROVE_INVOICE_GROUP_MESSAGE: (invoiceCount: any, provider: any) =>
    `Approve all ${invoiceCount} invoices for ${provider} below?`,
};

export const APPROVALS_INVOICE_DETAILS = {
  TITLE: 'Invoice to Approve',
  TOTAL_INVOICE_TITLE: 'Invoice Total',
  TOTAL_FOR_APPROVAL: 'Total for Approval',
  INVOICE_NUMBER: 'Invoice number',
  INVOICE_DATE: 'Invoice date',
  PROVIDER: 'Support Provider',
  DATE_RANGE: 'Date range',
  APPROVED: 'Approved',
  APPROVAL_FAILED: 'Approval Failed',
  SECTIONS: {
    NEEDS_APPROVAL: 'Needs Approval',
    APPROVED: 'Claim Approved',
    PARTIALLY_APPROVED: 'Claim Approved',
    NOT_CLAIMABLE: 'Not Claimable',
  },
  CLAIMABLE_FOR_APPROVAL: 'Claimable for Approval',
  NOT_CLAIMABLE: 'Not claimable',

  SECTIONS_SUBTEXT: {
    NEEDS_APPROVAL:
      'These items are covered under your NDIS budget and need your approval to be claimed for payment to the provider.',
    APPROVED:
      "These items are covered under an existing service agreement or approved 'ad hoc' invoice, and have been pre-authorised and lodged for claim and payment to your provider.",
    NOT_CLAIMABLE:
      'These items are not covered by your plan, and your provider has been notified that they cannot be claimed.',
  },
  COLUMNS: {
    ITEM_CODE: 'NDIS Code',
    DESCRIPTION: 'Description',
    CATEGORY: 'Support category',
    QUANTITY: 'Quantity',
    UNIT_PRICE: 'Unit price',
    TOTAL_PRICE: 'Total price',
    DATE: 'Service dates',
  },
  COLUMNS_HH: {
    CLAIMED_QUANTITY: 'Claimed Quantity',
    CLAIMED_PRICE: 'Claimed Price',
    CLAIMED_TOTAL: 'Claimed Total',
    INVOICED_QUANTITY: 'Invoiced Quantity',
    INVOICED_PRICE: 'Invoiced Price',
    INVOICED_TOTAL: 'Invoiced Total',
    FUNDED_TOTAL: 'Funded Total',
  },
  ACTUAL_INVOICED: {show: 'Actual Invoiced', hide: 'Hide Invoiced Details'},
  ADDITIONAL_DETAILS: [
    'This invoice is complete and from a recognized provider',
    'You have budget available to cover this invoice',
    'The provider has not exceeded the NDIS price guide',
  ],
  ADDITIONAL_DETAILS_TITLE: 'We have undertaken the following checks for you:',
  NOTES_FOR_MEMBER: 'Invoice notes',
  NOTES_FOR_MEMBER_STAFF: 'Notes for Member',
  WORK_NOTES: 'Work notes',
};

export const APPROVALS_INVOICE_REJECT = {
  TITLE: "Please tell us why you've declined this ",
  SUB_TITLE: 'Please select one.',
  TITLE_FURTHER_MORE: 'Further information',
  SUB_TITLE_FURTHER_MORE: 'Please provide as much information as you can.',
  SUB_TITLE_FURTHER_MORE_SA:
    'Provide any other information you think might be useful.',
  DETAILS_PLACEHOLDER: 'E.g Price is not as expected',
  INVOICE: 'invoice?',
  AGREEMENT: 'service agreement?',
};

export const APPROVALS_AGREEMENT_DETAILS = {
  TITLE: 'Service Agreement to Approve',
  PROVIDER: 'Support Provider:',
  CATEGORY: 'Budget Category',
  DESC: 'Description:',
  DATE: 'Start - end date:',
  AMOUNT: 'Amount:',
  TABLE_TITLES: ['Description', 'Code', 'Units', 'Unit Price', 'Total'],
  TOTAL: 'Total',
  PRICE: 'Unit Price',
  QUANTITY: 'Quantity',
  BUDGET: 'Total',
};

export const CREWS_SCREEN = {
  TITLE: 'crew',
};

export const ADD_SUPPORT_SCREEN = {
  TITLE: 'supports',
  SUPPORT_CATEGORY: 'NDIS support category',
};

export const NEXT_BUTTON = {
  NEXT_BUTTON_MESSAGE: 'Next button enabled',
};

export const INPUT_CONTROL_ACCESSIBILITY = {
  UPLOAD_INVOICE: ' document upload',
  DATE_PICKER: ' date picker',
  SEARCH: ' Search',
  DROPDOWN: ' Dropdown Button',
  MANDATORY: ' Required',
  DISABLED: ' Disabled',
};

export const INVOICE_DETAILS_SCREEN = {
  MAIN_TITLE: 'Invoice Details',
  UPLOAD_INVOICE: 'Upload invoice',

  SELECT_INVOICE: 'Select invoice',
};

export const ADD_NEW_PROVIDER = {
  HEADER_TITLE: 'Add or Search Provider',
  MAIN_TITLE: 'Add or search for a provider by name or ABN',
  YES: 'Yes',
  NO: 'No',
};

export const SUPPORT_ITEM_SCREEN = {
  TITLE_1: 'Support item description',
  TITLE_2: 'Price and quantity',
  TITLE_3: 'Support date range',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  INPUT_PLACEHOLDER: 'Start typing here\u2026',
  RATE_IN_DOLLARS: 'Rate (in dollars)',
  SUPPORT_DATE_RANGE: 'Support date range',
};

export const REVIEW_INVOICE_SUMMARY_SCREEN = {
  MAIN_TITLE: 'Review Draft Invoice',
  TITLE_2: 'Provider',
  TITLE_3: 'Invoice detail',
  TITLE_4: 'Support item',
  ABN: 'ABN',
  INVOICE_NUMBER: 'Invoice Number',
  TOTAL_INVOICE_TITLE: 'Invoice Total',
  APPROVED_HEADER: 'Approved',
  APPROVED_SUBHEADER:
    'Your claim is being lodged and your provider should be paid soon.',
  MEMBER_APPROVAL_HEADER: 'Success',
  MEMBER_APPROVAL_SUBHEADER:
    'This draft claim has been validated and will be presented to the member for approval.',
  ALREADY_APPROVED_HEADER: 'Invoice Already Saved',
  ALREADY_APPROVED_SUBHEADER:
    'This invoice has been saved already. You can check details in payment history.',
  REJECTED_HEADER: 'Claim cannot be submitted',
  REJECTED_SUBHEADER: 'Your claim cannot be submitted.Please check your claim',
  BEINGPROCESS_HEADER: 'Claim is being processed',
  PREVIEW_INVOICE: 'Preview your invoice',
  COLUMNS: {
    ITEM_CODE: 'NDIS Code',
    DESCRIPTION: 'Description',
    CATEGORY: 'Support category',
    QUANTITY: 'Quantity',
    UNIT_PRICE: 'Unit price',
    TOTAL_PRICE: 'Total price',
    DATE: 'Service dates',
  },
  APPROVED: 'approved',
  ALREADY_APPROVED: 'already_approved',
  SUBMIT_PREAPPROVED: 'Submit Pre-approved',
  SUBMITED_FOR_APPROVAL: 'submitted_for_approval',
  BILL_TO: 'Leap in! bills to ',
};

export const ACCESSIBILITY_SCREEN_TITLES = {
  SPLASH_PART1: 'Welcome To',
  SPLASH_PART2: 'NDIS Planning App',
  SPLASH_PART3: ', powered by Leap in',
  SIGNUP_OR_LOGIN: 'Leap in to your NDIS Journey',
  SIGNUP: 'Sign up with Leap in! as a Participant or Crew',
  SIGNUP_PARTICIPANT: 'Sign up as Participant',
  SIGNUP_CREW: 'Sign up as Crew',
  SIGNIN: 'Sign in to Leap in!',
  SIGNIN_PARTICIPANT: 'Create member account with Leap in',
  SIGNIN_CREW: 'Create crew account with Leap in',
  PARTICIPANT_OR_CREW: 'Select participan or crew',
  HERE: 'here',
  LIKETOLOGIN: "I'd like to login to My account",
  CREATE_ACCOUNT: 'Create an account with Leap in',
  TERM_CONDITION: 'Terms and Conditions required',
  SELECTED: 'Checkbox is selected double tap to deselect',
  NOT_SELECTED: 'Checkbox is not selected double tap to select',
};

export const POPUP_TITLES = {
  SIGNIN_FAIL: 'Sign in failed - Try again?',
  SIGNUP_FAIL: 'Account creation failed',
  PERSON_YOU_ARE_HELPING: 'Important Information about creating a member',
  WRONG_PASSWORD: 'Wrong password',
  PASSWORD_CHANGE: 'Password changed confirmation',
  CLOSE_ACCOUNT: 'Close account confirmation request',
  DATA_SHARING_CONSENT: 'Terms and data sharing consent confirmation',
  LOGOUT_CONFIRMATION: 'Logout confirmation request',
  ADD_PROFILE_PICTURE: 'Add Profile picture',
  ADD_SUPPORT_PICTURE: 'Add Support picture',
  ADD_CREW_PICTURE: 'Add Crew profile picture',
  PLAN_MANAGEMENT_OPTION_SELECTED:
    'Select Leap in or other plan manager request',
  PLAN_MANAGEMENT_WELCOME: 'Welcome aboard',
  CLAIM_FAIL: 'Claim unsuccessful warning',
  CLAIM_SUCCESS: 'Claim success confirmation',
  INVOICE_APPROVAL: 'Invoice approval recorded confirmation',
  SERVICE_AGREEMENT_APPROVAL: 'Service agreement recorded confirmation',
  SERVICE_AGREEMENT_FAIL: 'Service agreement approval unsuccessful warning',
  LEAVE_CURRENT_FORM: 'Leave current screen confirmation prompt',
  ACCOUNT_ACCESS: 'Leap in! account access',
};

export const ADD_SUPPORTS_BY = {
  TITLE: 'Add supports',
  DESCRIPTION:
    'Think of any supports, services, consumables, equipments, aids or modifications that you use, and capture them all here.',
  CREW: 'crew',
  CREW_DESC:
    "By selecting 'Crew' you can add a support by person or organisation.",
  OR: 'or',
  CATEGORY: 'category',
  NONE: 'none',
  CATEGORY_DESC:
    "By selecting 'Category' you can explore a range of suggested support descriptions by the category of support you need.",
  SUPPORT_OPTIONS: [
    {
      OPTION_KEY: 'category',
      OPTION_TITLE: 'Add by Category',
      OPTION_DESC: 'Flip through categories to explore a range of suggested',
    },
    {
      OPTION_KEY: 'crew',
      OPTION_TITLE: 'Add by Crew',
      OPTION_DESC:
        'For people or organization in your crew, add a list of the supports they provide',
    },
  ],
};

export const ADD_SUPPORTS_BY_CREW = {
  TITLE: 'Who provides your support?',
  DESC: 'Select all crew and organisations that apply',
  FORM_TITLE: 'Add any supports ',
  AND: ' and ',
  PROVIDE_YOU: ' provide you',
  SEARCH_FIELD_TEXT_EMPTY: 'Type here to search or enter your own support',
  SEARCH_FIELD_TEXT_NON_EMPTY: 'Type here to add another',
};

export const ADD_SUPPORTS_BY_CATEGORY = {
  TITLE: 'What current supports do you have?',
  DESC: 'Tap a category to see related supports',
};

export const CREW_ACCESS = {
  MAIN_TITLE: "It's easy to help!",
  DESC: "If you'd like to help a Member to navigate their NDIS journey, you'll need to ask them to set you up as a Crew member who has the ok to view or edit their profile.",
  TITLE_3: 'Ready now?',
  TITLE_4: "Here's how to do it together...",
  TITLE_5: '1. ',
  TITLE_5_1: 'Go to the My Profile ',
  TITLE_5_2: 'page and select ',
  TITLE_5_3: ' in the ',
  TITLE_5_4: 'Crew ',
  TITLE_5_5: '  section',
  TITLE_6: '2. Select ',
  TITLE_7: '3. Fill in your details and',
  TITLE_8: '4 ',
  TITLE_8_1:
    "Look for an email from Leap in!, click on the link and  you're good to go!",
};

export const CHOOSE_PARTICIPANT_CREW = {
  I_AM: 'I am...',
  PARTICIPANT: 'Participant',
  PARTICIPANT_DESC: 'I am a current or potential NDIS participant',
  PARTICIPANT_DESC_BR: 'I am a current or potential NDIS participant',
  CREW: 'Crew',
  CREW_DESC: 'I am helping someone else with their NDIS plan',
  CREW_DESC_BR: 'I am helping someone else with their NDIS plan',
};

export const MEMBER_TYPE_NOT_SPECIFIED = {
  status: 'undefined',
  MESSAGE: 'Member type has not been specified',
};

export const NAVIGATION_CONFIRM_MODAL = {
  NAVIGATION_TITLE: 'Leave current screen confirmation prompt',
  NAVIGATION_CONFIRMATION_MESSAGE:
    'You haven’t saved your changes yet. Click OK to leave this screen anyway, or Cancel to stay, so you can check and save your changes.',
  NAVIGATION_CONFIRM_MODAL_OK: 'Ok',
  NAVIGATION_CONFIRM_MODAL_CANCEL: 'Cancel',
};

export const LOGOUT_CONFIRMATION_MODAL = {
  LOGOUT: 'Logout confirmation request',
  LOGOUT_CONFIRMATION_MESSAGE: 'Are you sure you want to logout?',
  LOGOUT_CONFIRMATION_OK: 'Ok',
  LOGOUT_CONFIRMATION_CANCEL: 'Cancel',
  EXIT: 'Exit confirmation request',
  EXIT_CONFIRMATION_MESSAGE: 'Are you sure you want to Exit?',
  EXIT_CONFIRMATION_SUB_MESSAGE:
    'If you continue you’ll lose any temporary changes you’ve made this demo profile.',
};

export const DATA_SHARING_CONSENT_MODAL = {
  TITLE_TEXT_1: "We've noticed this is the first time you've logged into the ",
  TITLE_TEXT_2: ' NDIS Planning App.',
  ACCEPT_TERMS_TEXT_1: 'I accept the ',
  TERMS: 'terms',
  ACCEPT_TERMS_TEXT_2: ' for this app',
  SHARE_PROFILE_INFO_TEXT:
    "I'm happy to keep sharing my profile information with ",
  CONTINUE_TEXT: 'Continue',
};

export const MEMBER_STATUS = {
  PLAN_READY: 'Plan ready',
  ONBOARDING: 'Onboarding',
  MANAGED: 'Plan managed',
};

export const ADD_GOALS = {
  GOAL_DETAIL: 'Goal Detail',
  GOAL_IS: 'My Goal is..',
  TYPE_OR_SELECT: 'Type your goal here or select from below.',
  SUGGESTED_GOALS: 'Suggested goals for your lifestage:',
  CHECK_OTHERS: 'Check some others?',
  SHOW_GOALS:
    'Click here to find goals that match your life stage and interests',
  GOAL_FILTERS: 'Goal Filters',
  CHOOSE_FILTERS: 'Choose categories or life stages to filter suggested goals',
  SELECT_OPTIONS: 'Please select all that apply.',
  LIFE_STAGE: 'Life Stage',
  CATEGORY: 'Category',
  STEP: 'Step',
  STEP_INFO: 'Type the next step to your goal or select from below',
  ADD_STEPS_INPUT_PLACEHOLDER:
    'Type your step here or select from the inspiration below.',
  GOAL_NAME: 'Type your goal here or select from the inspiration below.',
  SUGGESTED_STEPS: 'Here are some suggested steps..',
  DELETE_MESSAGE: 'Do you want to delete this goal and it’s steps?',
  STEP_NOT_SELECTED:
    'You have not selected or entered  step title, to proceed adding a new step.',
  TITLE_NOT_SELECTED:
    'You have not selected or entered a goal title to proceed to next step.',
};

export const SEARCH_MEMBERS = 'Search members';
export const INVOICE_PROCESSING_WARNING = {
  LINE: "We'll notify you if your claim is successful.If you don't hear from us, please contact us \n on 1300 057 878",
};

export const SETTINGS_SCREEN = {
  HEADER_TITLE: 'Settings and info',
  CHANGE_MY_PASSWORD: 'Change my password',
  CLOSE_MY_ACCOUNT: 'Close my account',
  ASSISTANCE: 'Need assistance?',
  LINK_MEMBER_TO_MY_ACCOUNT: 'Link Member to my account',
  ADD_NEW_MEMBER: 'Add new Member',
  LOG_OUT: 'Log out',
  LOGIN_TO_MBC: `Log in to Member
Benefits Club`,
  LOGIN_TO_MEMBER_BENEFITS_CLUB: 'Login to Member Benefits Club',
  ABOUT: 'About ',
  ABOUT_LEAP_IN: 'About Leap in!',
  TERMS_OF_USE_AND_PRIVACY_POLICY: 'Terms of Use & Privacy Policy',
  TERMS_OF_USE_AND_PRIVACY: 'Terms of Use & Privacy',
  PLAN_MANAGED_TS_N_CS: 'Plan Managed Ts & Cs',
  PLAN_MANAGEMENT_TERMS: 'Plan Management Terms',
  ARE_YOU_SURE: 'Are you sure?',
  PERMANENTLY_CLOSE: 'Do you want to permanently close your Leap in! account?',
  PERMANENTLY_CLOSE_MESSAGE:
    'Are you sure you want to close your Leap in! account and log out of the app?',
  PERMANENTLY_CLOSE_CHECKBOX_MESSAGE:
    'Delete all data asssociated with my account',
  PERMANENTLY_CLOSE_ACTIVE_USER_LINE_1:
    'Do you have questions or need help? Please call 1300 05 78 78 or email crew@leapin.com.au.',
  PERMANENTLY_CLOSE_ACTIVE_USER_LINE_2:
    'If you wish to change from Leap in! plan management, please contact us on the number or email above to provide the required notice for your service agreement.',
  PERMANENTLY_CLOSE_ACTIVE_USER_LINE_3:
    'Your account will remain active whilst this is being actioned.',
  CANCEL: 'Cancel',
  NO: 'No',
  YES: 'Yes, Close \n Account',
  YES_CLOSE_MY_ACCOUNT: 'Yes, close my account',
  APP_VERSION: 'App version: ',
  BUILD_NO: 'Build number: #',
  ENVIRONMENT: 'Environment: ',
  DEV: 'dev',
  DEV_DISPLAY: 'DEV',
  QA: 'qa',
  QA_DISPLAY: 'QA',
  UAT: 'uat',
  UAT_DISPLAY: 'UAT',
  OK: 'OK',
};

export const PLANS_SCREEN = {
  HEADER_TITLE: 'My NDIS Plans',
};

export const CHANGE_PASSWORD_SCREEN = {
  HEADER_TITLE: 'Change my password',
  OLD_PASSWORD: 'Old Password',
  NEW_PASSWORD: 'New Password',
  RE_ENTER_PASSWORD: 'Re-enter Password',
  PASSWORD_CHANGED: 'Password changed confirmation',
  PASSWORD_CHANGED_MESSAGE: "We've saved your new password.",
  WRONG_PASSWORD: 'Wrong password',
  WRONG_PASSWORD_MESSAGE: "The password you entered doesn't match our records.",
  REQUEST_NEW_PASSWORD: 'To request a new password click on ',
  NEW: 'New ',
  OLD: 'Old ',
  RE_ENTERED: 'Re-entered ',
  PASSWORD_DONT_MATCH: "Passwords don't match",
  SAVE_BUTTON_TEXT: 'Update',
};

export const PAGINATION_NAVIGATOR = {
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  DISPLAYING_PAGE: 'Displaying the page',
  OF: 'of',
};

export const STAR_RATING = {
  APPROVED: 'Approved',
  YOUR_RATING: 'Your rating',
  HOW_DID_YOU_FIND: 'How did you find their service?',
  YOU_CAN_SKIP: "You can skip this and leave a rating later if you'd prefer.",
  YOUR_INVOICE_APPROVED:
    "Your invoice is approved and we will submit your provider's claim.",
};

export const DEEPLINK_BANNER = {
  VIEW_ON_APP: 'View on app',
  CLOSE: 'Close view on app',
};

export const CREATE_ACCOUNT_FROM_DEMO_MODAL = {
  TITLE: 'Exit confirmation request',
  CONFIRMATION_MESSAGE: 'Are you sure you want to Exit?',
  CONFIRMATION_SUB_MESSAGE:
    'If you continue you’ll lose any temporary changes you’ve made to this demo profile.',
};

export const ABOUT_ME_MY_DETAILS_FORM = {
  SOMETHING_WENT_WRONG: 'Something went wrong! Please try again.',
  CANT_FIND_MY_ADDRESS: "Can't find my address",
  BACK_TO_AUTO_SUGGESTION: 'Back to Auto suggestion',
};

export const REFERRAL_POPUP = {
  TITLE: 'No listing found',
  MESSAGE1: "Seems we can't find anyone in your area at the moment.",
  MESSAGE2:
    'However, our directory is growing all the time so please call your Leap in! crew for their expert advice on 1300 05 7878',
};

export const PROVIDER_ENQUIRY = {
  TELL_PROVIDER_WHAT_YOU_ARE_LOOKING_FOR: (providerName: any) =>
    `Tell ${providerName} what you're looking for..`,
  WE_HAVE_SENT_ENQUIRY: (providerName: any) =>
    `We've sent your enquiry to ${providerName}. We'll send you a copy as well.`,
};

export const DOCUMENT_PLAN_POPUP = {
  TITLE: 'Reminder...',
  MESSAGE: `Is this a new NDIS Plan? Don't forget to share with the Leap in! crew by emailing it to crew@leapin.com.au`,
};

export const BUDGET_UNDER_MAINTENANCE = {
  HEADER_STRONG:
    'We’re working on your account, so the information below may not be up to date. ',
  BODY_NORMAL: 'Need information now? Call us on ',
  MOBILE_NUMBER: '1300 05 78 78.',
  BODY_NORMAL_END:
    'This message will disappear when your information has been updated',
};

export const MY_BUDGETS = {
  EXITED_PLAN_MANAGEMENT_MESSAGE: 'This member has exited plan management',
};
