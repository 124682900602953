import React, {Component} from 'react';
import {CommonActions, DrawerActions} from '@react-navigation/native';
import {
  ScrollView,
  Text,
  View,
  StyleSheet,
  Platform,
  Image,
  TouchableHighlight,
  Linking,
} from 'react-native';
import {StandardText, StandardTouchableOpacity} from '../Components/Atoms';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import Icon from 'react-native-vector-icons/Ionicons';
import {isEmpty} from 'lodash';
import CustomIcon from '../assets/images/CustomIcon';

import {LILogo} from '../assets/images/vector-icons';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

import {connect} from 'react-redux';
import * as brand from '../Constants/BrandConstants';

import {BrandIcons} from '../Styles/BrandIcons';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {getMember} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import * as NotificationActions from '../Actions/NotificationActions';
import {NotificationBadge} from '../Components/Molecules/NotificationBadge';
import * as MemberActions from '../Actions/MemberActions';
import * as GuideMeActions from '../Actions/GuideMeActions';
import * as SideMenuActions from '../Actions/SideMenuActions';
//Detect device is a tablet or mobile
import DeviceInfo from 'react-native-device-info';
import {SidebarDetailsSection, PayInvoiceStep} from '../Components/Molecules';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {focusOnView} from '../Helpers/PlatformSynchronizer';
import {checkFlag} from '../Helpers/featureFlagsHelpers';
import {APP_ID} from '../environments';
import {getNotifications} from '../API/NotificationAPI';
// import FCM, { FCMEvent } from 'react-native-fcm';
import logger from 'helpers/Logger';

class Menu extends Component {
  loggedInRef: any;
  constructor(props: any) {
    super(props);
    this.navigateToScreen = this.navigateToScreen.bind(this);
    this.renderMenuItems = this.renderMenuItems.bind(this);
    this.logout = this.logout.bind(this);
    this._renderUnReadCount = this._renderUnReadCount.bind(this);
  }
  state = {
    isVisible: false,
    orientation: '',
    notificationData: {},
    loadedMemberId: this.props.loadedMemberId,
    componentView: undefined,
  };

  componentDidMount() {
    this._loadNotifications(this.state.loadedMemberId);
  }

  componentDidUpdate(prevProps: any) {
    this.highlightComponent(prevProps);

    if (
      (this.loggedInRef &&
        prevProps.sideMenu.currentScreen !==
          this.props.sideMenu.currentScreen &&
        this.props.isOpen) ||
      (this.props.isOpen && prevProps.isOpen !== this.props.isOpen)
    ) {
      focusOnView(this.loggedInRef);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.notifications && nextProps.notifications.notificationData) {
      this.setState({
        notificationData: nextProps.notifications.notificationData,
      });
    }
    if (
      this.state.loadedMemberId !== undefined &&
      this.state.loadedMemberId.loadedMemberId !=
        nextProps.loadedMemberId.loadedMemberId
    ) {
      this.setState({loadedMemberId: nextProps.loadedMemberId});
      this._loadNotifications(nextProps.loadedMemberId);
    }
  }

  highlightComponent = (prevProps: any) => {
    let isManagedMember =
      this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
      this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED;

    if (
      this.props.guideMe &&
      this.props.guideMe.showWelcomeManagedGuide &&
      prevProps.guideMe.welcomeGuideStep !== this.props.guideMe.welcomeGuideStep
    ) {
      this.toggleSideMenu(DeviceInfo.isTablet());
      const menuItems = this._renderDynamicSideMenuContent();
      if (
        menuItems &&
        menuItems.length &&
        menuItems[this.props.guideMe.welcomeGuideStep - 2]
      ) {
        this.props.actions.GuideMeActions.setHighlightedMenuItem(
          menuItems[this.props.guideMe.welcomeGuideStep - 2].screen,
        );
      }
    }
  };

  toggleSideMenu = (isTablet: any) => {
    if (
      this.props.guideMe &&
      this.props.guideMe.welcomeGuideStep === types2.GUIDE_ME_STEPS.STEP_1
    ) {
      if (!isTablet) {
        this.props.navigation.dispatch(DrawerActions.closeDrawer());
      }
    } else if (
      this.props.guideMe &&
      this.props.guideMe.welcomeGuideStep === types2.GUIDE_ME_STEPS.STEP_2 &&
      !this.props.isOpen
    ) {
      if (isTablet) {
        this.props.toggleMenu();
      } else {
        this.props.navigation.dispatch(DrawerActions.openDrawer());
      }
    }
  };

  navigateToScreen = (route: any, params: any) => () => {
    let navigate = CommonActions.navigate({
      name: route,
      params,
    });

    if (route === types2.NOTIFICATIONS) {
      this.props.actions.NotificationActions.notificationPageNumber(1);
    }

    if (
      route === types2.SIGNUP_FOR_PLAN_MANAGEMENT &&
      this.props.member.pmtnCAccepted
    ) {
      navigate = CommonActions.navigate({
        name: types2.UPLOADPLAN,
        params,
      });
    }

    this.props.navigation.dispatch(navigate);

    if (this.props.itemClickCallBack) {
      this.props.itemClickCallBack();
    }
  };

  _renderDynamicSideMenuContent = () => {
    let dynamicSideMenuContent: any = [];
    const scene = this.props.sideMenu.currentScreen;
    const memberStatus = this.props.member.memberStatus;
    const enableNewPlanOption = this.props.member.enableNewPlanOption;

    const memberObj = this.props.member;
    const userObj = this.props.user;

    const isSAEnabled = this.props.member.isServiceAgreementEnabled;
    if (scene === types2.LINKED_MEMBER) {
      dynamicSideMenuContent = types2.MenuItemsDefault;
    } else if (scene === types2.BUDGETS) {
      if (
        memberObj.pmExitDate != '' &&
        (userObj.isEmployee === true || userObj.isFinancialStaff === true)
      ) {
        dynamicSideMenuContent = types2.MenuItemsBudget;
        dynamicSideMenuContent = dynamicSideMenuContent.filter(
          item =>
            item.screen !== 'DraftClaimInvoices' &&
            item.screen !== 'ClaimInvoice',
        );
      } else {
        if (memberStatus === types2.MEMBER_STATUS_DATA.PLAN_READY) {
          dynamicSideMenuContent = types2.MenuItemsPlanReady;
        } else if (
          memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
          memberStatus === types2.MEMBER_STATUS_DATA.MANAGED
        ) {
          dynamicSideMenuContent = types2.MenuItemsBudget;
          // Remove the cliam invoice menu item from the side menu
          // If the user is in ONBOARDING state or the draft invoices are 0
          if (!checkFlag('claimInvoice', this.props.admin.featureFlags)) {
            dynamicSideMenuContent = dynamicSideMenuContent.filter(
              item =>
                item.screen !== 'DraftClaimInvoices' &&
                item.screen !== 'ClaimInvoice',
            );
          }
          if (
            memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
            this.props.payInvoice.draftInvoicesCount === 0
          ) {
            dynamicSideMenuContent = dynamicSideMenuContent.filter(
              item => item.screen !== 'DraftClaimInvoices',
            );
          }
        }
      }
    } else {
      if (memberStatus === types2.MEMBER_STATUS_DATA.PLAN_READY) {
        dynamicSideMenuContent = types2.MenuItemsPlanReady;
      } else if (
        memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
        memberStatus === types2.MEMBER_STATUS_DATA.MANAGED
      ) {
        if (!enableNewPlanOption) {
          dynamicSideMenuContent = types2.MenuItemsManagedOrOnBoarding;
        } else {
          dynamicSideMenuContent = types2.MenuItemsNewPlanEnabled;
        }
      }
    }

    if (
      isSAEnabled !== undefined &&
      isSAEnabled !== null &&
      JSON.parse(isSAEnabled) === false
    ) {
      dynamicSideMenuContent = dynamicSideMenuContent.filter(
        item => item.screen !== types2.ServiceAgreementsList,
      );
    }

    return dynamicSideMenuContent;
  };

  _memberStatusCheck = () => {
    const memberStatus = this.props.member.memberStatus;
    if (
      memberStatus !== undefined &&
      memberStatus !== types2.MEMBER_STATUS_DATA.MANAGED
    ) {
      return true;
    }
    return false;
  };

  _appIdCheck = () => {
    if (APP_ID !== undefined && APP_ID === types2.APP_ID.LEAPIN) {
      return true;
    }
    return false;
  };

  handleCallUsButtonClick = (phoneNumber: string) => {
    Linking.openURL(`tel:${phoneNumber}`)
  }

  _renderNeedHelp = () => {
    const scene = this.props.sideMenu.currentScreen;

    if (
      scene === types2.PROFILE &&
      this._memberStatusCheck() &&
      this._appIdCheck()
    ) {
      return (
      <StandardTouchableOpacity accessibilityLabel="Call us" onPress={() => this.handleCallUsButtonClick(types2.NEED_HELP.NEED_HELP_TP_NUM)}>
        <View style={[CommonStyles.containerSideMenuNeedHelp]}>
          <StandardText
            style={[
              CommonStyles.customFontBold,
              CommonStyles.font18,
              BrandStyles.brandBlockTxtColor3,
              CommonStyles.textAlignVerticalCenter,
              CommonStyles.marginBottom5,
            ]}
          >
            {types2.NEED_HELP.NEED_HELP_TEXT}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFontBold,
              CommonStyles.font20,
              BrandStyles.brandBlockTxtColor3,
              CommonStyles.textAlignVerticalCenter,
              CommonStyles.underlineText,
            ]}
          >
            {types2.NEED_HELP.NEED_HELP_TP_NUM}
          </StandardText>
        </View>
      </StandardTouchableOpacity>
      );
    }
  };

  renderMenuItems = () => {
    let dynamicMenuItems = this._renderDynamicSideMenuContent();

    if (dynamicMenuItems && dynamicMenuItems.length > 0) {
      return dynamicMenuItems.map((item, index) => {
        let isSelected = false;

        if (this.props.navigation) {
          if (item.screen === this.props.navigation.getState().routeName) {
            isSelected = true;
          }
        }

        let isMenuItemEnable = true;

        // Disable signup for plan management and notifications if not a decision maker
        if (
          item.screen == types2.SIGNUP_FOR_PLAN_MANAGEMENT ||
          item.screen == types2.NOTIFICATIONS ||
          item.screen == types2.UPLOADPLAN
        ) {
          if (
            (this.props.member.access &&
              this.props.member.access.notifications === types.ACCESS_NONE &&
              !this.props.member.access.decisionMaker) ||
            (this.props.user.demoProfile &&
              item.screen !== types2.NOTIFICATIONS)
          ) {
            isMenuItemEnable = false;
          }
        }
        // Disable documents if no access to documents
        if (item.screen == types2.DOCUMENTS) {
          if (
            this.props.member.access &&
            this.props.member.access.documents == types.ACCESS_NONE
          ) {
            isMenuItemEnable = false;
          }
        }

        // Disable budget related secondary navigation items for onboarding members
        const {memberStatus, planStatus} = this.props.member;
        if (
          memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
          planStatus === types2.PLAN_STATUS_DATA.PM_EXPIRED
        ) {
          if (
            item.screen === types2.ApprovalsSummary ||
            item.screen === types2.ClaimInvoice ||
            item.screen === types.DraftInvoice ||
            item.screen === types2.PaymentsHistory ||
            item.screen === types2.ServiceAgreementsList ||
            item.screen === types2.PLANS
          ) {
            isMenuItemEnable = false;
          }
        }

        // Check for readonly access for Budgets Menu
        if (
          item.screen === types2.ApprovalsSummary ||
          item.screen === types2.ClaimInvoice ||
          item.screen === types.DraftInvoice
        ) {
          if (
            this.props.member.access &&
            (this.props.member?.access?.finances === types.ACCESS_READ_ONLY ||
              this.props.member?.access?.finances === types.ACCESS_NONE)
          ) {
            isMenuItemEnable = false;
          }
        }

        if (item.params) {
          if (item.params.isLogout) {
            isMenuItemEnable = true;
            return (
              <View key={'logout'}>
                <TouchableHighlight
                  onPress={this.logout}
                  underlayColor={BrandColors.WHITE}
                >
                  {this._renderHighLightedItem(
                    item,
                    isSelected,
                    isMenuItemEnable,
                  )}
                </TouchableHighlight>
                <View
                  style={[
                    CommonStyles.justifyContentSpaceBetween,
                    CommonStyles.content,
                    CommonStyles.sideMenuLine,
                    CommonStyles.flexRow,
                    BrandStyles.borderColor6,
                  ]}
                />
              </View>
            );
          } else {
            return (
              <View key={item.screen}>
                <TouchableHighlight
                  onPress={
                    isMenuItemEnable
                      ? this.navigateToScreen(item.screen, item.params)
                      : null
                  }
                  underlayColor={BrandColors.WHITE}
                >
                  {this._renderHighLightedItem(
                    item,
                    isSelected,
                    isMenuItemEnable,
                  )}
                </TouchableHighlight>
                <View
                  style={[
                    CommonStyles.justifyContentSpaceBetween,
                    CommonStyles.content,
                    CommonStyles.sideMenuLine,
                    CommonStyles.flexRow,
                    BrandStyles.borderColor6,
                  ]}
                />
              </View>
            );
          }
        } else {
          return (
            <View key={item.screen}>
              <TouchableHighlight
                onPress={this.navigateToScreen(
                  isMenuItemEnable ? item.screen : null,
                )}
                underlayColor={BrandColors.WHITE}
              >
                {this._renderHighLightedItem(
                  item,
                  isSelected,
                  isMenuItemEnable,
                )}
              </TouchableHighlight>
              <View
                style={[
                  CommonStyles.justifyContentSpaceBetween,
                  CommonStyles.content,
                  CommonStyles.sideMenuLine,
                  CommonStyles.flexRow,
                  BrandStyles.borderColor6,
                ]}
              />
            </View>
          );
        }
      });
    }
  };

  logout() {
    this.props.actions.LogoutConfirmationActions.showConfirmLogout();
  }

  updateComponentViewInfo = (nativeEvent: any) => {
    this.setState({componentView: nativeEvent.layout});
  };

  _renderHighLightedItem(item: any, isSelected: any, isMenuItemEnable: any) {
    const isTablet = DeviceInfo.isTablet();
    if (!isTablet) {
      isSelected = isTablet;
    }
    const isItemHighLighted =
      this.props.guideMe.highlightedMenuItem === item.screen;
    if (isSelected) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignItemsCenter,
            isItemHighLighted
              ? CommonStyles.sideMenuItemContainerHighlighted
              : CommonStyles.sideMenuItemContainer,
            BrandStyles.secondaryBgColor3,
          ]}
        >
          {isMenuItemEnable ? this._showNotificationBadge(item.screen) : null}
         {
          Platform.OS === types2.ANDROID && item.icon=== 'logout-2' && isMenuItemEnable ?  <Image source={{ uri: types.LOGOUT_ICON }}   style={[
            CommonStyles.sideMenuIndicator,
            CommonStyles.zIndex02Notification,
            {marginLeft: 6}
          ]} />: <CustomIcon
          style={[
            CommonStyles.sideMenuIndicator,
            CommonStyles.zIndex02Notification,
          ]}
          name={isMenuItemEnable ? item.icon : item.disableIcon}
        />
         } 
          
          <StandardText
            style={[
              CommonStyles.sideMenuTextContainer,
              CommonStyles.textAlignVerticalCenter,
              CommonStyles.alignItemsCenter,
              CommonStyles.customFont,
              CommonStyles.zIndex01Notification,
            ]}
          >
            {item.label}{' '}
          </StandardText>
        </View>
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignItemsCenter,
            isItemHighLighted
              ? CommonStyles.sideMenuItemContainerHighlighted
              : CommonStyles.sideMenuItemContainer,
          ]}
        >
          {isMenuItemEnable ? this._showNotificationBadge(item.screen) : null}
          {
          Platform.OS === types2.ANDROID && item.icon=== 'logout-2' && isMenuItemEnable?  <Image source={{ uri: types.LOGOUT_ICON }}  style={[
            CommonStyles.sideMenuIndicator,
            CommonStyles.zIndex02Notification,
            {marginLeft: 6}
          ]} /> : <CustomIcon
          style={[
            CommonStyles.sideMenuIndicator,
            CommonStyles.zIndex02Notification,
          ]}
          name={isMenuItemEnable ? item.icon : item.disableIcon}
        />
         } 
          <StandardText
            style={[
              CommonStyles.sideMenuTextContainer,
              CommonStyles.textAlignVerticalCenter,
              CommonStyles.alignItemsCenter,
              CommonStyles.customFont,
              CommonStyles.zIndex01Notification,
              !isMenuItemEnable ? CommonStyles.textLightGrey : null,
            ]}
          >
            {item.label}{' '}
          </StandardText>
        </View>
      );
    }
  }

  _showNotification() {
    let showNotification = false;
    if (this.props.member.access != undefined) {
      if (this.props.member.access.notifications != types.ACCESS_NONE) {
        showNotification = true;
      }
    } else {
      showNotification = true;
    }
    return showNotification;
  }

  _showNotificationBadge(screen: any) {
    if (screen == types2.NOTIFICATIONS && this._showNotification()) {
      let badgeBgColor = BrandStyles.notificationBadgeBgColor;
      let badgeTxtColor = BrandStyles.notificationBadgeTextColor;
      if (
        this.props.selectedIcon != undefined &&
        this.props.selectedIcon == types.SCREEN_NOTIFICATIONS
      ) {
        badgeBgColor = BrandStyles.notificationSelectedBadgeBgColor;
        badgeTxtColor = BrandStyles.notificationSelectedBadgeTextColor;
      }
      return (
        <NotificationBadge
          count={this._renderUnReadCount()}
          bgColor={badgeBgColor}
          txtColor={badgeTxtColor}
        />
      );
    }
  }

  _loadNotifications(loadedMemberId: any) {
    let notificationUserId = this.props.user.id;
    let notificationPageNumber = this.state.notificationData
      ? this.state.notificationData.pageNumber
      : undefined;
    if (loadedMemberId !== undefined) {
      notificationUserId = loadedMemberId.loadedMemberId;
    }

    if (notificationPageNumber === undefined) {
      notificationPageNumber = 1;
    }
    const downloadCallback = (data: any) => {
      this.props.actions.NotificationActions.loadNotifications(data[0].content);
    };

    callAPIs(
      [
        getNotifications(
          notificationUserId,
          notificationPageNumber,
          types.NOTIFICATION_PAGE_SIZE,
          false,
          this.props.user.demoProfile,
        ),
      ],
      downloadCallback,
      (err: any) => logger.log('notifications error: ', err),
    );
  }

  _renderUnReadCount = () => {
    if (!isEmpty(this.state.notificationData)) {
      return this.state.notificationData.totalUnreadCount;
    } else {
      return 0;
    }
  };

  render() {
    const isTablet = DeviceInfo.isTablet();
    return (
      <View
        style={this.props.containerStyle}
        accessibilityElementsHidden={!this.props.isOpen && isTablet}
      >
        <ScrollView>
          <TouchableHighlight
            accessible={false}
            onPress={this.navigateToScreen('Settings')}
            underlayColor={BrandColors.WHITE}
          >
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.flexDirectionRow,
                CommonStyles.alignItemsCenter,
                CommonStyles.loggedUserContainer,
              ]}
            >
              <View style={[CommonStyles.sideMenuLoggedIcon]}>
                <Image
                  source={BrandIcons.LOGO_ICON}
                  accessible={true}
                  accessibilityLabel={APP_ID + ' Logo'}
                  style={[CommonStyles.sideMenuLoggedUserIcon]}
                  ref={ref => {
                    if (ref) {
                      this.loggedInRef = ref;
                    }
                  }}
                />
              </View>
              <View style={[CommonStyles.sideMenuUserContainer]}>
                <StandardText
                  style={[
                    CommonStyles.customFontSemiBold,
                    CommonStyles.SidebarMainText,
                    BrandStyles.TextColorLoggedIn,
                    CommonStyles.textAlignVerticalCenter,
                  ]}
                >
                  {this.props.user.firstName + ' logged in'}
                </StandardText>
              </View>
            </View>
          </TouchableHighlight>
          <View
            style={[
              CommonStyles.justifyContentSpaceBetween,
              CommonStyles.content,
              CommonStyles.sideMenuLine,
              CommonStyles.flexRow,
              BrandStyles.borderColor6,
            ]}
          />
          <View
            onLayout={({nativeEvent}) =>
              this.updateComponentViewInfo(nativeEvent)
            }
          >
            {this.renderMenuItems()}
          </View>
          {this._renderNeedHelp()}
        </ScrollView>
      </View>
    );
  }
}

Menu.propTypes = {
  navigation: PropTypes.object,
  menuItems: PropTypes.object,
};

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  sideMenu: state.SideMenuReducer,
  notifications: state.NotificationReducer,
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  payInvoice: state.PayInvoiceReducer,
  guideMe: state.GuideMeReducer,
  admin: state.AdminReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    NotificationActions: bindActionCreators(NotificationActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
