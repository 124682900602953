import React, {Component} from 'react';
import {Image, View, FlatList, StyleSheet} from 'react-native';
import {StandardTouchableOpacity, StandardText} from '../Components/Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as PayInvoiceActions from '../Actions/PayInvoiceActions';

import {addIconWhite} from '../assets/images';
// Import Stylesheets
import BrandStyles from '../Styles/BrandStyles';
import CommonStyles from '../Styles/CommonStyles';
import {ActionButton} from '../Components/Molecules';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {AddProvider} from '../Screens';
import {OfflineNotice} from '../Components/Organisms';

class SelectProvider extends Component {
  state = {
    selectedProvider: {},
    validBankAccNumber: '',
    validBSB: '',
    validEmail: '',
    validABN: '',
    validOrganisationName: '',
    addProviderModalVisible: false,
  };

  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <View style={[CommonStyles.content, CommonStyles.flex1]}>
        <OfflineNotice />
        <View style={[CommonStyles.content]}>
          <View style={CommonStyles.mainTitle}>
            <StandardText
              style={[
                CommonStyles.ContentTitle,
                CommonStyles.customFontBold,
                BrandStyles.TextColor5,
                CommonStyles.textAlignVerticalCenter,
              ]}
            >
              {this.props.subHeading}
            </StandardText>
          </View>

          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.justifyContentSpaceBetween,
            ]}
          >
            <View
              style={[
                BrandStyles.primaryBgColor3,
                CommonStyles.subHeadingContainer,
              ]}
            >
              <StandardText
                style={[
                  BrandStyles.TextColor1,
                  CommonStyles.customFontBold,
                  CommonStyles.fontWeight400,
                  CommonStyles.font20,
                ]}
              >
                {'Provider'}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.justifyContentSpaceBetween,
            ]}
          >
            <StandardText
              style={[
                BrandStyles.TextColor5,
                CommonStyles.textAlignVerticalCenter,
              ]}
            >
              {'First select or add a provider'}
            </StandardText>
          </View>
          <View style={[CommonStyles.flexDirectionRow]}>
            <View
              style={[
                CommonStyles.SelectProviderFlex1,
                CommonStyles.SelectProviderPT,
              ]}
            >
              <StandardText
                style={[
                  BrandStyles.TextColor6,
                  CommonStyles.customFontBold,
                  CommonStyles.StandardFontSize,
                  CommonStyles.font,
                ]}
              >
                {'Select Provider'}
              </StandardText>
            </View>
            <View style={[CommonStyles.SelectProviderFlex1]}>
              <StandardText
                style={[
                  BrandStyles.TextColor6,
                  CommonStyles.customFontBold,
                  CommonStyles.font25,
                  CommonStyles.font,
                ]}
              >
                {'or'}
              </StandardText>
            </View>
            <View style={[CommonStyles.SelectProviderFlex1]}>
              <StandardTouchableOpacity
                style={[CommonStyles.SelectProviderPT]}
                readOnly={this.props.readOnly}
                accessibilityLabel="Add provider"
                activeOpacity={0.6}
                onPress={() => this._addProvider()}
              >
                <ActionButton
                  title={'provider'}
                  actionType={types.ACTION_BUTTON.ADD}
                />
              </StandardTouchableOpacity>
            </View>
          </View>
          {this.props.payInvoice.memberBillers != undefined && (
            <FlatList
              data={this.props.payInvoice.memberBillers}
              keyExtractor={item => item.billerKey}
              renderItem={({item}) => this._renderMemberBillerItem(item)}
            />
          )}
        </View>
        {this._renderAddProvider()}
      </View>
    );
  }

  _addProvider() {
    this.setState({addProviderModalVisible: true});
  }

  _renderMemberBillerItem = (item: any) => {
    let isDisabled = false;
    if (this.props.member.access != undefined) {
      if (this.props.member?.access?.finances != types.ACCESS_FULL) {
        isDisabled = true;
      }
    }

    return (
      <View style={CommonStyles.marginLeft2}>
        <StandardTouchableOpacity
          disabled={isDisabled}
          style={[
            CommonStyles.supportProviderContainerBox,
            CommonStyles.supportProviderElevation,
          ]}
          accessibilityLabel={item.name}
          onPress={() => this._providerSelected(item)}
        >
          <StandardText
            style={[
              CommonStyles.paddingRight15,
              CommonStyles.font17,
              CommonStyles.customFontMedium,
              BrandStyles.TextColor2,
            ]}
          >
            {item.name}
          </StandardText>
        </StandardTouchableOpacity>
      </View>
    );
  };

  _providerSelected(item: any) {
    this.props.actions.payInvoiceActions.setPayInvoiceStep(2);
    this.props.actions.payInvoiceActions.addNewProviderAction({
      organisation: item.name,
      abn: item.abn,
      email: item.email,
      bsb: item.abn,
      accountNumber: item.abn,
      billerKey: item.billerKey,
    });
    this.props.navigation.navigate('InvoiceDetails');
  }

  _renderAddProvider = () => {
    return (
      <AddProvider
        close={this._closeAddProviderModal.bind(this)}
        determineAccess={this._determineAccess}
        visible={this.state.addProviderModalVisible}
      />
    );
  };

  _closeAddProviderModal = () => {
    this.setState({addProviderModalVisible: false});
  };

  _determineAccess = () => {
    return this.props.member.access.profile;
  };
}

const mapStateToProps = (state: any) => ({
  payInvoice: state.PayInvoiceReducer,
  member: state.MemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectProvider);
