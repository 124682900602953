import logger from 'helpers/Logger';
import {RNkeychain as Keychain} from './PlatformSynchronizer';
import {CommonActions} from '@react-navigation/native';
import {StackActions} from '@react-navigation/native';

import {
  signUpCognito,
  loginCognito,
  cognitoPasswordChallenge,
  logoutCognito,
  getCurrentAuthenticatedUser,
} from '../API/AuthenticationAPI';

import {firebaseAnalysisLogEvent} from './PlatformSynchronizer';
import {putCognitoIdByEmail} from '../API/PersonAPI';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {
  ERRORS,
  COMMON_BUTTONS,
  POPUP_TITLES,
  LOGIN_SIGNUP_SCREEN,
} from '../Constants/Messages';

import {storeData} from '../API/StorageHelper';

import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';

class AuthenticationUtil {
  Email: any;
  FirstName: any;
  LastName: any;
  Password: any;
  awsIdentity: any;
  cognitoId: any;
  isCrewSignUp: any;
  isDemoProfile: any;
  isPasswordChallenge: any;
  reCaptureToken: any;
  typeConfirmed: any;
  constructor(
    firstName: any,
    lastName: any,
    email: any,
    password: any,
    reCapture: any,
    typeConfirmed: any,
    isCrewSignUp = false,
  ) {
    this.FirstName = firstName ? firstName.trim() : '';
    this.LastName = lastName ? lastName.trim() : '';
    this.Email = email;
    this.Password = password;
    this.reCaptureToken = reCapture;
    this.cognitoId = null;
    this.awsIdentity = null;
    this.isCrewSignUp = isCrewSignUp;
    this.typeConfirmed = typeConfirmed;
    this.isDemoProfile = false;
  }

  async signUp(): Promise<{
    isSuccess: boolean;
    errorMessage?: any;
  }> {
    try {
      const response: any = await signUpCognito(
        this.Email,
        this.Password,
        this.FirstName,
        this.LastName,
        this.FirstName,
        this.reCaptureToken,
        this.typeConfirmed,
      );
      this.cognitoId = response.cognitoIdToken;
      this.awsIdentity = response.awsIdentity;
      if (!types.isWeb) {
        firebaseAnalysisLogEvent(
          types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_CLICKED,
          {},
        );
      }
      await storeData(types.LOGIN_LAST_TYPE, types.LOGIN_TYPE_EMAIL);
      await storeData(types.PREVIOUSLY_SIGNIN, 'logged');
      logger.log('Signup successful', {
        cognitoIdToken: response.cognitoIdToken,
      });
      return {
        isSuccess: true,
      };
    } catch (error) {
      logger.log('Signup error ', error);

      return {
        isSuccess: true,
        errorMessage: this.createAlertErrorMessage(error),
      };
    }
  }

  login(
    onSuccess: any,
    onFailure: any,
    updateState: any,
    isDemoProfile = false,
  ) {
    loginCognito(this.Email, this.Password, isDemoProfile)
      .then(response => {
        if (response.type == types.COGNITO_TYPE_LOGIN) {
          this.cognitoId = response.cognitoIdToken;
          this.awsIdentity = response.awsIdentity;
          onSuccess();
          return;
        } else if (response.type == types.COGNITO_TYPE_CHALLENGE) {
          this.isPasswordChallenge = true;
          return updateState({
            passwordChallenge: true,
            loading: false,
            cognitoUserAttributes: response.userAttributes,
            cognitoUser: response.cognitoUser,
          });
        }
      })
      .catch(error => {
        return onFailure(this.createAlertErrorMessage(error));
      });
  }

  /* 
  Staff member SSO login
  */
  SsoLogin(success: any) {
    getCurrentAuthenticatedUser(success);
  }

  preAuthenticate(
    cognitoId: any,
    actions: any,
    callback: any,
    isDemoProfile = false,
    isCrewSignUp = false,
  ) {
    this.awsIdentity = {IdentityId: cognitoId};
    this.isCrewSignUp = isCrewSignUp;
    this.navigateToNext(
      actions,
      isDemoProfile,
      (result: any) => {
        callback(result);
      },
      callback,
    );
  }

  respondToPasswordChallenge(
    newPassword: any,
    onSuccess: any,
    onFailure: any,
    updateState: any,
    cognitoUserAttributes: any,
    cognitoUser: any,
    isDemoProfile = false,
  ) {
    cognitoPasswordChallenge(
      this.Email,
      newPassword,
      cognitoUserAttributes,
      cognitoUser,
    )
      .then(response => {
        // The cognito id will have been written to the backend when the success has returned
        updateState({isFirstTimeLogin: true});

        if (response.type == types.COGNITO_TYPE_LOGIN) {
          this.cognitoId = response.cognitoIdToken;
          this.awsIdentity = response.awsIdentity;
          onSuccess();
          return;
        }
      })
      .catch(error => {
        return onFailure(this.createAlertErrorMessage(error));
      });
  }
  storeLoginData = async () => {
    await storeData(types.LOGIN_LAST_TYPE, types.LOGIN_TYPE_EMAIL);
    await storeData('awsIdentity', this.awsIdentity.toString());
    await storeData('cognitoId', this.cognitoId);
    if (!this.isDemoProfile) {
      await storeData(types.PREVIOUSLY_SIGNIN, 'logged');
    }
  };

  async saveEmailCredentials(
    actions: any,
    updateState: any,
    callback: any,
    onFailure: any,
    isDemoProfile = false,
    isShowcase = false,
    isCreateMode = false,
  ) {
    this.isDemoProfile = isDemoProfile;
    actions.userActions.actionUpdateUser({
      demoProfile: isDemoProfile,
      isShowCase: isShowcase,
    });
    if (isDemoProfile && !isShowcase) {
      actions.guideMeActions &&
        actions.guideMeActions.setIsDemoProfileFirstTime(false);
    }
    actions.guideMeActions &&
      actions.guideMeActions.setHighlightedMenuItem(null);
    actions.authActions.actionLoginSuccess(
      types.LOGIN_TYPE_EMAIL,
      this.cognitoId,
      this.awsIdentity,
    );
    if (types.isWeb) {
      await this.storeLoginData();

      updateState({
        cognitoId: this.cognitoId,
        awsIdentity: this.awsIdentity.IdentityId,
      });

      if (isDemoProfile && !isShowcase) {
        putCognitoIdByEmail(
          this.Email,
          this.awsIdentity.IdentityId,
          () => {
            this.navigateToNext(
              actions,
              isDemoProfile,
              (result: any) => {
                callback(result);
              },
              onFailure,
            );
          },
          true,
        );
      } else {
        this.navigateToNext(
          actions,
          isDemoProfile,
          (result: any) => {
            callback(result);
          },
          onFailure,
        );
      }
    } else {
      Keychain.setInternetCredentials(
        types.COGNITO_KEY,
        this.Email,
        this.Password,
      ).then(async () => {
        if (isCreateMode) {
          return callback(true);
        }
        if (isDemoProfile && !isShowcase) {
          await this.storeLoginData();

          putCognitoIdByEmail(
            this.Email,
            this.awsIdentity.IdentityId,
            (data: any) => {
              this.navigateToNext(
                actions,
                isDemoProfile,
                (result: any) => {
                  callback(result);
                },
                onFailure,
              );
            },
            true,
          );
        } else {
          this.navigateToNext(
            actions,
            isDemoProfile,
            (actionToDispatch: any, routingObj: any) => {
              callback(actionToDispatch, routingObj, this.storeLoginData);
            },
            onFailure,
          );
        }
      });
    }
  }

  navigateToNext(
    actions: any,
    isDemoProfile: any,
    callback: any,
    onFailure: any,
  ) {
    const callbackFunction = (
      routingObj: any,
      memberData: any,
      ManagedMemberCount: any,
      flags: any,
    ) => {
      if (routingObj === undefined) {
        return callback(types.NOT_AUTHORIZED);
      } else if (
        routingObj.code === types.BAD_REQUEST_ERROR ||
        routingObj.code === types.VERSION_ERROR ||
        routingObj.statusCode === types.COGNITO_INVALID_SIGNATURE_ERROR
      ) {
        return onFailure(this.createAlertErrorMessage(routingObj));
      } else if (this.isPasswordChallenge && routingObj !== '200') {
        return onFailure(this.createAlertErrorMessage(routingObj));
      }
      if (flags && flags.length > 0) {
        actions.adminActions.actionGetFeatureFlags(flags);
      }
      if (isDemoProfile) {
        actions.userActions.setFirstTimeSignUp(isDemoProfile, true);
      }
      const userObj = routingObj.params.userObj;
      const isUserOnlyMember =
        userObj.isMember && userObj.numberOfMembers === 1;

      if (userObj.managedMemberId !== undefined && !isUserOnlyMember) {
        actions.loadedMemberActions.actionGetLoadedMemberId(
          userObj.managedMemberId,
        );
      } else if (!userObj.isCrew) {
        actions.loadedMemberActions.actionGetLoadedMemberId(userObj.user.id);
      }

      if (userObj.isMember && !userObj.isCrew && memberData) {
        actions.memberActions.actionGetMember(
          memberData.payload,
          memberData.loadedMemberId,
          memberData.isEmployee,
          memberData.isFinancialStaff,
        );
      }
      actions.userActions.actionGetUser(userObj);

      if (!types.isWeb) {
        const actionToDispatch = CommonActions.reset({
          index: 0,
          routes: [
            {
              name: routingObj.routeName,
              params: routingObj.params,
            },
          ],
        });
        callback(actionToDispatch, routingObj);
      } else {
        callback(routingObj.routeName);
      }
    };
    routeTolanding(
      this.awsIdentity.IdentityId,
      callbackFunction,
      true,
      isDemoProfile,
      this.isCrewSignUp,
    );
  }

  logout(actions: any) {
    logoutCognito(actions)
      .then(result => {
        actions.userActions.actionUpdateUser({
          demoProfile: false,
          showCase: false,
        });
        return;
      })
      .catch(error => {
        logger.log('Logout error', error);
      });
  }

  createAlertErrorMessage(error: any) {
    switch (error.code) {
      case types.NOT_AUTHORIZED:
        if (error.message === types.COGNITO_TEMP_PASSWORD_EXPIRED) {
          return {
            title: LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_EXPIRED_MODAL_HEADER,
            body: LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_EXPIRED_MODAL_MESSAGE,
            resendPassword: true,
          };
          break;
        }
        return {
          title: ERRORS.UNABLE_TO_LOGIN_HEADER,
          body: ERRORS.UNABLE_TO_LOGIN_MESSAGE,
        };
        break;
      case types.USER_NOT_FOUND:
        return {
          title: ERRORS.UNABLE_TO_LOGIN_HEADER,
          body: ERRORS.UNABLE_TO_LOGIN_MESSAGE,
        };
        break;
      case types.BAD_REQUEST_ERROR:
        return {
          title: ERRORS.GENERIC_ISSUE_ALERT_TITLE,
          body: ERRORS.GENERIC_ISSUE_ALERT_MSG,
        };
        break;
      case types.USERNAME_EXISTS:
        return {
          title: POPUP_TITLES.SIGNUP_FAIL,
          body: ERRORS.GENERIC,
          buttonText: COMMON_BUTTONS.CLOSE,
          forceUpdate: false,
        };
        break;
      case types.VERSION_ERROR:
        return {
          title: ERRORS.UPDATE_AVAILABLE,
          body: ERRORS.FORCE_UPDATE_TEXT,
          buttonText: COMMON_BUTTONS.UPDATE,
          forceUpdate: true,
        };
        break;
      case ERRORS.NO_INTERNET:
        return {
          title: POPUP_TITLES.SIGNUP_FAIL,
          body: error.message,
          buttonText: COMMON_BUTTONS.CLOSE,
        };
        break;
      case types.COGNITO_INVALID_SIGNATURE_ERROR_MESSAGE:
        return {
          title: POPUP_TITLES.GENERIC_ISSUE_ALERT_TITLE,
          body: ERRORS.INVALID_SIGNATURE_TEXT,
          buttonText: COMMON_BUTTONS.CLOSE,
        };
        break;
      default:
        return {
          title: ERRORS.GENERIC_ISSUE_ALERT_TITLE,
          body: ERRORS.GENERIC_ISSUE_ALERT_MSG,
        };
        break;
    }
  }
}

export default AuthenticationUtil;
