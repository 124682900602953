/*
 * Author: Suranga N Fernando
 * Date: 18/04/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Provider API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway

import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';

//* ********************************
//*       PROVIDER DATA
//* ********************************

/*
 * Returns the List of provider billers
 * @async
 * @param {string} personId The Person ID of the user you want to return
 * @returns {Promise<object>} The living arrangements object
 */
export const getMemberBillers = (personId: any, autoHandleError = true) => {
  return () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getMemberBillersList =  await configuredAWSLamda();
      const requestBody = {personId};
      const functionName = 'getMemberBillers';
      logger.log('Lambda::', functionName);
      // Need to change this to service constants after deploying the api in member service
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = new Date().getTime();
      getMemberBillersList.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const endTime = new Date().getTime();
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
};

//* ********************************
//*       LIST PROVIDER DATA BY CATEGORY AND REGION
//* ********************************

/*
 * Returns the List of providers based on category and region
 * @async
 * @param {string} personId The Person ID of the user you want to return
 * @returns {Promise<object>} The list of provider array
 */
export const getProvidersByCategoryRegion = (
  regionCode: any,
  category: any,
  autoHandleError = true,
) => {
  return () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getProvidersByCategoryRegionLambda =  await configuredAWSLamda();
      const requestBody = {
        region: regionCode,
        category: category,
        isPreview: true,
      };
      const functionName = 'getProvidersByRC';
      logger.log('Lambda::', functionName);
      // Need to change this to service constants after deploying the api in member service
      const serviceName = AWS_LAMBDA_SERVICES.PARTNERPROVIDER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getProvidersByCategoryRegionLambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
};

//* ********************************
//*       GET PROVIDER BASED ON PROVIDERSYSID
//* ********************************

/*
 * Returns provider data based on providerSysId
 * @async
 * @param {string} providerSysId The providerSys ID of the provider you want to return
 * @returns {Promise<object>} The provider object
 */
export const getProviderBySysId = (
  providerSysId: any,
  autoHandleError = true,
) => {
  return () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getProviderBySysIdLambda =  await configuredAWSLamda();
      const requestBody = {
        providerSysId: providerSysId,
        isPreview: true,
      };
      const functionName = 'getProviderbyId';
      logger.log('Lambda::', functionName);
      // Need to change this to service constants after deploying the api in member service
      const serviceName = AWS_LAMBDA_SERVICES.PARTNERPROVIDER;
      const params = buildLambda(requestBody, functionName, serviceName);
      getProviderBySysIdLambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
};

//* ********************************
//*       SEND EMAIL TO PROVIDER
//* ********************************

/*
 * Sends and email to the provider selected by crew or member
 * @async
 * @param {string} personId The Person ID of the user you want to return
 * @returns {Promise<object>} The living arrangements object
 */
export const sendEmailProvider = (payload: any, autoHandleError = true) => {
  return () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const sendEmailProviderLambda =  await configuredAWSLamda();
      const functionName = 'sendEmailProvider';
      logger.log('Lambda::', functionName);
      const requestBody = payload;
      // Need to change this to service constants after deploying the api in member service
      const serviceName = AWS_LAMBDA_SERVICES.MEMBER;
      const params = buildLambda(requestBody, functionName, serviceName);
      sendEmailProviderLambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
};

/* ********************************
//*       SEARCH PROVIDER DATA
//* ********************************

/*
* Returns the List of providers
* @async
* @param {string} memberId The member ID of the member
* @returns {Promise<object>} The filtered provider list
*/
export const getProviders = (
  id: any,
  searchQuery: any,
  isMemberProvidersOnly = false,
  autoHandleError = true,
) => {
  return () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSearchProviders =  await configuredAWSLamda();
      const requestBody = {id, searchQuery, isMemberProvidersOnly};
      const functionName = 'getProviders';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getSearchProviders.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
};

//* *************************
//* SAVE PROVIDER DATA
//* *************************

export const postProvider = (
  id: any,
  provider: any,
  autoHandleError = true,
) => {
  return () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const postProviderDetails =  await configuredAWSLamda();
      const requestBody = {id, provider};
      const functionName = 'postProvider';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      postProviderDetails.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
};
