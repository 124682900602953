import React, {Component} from 'react';
import {View, Image, Platform} from 'react-native';
import {EyePurple4, GreyClockIcon3x} from '../assets/images';
import {ClaimInvoiceRed3x} from '../assets/images/vector-icons';
import {
  StandardText,
  StandardInput,
  StandardTouchableOpacity,
  StandardButton,
} from '../Components/Atoms';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {formatAsCurrency} from '../Helpers/Utils';
import * as mime from 'react-native-mime-types-enhanced';
import {
  isTablet,
  getTimezone,
  RNFetchBlobSingleton,
  FileDownloaderSingleton,
} from '../Helpers/PlatformSynchronizer';
import {formatDateToString} from '../Helpers/Utils';
import {
  DocumentsTopBar,
  Container,
  CrewNoAccess,
  BottomMessageBar,
  SummaryTile,
  EditIconComponent,
  InvoiceList,
  CurrencyText,
  BasicOverlayPopupModal,
  RadioButton,
  ActionButton,
  AccordianInvoiceNotes,
} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {callAPIs} from '../API/APICaller';
import {postMemberInvoice, getUploadURL} from '../API/MemberAPI';
import {postDraftInvoice, putDraftInvoice} from '../API/PlanDataAPI';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  REVIEW_INVOICE_SUMMARY_SCREEN as messages,
  INVOICE_PROCESSING_WARNING as warning,
} from '../Constants/Messages';

import {uploadFileToS3, uploadImageToS3} from '../Helpers/FileHelper';
import {BasicModal, BasicForm, BasicPopupAlert} from '../Components/Organisms';
import {formatDateForReviewSummary, formatDDMMMYYDate} from '../Helpers/Utils';

import logger from 'helpers/Logger';
import FileUploader from '../Helpers/FileUploader';

class ReviewInvoiceSummary extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      isInvoiceSubmissionInProgress: false,
      invoiceProcessing: false,
      isDraftInvoice: this._getParams('isDraftInvoice'),
      isDraftEdited: this._getParams('saveAsDraft'),
      preApproved: false,
      errorPopUpVisible: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      loadedInvoice: this.props.payInvoice,
    });
  }

  render() {
    let displayTitle = messages.MAIN_TITLE;
    if (types.isWeb) {
      return this._createMainContents();
    }

    return (
      <View style={[CommonStyles.content]}>
        <Container
          contents={this._createMainContents}
          loading={this.state.loading}
          screenType={types.SCREEN_TYPE_MAIN}
          nav={this.props.navigation}
          headerTitle={displayTitle}
          needsSidebar={true}
          toggleMenu={this.props.screenProps?.toggleMenu}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          sidebarMode={types2.SIDEBAR_MODE.PLAN_PAY_INVOICE}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      </View>
    );
  }

  _renderSaveStyles = () => {
    let styles = {};
    return (styles = {
      style: [BrandStyles.brandBlockTxtColor5, CommonStyles.buttonBudgetAction],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ],
    });
  };

  _getParams = (key: any) => {
    if(types.isWeb) {
      return  this.props.navigation.getParam(key, undefined) || undefined;
    }
    return  this.props.route.params? this.props.route.params[key] : undefined;
  };

  _isReadOnly = () => {
    const access = this.props.member?.access?.finances;
    if (access === types.ACCESS_READ_ONLY || access === types.ACCESS_NONE) {
      return true;
    }
    return false;
  };

  _closeReview = () => {
    if (this._isReadOnly()) {
      return this.props.navigation.goBack();
    }
    if (this.state.isDraftInvoice) {
      return this.props.navigation.navigate('ClaimInvoice', {
        isDraftEditing: true,
        invoiceId: this._getParams('invoiceId'),
        itemTotal: this._getParams('itemTotal'),
      });
    } else {
      return this.props.navigation.goBack();
    }
  };

  _createMainContents = () => {
    return (
      <View style={CommonStyles.justifyFlexColumnStart}>
        <BasicForm
          headerDisplayString={messages.MAIN_TITLE}
          headerIconImage={ClaimInvoiceRed3x}
          HeaderTextColor={BrandStyles.TextColor4}
          HeaderBoarderColor={BrandStyles.borderColor4}
          fontSize={CommonStyles.font20}
          readOnly={this.props.member?.access?.finances}
          disableMore={true}
          saveStyle={this._renderSaveStyles()}
          saveLabel={
            this.isEmployeeIsFinancialStaff()
              ? 'Submit for Approval'
              : 'Submit Claim'
          }
          closeLabel={this._isReadOnly() ? types2.CANCEL : types2.EDIT}
          close={this._closeReview}
          save={() =>
            this._uploadInvoiceDoc(false, !this.isEmployeeIsFinancialStaff())
          }
          buttonsPositionBottom={true}
          modal={() =>
            this._submit(
              this.state.loadedInvoice.invoice.invoiceDocPath,
              this.state.isDraftInvoice,
              !this.isEmployeeIsFinancialStaff(),
            )
          }
          containsData={true}
          showButton={this.isEmployeeIsFinancialStaff()}
          showButtonTitle={types.ACTION_BUTTON.UPDATE_NOTES}
          contentWidthFull={true}
        >
          {this._renderSubmissionResults()}
          {this._renderInvoiceDetails()}
          {this._renderNotesSection()}
          {this.state.loadedInvoice.supportItems != undefined && (
            <View
              style={[CommonStyles.containerCons, CommonStyles.marginTop20]}
            >
              {this._renderSupporItemList(
                this.state.loadedInvoice.supportItems,
              )}
            </View>
          )}
        </BasicForm>
        {this.isEmployeeIsFinancialStaff() ? (
          <View
            style={[
              CommonStyles.formTitleContainer,
              CommonStyles.formTitlePadding,
            ]}
          >
            <ActionButton
              showButtonTitle={messages.SUBMIT_PREAPPROVED}
              actionType={types.ACTION_BUTTON.SUBMIT_PRE_APPROVED}
              isToucable={true}
              onPress={() => this._uploadInvoiceDoc(false, true)}
            />
          </View>
        ) : null}
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.state.closeAction}
          cancelLabel={this.state.alertButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
          isMobile={this.props.isMobile}
        />
      </View>
    );
  };

  _renderVerifiedName = (friendlyName: any, billerName: any) => {
    if (friendlyName && friendlyName !== billerName) {
      return (
        <View style={CommonStyles.paddingBottom10}>
          <StandardText
            style={[CommonStyles.textProvider, BrandStyles.TextColor10]}
          >
            {messages.BILL_TO + billerName}
          </StandardText>
        </View>
      );
    }
  };

  _renderInvoiceDetails = () => {
    const billerName =
      this.state.loadedInvoice.selectedProvider != undefined
        ? this.state.loadedInvoice.selectedProvider.organisation
        : '';
    const providerFriendlyName =
      this.state.loadedInvoice.selectedProvider.providerFriendlyName;
    return (
      <View style={[CommonStyles.marginTop10]}>
        <StandardText
          style={[
            CommonStyles.customFontSemiBold,
            types.isWeb ? CommonStyles.font18 : CommonStyles.font22,
            BrandStyles.TextColor4,
          ]}
        >
          {providerFriendlyName ? providerFriendlyName : billerName}
        </StandardText>
        {this._renderVerifiedName(providerFriendlyName, billerName)}
        <View style={[CommonStyles.justifyFlexRowStart]}>
          <View
            style={[CommonStyles.justifyFlexColumnStart, CommonStyles.flex06]}
          >
            <View style={[CommonStyles.marginTop10]}>
              <StandardText
                style={[
                  CommonStyles.customFontSemiBold,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                  BrandStyles.TextColor5,
                ]}
              >
                {messages.ABN}
              </StandardText>
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              >
                {this.state.loadedInvoice.selectedProvider != undefined
                  ? this.state.loadedInvoice.selectedProvider.abn
                  : ''}
              </StandardText>
            </View>
            <View style={[CommonStyles.marginTop10]}>
              <StandardText
                style={[
                  CommonStyles.customFontSemiBold,
                  CommonStyles.justifyFlexRowEnd,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                  BrandStyles.TextColor5,
                ]}
              >
                {messages.INVOICE_NUMBER}
              </StandardText>
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              >
                {this.state.loadedInvoice.invoice != undefined
                  ? this.state.loadedInvoice.invoice.invoiceNumber
                  : ''}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              CommonStyles.justifyFlexColumnStart,
              CommonStyles.flex04,
              CommonStyles.marginBottom10,
            ]}
          >
            <View style={[CommonStyles.flexDirectionColumn]}>
              <CurrencyText
                value={this._invoiceApprovalValue()}
                isPositive={true}
                integerStyle={[
                  types.isWeb ? CommonStyles.font20 : CommonStyles.font40,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              />
              <View>
                <StandardText
                  style={[
                    CommonStyles.title_ListContainer,
                    CommonStyles.marginRight0,
                    CommonStyles.textAlignRight,
                  ]}
                >
                  {messages.TOTAL_INVOICE_TITLE}
                </StandardText>
              </View>
            </View>
          </View>
        </View>
        <View style={[CommonStyles.justifyFlexRowStart]}>
          <View
            style={[
              CommonStyles.justifyFlexRowStart,
              CommonStyles.alignItemsCenter,
              CommonStyles.flex1,
            ]}
          >
            <StandardText
              onPress={() =>
                this._openDocument(
                  this.state.loadedInvoice.invoice.invoiceDocPath,
                )
              }
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                CommonStyles.customFontSemiBold,
                BrandStyles.TextColor5,
                CommonStyles.underlineText,
              ]}
            >
              {this.state.loadedInvoice.invoice != undefined
                ? this.state.loadedInvoice.invoice.invoiceDocPath.fileName +
                  this._getFileExtension(
                    this.state.loadedInvoice.invoice.invoiceDocPath.type,
                  ) +
                  ' '
                : ''}
            </StandardText>
            <View>
              <StandardTouchableOpacity
                accessibilityLabel={messages.PREVIEW_INVOICE}
                style={[BrandStyles.primaryBgColor1]}
                onPress={() =>
                  this._openDocument(
                    this.state.loadedInvoice.invoice.invoiceDocPath,
                  )
                }
              >
                <Image
                  style={
                    types.isWeb
                      ? CommonStyles.spendingSupportIconView
                      : CommonStyles.reviewAdhocViewImageData
                  }
                  source={EyePurple4}
                />
              </StandardTouchableOpacity>
            </View>
          </View>
          <View
            style={[CommonStyles.justifyFlexRowEnd, CommonStyles.marginLeft30]}
          >
            <Image
              style={CommonStyles.reviewAdhocClockImageData}
              source={GreyClockIcon3x}
            />
            <StandardText
              style={[
                CommonStyles.font15,
                CommonStyles.customFont,
                BrandStyles.TextColor10,
              ]}
            >
              {formatDDMMMYYDate(this.state.loadedInvoice.invoice.invoiceDate)}
            </StandardText>
          </View>
        </View>
      </View>
    );
  };

  _renderNotesSection = () => {
    return (
      <AccordianInvoiceNotes
        isMobile={this.props.isMobile}
        viewWidth={this.props.viewWidth}
        invoiceDetails={this.state.loadedInvoice.invoice}
        updateNote={(invoiceNotes: any, noteType: any) =>
          this._updateInvoiceNotes(invoiceNotes, noteType)
        }
        isEmployee={this.props.user.isEmployee}
      />
    );
  };

  _updateInvoiceNotes(note: any, type: any) {
    const {loadedInvoice} = this.state;
    if (type === types2.INVOICE_NOTES.MEMBER_NOTE) {
      loadedInvoice.invoice.memberNote = note;
    } else if (type === types2.INVOICE_NOTES.WORK_NOTE) {
      loadedInvoice.invoice.workNote = note;
    }
    this.setState({
      isDraftEdited: true,
      loadedInvoice,
    });
  }

  _closeAlert = () => {
    this.setState({
      errorPopUpVisible: false,
    });
  };

  _showAlert = (
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
  ) => {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
    });
  };

  _getFileExtension(contentType: any) {
    const extension = mime.extension(contentType);
    if (extension) {
      return '.' + extension;
    }
    return '.jpeg';
  }

  _openDocument = (document: any) => {
    const fileType = document.type;
    if (Platform.OS === types2.IOS) {
      const ios = RNFetchBlobSingleton.ios;
      if (!this.state.isDraftInvoice) {
        const cleanUri = decodeURI(document.uri.replace('file://', ''));
        ios.openDocument(cleanUri);
      } else {
        this.setState({loading: true});
        const fileNameModified =
          document.fileName + this._getFileExtension(document.type);
        const dirs = RNFetchBlobSingleton.fs.dirs;
        RNFetchBlobSingleton.config({
          path: dirs.DocumentDir + '/LeapIn/' + fileNameModified,
          fileCache: true,
        })
          .fetch('GET', document.uri, {})
          .then(res => {
            this.setState({loading: false});
            ios
              .openDocument(res.path())
              .catch((err: any) =>
                this._showAlert(
                  types2.ERROR,
                  types2.DOWNLOAD_ERROR,
                  types2.OK,
                  this._closeAlert,
                  true,
                ),
              );
          })
          .catch(err => {
            this.setState({loading: false});
            this._showAlert(
              types2.ERROR,
              types2.DOWNLOAD_ERROR,
              types2.OK,
              this._closeAlert,
              true,
            );
          });
      }
    }
    if (Platform.OS === types2.ANDROID) {
      if (fileType === types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.uri,
          fileName: document.fileName,
        });
      } else if (types2.IMAGE_FILE_TYPES.includes(fileType)) {
        this.props.navigation.navigate('WebViewer', {
          url: document.data
            ? types2.BASE64_PREFIX + document.data
            : document.uri,
          fileName: document.fileName,
        });
      } else {
        // Download the file locally if it is not supported
        const url = document.uri;
        const {config, fs} = RNFetchBlobSingleton;
        const DownloadDir = fs.dirs.DownloadDir;
        const options = {
          fileCache: true,
          addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            mime: document.type,
            title: document.fileName + ' has been downloaded',
            description: 'File downloaded by file manager',
            mediaScannable: true,
            path: DownloadDir + '/LeapIn/' + document.fileName,
          },
        };
        config(options)
          .fetch('GET', url)
          .then(res => {});
      }
    } else if (types.isWeb) {
      if (document.uri) {
        window.open(document.uri);
      } else {
        FileDownloaderSingleton(document, document.fileName);
      }
    }
  };

  _invoiceApprovalValue = () => {
    let totalValue = 0.0;
    if (Array.isArray(this.state.loadedInvoice.supportItems)) {
      this.state.loadedInvoice.supportItems.forEach(function (item: any) {
        const formattedCurrency = parseFloat(item.itemtotal).toFixed(
          types2.AMOUNT_DECIMAL_POINT,
        );
        totalValue += +formattedCurrency;
      });
    }
    return totalValue;
  };

  _renderSupporItemList = (data: any) => {
    const dataArray = data.map((item: any, index: any) => {
      const itemContent = {
        itemCode: item.billerCode,
        description: item.selectedSuportItem.description,
        budgetCategory: item.selectedSuportItem.description,
        quantity: item.unit ? item.unit : undefined,
        duration: item.hour + ':' + item.mins,
        unitPrice: item.rate,
        total: item.itemtotal,
        startDate: item.startdate,
        endDate: item.enddate,
      };
      return itemContent;
    });
    return (
      <InvoiceList
        itemCode={messages.COLUMNS.ITEM_CODE}
        itemDesc={messages.COLUMNS.DESCRIPTION}
        itemCategory={messages.COLUMNS.CATEGORY}
        itemQty={messages.COLUMNS.QUANTITY}
        itemUnitPrice={messages.COLUMNS.UNIT_PRICE}
        itemTotalPrice={messages.COLUMNS.TOTAL_PRICE}
        itemDate={messages.COLUMNS.DATE}
        data={dataArray}
        section={'title'}
      />
    );
  };

  _uploadInvoiceDoc = (isDraft: any, preApproval: any) => {
    const invoiceDetails = this.state.loadedInvoice.invoice.invoiceDocPath;

    if (!invoiceDetails) {
      return;
    }

    if (types.isWeb) {
      this.props.setLoading(true);
    }

    if (invoiceDetails.s3FileKey) {
      invoiceDetails.s3FileName = invoiceDetails.s3FileKey
        .split(types2.MEMBER_INVOICE_PATH)
        .pop();
    }

    const submissionTime = Date.now();
    const {invoiceNumber} = this.state.loadedInvoice.invoice;
    let invoiceAttachmentFileName = '';
    if (invoiceDetails.isChanged) {
      invoiceAttachmentFileName = `${invoiceNumber}_${submissionTime}`;
    } else {
      invoiceAttachmentFileName = invoiceDetails.s3FileName;
    }

    const requestObject = {
      fileType: 'memberInvoice',
      filename: invoiceAttachmentFileName,
      contentType: invoiceDetails.type,
    };
    const success = () => {
      this._submit(invoiceAttachmentFileName, isDraft, preApproval);
    };
    const error = (err: any) => {
      logger.log('The error is: ', err);
      this.setState({
        loading: false,
        isInvoiceSubmissionInProgress: false,
      });
    };

    this.setState({loading: true, isInvoiceSubmissionInProgress: true});

    if (invoiceDetails.isChanged) {
      const fileUploader = new FileUploader(
        this.props.loadedMemberId.loadedMemberId,
        invoiceDetails,
        success,
        error,
      );
      fileUploader.startUploading(requestObject);
    } else {
      this._submit(invoiceAttachmentFileName, isDraft, preApproval);
    }
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push([CommonStyles.containerAdhocInvoiveSubmissionPopUpTablet]);
    } else {
      styles.push([CommonStyles.containerAdhocInvoiveSubmissionPopUpMobile]);
    }
    return styles;
  };

  _renderSubmissionResults = () => {
    let paymentResult = this.props.payInvoice.paymentResult;
    let headerTitle = '';
    let messageBody = '';
    if (paymentResult && paymentResult.status == messages.APPROVED) {
      headerTitle = messages.APPROVED_HEADER;
      messageBody = messages.APPROVED_SUBHEADER;
    } else if (
      paymentResult &&
      paymentResult.status == messages.ALREADY_APPROVED
    ) {
      headerTitle = messages.ALREADY_APPROVED_HEADER;
      messageBody = messages.ALREADY_APPROVED_SUBHEADER;
    } else if (
      paymentResult &&
      paymentResult.status == messages.SUBMITED_FOR_APPROVAL
    ) {
      headerTitle = messages.MEMBER_APPROVAL_HEADER;
      messageBody = messages.MEMBER_APPROVAL_SUBHEADER;
    } else if (this.state.invoiceProcessing) {
      headerTitle = messages.BEINGPROCESS_HEADER;
      messageBody = warning.LINE;
    } else {
      headerTitle = messages.REJECTED_HEADER;
      let errors =
        paymentResult && paymentResult.validationErrors
          ? paymentResult.validationErrors
          : null;
      let errroStr = messages.REJECTED_SUBHEADER;
      if (errors) {
        errroStr = '';
        errors.map((value: any, i: any) => {
          errroStr += '\u2022 ' + value + ' \n\n';
        });
      }
      messageBody = errroStr;
    }

    let messageFontSize = CommonStyles.font18;
    let headerFontSize = CommonStyles.font20;
    if (types.isWeb) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    } else if (isTablet()) {
      messageFontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font25;
    }

    return (
      <BasicOverlayPopupModal
        visible={this.state.visible}
        style={this._determinePopUpStyle(isTablet())}
        backdropOpacity={0.2}
        divider={true}
        close={this._closeSubmitResult}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={CommonStyles.buttonPopupOk}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View style={[]}>
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.scrollView_subContentCenter,
              CommonStyles.paddingBottom20,
            ]}
          >
            {headerTitle == messages.REJECTED_HEADER ||
            headerTitle == messages.BEINGPROCESS_HEADER ? (
              <View>
                {/* <Image
                  visible={this.state.errorPopup}
                  style={[CommonStyles.changePasswordButtonImage]}
                  source={exclamationYellow}
                /> */}
              </View>
            ) : null}
            <View style={[CommonStyles.scrollView_subContentCenter]}>
              <StandardText
                style={[
                  headerFontSize,
                  BrandStyles.TextColor10,
                  CommonStyles.customFontSemiBold,
                ]}
              >
                {headerTitle}
              </StandardText>
            </View>
          </View>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                messageFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.textAlignCentre,
              ]}
            >
              {messageBody}
            </StandardText>
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  isEmployeeIsFinancialStaff = () => {
    return this.props.user.isEmployee || this.props.user.isFinancialStaff;
  };
  _closeSubmitResult = () => {
    let paymentResult = this.props.payInvoice.paymentResult;
    this.setState({visible: false});
    if (
      (paymentResult && paymentResult.status == 'approved') ||
      this.state.invoiceProcessing
    ) {
      this.setState({invoiceProcessing: false});
      this.props.navigation.navigate(
        'Home',
        null,
        //replace current route for web
        types.isWeb,
      );
    } else if (
      paymentResult.status == 'already_approved' ||
      paymentResult.status == 'submitted_for_approval'
    ) {
      this.setState({invoiceProcessing: false});
      this.props.navigation.navigate(
        'PaymentsHistory',
        null,
        //replace current route for web
        types.isWeb,
      );
    }
  };

  _submit = (
    invoiceAttachmentFileName: any,
    isDraft: any,
    preApproval: any,
  ) => {
    if (
      (this.state.loadedInvoice.supportItems == undefined) |
      (this.state.loadedInvoice.supportItems.length == 0)
    ) {
      this.setState({
        validSupportItem: types.INVOIVE_SUPPORT_ITEM_EMPTY,
      });
    } else {
      this.setState({loading: true, isInvoiceSubmissionInProgress: true});

      var claimsValue: any = [];

      this.state.loadedInvoice.supportItems.map((item: any) => {
        if (item && item.billerCode) {
          var quantityValue = {};

          if (item.hour != '' && item.mins != '') {
            quantityValue = {
              duration: item.hour + ':' + item.mins,
            };
          } else {
            quantityValue = {
              units: item.unit,
            };
          }

          claimsValue.push({
            itemCode: item.billerCode,
            itemName: item.selectedSuportItem.name,
            itemDescription: item.selectedSuportItem.description,
            unitPriceIncTax: item.rate,
            serviceStartTime: formatDateToString(item.startdate),
            serviceEndTime: item.enddate
              ? formatDateToString(item.enddate)
              : formatDateToString(item.startdate),
            quantity: quantityValue,
          });
        }
      });

      const paymentDetails = {
        billerKey: this.state.loadedInvoice.selectedProvider.billerKey,
        billerName: this.state.loadedInvoice.selectedProvider.organisation,
        invoiceDate: formatDateToString(
          this.state.loadedInvoice.invoice.invoiceDate,
        ),
        invoiceNumber: this.state.loadedInvoice.invoice.invoiceNumber,
        invoiceTotal: this._invoiceApprovalValue(),
        invoiceAttachmentFileName,
        claims: claimsValue,
        memberProviderId:
          this.state.loadedInvoice.selectedProvider.memberProviderId,
        invoiceWorkNote: this.state.loadedInvoice.invoice.workNote,
        invoiceMemberNote: this.state.loadedInvoice.invoice.memberNote,
      };
      const isDraftSubmission =
        this._getParams('isDraftInvoice') !== 'undefined'
          ? this._getParams('isDraftInvoice')
          : false;
      const invoiceId =
        this._getParams('invoiceId') !== 'undefined'
          ? parseInt(this._getParams('invoiceId'))
          : null;
      let APIArray = [
        postMemberInvoice(
          this.props.loadedMemberId.loadedMemberId,
          paymentDetails,
          isDraftSubmission,
          invoiceId,
          preApproval,
          false,
        ),
      ];

      // Check first Time Draft Invoice
      if (
        isDraft &&
        this.state.isDraftEdited &&
        (invoiceId === null || isNaN(invoiceId))
      ) {
        APIArray = [
          postDraftInvoice(
            this.props.loadedMemberId.loadedMemberId,
            paymentDetails,
            false,
          ),
        ];
      }

      // Check for existing Draft Invoice
      if (
        isDraft &&
        this.state.isDraftEdited &&
        invoiceId !== null &&
        !isNaN(invoiceId)
      ) {
        APIArray = [
          putDraftInvoice(
            this.props.loadedMemberId.loadedMemberId,
            invoiceId,
            true,
            paymentDetails,
            false,
          ),
        ];
      }

      callAPIs(
        APIArray,
        (data: any) => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }

          // Check for Invoice Submit
          if (!isDraft && data[0]) {
            if (data[0].content.message === `Duplicate`) {
              return;
            }
            this.props.actions.payInvoiceActions.setPaymentSubmitResult(
              data[0].content,
            );
            this.setState({loading: false});
            this.setState({visible: true});
            this.setState({isInvoiceSubmissionInProgress: false});
          }
          // Check for Draft Invoice Save
          if (isDraft && data[0] && data[0].content) {
            const count = this.state.loadedInvoice.draftInvoicesCount + 1;
            this.props.actions.payInvoiceActions.setDraftInvoicesCount(count);
            this.setState({loading: false});
            this.setState({isInvoiceSubmissionInProgress: false});
            if (types.isWeb) {
              this.props.navigation.navigate('DraftClaimInvoices');
            } else {
              const actionToDispatch = CommonActions.reset({
                index: 1,
                routes: [
                  {name: 'Home'},
                  {name: 'DraftClaimInvoices'}
                ],
              });
              this.props.navigation.dispatch(actionToDispatch);
            }
            //this.props.navigation.navigate('DraftClaimInvoices');
            return;
          }
        },
        (err: any) => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          if (err.code === '408') {
            this.setState({visible: true, invoiceProcessing: true});
          }
          this.setState({loading: false});
        },
        () => {
          this.setState({loading: false});
        },
      );
    }
  };
}

const mapStateToProps = (state: any) => ({
  payInvoice: state.PayInvoiceReducer,
  user: state.UserReducer,
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewInvoiceSummary);
