/*
 * Author: Nirodha Perera
 * Date: 20/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Screen for adding new goals, edit and delete
 */

import React, {Component} from 'react';
import {View, ScrollView, Dimensions} from 'react-native';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {
  SummaryTile,
  Container,
  GridView,
  ActionButton,
  GoalInspirationTile,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import {
  StandardInput,
  StandardButton,
  StandardText,
  StandardTouchableOpacity,
} from '../Components/Atoms';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isEmpty} from 'lodash';

// import DeviceInfo from 'react-native-device-info';
import {isTablet} from '../Helpers/PlatformSynchronizer';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {
  getGoalTemplateReference,
  getGoalFiltersReference,
  getGoalStepReference,
} from '../API/ReferenceAPI';
import {
  putGoalForPlan,
  deleteGoalForPlan,
  getGoalsForPlan,
} from '../API/PlanDataAPI';
import {getValue} from '../API/WebPersistenceStore';

import * as GoalActions from '../Actions/GoalActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {
  addIcon,
  goalsIconRed,
  deleteIconCharcoal60,
} from '../assets/images/vector-icons';

import * as messages from '../Constants/Messages';
import {BrandColors} from '../Styles/Colours';
import {G} from 'react-native-svg';
import { DOMPurify } from 'helpers/dompurify';

const home = 'Home';
const goal = 'GoalSummary';
const goalPreview = 'GoalPreview';
const {height, width} = Dimensions.get('window');
const CURRENT_GOAL = 'currentGoal';
const CURRENT_GOAL_OBJECT = {
  goalName: '',
};
let screenMode: any;

class AddGoals extends Component {
  scrollView: any;
  state = {
    isLoading: false,
    goalName: '',
    tileContainerDimensions: undefined,
    viewContainerDimensions: undefined,
    goalsFilters: [],
    setFilterLifeStages: true,
    goalStepList: [],
    stepName: '',
    scrollEnabled: true,
    inspirationContent: undefined,
    defaultInspirationContent: [],
    bgColor: [
      BrandColors.GOALS_INSPIRATION_COLORS.FIRE_ENGINE_RED,
      BrandColors.GOALS_INSPIRATION_COLORS.RED_ORANGE,
      BrandColors.GOALS_INSPIRATION_COLORS.RAZZMATAZZ,
      BrandColors.GOALS_INSPIRATION_COLORS.EGGPLANT,
      BrandColors.GOALS_INSPIRATION_COLORS.PERSIAN_ROSE,
      BrandColors.GOALS_INSPIRATION_COLORS.DARK_ORCHID,
      BrandColors.GOALS_INSPIRATION_COLORS.INDIGO,
      BrandColors.GOALS_INSPIRATION_COLORS.DARK_BLUE,
      BrandColors.GOALS_INSPIRATION_COLORS.COBALT,
      BrandColors.GOALS_INSPIRATION_COLORS.NAVY_BLUE,
      BrandColors.GOALS_INSPIRATION_COLORS.BONDI_BLUE,
      BrandColors.GOALS_INSPIRATION_COLORS.SHERPA_BLUE,
      BrandColors.GOALS_INSPIRATION_COLORS.TROPICAL_RAIN_FOREST,
      BrandColors.GOALS_INSPIRATION_COLORS.PIGMENT_GREEN,
      BrandColors.GOALS_INSPIRATION_COLORS.FUN_GREEN,
    ],
    errorPopUpVisible: false,
    errorPopUpMessage: '',
    approveDeleteModal: false,
    initialFormState: {},
  };

  constructor(props: any) {
    super(props);
    this._goNext.bind(this);
    this._goNextInsparation.bind(this);
    this._goNextStepInsparation.bind(this);
    this._showFiltersModal.bind(this);
    this._renderFilterDescription.bind(this);
    this._filterText.bind(this);
    this._filterTextBold.bind(this);
    this._renderFilterHeaders.bind(this);
    this._mountFilter.bind(this);
    this._getRandomColor.bind(this);
    this._renderStepContent.bind(this);
    this._getInspirationSteps.bind(this);
    this._deleteGoal.bind(this);
    this._renderGoalNextContent.bind(this);
    this._close.bind(this);
    this._saveGoal.bind(this);
    this._addStep.bind(this);
    this._deleteStep.bind(this);
    this._updateStep.bind(this);
    this._closeErrorPopUp.bind(this);
    this._determinePopUpStyle.bind(this);
    this._renderErrorPopUp.bind(this);
    this.getPopUpContent.bind(this);
    this._renderDeletePopUp(this);
    this._closeDeletePopUp(this);
  }

  componentDidMount() {
    let currentGoalFromStore = CURRENT_GOAL_OBJECT;
    if (types.isWeb && isEmpty(this.props.goals)) {
      currentGoalFromStore = this._setCurrentGoalFromStore();
      this.fetchData();
    }

    this._setLoading(true);

    // Get the goals templates
    let getGoalTemplateReferenceCallback = (data: any) => {
      let content = data[0].content;
      this.props.actions.GoalActions.getGoalTemplates(content.templates);
      this._mountFilter(true);
      this._setLoading(false);
    };

    if (
      types.isWeb &&
      !isEmpty(this.props.goals) &&
      this.props.navigationParams.params &&
      this.props.navigationParams.params.isFilterApplied
    ) {
      this.setState({
        goalName: this.props.goals.currentGoal.goalName,
      });
      let goalFilter = JSON.parse(
        JSON.stringify(this.props.goals.goalsFilters.referenceData),
      );
      this.setState({goalsFilters: goalFilter});

      this._setLoading(false);
    } else {
      this.setState({
        goalName: this.props.goals.currentGoal
          ? this.props.goals.currentGoal.goalName
          : currentGoalFromStore.goalName,
      });
      if (
        this.props.goals.goalsTemplates === undefined ||
        this.props.goals.goalsTemplates == null
      ) {
        // First time calling the API
        callAPIs(
          [getGoalTemplateReference()],
          getGoalTemplateReferenceCallback,
          null,
          () => {
            this._setLoading(false);
          },
        );
      } else {
        // API already called once, check the timeout. If not expired redux will continue to be used
        if (
          callApiChecker(
            this.props.goals.goalsTemplates.apiCalled,
            types.API_REFERENCE_TIMEOUT_CHECK,
          )
        ) {
          callAPIs(
            [getGoalTemplateReference()],
            getGoalTemplateReferenceCallback,
            null,
            () => {
              this._setLoading(false);
            },
          );
        } else {
          this.setState({
            setFilterLifeStages: true,
          });
          this._mountFilter(true);

          setTimeout(
            function (this: any) {
              this._setLoading(false);
            }.bind(this),
            2000,
          );
        }
      }

      this._setInitialState();
    }
  }

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  UNSAFE_componentWillMount() {
    let currentGoalFromStore = '';
    if (types.isWeb) {
      currentGoalFromStore = JSON.parse(getValue(CURRENT_GOAL));
    }
    if (this.props.goals.currentGoal || !isEmpty(currentGoalFromStore)) {
      const goalName = this.props.goals.currentGoal
        ? this.props.goals.currentGoal.goalName
        : currentGoalFromStore.goalName;
      this.setState({
        goalName: goalName,
        goalStepList: this.props.goals.currentGoal
          ? this.props.goals.currentGoal.currentGoalSteps
          : currentGoalFromStore.currentGoalSteps,
        inspirationContent: [],
      });
    }
  }

  fetchData = () => {
    const screenMode = this._getParams('screenMode');
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({refreshing: true});
    const callbackFunction = (results: any) => {
      const goalsPayload = results[0].content.currentGoals;

      this.props.actions.GoalActions.actionGetGoals(goalsPayload);
      this.props.actions.NavigationParamActions.setParam({
        isFilterApplied: false,
      });
      if (screenMode === types.SCREEN_MODE_NEW) {
        this.props.actions.GoalActions.addNewGoal(
          this.props.member.id,
          this.props.member.planId,
        );
      }
      if (screenMode === types.SCREEN_MODE_EDIT) {
        this.props.actions.GoalActions.editExistingGoal(
          JSON.parse(getValue(CURRENT_GOAL)),
        );
      }

      // Set the state to no longer be loading
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    };

    callAPIs(
      [
        getGoalsForPlan(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
        ),
      ],
      callbackFunction,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  _setCurrentGoalFromStore = () => {
    let currentGoalFromStore = JSON.parse(getValue(CURRENT_GOAL));
    if (currentGoalFromStore === undefined || currentGoalFromStore === null) {
      currentGoalFromStore = CURRENT_GOAL_OBJECT;
    }
    return currentGoalFromStore;
  };

  _setLoading = (loadingState: any) => {
    if (types.isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({isLoading: loadingState});
    }
  };

  _mountFilter(applyLifeStage: any) {
    let getGoalFiltersReferenceCallback = (data: any) => {
      let payload = data[0];

      // Create a deep copy of the payload.
      this.props.actions.GoalActions.getGoalFiltersReference(
        JSON.parse(JSON.stringify(payload.content)),
      );
      let goalFilter = JSON.parse(JSON.stringify(payload.content));
      goalFilter = this._lifeStageFilterFunction(goalFilter);
      this._setLoading(false);
      this.setState({goalsFilters: goalFilter});
    };

    if (
      this.props.goals.goalsFilters === undefined ||
      this.props.goals.goalsFilters == null
    ) {
      // First time calling the api
      callAPIs(
        [getGoalFiltersReference()],
        getGoalFiltersReferenceCallback,
        null,
        () => {
          this._setLoading(false);
        },
      );
    } else {
      // API already called once, check the timeout. If not expired redux will continue to be used
      if (
        callApiChecker(
          this.props.goals.goalsFilters.apiCalled,
          types.API_REFERENCE_TIMEOUT_CHECK,
        )
      ) {
        callAPIs(
          [getGoalFiltersReference()],
          getGoalFiltersReferenceCallback,
          null,
          () => {
            this._setLoading(false);
          },
        );
      } else {
        let goalFilter = JSON.parse(
          JSON.stringify(this.props.goals.goalsFilters.referenceData),
        );
        if (applyLifeStage) {
          goalFilter = this._lifeStageFilterFunction(goalFilter);
        }
        this.setState({goalsFilters: goalFilter});
      }
    }
  }

  _lifeStageFilterFunction(goalFilter: any) {
    let selectedCategories: any = [];
    let lifeId: any = [];
    const selectedFilterNames: any = [];
    if (this.props.member.lifeStages.length > 0) {
      for (let i = 0; i < this.props.member.lifeStages.length; i++) {
        lifeId.push(this.props.member.lifeStages[i].lifeStageId);
        selectedFilterNames.push(
          this.props.member.lifeStages[i].lifeStageDescription,
        );
      }
      this.props.actions.GoalActions.setSelectedFilters(
        JSON.parse(JSON.stringify(goalFilter)),
        selectedCategories,
        lifeId,
        selectedFilterNames,
      );
      return goalFilter;
    } else {
      this.props.actions.GoalActions.setSelectedFilters(
        JSON.parse(JSON.stringify(goalFilter)),
        selectedCategories,
        lifeId,
        selectedFilterNames,
      );
      return goalFilter;
    }
  }

  _getParams = (key: any) => {
    if(types.isWeb) {
      return this.props.navigation.getParam(key, undefined) || undefined;
     }
     
    return this.props.route.params? this.props.route.params[key] : undefined;
  };

  _checkForCurrentGoal = () => {
    if (types.isWeb) {
      let currentGoalFromStore = JSON.parse(getValue(CURRENT_GOAL));
      if (!isEmpty(currentGoalFromStore)) {
        return true;
      }
    }
    if (this.props.goals.currentGoal && this.props.goals.currentGoal.goalName) {
      return true;
    }
    return false;
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={false}
        ignorePadding={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        loading={this.state.isLoading}
        headerTitle={types2.NAVIGATION_TEXT.GOAL_DETAIL}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AddGoals.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    const screenMode = this._getParams('screenMode');
    return (
      <View
        onLayout={this._onScreenLayout}
        style={[
          CommonStyles.singleMainContentContainer,
          CommonStyles.marginTop20,
        ]}
      >
        {this._renderSummaryTile()}

        <ScrollView
          scrollEnabled={this.state.scrollEnabled}
          ref={ref => {
            this.scrollView = ref;
          }}
        >
          {screenMode === types.SCREEN_MODE_EDIT && this._checkForCurrentGoal()
            ? this.renderCancelSaveButtons()
            : null}
          {this._renderErrorPopUp()}
          {this._renderDeletePopUp()}

          <View style={[CommonStyles.containerGoalHeadContent]}>
            <StandardText
              style={[
                CommonStyles.textTitle,
                CommonStyles.customFontBold,
                CommonStyles.paddingRight10,
                BrandStyles.TextColor5,
              ]}
            >
              {messages.ADD_GOALS.GOAL_IS}
            </StandardText>
            <View style={[CommonStyles.flexDirectionRow]}>
              {this._checkForCurrentGoal() ? (
                <StandardText
                  style={[
                    CommonStyles.marginTop10,
                    CommonStyles.marginBottom10,
                    CommonStyles.textSubTitle,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                >
                  {this.state.goalName}
                </StandardText>
              ) : (
                <StandardInput
                  value={this.state.goalName}
                  placeholder={messages.ADD_GOALS.GOAL_NAME}
                  onChangeText={(goalName: any) => {
                    const sanitizedGoalName = DOMPurify.sanitize(goalName);

                    this.setState({goalName: sanitizedGoalName})}}
                  style={[
                    BrandStyles.primaryBgColor1,
                    CommonStyles.textInputContainerStep,
                    types.isWeb ? CommonStyles.font15 : null,
                  ]}
                  underlineColorAndroid="transparent"
                  applyWrapper={false}
                  hideTabOnFocus={true}
                  noMargin={true}
                  multiline={true}
                  readOnly={this.props.member.access.profile}
                />
              )}
            </View>
          </View>
          {screenMode === types.SCREEN_MODE_EDIT && this._checkForCurrentGoal()
            ? null
            : this._checkForCurrentGoal()
            ? null
            : this.renderCancelSaveButtons()}
          {this._renderGoalNextContent()}
          {this._renderActionButtons()}
        </ScrollView>
      </View>
    );
  };

  renderCancelSaveButtons = () => {
    return (
      <View style={[CommonStyles.containerGoalContent]}>
        <View style={this._deterMinActionButtonStyles()}>
          <View style={CommonStyles.ModalFooterLeft}>
            {this._renderCancelButton()}
          </View>
          <View style={CommonStyles.ModalFooterRight}>
            {this.props.member.access.profile == types.ACCESS_FULL &&
              this._renderSaveButton()}
          </View>
        </View>
      </View>
    );
  };

  _deterMinActionButtonStyles = () => {
    let styles = [
      CommonStyles.flexDirectionRow,
      CommonStyles.alignItemsCenter,
      CommonStyles.marginBottom10,
    ];
    if (this.props.isMobile) {
      return styles;
    }
    if (types.isWeb || isTablet) {
      styles.push([CommonStyles.marginLeftTab, CommonStyles.marginRightTab]);
    }
    return styles;
  };

  _renderActionButtons = () => {
    if (
      this.state.viewContainerDimensions &&
      this.state.viewContainerDimensions.viewHeight +
        types2.ADDED_VIEW_HEIGHT >=
        height
    ) {
      return (
        <View
          style={[
            CommonStyles.containerGoalContent,
            CommonStyles.paddingBottom10,
          ]}
        >
          <View style={this._deterMinActionButtonStyles()}>
            <View style={CommonStyles.ModalFooterLeft}>
              {this._renderCancelButton()}
            </View>
            <View style={CommonStyles.ModalFooterRight}>
              {this.props.member.access.profile == types.ACCESS_FULL &&
                this._renderSaveButton()}
            </View>
          </View>
        </View>
      );
    }
  };

  _renderGoalNextContent = () => {
    const screenMode = this._getParams('screenMode');
    let currentGoalFromStore = '';
    if (types.isWeb) {
      currentGoalFromStore = JSON.parse(JSON.stringify(getValue(CURRENT_GOAL)));
    }
    if (!isEmpty(currentGoalFromStore) || this._checkForCurrentGoal()) {
      return (
        <View>
          <View style={[CommonStyles.containerGoalHeadContent]}>
            {this._renderStepContent()}
            {this._renderStepAdd()}
          </View>
          {screenMode === types.SCREEN_MODE_EDIT
            ? null
            : this.renderCancelSaveButtons()}
          <View
            style={[CommonStyles.content, CommonStyles.containerGoalContent]}
          >
            <View
              onLayout={this._onTilesLayout}
              style={[CommonStyles.contentFlexWrap]}
            >
              {this._goalStepInspirationTiles()}
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View>
          {this._renderFilterHeaders()}
          <View
            style={[CommonStyles.content, CommonStyles.containerGoalContent]}
          >
            <View
              onLayout={this._onTilesLayout}
              style={[
                CommonStyles.contentFlexWrap,
                CommonStyles.paddingBottom5,
              ]}
            >
              {this._goalInspirationTiles()}
            </View>
          </View>
        </View>
      );
    }
  };

  _goNext = (applyValidation: any, insparationId: any) => {
    if (applyValidation) {
      // Update redux with goal name from state
      // In here - as if outside _goeNextInsparation calls this function
      // And goalName state is still blank as the state is still setting
      if (this.state.goalName == '') {
        this.setState({
          errorPopUpVisible: true,
          errorPopUpMessage: messages.ADD_GOALS.TITLE_NOT_SELECTED,
        });
      } else {
        this.props.actions.GoalActions.setGoalName(this.state.goalName);
        this._addStep();
      }
    } else {
      this._getInspirationSteps(insparationId);
      this._addStep();
    }
  };

  _addStep = () => {
    if (screenMode == types.SCREEN_MODE_EDIT) {
      this._getInspirationSteps(this.props.goals.currentGoal.currentGoalId);
    }

    var goalStepListValue = this.state.goalStepList;
    var goalStepsLength = goalStepListValue.length;
    if (
      goalStepsLength > 0 &&
      goalStepListValue[goalStepsLength - 1].stepName == ''
    ) {
      this.setState({
        errorPopUpVisible: true,
        errorPopUpMessage: messages.ADD_GOALS.STEP_NOT_SELECTED,
      });
    } else {
      //Add first step
      goalStepListValue.push({
        currentGoalStepId: goalStepsLength + 1,
        listPosition: goalStepsLength + 1,
        stepName: '',
        stepDescription: '',
      });

      this.setState({goalStepList: goalStepListValue, scrollEnabled: true});
      if (goalStepListValue.length == 2) {
        this.scrollView.scrollTo({y: 400});
      }
    }
  };

  _closeErrorPopUp = () => {
    this.setState({
      errorPopUpVisible: false,
      errorPopUpMessage: '',
      scrollEnabled: true,
    });
  };

  _closeDeletePopUp = () => {
    this.setState({
      approveDeleteModal: false,
    });
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push(
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height300,
      );
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height230,
      );
    }
    return styles;
  };

  _renderErrorPopUp = () => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.errorPopUpVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={true}
        close={this._closeErrorPopUp}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>{this.getPopUpContent(isTablet)}</View>
      </BasicOverlayPopupModal>
    );
  };

  _renderDeletePopUp = () => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.approveDeleteModal}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closeDeletePopUp}
        cancelLabel={types2.CANCEL}
        saveLabel={types2.DELETE}
        save={this._deleteGoal}
      >
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.font20,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
                CommonStyles.paddingBottom20,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.DELETE_GOAL}
            </StandardText>
          </View>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.paddingBottom20,
                CommonStyles.textAlignCentre,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.DELETE_MESSAGE}
            </StandardText>
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  getPopUpContent(isTablet: any) {
    let fontSize = CommonStyles.rpfont15;
    if (types.isWeb) {
      fontSize = CommonStyles.font15;
    } else if (isTablet) {
      fontSize = CommonStyles.font18;
    }
    return (
      <View style={[]}>
        <View
          style={[
            CommonStyles.paddingTop10p,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              fontSize,
              CommonStyles.paddingBottom5p,
            ]}
          >
            {this.state.errorPopUpMessage}
          </StandardText>
        </View>
      </View>
    );
  }

  _renderCancelButton() {
    return (
      <StandardButton
        onPress={this._close}
        style={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ]}
        containerStyle={[
          BrandStyles.primaryBgColor1,
          BrandStyles.brandBorderColor1,
          CommonStyles.buttonContainerFormAction,
        ]}
        accessibilityLabel={types2.CANCEL}
      >
        {types2.CANCEL}
      </StandardButton>
    );
  }

  _close = () => {
    this.props.actions.GoalActions.cancelEdits();
    this.props.navigation.goBack();
  };

  _renderSummaryTile() {
    screenMode = this._getParams('screenMode');
    const screenTitle = types2.GOAL_SCREEN_LABELS.PREVIEW_GOAL;
    if (screenMode == types.SCREEN_MODE_EDIT) {
      return (
        <SummaryTile
          headerDisplayString={screenTitle ? screenTitle : goal}
          headerIconImage={goalsIconRed}
          screenType={types.SCREEN_TYPE_MAIN}
          modal={() => {
            this.setState({approveDeleteModal: true});
          }}
          containsData={true}
          displayMore={false}
          hideYellowStar={true}
          actionOverride={types.ACTION_BUTTON.DELETE}
          showIconString={true}
          hideActionButton={
            this.props.member.access.profile == types.ACCESS_FULL ? false : true
          }
          imageActionIcon={false}
        />
      );
    } else if (screenMode === types.SCREEN_MODE_NEW) {
      return (
        <SummaryTile
          headerDisplayString={screenTitle ? screenTitle : goal}
          headerIconImage={goalsIconRed}
          screenType={types.SCREEN_TYPE_MAIN}
          containsData={true}
          showButton={false}
          hideYellowStar={true}
          showIconString={true}
          imageActionIcon={false}
        />
      );
    } else {
      return null;
    }
  }

  _renderSaveButton() {
    if (this._checkForCurrentGoal()) {
      return (
        <StandardButton
          onPress={() => this._saveGoal()}
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonFormAction,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerFormAction,
          ]}
          disabled={
            this.props.member.access.profile == types.ACCESS_FULL ? false : true
          }
        >
          Save
        </StandardButton>
      );
    } else {
      return (
        <StandardButton
          onPress={() => this._goNext(true)}
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonFormAction,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerFormAction,
          ]}
          disabled={
            this.props.member.access.profile == types.ACCESS_FULL ? false : true
          }
        >
          Save
        </StandardButton>
      );
    }
  }

  _saveGoal() {
    this._setLoading(true);

    const goalObject = this.props.goals.currentGoal;
    const goalSteps = this.state.goalStepList.filter(
      goalStep => goalStep.stepName !== '',
    );
    goalObject.currentGoalSteps = goalSteps;

    if (goalObject.goalName !== this.state.goalName) {
      goalObject.goalName = this.state.goalName;
    }

    // Make sure the goal has plan id & member id for editing a goal
    goalObject.id = this.props.member.id;
    goalObject.planId = this.props.member.planId;
    goalObject.isDemoProfile = this.props.user.demoProfile;

    // Set the goal status to active
    goalObject.isActive = true;

    const putGoalForPlanCallback = (data: any) => {
      const payload = data[0];

      // Add the goal id to the object we saved, as when stored in redux
      // We need it to set a flatlist key, goal photo and for editing
      goalObject.currentGoalId = payload.content.currentGoalId;

      screenMode = this._getParams('screenMode');

      // Add goal to the goals list (Only if it's a new goal)
      if (screenMode == types.SCREEN_MODE_NEW) {
        this.props.actions.GoalActions.addGoalToList(goalObject);
      }

      if (screenMode == types.SCREEN_MODE_EDIT) {
        this.props.actions.GoalActions.updateGoalInList(goalObject);
      }

      // Also set the goal ID for the current goal which is used for goal
      // Image uploading
      this.props.actions.GoalActions.setGoalId(payload.content.currentGoalId);
      this._setLoading(false);
      this.props.navigation.replace('GoalPreview', null, types.isWeb);
    };

    // Call API to create/update the crew
    callAPIs(
      [putGoalForPlan(goalObject, this.props.user.demoProfile)],
      putGoalForPlanCallback,
      null,
      () => {
        this._setLoading(false);
      },
    );
  }

  _deleteGoal = () => {
    this._setLoading(true);
    this.setState({approveDeleteModal: false});

    let deleteGoalForPlanCallback = (data: any) => {
      // Remove from redux
      this.props.actions.GoalActions.deleteExistingGoalInList(
        this.props.goals.currentGoal.currentGoalId,
      );

      this._setLoading(false);
      // Navigate back to the goal summary page
      if (types.isWeb) {
        this.props.navigation.navigate(goal, null, types.isWeb);
      } else {
        const actionToDispatch = CommonActions.reset({
          index: 1,
          routes: [
            {name: home},
            {name: goal}
          ],
        });
        this.props.navigation.dispatch(actionToDispatch);
      }
    };

    // Call API to create/update the crew
    callAPIs(
      [
        deleteGoalForPlan(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.goals.currentGoal.currentGoalId,
        ),
      ],
      deleteGoalForPlanCallback,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  _renderStepText = (index: any, item: any) => {
    const screenMode = this._getParams('screenMode');
    if (this.state.goalStepList.length - 1 === index) {
      return (
        <StandardInput
          value={item.stepName}
          placeholder={messages.ADD_GOALS.ADD_STEPS_INPUT_PLACEHOLDER}
          onChangeText={(stepName: any) =>{
            const sanitizedStepName = DOMPurify.sanitize(stepName);

            this._updateStep(sanitizedStepName, item, index)
          }}
          style={[
            BrandStyles.primaryBgColor1,
            CommonStyles.textInputContainerStep,
            types.isWeb ? CommonStyles.font15 : null,
          ]}
          underlineColorAndroid="transparent"
          applyWrapper={false}
          errorMessage={this.state.validStepName}
          hideTabOnFocus={true}
          noMargin={true}
          multiline={true}
          readOnly={this.props.member.access.profile}
        />
      );
    } else {
      if (screenMode === types.SCREEN_MODE_NEW) {
        return (
          <StandardText
            style={[
              CommonStyles.textSubTitle,
              CommonStyles.customFontBold,
              CommonStyles.paddingTop10,
              BrandStyles.TextColor5,
            ]}
          >
            {item.stepName}
          </StandardText>
        );
      } else {
        return (
          <StandardInput
            value={item.stepName}
            placeholder={messages.ADD_GOALS.ADD_STEPS_INPUT_PLACEHOLDER}
            onChangeText={(stepName: any) =>{
              const sanitizedStepName = DOMPurify.sanitize(stepName);

              this._updateStep(sanitizedStepName, item, index)
            }}
            style={[
              BrandStyles.primaryBgColor1,
              CommonStyles.textInputContainerStep,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
            underlineColorAndroid="transparent"
            applyWrapper={false}
            errorMessage={this.state.validStepName}
            hideTabOnFocus={true}
            noMargin={true}
            multiline={true}
            readOnly={this.props.member.access.profile}
          />
        );
      }
    }
  };

  _renderStepContent() {
    if (this.state.goalStepList) {
      return this.state.goalStepList.map((item, index) => {
        const stepIndex = index + 1;
        return (
          <View key={stepIndex}>
            <StandardText
              style={[
                CommonStyles.textTitle,
                CommonStyles.customFontBold,
                CommonStyles.paddingRight10,
                BrandStyles.TextColor5,
                CommonStyles.paddingTop15,
              ]}
            >
              {messages.ADD_GOALS.STEP + ' ' + stepIndex + ' is...'}
            </StandardText>
            <View style={[CommonStyles.flexDirectionRow]}>
              {this._renderStepText(index, item)}
            </View>
            <View style={CommonStyles.ModalFooterRight}>
              {this._renderActionButton(item, stepIndex)}
            </View>
          </View>
        );
      });
    } else {
      return null;
    }
  }

  _updateStep = (stepName: any, item: any, index: any) => {
    const newGoalStepList = [...this.state.goalStepList];
    newGoalStepList[index].stepName = stepName;
    this.setState({goalStepList: newGoalStepList});
    if (stepName === '') {
      const inspirationItem = this.state.inspirationContent.filter(
        (insparation: any) => insparation.id == item.currentGoalStepId,
      );

      if (inspirationItem && inspirationItem.length === 0) {
        const removedInspiration = this.state.defaultInspirationContent.filter(
          insparation => insparation.id == item.currentGoalStepId,
        );
        this.setState({
          inspirationContent: JSON.parse(
            JSON.stringify([
              ...this.state.inspirationContent,
              ...removedInspiration,
            ]),
          ),
        });
      }
    }
  };

  _renderStepAdd = () => {
    const screenMode = this._getParams('screenMode');
    if (screenMode == types.SCREEN_MODE_EDIT) {
      return (
        <View
          style={[CommonStyles.ModalFooterRight, CommonStyles.paddingTop10]}
        >
          <ActionButton
            title={'Step'}
            actionType={types.ACTION_BUTTON.ADD}
            isToucable={true}
            onPress={this._addStep}
            image={addIcon}
            readOnly={this.props.member.access.profile}
          />
        </View>
      );
    }
  };

  _renderActionButton = (item: any, stepIndex: any) => {
    var screenMode = this._getParams('screenMode');

    if (this.state.goalStepList.length !== stepIndex) {
      if (screenMode === types.SCREEN_MODE_EDIT) {
        return (
          <ActionButton
            imageIcon={true}
            title={''}
            actionType={types.ACTION_BUTTON.DELETE}
            isToucable={true}
            onPress={() => {
              this._deleteStep(item);
            }}
            image={deleteIconCharcoal60}
            readOnly={this.props.member.access.profile}
            imageActionIcon={false}
          />
        );
      }
    } else {
      if (screenMode == types.SCREEN_MODE_EDIT) {
        return (
          <View>
            <ActionButton
              title={''}
              imageIcon={true}
              actionType={types.ACTION_BUTTON.DELETE}
              isToucable={true}
              onPress={() => {
                this._deleteStep(item);
              }}
              image={deleteIconCharcoal60}
              readOnly={this.props.member.access.profile}
              imageActionIcon={false}
            />
          </View>
        );
      } else {
        return (
          <ActionButton
            title={
              this.state.goalStepList[0].stepName !== ''
                ? 'another step'
                : 'a step'
            }
            actionType={types.ACTION_BUTTON.ADD}
            isToucable={true}
            onPress={this._addStep}
            image={addIcon}
          />
        );
      }
    }
  };

  _deleteStep = (item: any) => {
    let updatedGoalStepList = this.state.goalStepList.filter(
      step => step.stepName !== item.stepName,
    );

    let emptyGoalStep = this.state.goalStepList.filter(
      step => step.stepName == '',
    );

    this.setState({goalStepList: updatedGoalStepList});

    //Updates the goal step inspiration tile list
    if (item.stepName !== '') {
      var removedInspirationContent = [];
      removedInspirationContent = this.state.defaultInspirationContent.filter(
        insparation => insparation.description == item.stepName,
      );

      if (screenMode !== types.SCREEN_MODE_EDIT || emptyGoalStep.length !== 0) {
        this.setState({
          inspirationContent: JSON.parse(
            JSON.stringify([
              ...this.state.inspirationContent,
              ...removedInspirationContent,
            ]),
          ),
        });
      }
    }
    this.props.actions.GoalActions.addGoalStepList(updatedGoalStepList);
    //Removes goal step inspiration tiles in edit view
    if (screenMode == types.SCREEN_MODE_EDIT && emptyGoalStep.length == 0) {
      this.setState({
        inspirationContent: [],
      });
    }
  };

  _onTilesLayout = (event: any) => {
    let {width, height} = event.nativeEvent.layout;
    this.setState({tileContainerDimensions: {width, height}});
  };

  _onScreenLayout = (event: any) => {
    //Get layout width and height
    let viewWidth = event.nativeEvent.layout.width;
    let viewHeight = event.nativeEvent.layout.height;

    this.setState({viewContainerDimensions: {viewWidth, viewHeight}});
  };

  find_dimesions(layout: any) {
    const {x, y, width, height} = layout;
    this.setState({
      viewWidth: width,
    });
  }

  // The headers for the goal inspiration filters
  _renderFilterHeaders() {
    let headerSize = false;
    if (this.state.viewWidth < types2.MEDIA_QUERY.LARGE) {
      headerSize = true;
    }

    if (
      this.props.goals.currentGoal &&
      !this.props.goals.currentGoal.goalName
    ) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionColumn,
            CommonStyles.justifyContentFlexStart,
            CommonStyles.imageContainerGoalAdd,
          ]}
          onLayout={event => {
            this.find_dimesions(event.nativeEvent.layout);
          }}
        >
          <View
            style={[
              !isTablet
                ? CommonStyles.flex1
                : headerSize
                ? CommonStyles.flex1
                : CommonStyles.flex2,
              CommonStyles.paddingLeft10,
              this._getLandscapeMargin(),
            ]}
          >
            {this._renderFilterDescription()}
          </View>
        </View>
      );
    }
  }

  _getLandscapeScale() {
    if (isTablet && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return [CommonStyles.addGoalAvatarScaleLC];
    }
  }

  _getLandscapeMargin() {
    if (isTablet && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return [CommonStyles.addGoalAvatarCloseMarginLC];
    }
  }

  // Build the filter description with selected filters as bold text
  _renderFilterDescription() {
    const filterFields = [];

    if (
      this.props.goals.goalsFilters &&
      this.props.goals.goalsFilters.selectedFilterNames.length > 0
    ) {
      // Build the filtering message
      if (this.props.goals.goalsFilters.selectedFilterNames.length == 1) {
        let filterDescription = ` ${this.props.goals.goalsFilters.selectedFilterNames[0]}`;
        filterFields.push([this._filterTextBold(0, filterDescription)]);
      } else {
        for (
          let i = 0;
          i < this.props.goals.goalsFilters.selectedFilterNames.length;
          i++
        ) {
          // Last Item
          if (
            i ==
            this.props.goals.goalsFilters.selectedFilterNames.length - 1
          ) {
            // Last item
            let filterDescription = ` ${this.props.goals.goalsFilters.selectedFilterNames[i]}`;
            filterFields.push([this._filterTextBold(i, filterDescription)]);
            // Second last item
          } else if (
            i ==
            this.props.goals.goalsFilters.selectedFilterNames.length - 2
          ) {
            // Second last item
            let filterDescription = ` ${this.props.goals.goalsFilters.selectedFilterNames[i]}`;
            filterFields.push([this._filterTextBold(i, filterDescription)]);
            let andText = ' and';
            filterFields.push([this._filterText('andtext', andText)]);
            // Any other item that isn't one of the last two
          } else {
            let filterDescription = ` ${this.props.goals.goalsFilters.selectedFilterNames[i]},`;
            filterFields.push([this._filterTextBold(i, filterDescription)]);
          }
        }
      }
      // Wrap the filter fields, in a parent standard text, so the voiceover will read them all out at once.
      return (
        <View style={[CommonStyles.marginLeft20, CommonStyles.marginTop20]}>
          <View style={[CommonStyles.alignSelfFlexStart]}>
            <StandardText
              style={[
                CommonStyles.marginBottom5,
                CommonStyles.textAlignLeft,
                CommonStyles.customFontBold,
                BrandStyles.TextColor5,
              ]}
              accessible={true}
            >
              {messages.ADD_GOALS.SUGGESTED_GOALS}
            </StandardText>
            <StandardText
              accessible={true}
              style={[
                CommonStyles.textAlignLeft,
                CommonStyles.customFontBold,
                BrandStyles.TextColor5,
              ]}
            >
              {filterFields}
            </StandardText>
          </View>
          <StandardTouchableOpacity
            onPress={this._showFiltersModal}
            accessibilityLabel={messages.ADD_GOALS.CHECK_OTHERS}
            disabled={
              this.props.member.access.profile == types.ACCESS_FULL
                ? false
                : true
            }
            style={[CommonStyles.alignSelfCenter, CommonStyles.marginTop10]}
          >
            <StandardText
              style={[
                CommonStyles.textLink,
                CommonStyles.textAlignCentre,
                CommonStyles.customFont,
                CommonStyles.alignSelfCenter,
                BrandStyles.TextColor10,
              ]}
              accessible={true}
            >
              {messages.ADD_GOALS.CHECK_OTHERS}
            </StandardText>
          </StandardTouchableOpacity>
        </View>
      );
    } else {
      return (
        <View
          style={[CommonStyles.containerGoalFilters, CommonStyles.alignCenter]}
        >
          <StandardTouchableOpacity
            onPress={this._showFiltersModal}
            accessibilityLabel={messages.ADD_GOALS.SHOW_GOALS}
          >
            <StandardText
              style={[CommonStyles.textLink, CommonStyles.textAlignCentre]}
              accessible={true}
            >
              {messages.ADD_GOALS.SHOW_GOALS}
            </StandardText>
          </StandardTouchableOpacity>
        </View>
      );
    }
  }

  // Returns a text field for the filter text that is standard text
  _filterText(key: any, text: any) {
    return (
      <StandardText
        key={key}
        style={[
          CommonStyles.addGoalFiltering,
          BrandStyles.TextColor5,
          CommonStyles.customFont,
          CommonStyles.fontWeight400,
        ]}
      >
        {text}
      </StandardText>
    );
  }

  // Returns a text field for the filter text that is bold (For selected filter items)
  _filterTextBold(key: any, text: any) {
    return (
      <StandardText
        key={key}
        style={[
          CommonStyles.addGoalFiltering,
          BrandStyles.TextColor5,
          CommonStyles.customFontBold,
          CommonStyles.fontWeight400,
          ,
          CommonStyles.iosTextfontWeight500,
        ]}
      >
        {text}
      </StandardText>
    );
  }

  _goalInspirationTiles() {
    if (this.props.goals.goalsTemplates == null) {
      return null;
    } else {
      if (types.isWeb) {
        return (
          <GridView
            viewWidth={this.props.viewWidth - 75}
            itemDimension={types2.ITEM_DIMENSION}
            isMobile={this.props.isMobile}
          >
            {this._goalInspirationTilesArray()}
          </GridView>
        );
      } else {
        return this._goalInspirationTilesArray();
      }
    }
  }

  _goalInspirationTilesArray = () => {
    return this.props.goals.goalsTemplates.filteredTemplates.map(
      (goalInspiration: any) => {
        if (goalInspiration && goalInspiration.description !== '') {
          return (
            <GoalInspirationTile
              key={goalInspiration.id}
              content={goalInspiration}
              actionCallback={this._goNextInsparation}
              actionData={goalInspiration}
              columns={1}
              parentDimensions={this.state.tileContainerDimensions}
              getSelectedColor={this._getRandomColor}
              disabled={
                this.props.member.access.profile == types.ACCESS_FULL
                  ? false
                  : true
              }
            />
          );
        }
      },
    );
  };

  _goalStepInspirationTiles() {
    if (
      this.state.inspirationContent == null ||
      this.state.inspirationContent == []
    ) {
      return null;
    } else {
      if (types.isWeb) {
        return (
          <GridView
            viewWidth={this.props.viewWidth - 75}
            itemDimension={types2.ITEM_DIMENSION}
            isMobile={this.props.isMobile}
          >
            {this._goalStepInspirationTilesArray()}
          </GridView>
        );
      } else {
        return this._goalStepInspirationTilesArray();
      }
    }
  }

  _goalStepInspirationTilesArray = () => {
    const goalStepTemplates = this.state.inspirationContent
      ? this.state.inspirationContent
      : [];
    return goalStepTemplates.map(goalInspiration => {
      if (goalInspiration && goalInspiration.description !== '') {
        return (
          <GoalInspirationTile
            key={goalInspiration.id}
            content={goalInspiration}
            actionCallback={this._goNextStepInsparation}
            actionData={goalInspiration}
            columns={1}
            parentDimensions={this.state.tileContainerDimensions}
            getSelectedColor={this._getRandomColor}
            disabled={
              this.props.member.access.profile == types.ACCESS_FULL
                ? false
                : true
            }
          />
        );
      }
    });
  };

  _getRandomColor = () => {
    var item =
      this.state.bgColor[Math.floor(Math.random() * this.state.bgColor.length)];
    return item;
  };

  _getInspirationSteps = (insparationId: any) => {
    let currentTemplateId = insparationId;
    if (
      this.props.goals.currentGoal &&
      this.props.goals.currentGoal.templateId
    ) {
      currentTemplateId = this.props.goals.currentGoal.templateId;
    }

    const goalTemplates = this.props.goals.goalsTemplates.templates;
    const currentGoalTemplate = goalTemplates.find(
      (template: any) => template.id == currentTemplateId,
    );

    // If goal steps are not cached load it from the api

    if (currentGoalTemplate && currentGoalTemplate.goalSteps) {
      // Load from the cache in redux (These steps have been loaded before)
      const goalSteps = JSON.parse(
        JSON.stringify(currentGoalTemplate.goalSteps),
      );

      let filteredSteps = this.filterGoalSteps(goalSteps);
      this.setState({
        inspirationContent: filteredSteps,
        defaultInspirationContent: goalSteps,
      });
      this._setLoading(false);
    } else {
      // Get the goals templates
      let getGoalStepReferenceCallback = (data: any) => {
        let payload = data[0];
        // Add the goal steps for this template to redux for caching purposes
        this.props.actions.GoalActions.setGoalStepsForTemplate(
          currentTemplateId,
          payload.content.goalSteps,
        );
        const goalSteps = JSON.parse(JSON.stringify(payload.content.goalSteps));

        let filteredSteps = this.filterGoalSteps(goalSteps);
        this.setState({
          inspirationContent: filteredSteps,
          defaultInspirationContent: goalSteps,
        });
        this._setLoading(false);
      };

      callAPIs(
        [getGoalStepReference(currentTemplateId)],
        getGoalStepReferenceCallback,
        null,
        () => {
          this._setLoading(false);
        },
      );
    }
  };

  filterGoalSteps = (goalSteps: any) => {
    let filteredSteps = goalSteps;

    if (screenMode == types.SCREEN_MODE_EDIT) {
      let currentGoalSteps = this.props.goals.currentGoal.currentGoalSteps;

      for (var currentStep in currentGoalSteps) {
        filteredSteps = filteredSteps.filter(
          (step: any) =>
            step.description !== currentGoalSteps[currentStep].stepName,
        );
      }
    }
    return filteredSteps;
  };

  _goNextInsparation = (goalInsparation: any) => {
    // Set goal name & go next. Do not apply validation as the state is still setting as _goNext is called.
    this.setState({goalName: goalInsparation.description});
    this._setLoading(true);

    // Update redux with goal name from goalInsparation as there is a slight delay
    // In set state - so sometimes the screen will nav to fast
    this.props.actions.GoalActions.setGoalName(goalInsparation.description);
    this.props.actions.GoalActions.setGoalTemplate(
      goalInsparation.id,
      goalInsparation.description,
    );

    this._goNext(false, goalInsparation.id);
  };

  _goNextStepInsparation = (goalStepInsparation: any) => {
    let goalStepListValue = JSON.parse(JSON.stringify(this.state.goalStepList));
    const inspirationContentValue = this.state.inspirationContent;
    const defaultInspirationsLength = this.state.defaultInspirationContent;
    const insparationId = goalStepInsparation.id;
    var updatedInspirationContent = [];
    var removedInspirationContent: any = [];

    if (inspirationContentValue && goalStepListValue.length > 0) {
      updatedInspirationContent = inspirationContentValue.filter(
        (insparation: any) => insparation.id == insparationId,
      );
      goalStepListValue[goalStepListValue.length - 1].stepName =
        updatedInspirationContent[0].description;

      if (
        this.state.goalStepList[goalStepListValue.length - 1].stepName !== ''
      ) {
        removedInspirationContent = this.state.defaultInspirationContent.filter(
          insparation =>
            insparation.description ==
            this.state.goalStepList[goalStepListValue.length - 1].stepName,
        );
      }
      var filteredInspirationContent = this.state.inspirationContent.filter(
        (insparation: any) => insparation.id !== insparationId,
      );

      this.setState({
        inspirationContent: JSON.parse(
          JSON.stringify([
            ...filteredInspirationContent,
            ...removedInspirationContent,
          ]),
        ),
      });
    }

    this.props.actions.GoalActions.addGoalStepList(goalStepListValue);

    //Removes goal step inspiration tiles in edit view
    if (screenMode == types.SCREEN_MODE_EDIT) {
      this.setState({
        inspirationContent: [],
      });
    }

    this.setState({goalStepList: goalStepListValue, scrollEnabled: true});
  };

  _showFiltersModal = () => {
    this.props.actions.NavigationParamActions.setParam({
      isthereFilter: this.state.goalsFilters,
      setFilterLifeStages: this.state.setFilterLifeStages,
      isFilterApplied: false,
    });
    this.props.navigation.navigate('GoalFiltersForm');
    this.setState({
      setFilterLifeStages: false,
    });
  };

  _setInitialState = () => {
    if (this.props.goals.currentGoal) {
      const currentGoalName = JSON.parse(
        JSON.stringify(this.props.goals.currentGoal.goalName),
      );
      const currentGoalSteps = JSON.parse(
        JSON.stringify(this.props.goals.currentGoal.currentGoalSteps),
      );
      this.setState({
        initialFormState: {
          goalName: JSON.parse(JSON.stringify(currentGoalName)),
          goalStepList: JSON.parse(JSON.stringify(currentGoalSteps)),
        },
      });
    }
  };

  _hasFormContentChanged = () => {
    const initialFormState = this.state.initialFormState;
    const currentFormState = {
      goalName: this.props.goals.currentGoal.goalName,
      goalStepList: this.props.goals.currentGoal.currentGoalSteps,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  goals: state.GoalReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGoals);
