import React, {Component} from 'react';
import {Image, View, StyleSheet, Platform} from 'react-native';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {StandardText, StandardPicker} from '../Components/Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {orderBy, findIndex, isEmpty, forEach, isEqual} from 'lodash';

import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {formatDateToString} from '../Helpers/Utils';
import {checkFlag} from '../Helpers/featureFlagsHelpers';
import {ClaimInvoiceRed3x} from '../assets/images/vector-icons';
// Import Stylesheets
import BrandStyles from '../Styles/BrandStyles';
import CommonStyles from '../Styles/CommonStyles';
import {FormTitle, Container, ActionButton} from '../Components/Molecules';

import FileUploader from '../Helpers/FileUploader';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {InvoiceDetails, SupportItem} from '../Screens';
import {BasicForm} from '../Components/Organisms';
import {CLAIM_INVOICES as messages} from '../Constants/Messages';

import {callAPIs} from '../API/APICaller';
import {getProviders} from '../API/ProviderAPI';
import {getUploadURL} from '../API/MemberAPI';
import {postDraftInvoice, putDraftInvoice} from '../API/PlanDataAPI';
import logger from 'helpers/Logger';

import {RNFetchBlobSingleton} from '../Helpers/PlatformSynchronizer';

const EMPTY = 'empty';
const DELETE = 'Delete';
const EMPTY_STRING = '';

class ClaimInvoice extends Component {
  inputs: any;
  invoiceChild: any;
  constructor(props: any) {
    super(props);
    this.inputs = {};
    this.addSupportItem = this.addSupportItem.bind(this);
    this.addProvider = this.addProvider.bind(this);
    this.supporItemsRef = this.supporItemsRef.bind(this);

    this.state = {
      isDraftEdit: this._getParams('isDraftEditing'),
      selectedProvider: {},
      validBankAccNumber: '',
      validBSB: '',
      validEmail: '',
      validABN: '',
      validOrganisationName: '',
      validIProvider: '',
      addProviderModalVisible: false,
      loading: false,
      supportItemArray: [],
      memberBillers: this.props.payInvoice.memberBillers,
      isInvoiceSubmissionInProgress: false,
      invoiceProcessing: false,
      addNewProviderForDone: false,
    };
  }

  _getParams = (key: any) => {
  //   const { route } = this.props;
  //   const params = route.params || {};
  //   return params[key] 
  //  // return this.props.navigation.getParam(key, undefined);
    if(types.isWeb) {
      return  this.props.navigation.getParam(key, undefined) ;
     }else {
      return this.props.route.params[key] ? this.props.route.params[key] : undefined;
     }

  };

  componentDidMount = () => {
    this.setState({
      loading: true,
    });
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    callAPIs(
      [getProviders(this.props.loadedMemberId.loadedMemberId, '', true, false)],
      (data: any) => {
        const orderedProviders = orderBy(
          data[0].content,
          [biller => biller.name.toLowerCase()],
          ['asc'],
        );
        this.props.actions.payInvoiceActions.getMemberBillersList(
          orderedProviders,
        );

        let selectedIndex = 0;
        if (
          !isEmpty(this.props.payInvoice) &&
          this.props.payInvoice.selectedProvider !== undefined
        ) {
          selectedIndex = findIndex(orderedProviders, {
            billerKey: this.props.payInvoice.selectedProvider.billerKey,
          });
        }
        // loadlash lib findIndex function returns -1 if index not found.in this case,selectedIndex is set to 0
        if (selectedIndex < 0) {
          selectedIndex = 0;
        }

        if (orderedProviders.length > 0) {
          this.setState({
            selectedProvider: this.props.member?.access?.finances
              ? orderedProviders[selectedIndex]
              : orderedProviders[0],
          });
        }

        this.setState({
          memberBillers: orderedProviders,
          loading: false,
        });

        if (this.state.isDraftEdit) {
          forEach(this.props.payInvoice.supportItems, item => {
            this.addSupportItem(item, this.state.isDraftEdit);
          });
        } else {
          this.setState({
            supportItemArray: [
              {
                visible: true,
                item: (
                  <SupportItem
                    navigation={this.props.navigation}
                    route={this.props.route}
                    isDraftEdit={false}
                    onRef={(ref: any) => this.supporItemsRef(ref, 0)}
                    isMobile={this.props.isMobile}
                  />
                ),
              },
            ],
          });
        }
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
      },
      (err: any) => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
        logger.log('notifications error: ', err);
        this.setState({
          memberBillers: undefined,
          loading: false,
          supportItemArray: [
            {
              visible: true,
              item: (
                <SupportItem
                  navigation={this.props.navigation}
                  isDraftEdit={false}
                  route={this.props.route}
                  onRef={(ref: any) => this.supporItemsRef(ref, 0)}
                  isMobile={this.props.isMobile}
                />
              ),
            },
          ],
        });
      },
    );
  };

  UNSAFE_componentWillUpdate(nextProps: any, nextState: any) {
    let selectedIndex = 0;
    if (
      nextProps.payInvoice.memberBillers &&
      (this._nonEmptyProviderChecker(nextProps) ||
        this._emptyProviderChecker(nextProps))
    ) {
      if (this.props.payInvoice.selectedProvider !== undefined) {
        selectedIndex = findIndex(nextProps.payInvoice.memberBillers, {
          billerKey: this.props.payInvoice.selectedProvider.billerKey,
        });
      }

      this.setState({
        memberBillers: nextProps.payInvoice.memberBillers,
        selectedProvider: nextProps.payInvoice.memberBillers[selectedIndex],
      });
    }

    if (
      !types.isWeb &&
      nextProps.payInvoice.memberBillers &&
      nextProps.payInvoice.selectedProvider &&
      this.state.selectedProvider &&
      this.state.selectedProvider.billerKey != undefined &&
      !isEqual(
        nextProps.payInvoice.selectedProvider,
        this.props.payInvoice.selectedProvider,
      )
    ) {
      if (nextProps.payInvoice.selectedProvider !== undefined) {
        selectedIndex = findIndex(nextProps.payInvoice.memberBillers, {
          billerKey: nextProps.payInvoice.selectedProvider.billerKey,
        });
      }

      if (selectedIndex >= 0) {
        this.setState({
          memberBillers: nextProps.payInvoice.memberBillers,
          selectedProvider: nextProps.payInvoice.memberBillers[selectedIndex],
        });
      }
    }
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.CLAIM_INVOICES}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    if (!checkFlag('claimInvoice', this.props.admin.featureFlags)) {
      return null;
    }
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignSelfFlexStart,
        ]}
      >
        <BasicForm
          contentWidthFull={true}
          headerDisplayString={messages.MAIN_TITLE}
          headerIconImage={ClaimInvoiceRed3x}
          HeaderTextColor={BrandStyles.TextColor3}
          HeaderBoarderColor={BrandStyles.borderColor3}
          fontSize={CommonStyles.font22}
          readOnly={this.props.readOnly}
          disableMore={true}
          readOnly={this.props.member?.access?.finances}
          showButton={this.state.isDraftEdit ? true : false}
          actionOverride={DELETE}
          showButtonTitle={DELETE}
          modal={() => this._deleteDraftInvoice()}
          close={this._closeClaimInvoice}
          save={() => this._reviewClaimInvoice()}
          buttonsPositionBottom={true}
          saveLabel={messages.REVIEW_AND_SUBMIT}
        >
          <FormTitle
            style={types.isWeb ? [CommonStyles.font15] : null}
            disableYellowStar={true}
            text={messages.SELECT_OR_ADDPROVIDERS}
            showButton={
              this.props.member?.access?.finances !== types.ACCESS_READ_ONLY
            }
            customActionButtonStyle={
              types.isWeb ? null : CommonStyles.paddingTop30
            }
            actionButtonLeft={types.isWeb ? true : false}
            actionType={messages.ACTION_ADD}
            showButtonTitle={messages.ADD_PROVIDER}
            actionButtonAccessibilityLabel={'Add provider'}
            onPress={this.addProvider}
          />
          {/* 1. Select or add provider  */}
          {this._renderSelectPoroviders()}
          <FormTitle
            style={
              types.isWeb
                ? [CommonStyles.font15, CommonStyles.paddingTop20]
                : null
            }
            disableYellowStar={true}
            text={messages.ENTER_INVOICE_DETAILS}
          />
          {/* 2. Invoice details  */}
          <View
            style={
              types.isWeb
                ? [CommonStyles.zIndex999, CommonStyles.paddingBottom10]
                : null
            }
          >
            <InvoiceDetails
              navigation={this.props.navigation}
              route={this.props.route}
              onRef={(ref: any) => (this.invoiceChild = ref)}
            />
          </View>
          <View style={CommonStyles.marginTop10} />
          <FormTitle
            disableYellowStar={true}
            text={messages.ENTERSUPPORT_ITEM_DETAILS}
            style={types.isWeb ? [CommonStyles.font15] : null}
          />

          {/* . Add support items  */}
          {this._renderAllSupportItems()}
          {this._renderAdditionSupportItemsHeaders()}
        </BasicForm>
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.alignFlexEnd,
          ]}
        >
          <ActionButton
            showButtonTitle={'Save as Draft'}
            actionType={types.ACTION_BUTTON.EDIT}
            isToucable={true}
            onPress={() => {
              const validFields = this._validateReviewClaimInvoice();
              setTimeout(() => {
                this._saveAsDraft(validFields);
              }, 1000);
            }}
            readOnly={this.props.member.access.profile}
          />
        </View>
      </View>
    );
  };

  _saveAsDraft = (validFields: any) => {
    if (validFields) {
      let invoiceDetails = this.props.payInvoice.invoiceDoc;
      if (invoiceDetails === undefined) {
        invoiceDetails = this.props.payInvoice.invoice.invoiceDocPath;
      }

      if (!invoiceDetails) {
        return;
      }

      if (invoiceDetails.s3FileKey) {
        invoiceDetails.s3FileName = invoiceDetails.s3FileKey
          .split(types2.MEMBER_INVOICE_PATH)
          .pop();
      }

      const submissionTime = Date.now();
      const {invoiceNumber} = this.props.payInvoice.invoice;
      let invoiceAttachmentFileName = '';
      if (invoiceDetails.isChanged) {
        invoiceAttachmentFileName = `${invoiceNumber}_${submissionTime}`;
      } else {
        invoiceAttachmentFileName = invoiceDetails.s3FileName;
      }

      const requestObject = {
        fileType: 'memberInvoice',
        filename: invoiceAttachmentFileName,
        contentType: invoiceDetails.type,
      };

      const success = () => {
        this._submit(false, invoiceAttachmentFileName);
      };
      const error = (err: any) => {
        logger.log('The error is: ', err);
        this.setState({
          loading: false,
          isInvoiceSubmissionInProgress: false,
        });
      };
      this.setState({loading: true, isInvoiceSubmissionInProgress: true});

      // Call the API to get the signed URL
      // if only isChanged flag available and true
      if (invoiceDetails.isChanged) {
        const fileUploader = new FileUploader(
          this.props.loadedMemberId.loadedMemberId,
          invoiceDetails,
          success,
          error,
        );
        fileUploader.startUploading(requestObject);
      } else {
        this._submit(false, invoiceAttachmentFileName);
      }
    }
  };

  // This submit function facilitate create new draft invoice
  // update existing draft invoice and delete draft invoice
  // depending on given aruguments and state params.
  _submit = (isDelete: any, invoiceAttachmentFileName = null) => {
    if (
      (this.props.payInvoice.supportItems === undefined) |
      (this.props.payInvoice.supportItems.length === 0)
    ) {
      this.setState({
        validSupportItem: types.INVOIVE_SUPPORT_ITEM_EMPTY,
      });
    } else {
      this.setState({loading: true, isInvoiceSubmissionInProgress: true});
      let paymentDetails = {};
      let APIArray = [];

      if (!isDelete) {
        const claimsValue: any = [];

        this.props.payInvoice.supportItems.map((item: any) => {
          if (item && item.billerCode) {
            let quantityValue = {};

            if (item.hour !== '' && item.mins !== '') {
              quantityValue = {
                duration: item.hour + ':' + item.mins,
              };
            } else {
              quantityValue = {
                units: item.unit,
              };
            }

            claimsValue.push({
              itemCode: item.billerCode,
              itemName: item.selectedSuportItem.name,
              itemDescription: item.selectedSuportItem.description,
              unitPriceIncTax: item.rate,
              serviceStartTime: formatDateToString(item.startdate),
              serviceEndTime: item.enddate
                ? formatDateToString(item.enddate)
                : formatDateToString(item.startdate),
              quantity: quantityValue,
            });
          }
        });

        paymentDetails = {
          billerKey: this.props.payInvoice.selectedProvider.billerKey,
          billerName: this.props.payInvoice.selectedProvider.organisation,
          invoiceDate: formatDateToString(
            this.props.payInvoice.invoice.invoiceDate,
          ),
          invoiceNumber: this.props.payInvoice.invoice.invoiceNumber,
          invoiceTotal: this._invoiceTotalValue(),
          claims: claimsValue,
          memberProviderId:
            this.props.payInvoice.selectedProvider.memberProviderId,
        };

        if (invoiceAttachmentFileName !== null) {
          paymentDetails.invoiceAttachmentFileName = invoiceAttachmentFileName;
        }
      }

      if (this.state.isDraftEdit) {
        let isActiveDraft = true;
        if (isDelete) {
          isActiveDraft = false;
          paymentDetails = {};
        }
        APIArray = [
          putDraftInvoice(
            this.props.loadedMemberId.loadedMemberId,
            this._getParams('invoiceId'),
            isActiveDraft,
            paymentDetails,
            false,
          ),
        ];
      } else {
        APIArray = [
          postDraftInvoice(
            this.props.loadedMemberId.loadedMemberId,
            paymentDetails,
            false,
          ),
        ];
      }

      if (types.isWeb) {
        this.props.setLoading(true);
      }

      callAPIs(
        APIArray,
        (data: any) => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }

          if (data[0] && data[0].content) {
            const count = this.props.payInvoice.draftInvoicesCount + 1;
            this.props.actions.payInvoiceActions.setDraftInvoicesCount(count);
            this.setState({loading: false});
            this.setState({isInvoiceSubmissionInProgress: false});
            if (types.isWeb) {
              if (this._getParams('itemTotal') == 1) {
                this.props.navigation.navigate('Home');
              } else {
                this.props.navigation.navigate('DraftClaimInvoices');
              }
            } else {
              let actionToDispatch = CommonActions.reset({
                index: 1,
                routes: [
                  {name: 'Home'},
                  {name: 'DraftClaimInvoices'}
                ],
              });
              if (this._getParams('itemTotal') == 1) {
                actionToDispatch = CommonActions.reset({
                  index: 0,
                  routes: [
                    {name: 'Hime'}
                  ],
                });
              }

              this.props.navigation.dispatch(actionToDispatch);
            }
            return;
          }
        },
        (err: any) => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          if (err.code === '408') {
            this.setState({invoiceProcessing: true});
          }
          this.setState({loading: false});
        },
        () => {
          this.setState({loading: false});
        },
      );
    }
  };

  // This function is to facilitate delete draft invoice flag for _submit function
  _deleteDraftInvoice = () => {
    this._submit(true);
  };

  _renderAdditionSupportItemsHeaders = () => {
    if (this.props.member?.access?.finances !== types.ACCESS_READ_ONLY) {
      return (
        <View
          style={[
            CommonStyles.formTitleContainer,
            CommonStyles.formTitlePadding,
          ]}
        >
          <View style={CommonStyles.RightStrict}>
            <ActionButton
              actionType={messages.ACTION_ADD}
              showIconString={true}
              isToucable={true}
              onPress={this.addSupportItem}
            />
          </View>
        </View>
      );
    } else {
      return null;
    }
  };

  _renderAllSupportItems = () => {
    let visibleItemCount = 0;
    const newSupportItemList = this.state.supportItemArray.map(
      (dict: any, index: any) => {
        if (dict.visible == false) {
          return null;
        }
        visibleItemCount += 1;
        return (
          <View style={[types.isWeb ? CommonStyles.zIndex1000 : {}]}>
            {this._renderEachSupportItem(dict.item, visibleItemCount, index)}
          </View>
        );
      },
    );
    return newSupportItemList;
  };

  _renderEachSupportItem = (
    supportItem: any,
    visibleItemCount: any,
    index: any,
  ) => {
    return (
      <View
        style={[
          CommonStyles.marginTop10,
          visibleItemCount > 1 ? CommonStyles.topdividerStyle : null,
        ]}
      >
        <FormTitle
          imageActionIcon={false}
          disableYellowStar={true}
          text={messages.SUPPORT_ITEM_NUMBER.concat(
            visibleItemCount.toString(),
          )}
          showButton={
            this.state.supportItemArray.filter((dict: any) => dict.visible)
              .length > 1
          }
          actionType={'Delete'}
          showButtonTitle={'Remove'}
          onPress={() => this.deleteSupportItem(index)}
          style={types.isWeb ? CommonStyles.font15 : null}
        />
        {supportItem}
      </View>
    );
  };

  deleteSupportItem = (deleteindex: any) => {
    const array = this.state.supportItemArray;
    this.setState({
      supportItemArray: array.map((element: any, index: any) => {
        if (index === deleteindex) {
          element.visible = false;
          return element;
        }
        return element;
      }),
    });
  };

  addSupportItem = (data: any, isDraftEdit: any) => {
    this.setState(prevState => ({
      supportItemArray: [
        ...prevState.supportItemArray,
        {
          visible: true,
          item: (
            <SupportItem
              supportData={data}
              route={this.props.route}
              isDraftEdit={isDraftEdit}
              navigation={this.props.navigation}
              onRef={(ref: any) =>
                this.supporItemsRef(ref, prevState.supportItemArray.length)
              }
              isMobile={this.props.isMobile}
            />
          ),
        },
      ],
    }));
  };

  supporItemsRef(ref: any, index: any) {
    this[`supporItemsChild${index}`] = ref;
  }

  addProvider() {
    this.props.navigation.navigate('AddProvider');
  }

  _invoiceTotalValue = () => {
    let totalValue = 0.0;
    if (Array.isArray(this.props.payInvoice.supportItems)) {
      this.props.payInvoice.supportItems.forEach(function (item: any) {
        const formattedCurrency = parseFloat(item.itemtotal).toFixed(
          types2.AMOUNT_DECIMAL_POINT,
        );
        totalValue += +formattedCurrency;
      });
    }
    return totalValue;
  };

  _emptyProviderChecker = (nextProps: any) => {
    if (
      nextProps.payInvoice.memberBillers.length === 1 &&
      this.state.memberBillers === undefined
    ) {
      return true;
    }
    return false;
  };

  _nonEmptyProviderChecker = (nextProps: any) => {
    if (
      nextProps.payInvoice.memberBillers.length >
      (this.state.memberBillers && this.state.memberBillers.length)
    ) {
      return true;
    }
    return false;
  };

  _renderPickerOptions = () => {
    const providers = [];
    if (this.state.memberBillers !== undefined) {
      this.state.memberBillers.map((biller: any) => {
        providers.push({
          type: biller.billerKey + biller.name,
          description: biller.name,
        });
      });
    } else {
      providers.push({type: EMPTY, description: EMPTY_STRING});
    }
    providers.push({
      type: messages.ADD_NEW_PROVIDER,
      description: messages.ADD_NEW_PROVIDER,
    });
    return providers;
  };

  _renderSelectPoroviders = () => {
    let selectedVal =
      this.state.memberBillers !== undefined
        ? this.state.selectedProvider.billerKey +
          this.state.selectedProvider.name
        : EMPTY;
    if (this.state.addNewProviderForDone) {
      selectedVal = messages.ADD_NEW_PROVIDER;
    }
    return (
      <View
        style={[
          CommonStyles.SelectAddproviderTopBottom,
          types.isWeb ? CommonStyles.maxWidth300 : null,
        ]}
      >
        <View
          style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
        >
          <StandardPicker
            access={this.props.member?.access?.finances}
            pickerOptions={this._renderPickerOptions()}
            style={[
              CommonStyles.standardPicker,
              CommonStyles.standardPickerBackgroundColorGray,
            ]}
            selectedValue={selectedVal}
            onValueChange={(itemValue: any, itemIndex: any) =>
              this._providerSelected(itemValue, itemIndex)
            }
            onDonePress={() => this._onDonePress()}
          />
        </View>
        <View>{this._renderErrorMessage(this.state.validIProvider)}</View>
      </View>
    );
  };

  _onDonePress() {
    if (this.state.addNewProviderForDone) {
      this.addProvider();
    }
  }
  _providerSelected(itemValue: any, index: any) {
    let item = {};
    if (this.state.memberBillers !== undefined) {
      item = this.state.memberBillers[index];
    }

    if (itemValue !== messages.ADD_NEW_PROVIDER) {
      this.setState({selectedProvider: item, addNewProviderForDone: false});
    } else {
      if (Platform.OS === types2.ANDROID || types.isWeb) {
        this.addProvider();
      } else {
        this.setState({addNewProviderForDone: true});
      }
    }
  }

  _closeClaimInvoice = () => {
    this.props.navigation.goBack();
  };

  _validateReviewClaimInvoice = () => {
    this.props.actions.payInvoiceActions.setInitialState();
    this.props.actions.payInvoiceActions.addNewProviderAction({
      organisation: this.state.selectedProvider.name,
      abn: this.state.selectedProvider.abn,
      email: this.state.selectedProvider.email,
      bsb: this.state.selectedProvider.abn,
      accountNumber: this.state.selectedProvider.abn,
      billerKey: this.state.selectedProvider.billerKey,
      memberProviderId: this.state.selectedProvider.memberProviderId,
    });

    if (
      isEmpty(this.state.selectedProvider) ||
      (!isEmpty(this.state.selectedProvider) &&
        this.state.selectedProvider.billerKey === '') ||
      this.state.selectedProvider.billerKey === messages.ADD_NEW_PROVIDER
    ) {
      this.setState({
        validIProvider: types.INVOICE_NO_PROVIDER_SELECTED,
      });
      return false;
    } else {
      this.setState({
        validIProvider: '',
      });
    }

    const validationInvoiceDetails = this.invoiceChild.validateAdding();

    const validationSuportedItems = this.state.supportItemArray.map(
      (dict: any, index: any) => {
        if (dict.visible) {
          return this[`supporItemsChild${index}`].validateAdding();
        }
      },
    );
    if (!validationInvoiceDetails || validationSuportedItems.includes(false)) {
      return false;
    }
    return true;
  };

  _reviewClaimInvoice = () => {
    const validaFields = this._validateReviewClaimInvoice();
    if (validaFields) {
      this.props.navigation.navigate('ReviewInvoiceSummary', {
        isDraftInvoice: this.state.isDraftEdit,
        invoiceId: this._getParams('invoiceId'),
        saveAsDraft: true,
      });
    }
  };

  _renderErrorMessage = (errorMessage: any) => {
    if (errorMessage !== undefined) {
      if (errorMessage !== '') {
        return (
          <StandardText
            accessibilityLabel={errorMessage}
            style={[
              CommonStyles.inputErrorMessageText,
              CommonStyles.customFont,
            ]}
          >
            {errorMessage}
          </StandardText>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
}

const mapStateToProps = (state: any) => ({
  payInvoice: state.PayInvoiceReducer,
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  admin: state.AdminReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimInvoice);
