/*
 * Author: Brad D'Costa
 * Date: 16/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the stylesheet for common elements used throughout the app
 */

import {StyleSheet, Dimensions, Platform} from 'react-native';
import {
  ScaledSheet,
  scale,
  moderateScale,
} from 'react-native-size-matters';
import {BrandColors} from './Colours';
import {isIphoneX} from 'react-native-iphone-x-helper';

const {height, width} = Dimensions.get('window');
const ScreenHeight = Dimensions.get('window').height;

export default ScaledSheet.create({
  //* *************************
  //*   C O N T A I N E R S
  //* *************************

  screenWrapper: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },

  screenWrapperTransparent: {
    flex: 1,
  },

  // Screnwrapper for non scroll view components
  screenWrapperContainer: {
    flexGrow: 1,
  },

  headerContainer: {
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    minHeight: 48,
    ...Platform.select({
      ios: {
        paddingTop: isIphoneX() ? 40 : 20,
      },
      android: {
        paddingTop: 10,
      },
    }),
  },
  headerContainerMobile: {
    paddingRight: 15,
    paddingBottom: 0,
    paddingLeft: 15,
    minHeight: 48,
    height: 90,
    ...Platform.select({
      ios: {
        paddingTop: isIphoneX() ? 40 : 20,
      },
      android: {
        paddingTop: 20,
      },
    }),
  },

  screenWrapperHeaderContainer: {
    paddingTop: 5,
    paddingBottom: 5,
  },

  screenWrapperHeaderContainerPhone: {
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 0,
  },

  headerTitleWrapper: {
    ...Platform.select({
      ios: {
        zIndex: 1,
        marginLeft: 100,
      },
      android: {
        top: 12,
      },
    }),
  },

  headerTitleContainer: {
    position: 'absolute',
    left: 15,
    zIndex: 1,
    ...Platform.select({
      ios: {
        marginTop: '4%',
      },
      android: {
        top: 12,
      },
    }),
  },

  headerTitleIconContainer: {
    position: 'absolute',
    zIndex: 2,
    padding: 5,
    borderRadius: 27,
    borderWidth: 2,
    borderStyle: 'solid',
    justifyContent: 'center',
  },

  headerTitleIcon: {
    height: 15,
    width: 15,
    resizeMode: 'contain',
  },

  flexWrap: {
    flexWrap: 'wrap',
  },

  headerItemIcon: {
    marginLeft: 10,
    marginRight: 5,
    height: 25,
    width: 25,
    fontSize: 24,
    resizeMode: 'contain',
    color: 'white',
  },

  addNewMemberIcon: {
    marginLeft: 3,
    fontSize: 26,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  contentWrapper: {
    flex: 1,
    flexDirection: 'row',
  },

  contentBackgroundColor: {
    backgroundColor: 'white',
  },

  sidebarWrapper: {
    flex: 0.4,
  },

  profilePictureWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  contentFlexWrap: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  contentWrap: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contentFlexWrapColumn: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  footerContainer: {
    paddingTop: 0,
    paddingRight: 5,
    paddingBottom: 0,
    paddingLeft: 5,
    height: 50,
  },

  leftContainer: {
    padding: 0,
    width: 15,
  },

  rightContainer: {
    padding: 0,
    width: 15,
  },

  sidebarContentContainer: {
    paddingTop: 50,
    paddingRight: 20,
    paddingLeft: 20,
  },

  sidebarDateContainer: {
    flex: 1,
    flexDirection: 'row',
  },

  sidebarDateCalander: {
    minWidth: 40,
    maxWidth: 70,
    marginRight: 5,
    alignSelf: 'center',
  },

  sidebarDateTextContainer: {
    flex: 1,
    minWidth: 100,
    alignSelf: 'center',
  },

  sidebarInputDateButton: {
    minWidth: 30,
    maxWidth: 40,
    alignSelf: 'center',
  },

  sideBarDockButton: {
    backgroundColor: 'white',
    width: 30,
    justifyContent: 'center',
    alignSelf: 'center',
    padding: 5,
  },

  sideBarRow: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    minHeight: 20,
  },

  sideBarTextCointainer: {
    flex: 1,
    minWidth: 100,
    alignSelf: 'center',
  },

  sideBarButtonContainer: {
    minWidth: 30,
    maxWidth: 40,
    alignSelf: 'center',
  },

  sidebarDetailsContainer: {
    padding: 15,
  },

  mainContentScrollView: {
    paddingTop: '5%',
    marginLeft: 10,
    marginRight: 10,
  },

  mainContentContainer: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
  },
  checklistContentContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  columnContainer: {
    flex: 1,
    flexDirection: 'column',
  },

  goalsContentContainerNoGoals: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  photoTileContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  goalsContentContainerHasGoals: {
    flex: 1,
    flexDirection: 'column',
  },

  goalsActionButtonsContainer: {
    height: 30,
    flexDirection: 'row',
    marginBottom: 10,
  },

  goalActionButton: {
    paddingRight: 20,
  },
  DocumentButtonIcon: {
    height: 18,
    width: 18,
    resizeMode: 'contain',
    marginTop: 0,
  },
  oneThirdContainer: {
    padding: 0,
    flex: 0.2,
    paddingRight: 0,
    marginLeft: 0,
  },

  oneTwoContainer: {
    padding: 0,
    flex: 0.48,
    paddingLeft: 0,
    marginLeft: 0,
  },

  oneContainer: {
    flex: 1,
    paddingRight: 1,
    marginLeft: 0,
  },

  leftMainContentContainer: {
    padding: 0,
    flex: 0.5,
    paddingRight: 10,
  },

  rightMainContentContainer: {
    padding: 0,
    flex: 0.5,
    paddingLeft: 10,
  },

  singleMainContentContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
  },

  singleMainContentContainerForTANDC: {
    paddingLeft: 10,
    paddingRight: 10,
  },

  goalContentContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    flex: 1,
  },

  content: {
    flex: 1,
  },

  justifyCenter: {
    justifyContent: 'center',
  },

  alignCenter: {
    alignItems: 'center',
  },

  justifyEnd: {
    justifyContent: 'flex-end',
  },

  justifyContentFlexEnd: {
    justifyContent: 'flex-end',
  },

  alignFlexStart: {
    alignItems: 'flex-start',
  },
  wrapperHeaderHeightPhone: {
    height: 55,
  },

  alignCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },

  alignItemsNormal: {
    alignItems: 'normal',
  },

  spendingSupportTile: {
    marginBottom: 5,
  },

  webViewHeader: {
    padding: 10,
    paddingTop: 20,
    height: 70,
  },

  webViewText: {
    flex: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },

  splashContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: null,
    height: null,
  },
  underMaintenanceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  underMaintenanceBackground: {
    backgroundColor: BrandColors.UNDER_MAINTENANCE_YELLOW,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  underMaintenanceMessage: {
    width: '39%',
  },
  tileContainer: {
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'row',
  },

  wrapTileContainer: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  tileWrapper: {
    padding: 15,
    flex: 1,
    marginTop: 20,
    borderRadius: 0,
    elevation: 5,
  },

  profileTileWrapper: {
    padding: 7,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
    elevation: 10,
    shadowOffset: {width: 0, height: 2},
    shadowColor: 'grey',
    shadowOpacity: 0.7,
    shadowRadius: 5,
  },

  addNewMemberTileWrapper: {
    padding: 7,
    marginTop: 0,
    marginBottom: 10,
    borderRadius: 5,
    elevation: 10,
    shadowOffset: {width: 0, height: 2},
    shadowColor: 'grey',
    shadowOpacity: 0.7,
    shadowRadius: 5,
  },

  supportsImageTileWrapper: {
    padding: 15,
    marginTop: 20,
  },

  supportsOpinionWrapper: {
    padding: 15,
    marginTop: 20,
    width: 100,
  },

  italicFont: {
    fontStyle: 'italic',
  },

  flexRow: {
    flexDirection: 'row',
  },

  justifyFlexRowStart: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  justifyFlexRowEnd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  justifyFlexColumnStart: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  justifyFlexColumnEnd: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },

  justifyFlexRowSpace: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  justifyFlexColumnSpace: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  SummaryTileHeaderContainer: {
    borderStyle: 'solid',
    borderBottomWidth: 2,
    paddingBottom: 10,
    alignItems: 'flex-end',
    width: '100%',
  },

  SummaryTileHeader: {
    borderStyle: 'solid',
    borderBottomWidth: 2,
    paddingBottom: 10,
    width: '100%',
  },
  MessageHeaderBorder: {
    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    paddingBottom: 20,
  },

  ProviderCategorySection: {
    borderStyle: 'solid',
    borderTopWidth: 2,
    paddingTop: 2,
    marginTop: 20,
  },

  standardTile: {
    marginBottom: 7,
    marginTop: 5,
    marginLeft: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  sideBarShadow: {
    marginRight: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        elevation: 20,
      },
    }),
  },

  SettingsFlatListContainer: {
    paddingLeft: 40,
    paddingRight: 40,
  },

  DocumentListContainer: {
    justifyContent: 'flex-start',
    marginRight: 10,
  },

  SectionHeaderTextContainer: {
    paddingBottom: 15,
  },

  SidebarBkgdImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  SupportsImageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },

  SidebarInnerContentContainer: {
    flex: 1,
    backgroundColor: 'transparent',
  },

  Transparent: {
    backgroundColor: 'transparent',
  },

  DatePickerBoxMargin: {
    marginLeft: 10,
    marginRight: 10,
  },

  StandardSearchContainer: {
    paddingRight: 10,
    paddingLeft: 5,
    borderRadius: 10,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    paddingTop: 5,
    paddingBottom: 5,
    width: 300,
  },

  StandardSearchContainerWeb: {
    paddingRight: 10,
    paddingLeft: 2,
    borderRadius: 10,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    paddingTop: 5,
    paddingBottom: 5,
  },

  StandardSearchInputContainer: {
    flex: 0.9,
  },

  SidebarSpacer: {
    marginTop: 15,
  },

  SidebarMessagesContainer: {
    padding: 10,
  },

  titleContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },

  spendingTitle: {
    marginBottom: 4,
  },

  imageMainContainer: {
    marginLeft: 8,
    marginRight: 0,
    marginTop: 8,
  },

  imageCaptionContainer: {
    paddingRight: 10,
    marginLeft: 5,
    maxWidth: '67%',
  },

  width100: {
    width: '100%',
  },

  width50: {
    width: '45%',
  },
  width65: {
    width: '65%',
  },
  width60: {
    width: '60%',
  },
  width70: {
    width: '70%',
  },

  marginRight250: {
    marginRight: 250,
  },

  width40: {
    width: '40%',
  },

  marginRight0: {
    marginRight: 0,
  },

  marginBottom0: {
    marginBottom: 0,
  },

  marginBottom3: {
    marginBottom: 3,
  },

  marginBottom10: {
    marginBottom: 10,
  },

  marginBottom15: {
    marginBottom: 15,
  },

  marginBottom20: {
    marginBottom: 20,
  },

  marginBottom30: {
    marginBottom: 30,
  },

  marginBottom48: {
    marginBottom: 48,
  },

  marginBottom5: {
    marginBottom: 5,
  },

  marginLeft10: {
    marginLeft: 10,
  },

  marginLeft15: {
    marginLeft: 15,
  },

  marginLeft50: {
    marginLeft: 50,
  },

  marginRight10: {
    marginRight: 10,
  },

  marginRight20: {
    marginRight: 20,
  },

  marginTop30: {
    marginTop: 30,
  },

  marginTop5: {
    marginTop: 5,
  },

  marginTop0: {
    marginTop: 0,
  },

  marginTop1: {
    marginTop: 1,
  },

  marginTop2: {
    marginTop: 2,
  },

  marginTop14: {
    marginTop: 14,
  },

  elevation0: {
    elevation: 0,
  },

  imageContainerBorder: {
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.4,
      },
    }),
  },

  imageContainerStoreIcons: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },

  imageListContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },

  iosMarginTop15: {
    ...Platform.select({
      ios: {
        marginTop: 15,
      },
    }),
  },

  orContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },

  // Width is calculated by the component
  goalInspirationTile: {
    minHeight: 80,
    paddingRight: 4,
    paddingLeft: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    borderRadius: 10,
  },

  goalFilter: {
    marginBottom: 5,
  },

  spendSection: {
    borderWidth: 1,
    borderStyle: 'solid',
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },

  budgetsSupportDetails: {
    borderStyle: 'solid',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: '#6D6E79',
    marginTop: 4,
  },

  spendingItemSpacing: {
    marginTop: 1,
    marginBottom: 1,
  },

  spendingBarContainer: {
    minHeight: 80,
    marginBottom: 15,
  },

  supportOtherItem: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingTop: 7,
    paddingBottom: 7,
    // marginTop: 13,
    // marginBottom: 13,
    borderRadius: 0.5, // Needed for dotted or dash border in RN
    borderStyle: 'dotted', // Does not work in RN 0.52 irregardless of the
    // above see https://github.com/facebook/react-native/issues/17251
  },

  sideBarTickIcon: {
    maxWidth: 40,
    minWidth: 30,
    maxHeight: 40,
    minHeight: 30,
    marginRight: 5,
    borderRadius: 100 / 2,
    backgroundColor: 'red',
    padding: 4,
  },

  sideBarIcon: {
    maxWidth: 40,
    minWidth: 30,
    maxHeight: 40,
    minHeight: 30,
    marginRight: 5,
    borderRadius: 100 / 2,
    backgroundColor: 'grey',
    padding: 4,
  },

  addCrewContainer: {
    minHeight: 40,
    padding: 5,
    alignItems: 'flex-end',
  },

  webHeaderContainer: {
    height: 50,
    paddingLeft: 10,
    paddingRight: 10,
    alignContent: 'space-between',
  },

  webHeaderContainerMobile: {
    height: 80,
    paddingLeft: 10,
    paddingRight: 10,
    alignContent: 'space-between',
  },

  //* ****************************
  //*    F O N T   S I Z E S
  //* ****************************

  font13: {
    fontSize: 13,
  },

  font14: {
    fontSize: 14,
  },

  font15: {
    fontSize: 15,
  },

  font16: {
    fontSize: 16,
  },

  font18: {
    fontSize: 18,
  },

  font20: {
    fontSize: 20,
  },
  font22: {
    fontSize: 22,
  },

  font23: {
    fontSize: 23,
  },

  font25: {
    fontSize: 25,
  },
  font27: {
    fontSize: 27,
  },
  font30: {
    fontSize: 30,
  },
  font17: {
    fontSize: 17,
  },

  font37: {
    fontSize: 37,
  },

  font40: {
    fontSize: 40,
  },

  font58: {
    fontSize: 58,
  },

  font85: {
    fontSize: 85,
  },

  ContentTitle: {
    // Used for the title styled text that would be displayed on screens
    // (e.g. Plan Management Screen)
    fontSize: 30,
  },

  ModalSubtitleText: {
    fontSize: 15,
  },

  StandardFontSize: {
    fontSize: 16,
  },

  ProfileTileSubText: {
    fontSize: 15,
  },

  SummaryTileText: {
    fontSize: 15,
  },

  ProfileTileHeaderText: {
    fontSize: 17,
  },

  SidebarMainText: {
    fontSize: 17,
  },

  buttonText: {
    alignSelf: 'center',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
      android: {
        paddingTop: 0,
      },
    }),
  },

  checkBoxLabelText: {
    fontSize: 15,
  },

  titleBoxText: {
    fontSize: 20,
  },
  multiLineText: {
    alignSelf: 'center',
    fontSize: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  multiLineMarginRight: {
    marginRight: 15,
  },
  multiLineMarginLeft: {
    marginLeft: '5%',
  },

  SearchInput: {
    fontSize: 20,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
    ...Platform.select({
      ios: {
        paddingBottom: 5,
      },
    }),
  },

  searchInputProvider: {
    height: 40,
    fontSize: 16,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
    ...Platform.select({
      ios: {
        paddingBottom: 5,
      },
    }),
  },

  SectionHeaderText: {
    fontSize: 37,
  },

  SummaryTileHeaderText: {
    fontSize: 18,
    textAlignVertical: 'bottom',
  },

  SummaryTileHeaderButtonText: {
    fontSize: 13,
  },

  ModalTitleText: {
    fontSize: 30,
  },

  TitleText: {
    fontSize: 55,
  },

  SubtitleText: {
    fontSize: 37,
  },

  bodyText: {
    fontSize: 17,
  },

  GoalSummaryDescriptionText: {
    fontSize: 25,
  },

  supportTileText: {
    fontSize: 25,
    textAlignVertical: 'center',
  },

  crewOptionTextAlign: {
    ...Platform.select({
      ios: {
        paddingTop: 10,
      },
      android: {
        paddingTop: 20,
      },
    }),
    width: 50,
  },

  noDataText: {
    fontSize: 25,
    textAlign: 'center',
    marginTop: 25,
  },

  GoalSummaryTitleText: {
    fontSize: 30,
  },

  goalInspText: {
    fontSize: 15,
  },

  orText: {
    fontSize: 25,
  },

  //* **************************************************
  //*   A U T H E N T I C A T I O N   F L O W
  //* **************************************************

  // Authentication screen stuff

  authenticationWhiteBlock: {
    height: 90,
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
  },

  authenticationWhiteBlockTextOne: {
    fontSize: 58,
    textAlign: 'center',
  },
  authenticationWhiteBlockTextTwo: {
    fontSize: 58,
    textAlign: 'center',
  },
  authenticationTransparentBlock: {
    minHeight: 90,
    justifyContent: 'center',
    alignItems: 'center',
  },
  authenticationTransparentBlockText: {
    fontSize: 70,
    textAlign: 'center',
  },
  authenticationDescriptionText: {
    fontSize: 30,
    textAlign: 'left',
  },

  authSmallButtonText: {
    fontSize: 40,
    textAlign: 'center',
    padding: 10,
  },
  authbuttonContainer: {
    justifyContent: 'center',
  },

  viewWhiteBorder: {
    borderWidth: 4,
  },

  liLogoCorner: {
    position: 'absolute',
    marginTop: 30,
    marginLeft: 30,
    width: 95,
    height: 95,
  },

  ProfileBudgetsPaymentLogo: {
    width: 35,
    height: 35,
  },

  SupportTileEdit: {
    padding: 15,
    marginTop: 20,
  },

  SupportTileEditBudget: {
    padding: 10,
  },

  //* *************************
  //*   A L I G N M E N T S
  //* *************************

  includeFontPaddingFalse: {
    includeFontPadding: false,
  },

  alignItemsFlexStart: {
    alignItems: 'flex-start',
  },

  alignItemsFlexEnd: {
    alignItems: 'flex-end',
  },

  alignItemsStretch: {
    alignItems: 'stretch',
  },

  textAlignCentre: {
    textAlign: 'center',
  },

  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignRight: {
    textAlign: 'right',
    alignSelf: 'stretch',
  },

  textAlignVerticalTop: {
    textAlignVertical: 'top',
  },

  textAlignVerticalCenter: {
    textAlignVertical: 'center',
  },

  textAlignVerticalBottom: {
    textAlignVertical: 'bottom',
  },

  justifyContentCentre: {
    justifyContent: 'center',
  },

  justifyContentFlexStart: {
    justifyContent: 'flex-start',
  },

  alignSelfStretch: {
    alignSelf: 'stretch',
  },

  alignSelfFlexStart: {
    alignSelf: 'flex-start',
  },

  alignSelfBaseline: {
    alignSelf: 'baseline',
  },

  scrollViewVerticalCentre: {
    flexGrow: 1,
  },

  alignFlexEnd: {
    alignSelf: 'flex-end',
  },

  alignSelfCenter: {
    alignSelf: 'center',
  },

  flexDirectionRow: {
    flexDirection: 'row',
  },

  invoiceWarningIcon: {
    width: 40,
    height: 40,
    alignSelf: 'center',
    resizeMode: 'contain',
    marginRight: 10,
  },

  flexDirectionColumn: {
    flexDirection: 'column',
  },

  flexDirectionRowReverse: {
    flexDirection: 'row-reverse',
  },

  flexWrap: {
    flexWrap: 'wrap',
  },

  flexBasisAuto: {
    flexBasis: 'auto',
  },

  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },

  margin1: {
    margin: 1,
  },

  margin15: {
    marginRight: 15,
  },

  margin05: {
    margin: 5,
  },

  margin10: {
    margin: 10,
  },

  marginMinus12: {
    margin: -12,
  },

  margin5: {
    marginRight: 5,
  },

  alignItemsFlexEndRow: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  //* *************************
  //*   F O N T  F A M I L Y
  //* *************************

  customFont: {
    fontFamily: 'arial',
  },
  customFontLight: {
    fontFamily: 'arial',
  },
  customFontMedium: {
    fontFamily: 'arial',
  },
  customFontSemiBoldOriginal: {
    fontFamily: 'arial',
  },

  customFontSemiBold: {
    fontFamily: 'arial',
    fontWeight: 'bold',
  },

  customFontBold: {
    fontFamily: 'arial',
    fontWeight: 'bold',
  },

  customFontV: {
    fontFamily: 'arial',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  customFontInput: {
    fontFamily: 'arial',
  },

  fontWeight400: {
    fontWeight: '400',
  },

  fontWeightBG400: {
    ...Platform.select({
      android: {
        fontWeight: '400',
      },
    }),
  },

  height100: {
    height: '100%',
  },
  logoutConfirmModalHeightWeb: {
    height: '100',
  },
  //* *************************
  //*       T E X T
  //* *************************

  textLightGrey: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  textLineHeight25: {
    lineHeight: 25,
  },
  textLineHeight30: {
    lineHeight: 30,
  },

  goalAddStepsTitle: {
    fontSize: 25,
  },
  addProviderButton: {
    paddingRight: 20,
    paddingTop: 20,
  },

  supportsTitle: {
    fontSize: 25,
  },

  goalAddStepsSubtitle: {
    fontSize: 15,
  },

  addGoalTitle: {
    fontSize: 25,
    marginBottom: 5,
  },

  addGoalFiltering: {
    fontSize: 15,
    marginBottom: 10,
  },

  goalSuggestedText: {
    fontSize: 17,
  },

  goalFiltersText: {
    fontSize: 15,
  },

  spendBarTitleBudget: {
    fontSize: 23,
  },

  trackingTitle: {
    fontSize: 23,
  },

  trackingSubTitle: {
    fontSize: 17,
  },

  spendSupportText: {
    fontSize: 16,
  },

  spendSupportViewText: {
    fontSize: 15,
  },

  spendBarTitleFunding: {
    paddingTop: 5,
  },

  spendBarSectionTitle: {
    fontSize: 14,
  },

  spendBarValue: {
    fontSize: 17,
  },

  spendBarTitleSupports: {
    fontSize: 25,
  },

  spendSupportOtherItem: {
    fontSize: 20,
  },
  messagecontainer: {
    backgroundColor: 'transparent',
    margin: 10,
    overflow: 'hidden',
  },
  messageFirstContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  messageCollaExpaImage: {
    width: 25,
    height: 25,
    fontSize: 30,
    textAlign: 'center',
  },
  messageChildrenBody: {
    backgroundColor: 'transparent',
  },
  planDateText: {
    fontSize: 15,
  },

  inputErrorMessageText: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
    marginTop: 6,
    marginLeft: 1,
  },

  textInputError: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  //* *************************
  //*     E L E M E N T S
  //* *************************

  tabBarLabel: {
    alignSelf: 'center',
    paddingBottom: '5@ms0.1',
    fontSize: '13@ms0.1',
  },

  buttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 0,
    alignItems: 'stretch',
  },

  supportCategorySelectorContainer: {
    marginRight: 10,
    marginBottom: 10,
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'stretch',
  },

  summaryTileButtonContainer: {
    justifyContent: 'flex-end',
  },

  summaryTileButtonText: {
    alignSelf: 'flex-end',
    paddingRight: 10,
    fontSize: 25,
    ...Platform.select({
      ios: {
        paddingTop: 10,
      },
      android: {
        paddingBottom: 5,
      },
    }),
  },

  summaryTileButtonIcon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    marginLeft: 5,
    fontSize: 19,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },
  summaryTileButtonIconCustomStyle: {
    resizeMode: 'contain',
    marginLeft: 5,
    fontSize: 29,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  spendingSupportIcon: {
    height: 33,
    width: 30,
    resizeMode: 'contain',
    fontSize: 30,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  floatingIcon: {
    height: 30,
    width: 28,
    resizeMode: 'contain',
    fontSize: 30,
    color: BrandColors.WHITE,
  },

  spendingSupportIconView: {
    height: 20,
    width: 20,
    marginRight: 4,
    resizeMode: 'contain',
    fontSize: 20,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  accordianButtonIcon: {
    height: 15,
    width: 15,
    resizeMode: 'contain',
    marginRight: 5,
  },

  SummaryTileContainer: {
    marginBottom: 5,
    flexDirection: 'column',
  },

  summaryTileTextContainer: {
    paddingTop: 5,
    paddingRight: 0,
    paddingBottom: 5,
    paddingLeft: 0,
  },

  memberExistBannerTextContainer: {
    alignSelf: 'center',
    textAlignVertical: 'center',
    textAlign: 'center',
    paddingVertical: 10,
    paddingHorizontal: 30,
    borderRadius: 5,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    color: 'white',
    fontSize: 20,
  },

  inputField: {
    backgroundColor: 'white',
    fontSize: 15,
  },

  shortDesInputField: {
    width: '100%',
  },

  textFieldWrapper: {
    borderWidth: 1,
    borderRadius: 10,
  },
  guideMeHighlightWrapper: {
    borderWidth: 3,
    borderRadius: 10,
  },

  inputFieldWrapper: {
    borderRadius: 10,
    paddingLeft: 5,
    textAlignVertical: 'center',
  },

  inputFieldBasic: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderRadius: 10,
    paddingTop: 5,
    paddingLeft: 5,
  },

  costInputField: {
    backgroundColor: 'white',
  },

  paddingTop10iOS: {
    ...Platform.select({
      ios: {
        paddingTop: 10,
      },
    }),
  },

  unitInputFeild: {
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
        padding: 2,
        height: 50,
      },
    }),
  },

  boardTopwidth0: {
    borderTopWidth: 0,
  },

  multilineInputField: {
    textAlignVertical: 'top',
  },

  pickerField: {
    borderWidth: 1,
    backgroundColor: 'white',
    elevation: 10,
  },

  inputWrapper: {
    width: 500,
    padding: 10,
  },

  topMargin12: {
    ...Platform.select({
      ios: {
        paddingTop: 12,
      },
    }),
  },

  costInputWrapper: {
    flex: 1,
    maxWidth: 150,
  },

  leftMargin10: {
    marginLeft: 10,
  },
  supportsInputWrapper: {
    padding: 10,
  },

  supportsDescriptionContainer: {
    marginBottom: 15,
    paddingBottom: 10,
  },

  supportImageUpload: {
    height: 125,
    width: 125,
    borderWidth: 5,
  },

  supportBudgetImage: {
    height: 75,
    width: 75,
    borderWidth: 5,
    borderRadius: 32,
    borderColor: 'white',
  },

  supportSummaryImage: {
    height: 80,
    width: 80,
    borderWidth: 5,
  },
  PhotoTileStandardSize: {
    width: 80,
    height: 80,
  },
  supportImageUploadContainer: {
    marginTop: 10,
    height: 135,
    width: 135,
    alignSelf: 'center',
    alignItems: 'center',
    elevation: 10,
  },

  supportsHeaderWrapper: {
    paddingTop: 20,
  },

  ProviderSupports: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  reviewImage: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
    resizeMode: 'contain',
    width: width * 0.08,
    height: width * 0.08,
  },

  reviewHeaderWrapper: {
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: 'center',
  },

  spaceAround: {
    marginTop: 10,
  },
  spaceAroundReviewSuccess: {
    marginTop: 0,
    marginBottom: 10,
  },
  spaceAroundReviewSuccessRateTheProvider: {
    marginTop: 10,
    marginBottom: 10,
  },
  wrapperHeaderTitleAbsolute: {
    position: 'absolute',
    alignSelf: 'center',
    ...Platform.select({
      ios: {
        marginTop: 30,
      },
      android: {
        marginTop: 15,
      },
    }),
  },

  pickerWrapper: {
    alignItems: 'stretch',
    flex: 1,
  },

  fullscreenVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },

  fullscreenActivityIndicator: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5FCFF88',
    zIndex: 999,
  },

  screenActivityIndicator: {
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5FCFF88',
    zIndex: 999,
  },

  fullscreenOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.6,
    zIndex: 998,
  },
  fullscreenOverlayBasic: {
    position: 'absolute',
    backgroundColor: "rgba(52, 52, 52, 0.9)",
    height: "100%",
    width: "100%",
    opacity: 1,
    zIndex: 998,
  },

  BenefitsOfLeapinOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },

  fullscreenCrewNoAccess: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5FCFF88',
    zIndex: 999,
  },

  bottomPositionActivityIndicator: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 50,
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 999,
  },

  tabBarIcon: {
    height: '26@ms0.1',
    width: '26@ms0.1',
    resizeMode: 'contain',
    fontSize: '25@ms0.1',
    color: '#ffffff',
  },

  titleBox: {
    padding: 10,
    alignItems: 'center',
    alignSelf: 'flex-start',
  },

  titleBoxElevation: {
    elevation: 5,
    borderRadius: 5,
  },

  textWrapper: {
    paddingLeft: 10,
  },

  titleBoxIcon: {
    height: 35,
    width: 35,
    fontSize: 34,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  requiredIndicatorIcon: {
    height: 25,
    width: 25,
    fontSize: 24,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  titleGoalSection: {
    height: 35,
    width: 35,
    fontSize: 34,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    marginRight: 10,
  },

  supportCategoryTitleBoxIcon: {
    height: 35,
    width: 35,
    resizeMode: 'contain',
  },

  titleBoxIconLarge: {
    fontSize: 26,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  leapInPlanManagedIcon: {
    height: 35,
    width: 35,
    resizeMode: 'contain',
    fontSize: 35,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  leapInReadPlanManagedTnCIcon: {
    height: 35,
    width: 35,
    resizeMode: 'contain',
    fontSize: 35,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  titleBoxIconMargin: {
    marginRight: 5,
  },
  supportItemSummaryRightMargin: {
    marginRight: 10,
  },
  commonBoxRightMargin: {
    marginRight: 10,
  },
  selectProviderRightMargin: {
    marginRight: 20,
  },

  headerTitle: {
    padding: 15,
    borderWidth: 3,
    borderColor: 'white',
    borderStyle: 'solid',
    fontSize: 30,
    textAlign: 'center',
    ...Platform.select({
      ios: {
        paddingTop: 17,
      },
    }),
  },

  reUploadIcon: {
    resizeMode: 'contain',
    fontSize: 25,
  },

  screenWrapperHeaderIcon: {
    height: 26,
    width: 26,
    resizeMode: 'contain',
    fontSize: 25,
    color: '#ffffff',
  },

  paginationIcon: {
    resizeMode: 'contain',
    fontSize: 35,
  },

  offlineText: {
    paddingLeft: 7,
    marginRight: 20,
  },
  offlineContainer: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 0,
    marginLeft: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  wifiIcon: {
    height: 30,
    width: 30,
    marginLeft: 30,
    resizeMode: 'contain',
  },

  moredots: {
    height: 7,
    width: 21,
    resizeMode: 'center',
  },
  moredotsButton: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreDotsContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginBottom: 10,
  },

  paddingscreenWrapperHeaderIcon: {
    marginTop: 10,
  },

  roundedProfileImage: {
    height: 30,
    width: 30,
    borderRadius: 20,
    borderWidth: 2,
  },

  roundedMemberImage: {
    height: 30,
    width: 30,
    borderRadius: 20,
    borderWidth: 2,
  },

  roundedProfileImageMobile: {
    height: 46,
    width: 46,
    borderRadius: 25,
    borderWidth: 2,
  },

  roundedProfileImageWidthMobile: {
    width: 46,
  },

  roundedProfileImageWidth: {
    width: 40,
  },

  phototileCorner: {
    borderRadius: 25,
  },

  fullButtonImageRight: {
    padding: 5,
    alignItems: 'center',
  },

  SidebarBkgdImage: {
    flex: 1,
    resizeMode: 'cover',
    width: undefined,
    height: undefined,
  },

  DatePickerContainer: {
    paddingLeft: 5,
  },

  bannerMessage: {
    textAlign: 'center',
    fontSize: 20,
  },

  ReviewInvoiceInvoiceNumberContainer: {
    flexDirection: 'row',
  },
  ReviewInvoiceDocumentPathContainer: {
    flex: 1,
    flexDirection: 'row',
  },

  CheckBoxButtonSelected: {
    elevation: 5,
  },

  CheckBoxButtonLabelContainer: {
    paddingRight: 7,
    paddingLeft: 7,
    paddingTop: 5,
    paddingBottom: 5,
  },

  CheckBoxButtonContainer: {
    paddingRight: 7,
    paddingLeft: 7,
    paddingTop: 5,
    paddingBottom: 5,
  },

  CheckBoxButtonLabelIconContainer: {
    ...Platform.select({
      ios: {
        marginRight: '5%',
      },
      android: {
        marginRight: '15%',
      },
    }),
  },

  CheckBoxBorderWidth: {
    borderWidth: 3,
  },

  CheckBoxButtonImageIconContainer: {
    marginRight: '5%',
  },

  CheckBoxButtonIcon: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
  },

  CheckBoxButtonNoIcon: {
    height: 35,
    width: 35,
  },

  checkBoxIcon_Segmented: {
    height: 26,
    width: 26,
    marginRight: 10,
    resizeMode: 'contain',
  },

  // TODO ASGOALS - TO MOVE AS

  goalStepTextContainer: {
    paddingRight: 5,
    paddingLeft: 5,
    width: '85%',
  },

  goalStepsContainer: {
    flexDirection: 'row',
  },

  addGoalsButton: {
    fontSize: 49,
  },

  addGoalsButtonContainer: {
    elevation: 5,
    padding: 20,
    marginTop: 40,
    marginBottom: 40,
  },

  goalsNextButtonContainer: {
    marginLeft: 15,
  },

  goalsNextButton: {
    minHeight: 50,
    minWidth: 120,
    fontSize: 25,
  },

  goalsTile: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 5,
    marginRight: 5,
  },

  goalsTileEditSteps: {
    flex: 1,
    flexDirection: 'row',
    marginRight: 5,
  },

  goalsTileContent: {
    // flex: 1,
  },

  addGoalsContainer: {
    flex: 1,
    marginTop: 10,
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderLeftWidth: 2,
    borderRadius: 0.5, // Needed for dotted or dash border in RN
    ...Platform.select({
      ios: {
        borderStyle: 'solid',
      },
      android: {
        borderStyle: 'dotted', // Does not work in RN 0.52 irregardless of the above see https://github.com/facebook/react-native/issues/17251
      },
    }),
    justifyContent: 'center',
    alignItems: 'center',
    width: 356,
  },

  addStepsNone: {
    marginTop: 10,
    flexDirection: 'column',
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderLeftWidth: 2,
    borderRadius: 0.5, // Needed for dotted or dash border in RN
    borderStyle: 'dotted', // Does not work in RN 0.52 irregardless of the above see https://github.com/facebook/react-native/issues/17251
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },

  goalContainer: {
    width: 356,
  },

  goalStepContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 1, // Needed for dotted or dash border in RN
    borderStyle: 'dotted', // Does not work in RN 0.52 irregardless of the above see https://github.com/facebook/react-native/issues/17251
    borderWidth: 1,
    marginTop: 5,
    marginBottom: 5,
    elevation: 5,
    borderColor: '#6D6E79',
  },

  goalCont: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  goalSummaryEditHaeder: {
    marginBottom: 8,
  },

  goalStepNumberContainer: {
    minWidth: 20,
  },

  centerTextHorizontally: {
    flex: 1,
    alignItems: 'center',
  },

  goalStepNumber: {
    fontSize: 18,
    flex: 1,
    flexDirection: 'row',
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
    }),
    justifyContent: 'center',
  },

  goalStepContainerText: {
    padding: 8,
  },

  goalEditStepIcon: {
    paddingRight: 8,
  },

  goalStepTitle: {
    fontSize: 18,
    ...Platform.select({
      ios: {
        color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
        fontWeight: '500',
      },
    }),
  },

  goalStepDescription: {
    fontSize: 18,
  },

  goalTileHeaderText: {
    fontSize: 24,
    textAlign: 'left',
    ...Platform.select({
      ios: {
        color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
        fontWeight: '500',
      },
    }),
  },

  alignButtonText: {
    textAlign: 'center',
  },

  goalTileAddPhotoText: {
    fontSize: 15,
    textAlign: 'center',
  },

  goalImage: {
    width: 320,
    height: 108,
  },

  progressOptionIcon: {
    width: 50,
    height: 50,
    margin: 5,
  },

  goalImageContainer: {
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },

  goalHeaderContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },

  goalOrText: {
    fontSize: 20,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },

  supportFeelingIcon: {
    minHeight: 30,
    maxHeight: 45,
    minWidth: 30,
    maxWidth: 45,
  },

  supportFeelingWrapper: {
    paddingTop: 10,
    margin: 5,
    alignItems: 'center',
  },

  errorApiIcon: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
    marginRight: 5,
  },

  nextButton: {
    minHeight: 30,
    minWidth: 100,
    fontSize: 28,

    textAlignVertical: 'center',
  },

  tapToUpload: {
    fontStyle: 'italic',
    paddingLeft: 10,
  },

  shadow: {
    shadowColor: '#202020',
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },

  tapToUploadContainer: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  flexDirectionRowPhototile: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  letsGoButton: {
    minHeight: 30,
    minWidth: 100,
    fontSize: 24,
    textAlignVertical: 'center',
  },

  prosConsIcon: {
    height: 35,
    width: 35,
    resizeMode: 'contain',
    marginLeft: 5,
    marginRight: 10,
  },

  alignedButtonContainer: {
    marginTop: 10,
    paddingLeft: 10,
    paddingTop: 10,
    borderRadius: 0,
    alignItems: 'flex-end',
  },

  crewTextInput: {
    minHeight: 80,
    borderWidth: 1,
  },

  alignIcon10: {
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
    }),
  },

  //* *************************
  //*       M O D A L S
  //* *************************
  standardModal: {
    backgroundColor: '#D2D2D2',
    flex: 1,
  },

  paddingTop25: {
    paddingTop: 25,
  },

  modalStyle: {
    position: 'relative',
    backgroundColor: '#F4F4F4',
    paddingTop: 25,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    borderRadius: 0,
    borderColor: 'black',
    borderWidth: 0.5,
  },

  ModalSingleRow: {
    flex: 1,
    flexDirection: 'row',
  },

  modalHeader: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  modalContent: {
    // might not be needed anymore as the modalFooter has been removed
  },

  modalContentScroll: {
    paddingTop: 0,
    paddingRight: 30,
    paddingBottom: 10,
    paddingLeft: 30,
  },

  ModalActionButtons: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  screenFooter: {
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },

  ModalFooterLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  ModalFooterRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  ModalFooterCenter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },

  ModalFooterLeftSkip: {
    flex: 7,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  ModalFooterRightSkip: {
    flex: 3,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  ModalTitleContainer: {
    paddingBottom: 10,
  },

  ModalSubtitleContainer: {
    paddingBottom: 20,
  },

  ModalTextStyle: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 30,
  },

  ModalTextInputWrapper: {
    alignItems: 'stretch',
    fontSize: 15,
    width: '100%',
    backgroundColor: 'white',
    height: 50,
    paddingLeft: 10,
  },
  CaptionTextInputWrapper: {
    // alignItems: 'stretch',
    width: '100%',
    backgroundColor: 'white',
    height: 50,
  },
  TextWrapperHeight50: {
    height: 200,
  },

  width80P: {
    width: '90%',
  },

  ReadOnlyModalTextInputWrapper: {
    alignItems: 'stretch',
    width: '100%',
    borderWidth: 0,
    elevation: 5,
  },
  DateInputWrapper: {
    paddingTop: 10,
    textAlignVertical: 'center',
    width: '100%',
    backgroundColor: 'white',
    height: 40,
    paddingLeft: 10,
  },
  ReadOnlyDateInputWrapper: {
    alignItems: 'stretch',
    width: '100%',
    borderWidth: 0,
    elevation: 5,
    height: 50,
    textAlignVertical: 'center',
  },

  ModalDescriptionText: {
    paddingTop: 10,
    paddingBottom: 20,
  },

  ModalTextInputContainer: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
  },

  MarginTopSection: {
    marginTop: 30,
  },

  ModalPickerInputContainer: {
    marginBottom: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  PickerInputContainer: {
    height: 50,
    borderRadius: 10,
  },

  PickerBackground: {
    elevation: 10,
    width: '100%',
  },

  pickerColor: {
    ...Platform.select({
      ios: {
        backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
      },
    }),
  },
  pickerGeneral: {
    fontSize: 16,
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
  },
  PickerReadOnlyBackground: {
    elevation: 10,
    width: '100%',
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    color: BrandColors.CHARCOAL_TINT60,
  },

  LIManagedActionBox: {
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 0,
    marginBottom: 10,
  },

  ProfileBudgetsPaymentLogoActionBox: {
    padding: 15,
    marginBottom: 15,
  },

  PlanSummaryActionBox: {
    padding: 10,
    marginBottom: 10,
  },

  supportByCrewDec: {
    marginTop: 20,
  },

  ModalButtonContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    borderRadius: 0,
    ...Platform.select({
      ios: {
        borderWidth: 1,
        paddingBottom: 5,
      },
      android: {
        borderWidth: 0,
        elevation: 5,
        paddingBottom: 10,
      },
    }),
  },

  ModalSaveButton: {
    marginLeft: 5,
  },
  UnitButton: {
    marginLeft: 15,
  },
  ModalCancelButton: {
    marginRight: 5,
  },

  closeIconPosition: {
    flex: 1,
    alignItems: 'flex-end',
    paddingRight: 30,
    paddingTop: 10,
  },

  closeIconImage: {
    height: 22,
    width: 22,
  },

  closeIconCharcoal60Image: {
    height: 15,
    width: 15,
  },

  SegmentedControl: {
    margin: 10,
    marginBottom: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderWidth: 0,
  },

  AvatarGridBlockStyle: {
    justifyContent: 'center',
    margin: 10,
    backgroundColor: '#ffffff',
    padding: 3,
    height: 120,
    width: 120,
    elevation: 8,
    borderRadius: 20,
    shadowOffset: {width: 3, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
  },

  AvatarGridContainer: {
    height: height / 2,
  },

  AvatarImagesContainer: {
    flex: 0.6,
    flexDirection: 'column',
  },

  ModalProfilePicture: {
    width: height * 0.15 - 10,
    height: height * 0.15 - 10,
    margin: 5,
    borderRadius: 20,
  },

  ModalProfilePictureContainer: {
    width: height * 0.15,
    height: height * 0.15,
    elevation: 8,
    borderRadius: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  toggleButtonContainer: {
    paddingTop: 5,
    paddingBottom: 5,
  },

  //* ******************************************
  //*       S P E C I A L    M O D A L S
  //* ******************************************

  AboutMeModalContentContainer: {
    flex: 1,
  },

  AboutMeModalSingleRow: {
    flex: 1,
    flexDirection: 'row',
  },

  singleModalContentContainer: {
    flex: 1,
  },
  subHeadingContainerMandotory: {
    alignSelf: 'flex-start',
  },
  leftAboutMeModalContentContainer: {},
  photoTileRoundContainer: {
    borderRadius: 33,
  },

  phototileCornerContainer: {
    borderRadius: 35,
  },

  rightAboutMeModalContentContainer: {
    flex: 0.6,
  },

  letsGetStartedButtonContainer: {
    marginLeft: 10,
  },

  twoColumns: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  twoColumnsLeft: {
    flex: 0.45,
  },

  twoColumnsRight: {
    flex: 0.45,
  },

  width100Per: {
    width: '100%',
  },

  ChangePasswordModalLabel: {
    fontSize: 15,
    paddingTop: 20,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  AboutMeModalLabel: {
    fontSize: 15,
  },

  CrewTypeLabel: {
    fontSize: 18,
  },

  ModalLabel: {
    fontSize: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },

  ModalLabelExtraPaddingTop: {
    fontSize: 18,
    paddingTop: 20,
    paddingBottom: 10,
  },

  ModalLabelOptional: {
    fontSize: 20,
    paddingTop: 10,
    paddingLeft: 50,
  },

  SelectProfileContent: {
    justifyContent: 'space-between',
    paddingTop: 10,
  },

  paddingTop5Android: {
    ...Platform.select({
      android: {
        paddingTop: 5,
      },
      ios: {
        marginTop: 10,
      },
    }),
  },

  iosMarginTop5: {
    ...Platform.select({
      ios: {
        marginTop: 5,
      },
    }),
  },

  height35: {
    ...Platform.select({
      android: {
        height: 35,
      },
    }),
  },

  ModalButtonIcon: {
    flex: 1,
    width: 20,
    height: 10,
    resizeMode: 'contain',
  },

  PaymentDetailsHeader: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },

  PaymentDetailsContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },

  subHeadingContainer: {
    marginTop: 15,
    marginBottom: 10,
    padding: 10,
    borderRadius: 0,
    alignSelf: 'flex-start',
  },

  //* ******************************************
  //*       N U M B E R   S P I N N E R
  //* ******************************************

  NSContainer: {
    borderWidth: 0.5,
    flexDirection: 'row',
    overflow: 'hidden',
    width: 200,
    elevation: 5,
  },

  NSButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  NSButtonText: {
    // color: 'white',
    textAlign: 'center',
  },

  NSNum: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  AlignCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  NSNumText: {
    textAlign: 'center',
  },

  //* *****************************************************
  //*       P E N D I N G   O R G A N I S A T I O N
  //* *****************************************************

  FTMtextsize: {
    fontSize: 30,
  },

  favThingsOverall: {
    flex: 0.5,
    flexDirection: 'row',
  },

  favThingsLayout: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  favThingsLike: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  favThingsText: {
    flex: 1,
    width: null,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: 'black',
    backgroundColor: 'white',
  },

  favThingsEdit: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },

  meetingDateText: {
    fontSize: 20,
    textAlignVertical: 'center',
  },

  contentFlexWrapWhereILive: {
    alignItems: 'flex-start',
  },

  myProfileTileTextContainer: {
    marginTop: 0,
    marginLeft: 10,
  },

  ChangePasswordContentContainer: {
    flex: 1,
  },

  ChangePasswordModalContentContainer: {
    alignItems: 'stretch',
  },

  changePasswordTextField: {
    alignItems: 'stretch',
    ...Platform.select({
      ios: {
        marginTop: 5,
      },
    }),
  },

  changePasswordMB: {
    marginBottom: 20,
  },

  passwordRestForm: {
    justifyContent: 'flex-start',
  },

  ChangePasswordButtonsContainer: {
    marginBottom: 20,
  },
  NotificationRowcontainer: {
    flex: 1,
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  NotificationRowunread: {
    backgroundColor: BrandColors.NOTIFICATION_ROW_UNREAD,
  },
  NotificationRowtextTitle: {
    marginLeft: 20,
    fontSize: 20,
    color: BrandColors.BLACK,
  },
  NotificationRowtextDescription: {
    marginLeft: 20,
    fontSize: 22,
    fontWeight: 'bold',
    color: BrandColors.BLACK,
  },
  NotificationRowtextTime: {
    marginLeft: 20,
    fontSize: 16,
    color: BrandColors.BLACK,
  },
  NotificationRowicon: {
    fontSize: 20,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  EyeIconGray: {
    resizeMode: 'contain',
    fontSize: 35,
    textAlign: 'center',
  },
  RightStrict: {
    position: 'absolute',
    right: 10,
  },
  LeftStrict: {
    position: 'absolute',
    left: 10,
  },
  NotificationRowunreadDotContainer: {
    marginLeft: 5,
    marginRight: 5,
  },
  MessageTileTextWrap: {
    flexWrap: 'wrap',
    flex: 1,
    paddingRight: 5,
    paddingLeft: 10,
  },
  NotificationRowiconDot: {
    height: 20,
    width: 20,
  },
  NotificationRowiconNext: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  maxWidth60: {
    maxWidth: 60,
  },

  maxWmaxWidth60P: {
    maxWidth: '60%',
  },

  flexColumn: {
    flex: 1,
    flexDirection: 'column',
  },

  topBarloggedIn: {
    alignItems: 'center',
    marginLeft: 10,
    paddingRight: 5,
    minHeight: 50,
    borderLeftWidth: 1,
    borderColor: BrandColors.WHITE,
    ...Platform.select({
      ios: {
        marginBottom: 10,
      },
      android: {
        marginBottom: 5,
      },
    }),
    marginTop: 5,
  },

  topBarloggedInPortrait: {
    alignItems: 'center',
    marginLeft: 10,
    paddingRight: 5,
    minHeight: 30,
    ...Platform.select({
      ios: {
        marginBottom: 10,
      },
      android: {
        marginBottom: 5,
      },
    }),
    marginTop: 5,
  },

  HeaderBarProfileName: {
    paddingLeft: 13,
    textAlignVertical: 'center',
    textAlign: 'left',
    minHeight: 50,
    ...Platform.select({
      ios: {
        paddingTop: 10,
      },
      android: {
        paddingTop: 0,
      },
    }),
  },

  ProfileDetailSeperator: {
    width: 1,
    position: 'absolute',
    backgroundColor: 'white',
    left: 0,
    top: 0,
    bottom: 0,
    marginBottom: 5,
    marginTop: 5,
  },

  NotificationsBtnContiner: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    maxHeight: 50,
    marginBottom: 20,
  },

  UnreleasedBudgetPopupDate: {
    minWidth: 160,
  },

  maxWidth600: {
    maxWidth: 600,
  },

  maxWidth150: {
    maxWidth: 150,
  },

  maxWidth200: {
    maxWidth: 200,
  },

  maxWidth250: {
    maxWidth: 250,
  },

  maxWidth160: {
    maxWidth: 160,
  },

  maxWidth150: {
    maxWidth: 150,
  },

  maxWidth120: {
    maxWidth: 120,
  },

  maxWidth125: {
    maxWidth: 125,
  },

  maxWidth99: {
    maxWidth: 99,
  },

  minWidth0: {
    minWidth: 0,
  },

  minWidth120: {
    minWidth: 120,
  },

  supportItemFit: {
    flex: 1,
    maxWidth: 700,
    minWidth: 350,
  },

  maxWidth280: {
    maxWidth: 280,
  },

  maxWidth500: {
    maxWidth: 500,
  },

  maxHeight15: {
    maxHeight: 15,
  },

  maxHeight25: {
    maxHeight: 25,
  },
  minHeight25: {
    minHeight: 25,
  },

  maxHeight28: {
    maxHeight: 28,
  },
  minHeight28: {
    minHeight: 28,
  },
  minHeight35: {
    minHeight: 35,
  },

  height20: {
    height: 20,
  },
  height370: {
    height: 370,
  },
  maxHeight20: {
    maxHeight: 20,
  },

  maxHeight28: {
    maxHeight: 28,
  },

  maxHeight35: {
    maxHeight: 35,
  },

  minHeight25: {
    minHeight: 25,
  },

  widthHeight50: {
    width: 50,
    height: 50,
  },
  flex1CenterContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  tabIconBadgeWrapperSmall: {
    position: 'absolute',
    top: -10,
    left: 10,
  },

  tabIconBadgeWrapper: {
    position: 'absolute',
    top: -13,
    left: 15,
  },
  tabIconBadge: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 12,
  },
  tabIconBadgeText: {
    fontSize: 10,
  },
  inputContainer: {
    maxWidth: 300,
  },
  inputContainerFullWidth: {
    flex: 1,
    alignItems: 'stretch',
  },

  UploadButtonIcon: {
    height: 22,
    width: 22,
    resizeMode: 'contain',
    marginTop: 0,
  },
  uploadFileText: {
    fontSize: 20,
    color: BrandColors.BLACK,
    textDecorationLine: 'underline',
  },

  uploadFileTextContainer: {
    marginLeft: '5%',
    marginRight: '5%',
  },

  underlineText: {
    textDecorationLine: 'underline',
  },
  ratetheprovider: {
    textDecorationLine: 'underline',
  },

  submitButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 10,
    margin: 10,
  },

  supportItemButton: {
    width: 130,
  },

  toggleItemButton: {
    width: 200,
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
      android: {
        paddingTop: 0,
      },
    }),
  },

  toggleOption: {
    marginTop: 20,
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //* *****************************************************
  //*      Unlisted Providers
  //* *****************************************************

  supportProviderContainerBox: {
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 13,
    paddingBottom: 10,
    alignSelf: 'flex-start',
    backgroundColor: 'white',
  },
  supportProviderElevation: {
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },
  addButtonContainer: {
    marginTop: 15,
    marginBottom: 10,
    padding: 10,
    borderRadius: 0,
    alignSelf: 'flex-end',
  },
  reviewInvoiceSummarySupportRow: {
    flex: 6,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  editSupportItem: {
    width: 60,
    marginBottom: 10,
  },
  editDeleteSupportItem: {
    width: 100,
  },

  mainTitle: {
    paddingTop: 30,
    paddingBottom: 10,
  },

  subTitle: {
    paddingTop: 30,
    paddingBottom: 10,
  },

  subTitleSelectProvider: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  topBarText: {
    fontSize: 20,
    paddingRight: 10,
    color: 'white',
  },

  addIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },

  alignAdd: {
    flex: 3.6,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  listContainer: {
    backgroundColor: BrandColors.WHITE,
    borderWidth: 1,
    borderColor: BrandColors.BLACK,
    marginRight: 20,
  },
  notificationBorderContainer: {
    backgroundColor: BrandColors.WHITE,
    borderWidth: 2.5,
    borderRadius: 10,
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 10,
    marginLeft: '10%',
    marginRight: '10%',
  },

  listItem: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },

  containerSubmit: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  invoiceSubmitWrapper: {
    flex: 1,
    alignSelf: 'center',
    height: height * 0.8,
  },

  ErrorShapeView: {
    width: width * 0.4,
    backgroundColor: '#FF4338',
  },
  WarningShapeView: {
    width: width * 0.4,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },

  SuccessShapeView: {
    width: width * 0.4,
    backgroundColor: '#5444A7',
  },

  maxHeight60: {
    maxHeight: 60,
  },

  ReviewContent: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 70,
    marginBottom: 10,
  },

  ProviderInfoRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  borderColorDarkGrey: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },
  borderColorGrey: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  messageExpandSeparator: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderWidth: 1.2,
  },
  sideBarTickImage: {
    flex: 1,
    resizeMode: 'contain',
    maxWidth: 20,
    maxHeight: 30,
  },

  BottomMessageBarIconPadding: {
    height: 25,
    width: 25,
    paddingRight: 60,
  },
  BottomMessageBarNextIconImage: {
    height: 25,
    width: 25,
  },

  AboutMeStandardTextMarginTop: {
    marginTop: -20,
  },

  AboutMeAdditionalInfoText: {
    borderStyle: 'solid',
    borderBottomWidth: 1,
    paddingBottom: 4,
    marginBottom: 4,
  },

  AddSupportsMarginBottom: {marginBottom: 10},

  AddSupportsFlex1: {flex: 1},

  ApprovalsDetailsInvoicepaddingBottom10: {paddingBottom: 10},

  ApprovalsDetailsInvoicepaddingRight10: {paddingRight: 10},

  ApprovalsDetailsInvoicePadding10: {padding: 10},

  ApprovalsDetailsInvoicePaddingTop5: {paddingTop: 5},

  ApprovalsDetailsInvoiceRejectFlexRowWrap: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  ApprovalsDetailsServAgreementPaddingB15: {paddingBottom: 15},

  ApprovalsDetailsServAgreementPaddingT10: {paddingTop: 10},

  BenefitOfLeapInFlex1: {flex: 1},

  BenefitOfLeapInFlex3: {flex: 3},

  BenefitOfLeapInFlex5: {flex: 5},

  BenefitOfLeapInFlex66: {flex: 66},

  BenefitOfLeapInFlex34: {flex: 34},

  BudgetsPaymentsViewFlex1: {flex: 1},

  CrewPhotoTile: {width: 75, height: 75},

  AddSupportCrewPhoto: {width: 100, height: 100},

  CrewAccessImageWH: {height: '100%', width: '100%'},

  CrewCreateMemberBox: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: 60,
  },

  CrewCreateMemberB4P10: {borderWidth: 4},

  CrewCreateMemberPR20PB29: {paddingRight: 20, paddingBottom: 20},

  CrewCreateMemberFlex4: {flex: 4},

  CrewCreateMemberFlex34: {flex: 34},

  CrewCreateMemberFlex30: {flex: 30},

  CrewCreateMemberF66PR30PL50: {flex: 66, paddingRight: 20, paddingLeft: 65},

  CrewCreateMemberFDrP20: {flexDirection: 'row', padding: 20},

  CrewCreateMemberFlex3: {flex: 3},

  CrewCreateMemberP10: {paddingBottom: 10},

  CrewCreateMemberE10: {elevation: 10, borderRadius: 10},

  CrewCreateMemberF2PL20: {flex: 2, paddingLeft: 20},

  CrewCreateMemberF1JCfsFDr: {
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  CrewCreateMemberH40W40MR10: {height: 40, width: 40, marginRight: 10},

  CrewCreateMemberFlex3: {flex: 1},

  CrewCreateMemberPB5: {paddingBottom: 5},

  CrewCreateMemberF1JAfe: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  CrewCreateMemberML20: {marginLeft: 20},

  CrewCreateMemberMR200: {marginRight: 150},

  CrewCreateMemberMTm20: {marginTop: -20},

  CrewFormF1: {flex: 1},

  CrewFormF2: {flex: 2},

  CrewFormFS20: {fontSize: 20},

  ForgotPasswordML10MB20: {marginLeft: '10%', marginBottom: 10},

  ForgotPasswordF5Jc: {flex: 5, justifyContent: 'center'},

  ForgotPasswordP10: {padding: 10},

  ForgotPasswordBW4MR20: {borderWidth: 4, marginRight: 20},

  ForgotPasswordMt40: {marginTop: 40},

  forgotpasswordF1JsaAIc: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  forgotpasswordP10PL15PR15ML60: {
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 60,
  },

  forgotpasswordF4: {flex: 4},

  forgotpasswordF34: {flex: 34},

  forgotpasswordF66: {flex: 66},

  forgotpasswordml13MT10: {marginLeft: 13, marginTop: 10},

  orgotpasswordF1Fr: {flex: 1, flexDirection: 'row'},

  forgotpasswordFMTMLMRMBP: {
    flex: 9,
    marginTop: 10,
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 40,
    paddingBottom: 20,
    backgroundColor: 'white',
  },

  forgotpasswordF3AIc: {flex: 3, alignItems: 'center'},

  GoalAddW75H75: {width: 75, height: 75},

  GoalAddF1: {flex: 1},

  GoalAddMH30: {minHeight: 30},

  GoalAddMRHW: {
    marginRight: 20,
    height: 3,
    width: '47%',
  },

  GoalAddMLHW: {
    marginLeft: 20,
    height: 3,
    width: '47%',
  },

  GoalAddStepsW400MR15: {width: 400, marginRight: 15},

  GoalAddStepsF1: {flex: 1},

  GoalAddStepsMB5: {marginBottom: 5},

  GoalAddStepsMT2: {marginTop: 2},

  GoalAddStepsMH: {minHeight: 30},

  GoalFiltersModalF1FDrFWw: {flex: 1, flexDirection: 'row', flexWrap: 'wrap'},

  GoalName: {
    margin: 10,
  },

  LoginSignUpP10: {padding: 10},

  LoginSignUpBW4MT10: {borderWidth: 4, marginTop: 10},

  LoginSignUpMB20: {marginBottom: 20},

  LoginSignUpP20: {padding: 0},

  LoginSignUpBW: {borderWidth: 2},

  LoginSignUpMB10: {marginBottom: 10, maxWidth: 300},

  LoginSignUpMR20H3BCwW40: {
    marginRight: 20,
    height: 3,
    backgroundColor: 'white',
    width: '40%',
  },

  LoginSignUpF1AIcJCc: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  LoginSignUpMT40: {marginTop: 40, marginLeft: 10},

  LoginSignUpMT5: {marginTop: 5},

  LoginSignUpML: {marginLeft: 50},

  LoginSignBW4P10PL15PR15: {
    borderWidth: 4,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },

  LoginSignmprplptpb: {
    marginLeft: '12%',
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 20,
    paddingBottom: 10,
  },

  LoginSignFBCPW: {
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },

  LoginSignF1FDr: {flex: 1, flexDirection: 'row'},

  LoginSignF5PT10: {paddingTop: 10},

  LoginSignF3AIc: {flex: 3, alignItems: 'center'},

  LoginSignF07FDrAIcASc: {
    flex: 0.7,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },

  LoginSignF2FDr: {flex: 2, flexDirection: 'row'},

  LoginSignFS13MR20MT20PB10: {
    fontSize: 13,
    marginRight: 20,
    marginTop: -15,
    paddingBottom: 10,
  },

  MultiSelectModalF1FDrFWw: {flex: 1, flexDirection: 'row', flexWrap: 'wrap'},

  PaymentsHistoryPR20PL20FDcJCfs: {
    paddingRight: 20,
    paddingLeft: 20,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  PaymentsHistoryFDr: {flexDirection: 'row'},

  PaymentsHistoryF1JCfeMT45MB15: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 45,
    marginBottom: 15,
  },

  PaymentsHistoryFDrF05P5: {flexDirection: 'row', flex: 0.05, padding: 5},

  PaymentsHistoryASfsPR15: {alignSelf: 'flex-start', paddingRight: 15},

  PaymentsHistoryASfe: {alignSelf: 'flex-end'},

  PaymentsHistoryF2FDcAIfeMT20: {
    flex: 2.5,
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: 20,
  },

  PaymentsHistoryFS30: {fontSize: 30},

  PDFViewerPT0: {paddingTop: 0},

  PlanManagementHeading: {
    marginBottom: 5,
    marginRight: 10,
  },

  PreAuthenticationMT70: {marginTop: 70},

  PreAuthenticationP15: {padding: 15},

  PreAuthenticationL13p: {left: '13%'},

  PreAuthenticationFFJAMPPP: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: '13%',
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },

  PreAuthenticationPR100: {paddingRight: 100},

  PreAuthenticationMR0MT10: {marginRight: 0, marginTop: 10},

  PreAuthenticationASfe: {alignSelf: 'flex-end'},

  PreAuthenticationF34: {flex: 34},

  PreAuthenticationPBML: {
    position: 'absolute',
    bottom: '10%',
    marginLeft: 30,
  },

  ReviewInvoiceSummaryF1FDrMH30: {flex: 1, flexDirection: 'row'},

  ReviewInvoiceSummaryF1PR10: {flex: 1, paddingRight: 10},

  ReviewInvoiceSummaryF1FDrMH70MB10: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 70,
    marginBottom: 10,
  },

  ReviewInvoiceSummaryPB5: {paddingBottom: 5},

  ReviewInvoiceSummaryF1FDwJCsbMH30: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 30,
  },

  ReviewInvoiceSummaryF1: {flex: 1},

  ReviewInvoiceSummaryF1FDrJCfeMB20: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },

  ReviewInvoiceSummaryF1FDwJCfeAIc: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  ReviewInvoiceSummaryFFDJCMH: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 60,
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 10,
    borderColor: '#6D6E79',
    borderBottomWidth: 1,
  },

  ReviewInvoiceSummarySupportItemContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 60,
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 10,
    borderColor: '#6D6E79',
    borderBottomWidth: 1,
  },

  ServiceAgreementsDetailsF1PB15: {flex: 1, paddingBottom: 15},

  ServiceAgreementsDetailsPB5: {paddingBottom: 5},

  ServiceAgreementsDetailsHMB20H: {
    height: 20,
    marginTop: 10,
    marginBottom: 20,
  },

  serviceAgreementsListF1: {flex: 1},

  serviceAgreementsListP10: {padding: 10},

  serviceAgreementsListF1PT10: {flex: 1, paddingTop: 10},

  serviceAgreementsListPL20: {paddingLeft: 20},

  serviceAgreementsListPR10AScF1: {
    paddingRight: 10,
    alignSelf: 'center',
    flex: 1,
  },
  ServiceAgreementWebWidth: {
    width: 360,
  },
  SignUpCrewInstructionsMT3: {marginTop: '3%'},

  SignUpCrewInstructionsBW4P10: {borderWidth: 4, padding: 10},

  SignUpCrewInstructionsPL17p: {paddingLeft: '17%'},

  SignUpCrewInstructionsBW4P10: {borderWidth: 4, padding: 10},

  SignUpCrewInstructionsMLPRPLPT: {
    marginLeft: '21%',
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 20,
  },

  SignUpCrewInstructionsPLPR5: {paddingLeft: 5, paddingRight: 5},

  SignUpCrewInstructionsBW4: {borderWidth: 4},

  SignUpCrewInstructionsPL10: {paddingLeft: '10%'},

  SignUpCrewInstructionsBW4P15: {borderWidth: 4, padding: 15},

  SignUpCrewInstructionsMTm25: {marginTop: -25},

  SignUpCrewInstructionsPR20PB5: {paddingRight: '20%', paddingBottom: '5%'},

  SignUpCrewInstructionsPL5PR5: {
    paddingLeft: 5,
    paddingRight: 5,
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
    }),
  },

  SignUpCrewInstructionsBW4: {borderWidth: 4},

  SignUpCrewInstructionsPL45: {paddingLeft: '45%'},

  SignUpCrewInstructionnBW4P15: {borderWidth: 4, padding: 15},

  SignUpCrewInstructionMT10: {marginTop: 10},

  SignUpCrewInstructionF34: {flex: 34},

  TermsAndConditionsFullPMAIcBCwP10: {
    alignItems: 'center',
    backgroundColor: 'white',
  },

  TermsAndConditionsFullPMPT10PB10PL75PR75: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 75,
    paddingRight: 75,
  },

  TermsAndConditionsPMMT40: {marginTop: 40},

  TermsAndConditionsPMBW4P10: {borderWidth: 4, padding: 10},

  TermsAndConditionsPMBOX: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: '8%',
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 40,
    paddingBottom: 10,
  },

  TermsAndConditionsPMAI: {alignItems: 'flex-start'},

  TermsAndConditionsPMPR60PTPB10: {
    paddingRight: 60,
    paddingTop: 10,
    paddingBottom: 10,
  },

  TermsAndConditionsPMASMT: {alignSelf: 'flex-end', marginTop: 40},

  TermsAndConditionsPMP10: {padding: 10},

  TermsAndConditionsPMF34: {flex: 34},

  UploadPlanMB20: {marginBottom: 20},

  UploadPlanMTm20: {
    ...Platform.select({
      ios: {
        marginTop: -17,
      },
      android: {
        marginTop: -20,
      },
    }),
  },

  WebViewerPT0: {paddingTop: 0},

  SignUpOptionsMT30: {marginTop: 30},

  SignUpOptionsBW4P10: {borderWidth: 4, padding: 10},

  SignUpOptionsFDJCAIMLP: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: '8%',
    paddingRight: 30,
    paddingLeft: 30,
    paddingTop: 20,
    paddingBottom: 10,
  },

  SignUpOptionsPLPR5: {paddingLeft: 5, paddingRight: 5},

  SignUpOptionsBW4ML20P: {borderWidth: 4, marginLeft: '20%'},

  SignUpOptionsPLPR20P: {paddingLeft: '20%', paddingRight: '20%'},

  SignUpOptionsPR10P: {paddingRight: '10%'},

  SignUpOptionsPL10P: {paddingLeft: '10%'},

  SignUpOptionsBW4: {borderWidth: 4, padding: 15},

  SignUpOptionsBW4ML3P: {borderWidth: 4, marginLeft: '3%'},

  SignUpOptionsPL10PPR40P: {paddingLeft: '10%', paddingRight: '40%'},

  SignUpOptionsMT10: {marginTop: 10},

  SignUpOptionsF34: {flex: 34},

  SignUpOptionsAccountTypeFFDJCAI: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  SignUpOptionsNewExistingPLPR2OPMB5: {
    paddingLeft: '20%',
    paddingRight: '20%',
    marginBottom: 5,
  },

  SignUpOptionsNewExistingPL40PPB7P: {
    paddingLeft: '40%',
    paddingBottom: '7%',
  },

  SignUpOptionsNewExistingBW4P15: {borderWidth: 4, padding: 15},

  SignUpOptionsNewExistingMT40: {marginTop: 40},

  SignUpOptionsNewExistingBW4P10: {borderWidth: 4, padding: 10},

  SignUpOptionsNewExistingMT30: {marginTop: 30},

  SignUpOptionsNewExistingBW4P5: {borderWidth: 4, padding: 5},

  SignUpOptionsNewExistingML50P: {marginLeft: '50%'},

  SignUpOptionsNewExistingMLPRPLPB: {
    marginLeft: '8%',
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 10,
  },

  SignUpOptionsNewExistingPT30: {paddingTop: 30},

  SignUpOptionsNewExistingPT20: {paddingTop: 20},

  SignUpOptionsNewExistingPLPR5: {paddingLeft: 5, paddingRight: 5},

  SignUpOptionsNewExistingP5: {padding: 5},

  SignUpOptionsNewExistingBW4: {borderWidth: 4},

  SignUpOptionsNewExistingML15P: {marginLeft: '15%'},

  SignUpOptionsNewExistingML10P: {marginLeft: '10%'},

  SignUpOptionsNewExistingML30P: {marginLeft: '30%'},

  SignUpOptionsNewExistingML25P: {marginLeft: '25%'},

  SignUpOptionsNewExistingPT: {paddingTop: '7%'},

  SignUpOptionsNewExistingPR10P: {paddingRight: '10%'},

  SignUpOptionsNewExistingPL15P: {paddingLeft: '15%'},

  SignUpOptionsNewExistingPR20P: {paddingRight: '20%'},

  SignUpOptionsNewExistingPL20P: {paddingLeft: '20%'},

  SignUpOptionsNewExistingMP5: {marginTop: 5},

  SignUpOptionsNewExistingMT0: {marginTop: 0},

  SUOPMmt40: {marginTop: 40},

  SUOPMbwMwMwPMr: {
    borderWidth: 4,
    minWidth: 300,
    maxWidth: 720,
    padding: 10,
    marginRight: '10%',
  },

  SUOPMf1fDjCaI: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  SUOPMpl10: {paddingLeft: 10},

  SUOPMf1fDmLasjcac: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: '20%',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },

  SUOPMp10: {padding: 10},

  SUOPMmlmrasjcac: {
    marginLeft: '25%',
    marginRight: '25%',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },

  SUOPMf34: {flex: 34},

  SUWF1: {flex: 1},

  SUWFG1: {flexGrow: 1},

  SUWMT80F1: {marginTop: 80, flex: 1},

  SUWBW4P10: {borderWidth: 4, padding: 10},

  SUWFFDJCAIMLPRPRPB: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: '10%',
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },

  SUWPTPBF: {paddingTop: 10, paddingBottom: 10, flex: 0.7},

  SUWFAS: {flex: 0.3, alignSelf: 'flex-end'},

  SUWPLPR5: {paddingLeft: 5, paddingRight: 5},

  SUWBW4: {borderWidth: 4},

  SUWF34: {flex: 34},

  SSIDF1: {flex: 1},

  SSIDMB10: {marginBottom: 10},

  SSIDF8: {flex: 8},

  SSIDF2: {flex: 2},

  supportItemSummaryItemContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 10,
    borderColor: '#6D6E79',
    borderBottomWidth: 1,
  },

  supportItemSummaryFlex1FDc: {flex: 1, flexDirection: 'column'},

  supportItemSummaryFlex1: {flex: 1},

  SupportTextInputWrapper: {
    width: '100%',
    backgroundColor: 'white',
    minHeight: 50,
    paddingLeft: 5,
  },

  reviewInvoiceSummaryMW200AIfe: {
    flex: 6,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },

  reviewInvoiceSummaryMR5: {marginRight: 5},

  SelectProviderFlex1: {
    flex: 1,
  },

  SelectProviderPT: {
    paddingTop: 5,
  },

  CCMf07pl20: {flex: 0.7, paddingLeft: 20},

  CCMf1jcFSfdR: {
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginBottom: 10,
  },

  CCMh40w40mr10: {height: 40, width: 40, marginRight: 10},

  CCMflex1: {flex: 1},

  CCMpb5: {paddingBottom: 5},

  CCMpl10: {paddingLeft: 10},

  InvoiceHeaderDocumentName: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  InvoiceHeaderItem: {fontSize: 15, textDecorationLine: 'underline'},

  InvoiceTextWidth: {
    maxWidth: '90%',
  },

  AddSupportsNextBtnW: {
    width: 150,
  },

  AddSupportsW80: {
    width: 80,
  },

  AddSupportsMB15: {
    marginBottom: 15,
  },
  AddSupportsMT15: {
    marginTop: 15,
  },

  AddSupportsAIFE: {
    alignItems: 'flex-end',
  },

  AddSupportsByFlex1: {
    flex: 1,
  },

  AddSupportsByFlex_5: {
    flex: 0.5,
  },

  AddSupportsAlignItems: {
    alignItems: 'center',
  },

  AddSupportsHeight: {
    height: 100,
  },

  AddSupportsML15: {
    marginLeft: 15,
  },

  AddSupportsMT15: {
    marginTop: 15,
  },

  addSupportTextAlign: {
    textAlign: 'left',
  },

  checkBoxAlign: {
    marginTop: 5,
  },

  supportCrewAlign: {
    padding: 15,
  },

  supportAddCrewNext: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  pannelViewShowHide: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },

  fontWeightBold: {
    fontWeight: 'bold',
  },

  textDecorationLineUnderline: {
    textDecorationLine: 'underline',
  },

  editBtnCrewAccess: {
    width: 70,
    padding: 5,
  },

  addBtnCrewAccess: {
    width: 110,
    padding: 5,
  },

  saveBtnCrewAccess: {
    width: 100,
  },

  crewAccessflexDirection: {
    flex: 1,
    flexDirection: 'row',
  },

  crewAccessItemContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 10,
  },

  crewAccessFlexWrap: {flexWrap: 'wrap'},

  CrewAccessButtonIcon: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
    margin: 15,
  },

  TermsAndConditionsFullPMPT30: {paddingTop: 30},

  addSupportsMB: {
    marginBottom: 50,
  },

  addSupportsML: {
    marginLeft: 10,
  },

  SelectedItemSelectedPadding: {
    padding: 10,
    marginBottom: 5,
  },

  SISelectedPadding: {
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  SISelectedContainer: {
    borderRadius: 18,
    borderWidth: 1,
    margin: 5,
  },
  SIClosePadding: {
    paddingLeft: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 10,
  },
  SICloseIcon: {
    width: 20,
    height: 20,
  },

  SelectedItemClosePadding: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
  },

  addSupportContainer: {
    minHeight: 40,
    padding: 5,
    alignItems: 'flex-end',
  },

  backButtonStyles: {
    marginTop: 20,
    height: 40,
    width: 40,
    resizeMode: 'contain',
    fontSize: 40,
  },

  backButtonCorner: {
    position: 'absolute',
    marginTop: 20,
    marginLeft: 10,
  },

  maxWidth400: {maxWidth: 400},
  maxWidth300: {maxWidth: 300},
  maxWidth500: {maxWidth: 500},
  width500: {width: 500},
  width400: {width: 400},

  maxWidth380: {maxWidth: 380},

  flex2: {
    flex: 2,
  },

  sectionContainer: {
    paddingTop: 10,
  },

  notificationContainer: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
  },

  notificationHeader: {
    flex: 1,
  },

  notificationContent: {
    flex: 2,
  },

  notificationPagination: {
    flex: 3,
  },

  selectTextContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  accessAdditionalInfo: {
    paddingBottom: 5,
    paddingTop: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  crewToggleInfo: {
    paddingBottom: 5,
    paddingTop: 5,
  },

  readOnlyProfileAccess: {
    paddingBottom: 15,
    paddingTop: 15,
    paddingLeft: 10,
    marginRight: 10,
  },

  termsAndConditionsDescription: {
    fontSize: 17,
  },
  termsAndConditions: {
    width: 200,
  },
  termsAndConditionsLine3: {
    fontSize: 17,
    paddingRight: 10,
    textDecorationLine: 'underline',
  },

  selectedDisabilityPadding: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  datePickerBorder: {
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: 'white',
  },

  CrewFormRightColumn: {
    marginLeft: 20,
  },

  listTopBarText: {
    fontSize: 20,
    paddingRight: 10,
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
      android: {
        paddingTop: 0,
      },
    }),
  },
  stHeight: {
    height: 0,
  },

  topBarContainer: {
    flex: 1,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#222538',
  },

  paymentTopBar: {
    flex: 1,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#222538',
  },

  alignTitle: {
    flex: 3.0,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  alignCategory: {
    flex: 1.5,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 23,
  },

  alignDate: {
    flex: 1.5,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 5,
  },

  sortIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },

  // Payments History
  alignHeading1: {
    flex: 1.7,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  alignHeading2: {
    flex: 3,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // paddingRight: 20
    //  justifyContent: 'flex-start',
  },

  alignHeading3: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  alignHeading4: {
    flex: 1.8,
    // justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: 45,
  },

  alignHeading5: {
    flex: 2,
    justifyContent: 'flex-start',
  },

  settingItem: {
    fontSize: 20,
    flex: 1,
    ...Platform.select({
      ios: {
        paddingTop: 4,
      },
      android: {
        paddingTop: 0,
      },
    }),
  },

  autoCompleteInput: {
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    maxHeight: 200,
    width: '100%',
  },

  autoCompletePopOverContainer: {
    position: 'absolute',
    top: 5,
    right: 50,
    flex: 1,
    //alignItems: 'center',
    zIndex: 999,
    minHeight: 200,
    maxHeight: 200
  },

  autoCompleteSearchBorder: {
    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    paddingBottom: 5,
    marginLeft: 5,
    marginRight: 5,
  },

  autoCompleteContiner: {
    zIndex: 100,
    maxHeight: 200,
    marginBottom: 10,
  },

  alignClearButton: {
    marginLeft: 20,
  },

  heightLimit: {
    maxHeight: 200,
  },

  zIndex1: {
    ...Platform.select({
      ios: {
        zIndex: 1,
      },
    }),
  },

  actionButtoniOSpaddinLeft: {
    ...Platform.select({
      ios: {
        paddingLeft: 15,
      },
    }),
  },

  zIndex10: {
    ...Platform.select({
      ios: {
        zIndex: 10,
      },
    }),
  },
  ParticipantOrCrewContainer: {
    flex: 1,
    flexDirection: 'row',
  },

  padding10: {
    padding: 10,
  },

  padding15: {
    padding: 15,
  },

  padding2: {
    padding: 2,
  },

  padding5: {
    padding: 5,
  },

  padding20: {
    padding: 20,
  },

  alignItemsFlexEndMR10: {
    alignItems: 'flex-end',
    marginRight: 10,
  },

  borderRadius5: {
    borderRadius: 5,
  },

  SABarStyles: {height: 20, marginTop: 10, marginBottom: 10},
  SABarStylesUsedRemainingStyles: {marginTop: 10},

  AdhocInvoiceSpentAmountsLine: {
    ...Platform.select({
      ios: {
        borderStyle: 'solid',
      },
      android: {
        borderStyle: 'dotted',
      },
    }),
    borderTopWidth: 1,
    paddingTop: 2,
    marginTop: 20,
  },

  loginSingupFDrMT20: {flexDirection: 'row', marginBottom: 2},

  loginsingupFDPT: {
    flex: 1,
    flexDirection: 'row',
    ...Platform.select({
      ios: {
        paddingTop: 15,
      },
      android: {
        paddingTop: 15,
      },
    }),
  },

  loginsingupmt20: {marginTop: 20},

  loginsingupW150: {minWidth: 150},

  loginsingupf1fdrml50: {flex: 1, flexDirection: 'row', marginTop: '5%'},

  longinsingupml50: {marginLeft: 50},

  loginsingupf15: {flex: 1.5},

  loginsingupf05p20: {
    flex: 0.7,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
  },

  loginsingupf1fdcjcsb: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  loginsingupf34: {flex: 34},

  logingffdacaip: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 10,
  },

  loginsingupwh50: {width: 35, height: 35},

  loginsingupfacaip: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  loginsingupmt20: {marginTop: 20},

  loginsingupfdacaip: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    padding: 10,
  },

  loginsingupwh50ml10: {width: 50, height: 50, marginLeft: 10},

  chooseparticipantorcrew50ml50: {top: 40},

  chooseparticipantorcrew50mr50: {top: 40, zIndex: 1, marginRight: 20},

  chooseparticipantorcrewtm50: {top: -50},

  alignBackButton: {alignItems: 'flex-end', position: 'absolute', top: 20},

  buttonBorderRadius: {
    ...Platform.select({
      ios: {
        overflow: 'hidden',
      },
    }),
  },

  crewcreatememberW100: {width: 100},

  crewcreatememberBW: {borderWidth: 2},

  crewcreatememberaihmrmt: {
    alignItems: 'flex-end',
    height: 70,
    marginRight: 10,
    marginTop: 10,
  },

  creworpartcipant: {alignItems: 'flex-end', height: 50, marginRight: 10},

  forgotPWf15: {flex: 1.5},

  forgotPWmprplptpb: {
    marginLeft: '12%',
    paddingRight: 30,
    paddingLeft: 10,
    paddingTop: 20,
    paddingBottom: 10,
  },

  forgotPWMT40: {
    marginTop: 40,
    ...Platform.select({
      ios: {
        marginLeft: 10,
      },
    }),
  },

  crewcreatemembermlpr: {
    marginLeft: '15%',
    paddingRight: 30,
  },

  crewcreatememberF60: {flex: 60},

  crewcreatememberf1fdrml50: {flex: 1, flexDirection: 'row'},

  crewcreatememberml50: {marginLeft: '25%'},

  nextButtonPb: {
    ...Platform.select({
      ios: {
        paddingBottom: 5,
      },
      android: {
        paddingBottom: 10,
      },
    }),
  },

  skipButtonwh30: {
    fontSize: 25,
    color: BrandColors.WHITE,
  },

  loginSignUpHeadingPadding: {padding: 3},

  loginSignupButtonWidth: {
    flex: 1,
    marginTop: 5,
    marginBottom: 15,
    marginRight: 5,
    marginLeft: 5,
  },

  forgotpasswordf1fdrml50: {
    flex: 1,
    flexDirection: 'row',
    ...Platform.select({
      ios: {
        marginLeft: 50,
      },
      android: {
        marginLeft: '30%',
      },
    }),
  },

  forgotpasswordBtnpadding: {
    paddingLeft: 10,
    paddingRight: 10,
  },

  forgotpasswordMarginRight100: {
    marginRight: 100,
  },

  inputFieldFont20: {
    backgroundColor: 'white',
    fontSize: 20,
  },

  passwrodForgotButtonWidth: {
    width: 230,
    marginTop: 20,
  },

  addSupportsML2: {
    marginLeft: 2,
  },

  addSupportsMR10: {
    marginRight: 10,
  },

  addsupportLinkButton: {
    fontSize: 17,
    paddingRight: 10,
    textDecorationLine: 'underline',
    paddingBottom: 20,
  },

  height80: {height: 80},

  goalsAddBtnTxt: {
    ...Platform.select({
      ios: {
        fontWeight: '500',
      },
    }),
  },

  iosGreyText: {
    ...Platform.select({
      ios: {
        color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
      },
    }),
  },

  iosTextfontWeight500: {
    ...Platform.select({
      ios: {
        fontWeight: '500',
      },
    }),
  },

  TrackerSplashContentbuttonContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    borderRadius: 0,
    alignItems: 'stretch',
  },

  PlatformMarginR15: {
    ...Platform.select({
      ios: {
        marginRight: 15,
      },
      android: {
        marginRight: 10,
      },
    }),
  },

  // Start - Guide Me Popup Modal and Carousel Related Styles
  carouselContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 10,
  },

  popUpButton: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },

  popUpOkButton: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },

  popUpButtonText: {
    fontSize: 18,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  popUpContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  popUpContentContainer: {
    flex: 6.5,
    borderRadius: 30,
    marginTop: 10,
  },

  popUpModal: {
    flex: 1,
    backgroundColor: 'white',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    zIndex: 300,
  },

  popUpModalAlign: {
    top: '25%',
    left: '25%',
    right: '25%',
  },

  popUpModalMobAlign: {
    top: '30%',
    left: '10%',
    right: '10%',
    bottom: '10%',
  },

  popUpAlertAlign: {
    top: '30%',
    bottom: '10%',
    left: '10%',
    right: '10%',
    minWdith: 600,
  },

  popUpModalAlignStepTwo: {
    top: '25%',
    left: '25%',
    right: '25%',
  },

  popUpModalAlignStepThree: {
    top: '25%',
    left: '25%',
    right: '25%',
  },

  popUpModalAlignStepFour: {
    top: '25%',
    left: '25%',
    right: '25%',
  },

  popUpModalAlignStepFive: {
    top: '25%',
    left: '25%',
    right: '25%',
  },

  popUpModalAlignMobile: {
    top: '15%',
    bottom: '20%',
    left: '10%',
    right: '10%',
  },

  popUpModalAlignStepTwoMobile: {
    top: '15%',
    bottom: '20%',
    left: '10%',
    right: '10%',
  },

  popUpModalAlignStepThreeMobile: {
    top: '15%',
    bottom: '20%',
    left: '10%',
    right: '10%',
  },

  popUpModalAlignStepFourMobile: {
    top: '15%',
    bottom: '20%',
    left: '10%',
    right: '10%',
  },

  popUpModalAlignStepFiveMobile: {
    top: '15%',
    bottom: '20%',
    left: '10%',
    right: '10%',
  },

  popUpModalAlert: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: 30,
  },

  popUptextAlign: {
    justifyContent: 'center',
    textAlign: 'center',
  },

  popTitleText: {
    fontSize: moderateScale(18, 0.2),
    marginBottom: 10,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  popGuideMeTitleText: {
    fontSize: 15,
    marginBottom: 10,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  WebViewStyle: {
    flex: 1,
    flexWrap: 'wrap',
    backgroundColor: 'transparent',
  },

  dividerStyle: {
    paddingTop: 10,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
    flex: 1,
  },
  topdividerStyle: {
    paddingTop: 10,
    borderBottomColor: 'grey',
    borderTopWidth: 1,
    flex: 1,
  },

  dividerStylePopupModal: {
    paddingTop: 10,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
    flex: 1,
    marginBottom: 10,
  },

  emptyDot: {
    width: 10,
    height: 10,
    backgroundColor: '#d3d3d3',
    borderRadius: 15,
    alignSelf: 'center',
    margin: 5,
  },
  filledDot: {
    width: 10,
    height: 10,
    backgroundColor: '#533fab',
    borderRadius: 15,
    alignSelf: 'center',
    margin: 5,
  },

  dotStyle: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  // End - Guide Me Popup Modal and Carousel Related Styles

  MultiSelectDQButtonText: {
    alignSelf: 'flex-end',
    paddingRight: 10,
    paddingTop: 10,
    ...Platform.select({
      ios: {
        fontSize: 25,
      },
      android: {
        fontSize: 20,
        paddingBottom: 5,
      },
    }),
  },

  androidPaddingBottom0: {
    ...Platform.select({
      android: {
        paddingBottom: 0,
      },
    }),
  },

  PlatformMarginTop20: {
    ...Platform.select({
      ios: {
        marginTop: 15,
      },
      android: {
        marginTop: 20,
      },
    }),
  },

  sideMenuItemContainer: {
    flex: 1,
    minHeight: 58,
    paddingLeft: 5,
  },

  loggedUserContainer: {
    flex: 1,
    minHeight: 60,
    marginBottom: 5,
  },

  sideMenuIndicator: {
    fontSize: 35,
    width: 36,
    height: 36,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  sideMenuIcon: {
    fontSize: 20,
    width: 21,
    height: 21,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  sideMenuIconDisabled: {
    fontSize: 20,
    width: 21,
    height: 21,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
  },

  disMenuItemDisabled: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 8,
    paddingTop: 8,
  },

  mainMenuIcon: {
    fontSize: 30,
    width: 29,
    height: 29,
    resizeMode: 'contain',
    color: BrandColors.WHITE,
  },

  mainMenuIconSelected: {
    fontSize: 30,
    width: 29,
    height: 29,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  sideMenuLoggedUserIcon: {
    width: 50,
    height: 50,
    fontSize: 50,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  loginButton: {
    width: 50,
    height: 50,
    fontSize: 50,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  sideBarDockButtonIcon: {
    width: 15,
    height: 45,
    fontSize: 40,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  sideMenuUserContainer: {
    flex: 1,
    minWidth: 100,
    alignSelf: 'center',
    paddingLeft: 20,
    alignItems: 'flex-start',
  },

  sideMenuLoggedIcon: {
    minWidth: 30,
    maxWidth: 40,
    alignSelf: 'center',
  },

  sideMenuTextContainer: {
    fontSize: 18,
    flex: 1,
    paddingLeft: 20,
    color: '#222538',
  },

  sideMenuLine: {
    ...Platform.select({
      ios: {
        borderStyle: 'solid',
      },
      android: {
        borderStyle: 'dotted',
      },
    }),
    borderTopWidth: 1,
    paddingTop: 2,
  },

  sideMenuContainer: {
    marginBottom: 5,
    marginTop: 10,
    marginRight: 5,
    marginLeft: 10,
    flex: 1,
    backgroundColor: 'white',
  },

  sideMenuContainerMobile: {
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 10,
    paddingTop: 20,
    paddingRight: 10,
  },

  drawerContainer: {
    padding: 20,
    flex: 1,
  },

  PlatformModalTextInputWrapper: {
    width: '90%',
    backgroundColor: 'white',
    height: 50,
    paddingLeft: 10,
  },

  borderWidth0: {
    borderWidth: 0,
  },

  PickerBackgroundSingleSelect: {
    width: '100%',
    ...Platform.select({
      ios: {},
      android: {
        borderWidth: 1,
      },
    }),
  },

  PlatformPickerBackgroundborderWidth: {
    ...Platform.select({
      ios: {},
      android: {
        borderWidth: 1,
      },
    }),
  },

  PlatformButtonPb: {
    ...Platform.select({
      android: {
        paddingBottom: 10,
      },
    }),
  },

  marginTop35: {
    marginTop: 35,
  },

  pickerColorWrapper: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },
  pickerColorWrapperGray: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  pickerBorderRadius: {
    borderRadius: 10,
  },

  marginLeft2: {
    marginLeft: 2,
  },

  PlatformPaddingVertical80: {
    ...Platform.select({
      ios: {
        paddingVertical: 80,
      },
      android: {
        paddingVertical: '5%',
      },
    }),
  },

  alignItemsCenter: {
    alignItems: 'center',
  },

  alignItemsStart: {
    alignItems: 'start',
  },

  alignItemsTop: {
    alignItems: 'flex-start',
  },

  myProfileAvatarName: {
    paddingTop: 10,
  },

  CPCalignItemsCentre: {
    ...Platform.select({
      ios: {
        alignItems: 'center',
      },
    }),
  },

  CPCjustifyContentCentre: {
    ...Platform.select({
      ios: {
        justifyContent: 'center',
      },
    }),
  },

  PlatformPaddingBottom10: {
    ...Platform.select({
      ios: {
        paddingBottom: 10,
      },
      android: {
        paddingBottom: 50,
      },
    }),
  },

  logoWH50: {
    width: 50,
    height: 50,
  },

  SignUplogoContainer: {
    marginRight: 15,
    marginTop: 0,
  },

  logoContainer: {
    marginRight: 20,
    marginTop: 10,
  },

  chooseparticipantzIndex03: {
    ...Platform.select({
      ios: {
        zIndex: 3,
      },
    }),
  },

  chooseparticipantorcrewt20l10: {
    position: 'absolute',
    top: 20,
    right: 20,
  },

  crewcreatememberl10: {
    top: 0,
    right: 20,
  },

  loginsingupLogowh60: {width: 60, height: 60},

  Padding30: {padding: 30},

  headerWrapper: {
    height: 80,
    maxHeight: 80,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  },

  loginsingupMl20: {marginLeft: '20%'},

  loginsingupmt5mb10: {
    marginTop: '5%',
    marginBottom: '10%',
  },

  width80present: {
    width: '80%',
  },
  endContent: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  inputFieldFont17: {
    backgroundColor: 'white',
    fontSize: 17,
  },

  choosePCmr5p: {
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: '15%',
    padding: 5,
  },

  chooseCrewImage: {width: 100, height: 100, borderRadius: 50},

  borderRadius10: {
    borderRadius: 10,
  },

  borderRadius15: {
    borderRadius: 15,
  },

  dropDownArrow: {
    position: 'absolute',
    top: 22,
    right: 10,
    width: 10,
    height: 10,
    alignSelf: 'flex-end',
  },

  flex1EndContent: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  // responsive design (to do)
  rpMarginRight5: {
    marginRight: scale(5),
  },

  rpMarginLeft: {
    marginLeft: scale(10),
  },

  rpMarginLeft20: {
    marginLeft: scale(20),
  },

  rpMarginRight20: {
    marginRight: scale(20),
  },

  rpMarginRight40: {
    marginRight: scale(40),
  },

  rpMarginRight35: {
    marginRight: scale(35),
  },

  rpMarginRight: {
    marginRight: scale(10),
  },

  rpMarginTop4p: {
    marginTop: scale(4),
  },

  rpMarginTop5p: {
    marginTop: scale(5),
  },

  rpMarginTop10p: {
    marginTop: scale(10),
  },

  rpMarginTop20p: {
    marginTop: 20,
  },

  rpMarginTop25p: {
    marginTop: scale(25),
  },

  rpMarginBottom5p: {
    marginBottom: scale(5),
  },

  rpMarginBottom10p: {
    marginBottom: scale(10),
  },

  rpMarginBottom20p: {
    marginBottom: scale(20),
  },

  rpMarginBottom40p: {
    marginBottom: scale(40),
  },

  rpMargin5: {margin: moderateScale(5)},

  rpMarginLeft70: {marginLeft: scale(70)},

  rpMarginLeft60: {marginLeft: scale(60)},

  rpfont10: {
    fontSize: moderateScale(9.5),
  },

  rpfont11: {
    fontSize: moderateScale(10.5),
  },

  rpfont12: {
    fontSize: moderateScale(11.2),
  },

  rpfont13: {
    fontSize: moderateScale(12.2),
  },

  rpfont15: {
    fontSize: 15,
  },

  rpfont18: {
    fontSize: moderateScale(18, 0.2),
  },

  rpfont20: {
    fontSize: moderateScale(20, 0.1),
  },

  rpfont22MS: {
    fontSize: moderateScale(20.5),
  },

  rpfont19: {
    fontSize: moderateScale(19),
  },

  rpfont22: {
    fontSize: scale(20.5),
  },

  rpfont16: {
    fontSize: scale(16),
  },

  rpfont25: {
    fontSize: moderateScale(25, 0.1),
  },

  rpfont27: {
    fontSize: moderateScale(27, 0.1),
  },

  rpfont40: {
    fontSize: moderateScale(35, 0.1),
  },

  rpWidth200: {
    width: scale(200),
  },

  rpWidth250: {
    width: scale(250),
  },

  rpWidth270: {
    width: '80%',
    maxWidth: 300,
  },

  rpWidth300: {
    width: scale(300),
  },

  rploginSignupButtonWidth: {
    maxWidth: 250,
  },

  rpCrewParticipantButtonWidthHeight: {
    height: scale(45),
    ...Platform.select({
      ios: {
        maxHeight: scale(120),
      },
      android: {
        maxHeight: scale(140),
      },
    }),
  },

  rpCrewParticipantButtonWidthHeightlandscape: {
    minHeight: scale(100),
  },

  rpChooseCrewImage: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
  rpChooseWelcomeOptionImage: {
    width: 100,
    height: 100,
    paddingTop: 20,
  },

  standardRPfontSize: {
    fontSize: moderateScale(11.2),
  },

  ParticipantOrCrewButtonContainer: {
    height: 110,
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 5,
  },
  ParticipantOrCrewButtonHalfHeightContainer: {
    padding: scale(5),
    height: 80,
  },

  rpCreworpartcipant: {alignItems: 'flex-end', height: 50, marginTop: 10},

  termsOfuseBox: {marginRight: 10},

  secondHeaderTop: {top: -4},

  rploginsingupBtnWidth: {minWidth: moderateScale(130)},

  summaryTileHeaders: {
    fontSize: 20,
  },

  notificationText: {
    fontSize: 16,
  },

  notificationRowText: {
    fontSize: 16,
  },

  emptyNotificationsText: {
    fontSize: 16,
  },

  paginationActionText: {
    fontSize: 15,
  },

  approvalEmptyMsg: {
    fontSize: 15,
    fontFamily: 'arial',
  },

  summaryCardText: {
    fontSize: 18,
  },

  //= =================================== Responsive Design Styles ====================================
  // variation of main style - add descriptive suffix to main class (camelCase)
  // child element of the main component - add suffix with a underscore to main class

  //* *************************
  //* CONTAINERS
  //* *************************

  containerLearnMore: {
    paddingLeft: 35,
    marginRight: 5,
    paddingTop: 20,
  },

  containerImportantNotification: {
    flexDirection: 'row',
    paddingTop: 20,
  },

  containerRoundCorners: {
    flex: 1,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    borderWidth: 1,
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },

  containerPopup: {
    flex: 1,
    paddingTop: 20,
  },

  containerRoundCornersBorderless: {
    flex: 1,
    borderRadius: 10,
    padding: 10,
    paddingRight: 30,
    marginBottom: 10,
    justifyContent: 'space-between',
  },

  containerCons: {
    paddingTop: 15,
    borderTopColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    borderTopWidth: 1,
  },

  containerProConBullets: {
    paddingTop: 10,
    paddingRight: 5,
    paddingBottom: 0,
    paddingLeft: 0,
  },

  containerProConBullets_Points: {
    width: 6,
    height: 6,
    marginLeft: 5,
    marginRight: 10,
    marginTop: 10,
    backgroundColor: 'black',
    borderRadius: 3,
  },

  containerProsCons: {
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 10,
  },

  containerThumbIcon: {
    paddingTop: 5,
    paddingBottom: 5,
  },

  containerBasicPopUp: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 10,
  },

  containerPMOptions: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingRight: 1,
    flex: 1,
  },

  containerPMPopUpTablet: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '30%',
    width: 650,
    height: 350,
    borderRadius: 20,
  },

  containerPMPopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '10%',
    width: '90%',
    height: 400,
    borderRadius: 20,
  },

  containerBudgetReleasePopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '30%',
    width: '90%',
    borderRadius: 20,
  },

  containerBudgetReleasePopUpTablet: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '30%',
    borderRadius: 20,
  },

  containerPMPopUpWeb: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '30%',
    borderRadius: 20,
    borderWidth: 0,
    paddingTop: 10,
    maxWidth: 715,
    maxHeight: 420,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    borderWidth: 1,
  },

  containerPMPopUpWebMobile: {
    top: '25%',
    maxHeight: 420,
    maxWidth: 300,
    margin: 0,
  },

  containerPMPopUpRadioGroup: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 30,
  },

  containerGoalHeadContent: {
    flex: 1,
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerGoalContent: {
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },

  containerGoalFilters: {
    marginTop: 10,
    minWidth: '65%',
    paddingLeft: 10,
    bottom: 0,
    marginLeft: '30%',
  },

  containerInstructionPopUpTablet: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '10%',
    bottom: '10%',
    width: 520,
    height: 340,
    borderRadius: 20,
    borderWidth: 0,
    paddingTop: 50,
    paddingBottom: 50,
  },
  containerInstructionPopUpWebLarge: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '20%',
    maxWidth: 715,
    maxHeight: 420,
    borderRadius: 20,
    borderWidth: 0,
    paddingTop: 20,
    paddingBottom: 20,
    margin: 0,
  },

  containerInstructionPopUpWebMobileLarge: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '25%',
    maxWidth: '90%',
    maxHeight: 500,
    borderRadius: 20,
    borderWidth: 0,
    paddingTop: 15,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },

  containerAlertWeb: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '10%',
    borderRadius: 15,
    borderWidth: 0,
    paddingTop: 20,
    paddingBottom: 20,
    left: '18%',
    right: '18%',
    margin: 0,
  },

  containerAlertMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '10%',
    borderRadius: 15,
    borderWidth: 0,
    paddingTop: 20,
    paddingBottom: 20,
    left: '10%',
    right: '10%',
    margin: 0,
  },

  containerInstructionPopUpWeb: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '30%',
    borderRadius: 20,
    borderWidth: 0,
    paddingTop: 10,
    maxWidth: 715,
    maxHeight: 420,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },

  containerPopupAlign: {
    right: 0,
    left: 0,
  },

  popUpWebMobileTop: {
    top: '25%',
  },

  containerPopupWebMobile: {
    top: '45%',
    maxHeight: 420,
    maxWidth: 300,
    margin: 0,
  },

  containerWhyInvitePopUpTablet: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '20%',
    width: 500,
    height: 465,
    borderRadius: 20,
    borderWidth: 0,
    paddingTop: 20,
  },

  containerAdhocInvoiveSubmissionPopUpTablet: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '30%',
    width: 520,
    minHeight: 230,
    borderRadius: 20,
    paddingTop: 50,
  },

  containerAdhocInvoiveSubmissionPopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '10%',
    width: '90%',
    minHeight: 280,
    borderRadius: 20,
  },

  containerChangePasswordPopUpTablet: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '30%',
    width: 550,
    height: 350,
    borderRadius: 20,
    paddingTop: 50,
  },

  containerBudgetPlanReady: {
    backgroundColor: 'white',
  },

  containerBudgetOnboarding: {
    backgroundColor: 'white',
  },

  containerBudgetPopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '10%',
    width: 300,
    height: 330,
    borderRadius: 20,
  },

  containerInstructionPopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '10%',
    width: '90%',
    height: 330,
    borderRadius: 20,
    borderWidth: 0,
  },

  containerWhyInvitePopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '10%',
    width: '90%',
    height: 330,
    paddingTop: 15,
    borderRadius: 20,
    borderWidth: 0,
  },

  containerSAApprovedPopUp: {
    backgroundColor: 'white',
    position: 'absolute',
    width: 300,
    bottom: '30%',
    borderRadius: 20,
    borderWidth: 0,
    paddingRight: 10,
    paddingLeft: 10,
  },

  containerRatingApprovedPopUp: {
    backgroundColor: 'white',
    position: 'absolute',
    width: 300,
    bottom: '15%',
    borderRadius: 20,
    borderWidth: 0,
    paddingRight: 10,
    paddingLeft: 10,
  },
  containerRatingApprovedPopUpNoWidth: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '15%',
    borderRadius: 20,
    borderWidth: 0,
    paddingRight: 10,
    paddingLeft: 10,
  },

  containerBudgetSummary: {
    paddingTop: '5%',
    paddingRight: '1%',
    paddingLeft: '1%',
    paddingBottom: '2%',
  },

  containerInstructionDevider: {
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
  },

  containerSupportNavigation: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  containerSupportSummaryDetail: {
    flex: 1,
    marginBottom: 7,
    marginLeft: 20,
    marginTop: 10,
  },

  containerSupportTileEdit: {
    paddingLeft: 15,
    marginTop: 10,
  },

  containerSupport_AddDetailTitle: {
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  // This is a common style class which used across multiple screens
  // to display summary of content.
  // Don't change directly here if needs any custom styles changes
  containerSummaryCard: {
    flexDirection: 'column',
    borderRadius: 10,
    padding: 10,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  budgetsContainerSummaryCard: {
    minWidth: 310,
    minHeight: 180,
    flexDirection: 'column',
    borderRadius: 10,
    padding: 15,
    marginBottom: 10,
    marginRight: 10,
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  containerSummarySection: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  containerBudgetApprovalCard_Image: {
    margin: 5,
    width: 25,
    height: 25,
    alignItems: 'center',
  },

  containerBudgetApprovalCard_Image_Web: {
    margin: 5,
    width: 40,
    height: 40,
    alignItems: 'center',
  },

  containerBudgetApprovalCard_ImageDate: {
    marginTop: 5,
    marginRight: 5,
    marginBottom: 5,
    width: 16,
    height: 16,
    textAlign: 'center',
  },

  containerClock: {
    margin: 5,
    marginRight: 15,
    marginTop: 0,
    width: 20,
    height: 20,
    alignItems: 'center',
    resizeMode: 'stretch',
  },

  reviewAdhocViewImageData: {
    marginRight: 5,
    marginLeft: 5,
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
  reviewAdhocClockImageData: {
    marginRight: 5,
    width: 20,
    height: 20,
  },

  containerInvoiceDetails: {
    paddingTop: '3%',
  },

  containerInvoiceDetails_Buttons: {
    paddingRight: '5%',
    paddingLeft: '5%',
  },

  containerInvoice_Accordian: {
    paddingTop: 5,
    paddingBottom: 5,
  },

  containerInvoice_AccordianHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderStyle: 'solid',
    borderTopWidth: 2,
    paddingBottom: 2,
    marginBottom: 7,
  },

  containerPayment_AccordianHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderStyle: 'solid',
    borderBottomWidth: 2,
    paddingBottom: 2,
    marginBottom: 7,
  },

  containerList_Item: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: 10,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  containerList_LastItem: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },

  containerList_ItemFigureTitle: {
    flex: 1,
    alignItems: 'center',
  },

  containerInvoice_AccordianButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
    marginTop: 10,
  },

  containerInvoice_Cards: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  containerInvoice_CardsWeb: {
    paddingLeft: 10,
    paddingRight: 10,
  },

  containerInvoice_Summary: {
    paddingRight: '4%',
    paddingLeft: '4%',
  },

  containerSA_Summary: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  containerSupport_Options: {
    flex: 1,
    paddingLeft: 45,
  },

  containerSupport_AddButton: {
    minHeight: 40,
    padding: 5,
    alignItems: 'flex-end',
  },

  containerGreyTextWrapper: {
    flexDirection: 'row',
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  containerGreyTextPayment: {
    flexDirection: 'row',
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    maxWidth: 160,
    minWidth: 0,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  containerInvoiceIdTextPayment: {
    maxWidth: 160,
    minWidth: 0,
    paddingRight: 10,
    marginRight: 0,
  },

  containerRedTextWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  containerPurpleTextWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  ServiceAgreementStatusContainerGreyTextWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 15,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  containerSimpleSpendingBar: {
    flexDirection: 'row',
    flex: 1,
    borderRadius: 15,
    borderWidth: 2,
  },

  containerSimpleSpendingBar_Section: {
    borderRadius: 15,
    padding: 2,
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: 10,
  },

  containerPhotoTileImage_Summary: {
    width: 120,
    height: 120,
  },

  containerPhotoTileMyProfile: {
    width: 45,
    height: 45,
  },

  containerPhotoTileMyProfileNoPadding: {
    height: 67,
    width: 67,
  },

  containerPieChartKeys: {
    borderWidth: 1,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    width: 13,
  },

  containerPieChart: {
    paddingLeft: 5,
    marginLeft: 5,
  },

  containerThreeColumn: {
    flex: 0.33,
  },

  containerTwoColumn: {
    flex: 0.5,
  },

  containerOneColumn: {
    flex: 1,
  },

  containerPopMessage: {
    height: '100%',
    width: 200,
    zIndex: 999,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },

  containerPopMessageTablet: {
    height: '100%',
    width: 300,
    zIndex: 999,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },

  containerPopMessageWeb: {
    height: 770,
    width: 300,
    zIndex: 999,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    position: 'absolute',
    right: 0,
  },

  containerPopMessageWebMobile: {
    height: 525,
    width: 200,
    zIndex: 999,
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    position: 'absolute',
    right: 0,
  },

  containerOnBoardingBanner: {
    flex: 1,
    margin: 5,
    paddingLeft: 5,
    flexDirection: 'row',
    borderRadius: 15,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  containerBudgetLockedBanner: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 15,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  containerOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: -100,
  },

  containerOverlayWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },

  containerPopupWrapper: {
    position: 'absolute',
    zIndex: 300,
  },

  containerGuideMeText: {
    flex: 1,
    flexWrap: 'wrap',
  },

  containerSideMenuNeedHelp: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 20,
  },

  containerGoalDetails: {
    padding: 10,
    borderRadius: 8,
    elevation: 5,
    shadowOffset: {width: 0, height: 2},
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  containerCompletedGoalDetails: {
    padding: 10,
    marginBottom: 20,
    borderRadius: 8,
    paddingTop: 15,
    elevation: 5,
    shadowOffset: {width: 0, height: 2},
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  containerPhotoTileGoalImage: {
    width: 110,
    height: 110,
    marginLeft: 0,
    marginRight: 0,
  },

  containerEmptyGoals: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 25,
  },

  containerGoalActionButton: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },

  containerGoalProgressTile: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTopColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderTopWidth: 1,
  },

  borderTopWidth0: {
    borderTopWidth: 0,
  },

  containerGoalProgressOptionsMobile: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 5,
    marginBottom: 10,
  },

  containerGoalProgressOptions: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 5,
    marginBottom: 10,
  },

  containerProgressOption: {
    height: 85,
    width: 85,
    borderRadius: 8,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    elevation: 5,
    shadowOffset: {width: 0, height: 2},
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  containerRoundMemberPhoto: {
    height: 30,
    width: 30,
    borderRadius: 20,
    shadowOffset: {width: 3, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  containerSideMenu: {
    backgroundColor: '#E0292E',
    borderRadius: '8px',
    margin: '10px',
    paddingLeft: '20px',
    fontFamily: 'arial',
  },

  containerHeaderOptions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  containerMenuBar: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    paddingRight: 10,
  },

  containerSelectedMember: {
    margin: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },

  containerPopover: {
    flex: 1,
    position: 'absolute',
    right: 0,
    width: '70%',
    zIndex: 100,
    maxWidth: 300,
  },

  containerPopoverMenu: {
    flex: 1,
    position: 'absolute',
    right: 0,
    top: 139,
    width: '70%',
    zIndex: 100,
    maxWidth: 300,
  },

  containerMenuItem: {
    borderRadius: 8,
    margin: 10,
  },

  conatinerMenu: {
    flex: 1,
    margin: 10,
    borderWidth: 2,
    borderRadius: 15,
    display: 'block',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
  },

  containerOverlayPopover: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: '#34343444',
  },

  containerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },

  containerHeaderItems: {
    textAlignVertical: 'center',
    marginTop: 15,
  },

  containerMainMenuOption: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },

  containerSubMenuOption: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },

  containerMenuOptionMobile: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },

  containerSearchResults: {
    flexWrap: 'wrap',
    minHeight: 200,
  },

  containerTerms: {
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 10,
  },

  //* *************************
  //*   TEXT INPUT
  //* *************************

  textInputWrapper: {
    width: '80%',
    marginLeft: '10%',
  },

  textInputMultuline: {
    minHeight: 80,
  },

  textInputContainer: {
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 20,
  },

  textInput: {
    backgroundColor: 'white',
    width: '100%',
    height: 50,
    paddingLeft: 10,
  },

  textInputContainerGoals: {
    width: '100%',
    backgroundColor: 'white',
    minHeight: 100,
    paddingLeft: 10,
    textAlignVertical: 'top',
    marginTop: 10,
    marginBottom: 10,
  },

  textInputContainerStep: {
    width: '100%',
    backgroundColor: 'white',
    minHeight: 50,
    paddingLeft: 10,
    textAlignVertical: 'top',
    marginTop: 10,
    marginBottom: 10,
  },

  text_SupportContainer: {
    fontSize: 15,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    marginRight: 10,
  },

  textInputWrapper_Support: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: 10,
    width: '100%',
  },
  textInputPhotoCaption: {
    backgroundColor: 'white',
    height: 30,
    width: 120,
  },

  textInputErrorBorder: {
    borderWidth: 3,
  },

  textInputAddProvider: {
    fontFamily: 'arial',
    fontWeight: '400',
    fontSize: 15,
  },

  //* *************************
  //*   HEADER
  //* *************************

  header: {
    flex: 1,
    flexDirection: 'row',
    height: 80,
    maxHeight: 80,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
  },

  header_leftContent: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  header_rightContent: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  //* *************************
  //*   SCROLL VIEW
  //* *************************

  scrollView: {
    flex: 1,
  },

  scrollView_container: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  scrollView_subContent: {
    flex: 1,
    width: moderateScale(300, 0.3),
  },

  scrollView_subContentList: {
    flex: 1,
    width: moderateScale(300, 0.4),
    paddingBottom: 20,
  },

  scrollView_subContentCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  scrollView_subContentLeft: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  scrollViewGoal: {
    flex: 1,
    maxHeight: height * 0.5,
  },

  //* *************************
  //*   TITLE
  //* *************************

  title: {
    flex: 1,
    marginTop: scale(25),
    marginBottom: scale(25),
  },

  title_headerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },

  title_headerText: {
    padding: 10,
    fontSize: 22,
    fontWeight: 'bold',
  },

  title_popOverContainer: {
    position: 'absolute',
    bottom: -3,
    right: 50,
    flex: 1,
    alignItems: 'center',
    zIndex: 999,
  },
  title_popOverTextContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    minWidth: 110,
    paddingRight: 15,
    borderRadius: 10,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },
  title_subHeaderContainer: {
    flex: 1,
    marginLeft: 50,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    zIndex: 1,
    top: -4,
  },

  title_subHeaderText: {
    textAlign: 'center',
    borderWidth: 4,
    borderColor: 'white',
    padding: 10,
    fontSize: 22,
    fontWeight: 'bold',
  },

  title_wrapperHeaderContainer: {
    marginRight: 15,
    ...Platform.select({
      ios: {
        marginTop: 20,
      },
      android: {},
    }),
  },

  title_wrapperHeaderPadding: {
    paddingLeft: 10,
  },

  title_wrapperHeaderContainerSmall: {
    marginRight: 10,
    ...Platform.select({
      ios: {
        marginTop: isIphoneX() ? 35 : 15,
      },
      android: {},
    }),
  },

  title_ListContainerSemiBold: {
    fontSize: 15,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    marginRight: 10,
    marginBottom: 5,
  },

  title_InvoiceListWebMobile: {
    fontSize: 15,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    marginRight: 10,
  },

  title_ListContainer: {
    fontSize: 15,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    marginRight: 10,
    whiteSpace: 'preWrap',
  },

  title_ListContainerSmall: {
    fontSize: 15,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    marginRight: 10,
  },

  title_SupportQuestions: {
    fontSize: 18,
    color: BrandColors.CHARCOAL,
    marginBottom: 10,
  },

  //* *************************
  //*   LIST
  //* *************************

  listContent: {
    flex: 1,
    width: '80%',
    flexDirection: 'row',
  },

  listContentPasswordChallenge: {
    flex: 1,
    width: '80%',
    flexDirection: 'row',
    paddingBottom: 10,
  },

  listContent_textNumber: {
    fontWeight: '800',
    fontSize: moderateScale(20, 0.2),
  },

  listContent_text: {
    paddingTop: moderateScale(8, 0.1),
    paddingLeft: 5,
    fontSize: moderateScale(11.2),
  },

  listContent_text: {
    paddingTop: moderateScale(8, 0.3),
    paddingLeft: 5,
  },

  //* *************************
  //*   IMAGE
  //* *************************

  image_40_40: {
    width: 40,
    height: 40,
    fontSize: 38,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  image_35_35: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  image_25_25: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },

  imageContainer: {
    flex: 1,
    height: height * 0.25,
    width: width * 0.53,
    alignSelf: 'flex-start',
  },

  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    alignSelf: 'flex-start',
  },

  imageNoApprovals: {
    width: 40,
    height: 40,
    marginBottom: 20,
  },

  imageContainerPasswordReset: {
    flex: 1,
    ...Platform.select({
      ios: {
        width: scale(140),
        height: scale(70),
      },
      android: {
        width: scale(125),
        height: scale(60),
      },
    }),
    alignSelf: 'flex-start',
  },

  imageContainerGoalAdd: {
    flex: 1,
    minHeight: 90,
    paddingBottom: 10,
  },

  imageGoalAdd: {
    width: moderateScale(100, 0.5),
    height: moderateScale(30, 0.5),
    resizeMode: 'cover',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: 10,
    left: 0,
  },

  budgetImage: {
    width: 40,
    height: 40,
  },

  budgetLockedImage: {
    width: 30,
    height: 30,
  },

  imageAvatarPlanReady: {
    overflow: 'visible',
    marginLeft: 10,
    landscape: {
      height: scale(110, 1),
      width: scale(110, 1),
    },
    portrait: {
      height: scale(100, 1),
      width: scale(100, 1),
    },
  },

  imageAvatarPlanReadyMobile: {
    height: scale(100, 1),
    width: scale(100, 1),
    overflow: 'visible',
  },

  imageAvatarOnBoarding: {
    position: 'absolute',
    bottom: 0,
    overflow: 'visible',
    height: 325,
    width: 150,
  },

  imageAvatarOnBoardingMobile: {
    height: 220,
    width: 100,
    position: 'absolute',
    bottom: 0,
    right: 0,
    overflow: 'visible',
    resizeMode: 'contain',
  },

  imageAvatarOnBoardingMyProfile: {
    height: 190,
    width: 85,
    marginTop: 20,
    resizeMode: 'contain',
  },

  imageAvatarOnBoardingMyProfileMobile: {
    height: 165,
    width: 75,
    resizeMode: 'contain',
  },

  iconCloseWhite: {
    fontSize: 25,
    color: BrandColors.WHITE,
  },

  infoIcon: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
  },

  imageBudgetPlaceholder: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },

  imageBudgetPlaceholderWeb: {
    width: '100%',
    height: 650,
  },

  imageBudgetPlaceholderWebMobile: {
    width: '104%',
    height: 500,
  },

  imageGuideMe: {
    width: 100,
    height: 105,
  },

  imageGuideMeStepThree: {
    width: 100,
    height: 105,
  },

  imageGuideMeStepTwo: {
    width: 60,
    height: 150,
  },

  imageGuideMeStepFour: {
    width: 33,
    height: 139,
  },

  imageGuideMeStepFive: {
    width: 115,
    height: 120,
  },

  imageGuideMeStepSix: {
    width: 100,
    height: 140,
  },

  imageGuideMeManagedStepTwo: {
    width: 115,
    height: 55,
  },

  imageGuideMeBrandIcon: {
    width: 60,
    height: 60,
    marginTop: 10,
    marginBottom: 10,
  },

  imageGoalCompleteAvatar: {
    width: 100,
    height: 120,
    marginRight: 10,
  },

  imageGoalCompleteText: {
    width: 110,
    height: 110,
    marginLeft: 10,
  },

  imageGuideMeStyle: {
    width: 38,
    height: 160,
  },

  imageGuideMeYooralla: {
    width: 80,
    height: 120,
  },

  imageBrandLogoText: {
    width: '120px',
    height: '40px',
    marginTop: 5,
    marginRight: 10,
  },

  imageMainMenuItem: {
    width: 20,
    height: 20,
    marginLeft: 10,
  },

  imageSubMenuItem: {
    width: 20,
    height: 20,
  },

  imageRoundTile: {
    height: 30,
    width: 30,
    borderRadius: 20,
    borderWidth: 2,
    margin: 10,
    marginBottom: 0,
  },

  //* *************************
  //*   BUTTON
  //* *************************

  button: {
    fontSize: 18,
    textAlign: 'center',
  },

  buttonContainer: {
    elevation: 5,
    borderRadius: 5,
    borderWidth: 2,
    width: 150,
    padding: 10,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  buttonLearnMore: {
    fontSize: 15,
    textAlign: 'center',
  },

  buttonContainerLearnMore: {
    elevation: 5,
    borderRadius: 18,
    shadowRadius: 10,
    borderWidth: 2,
    width: 120,
    padding: 5,
    shadowOffset: {width: 0, height: 5},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttonFormAction: {
    fontSize: 15,
    textAlign: 'center',
  },

  buttonFormActionWeb: {
    fontSize: 25,
    textAlign: 'center',
  },

  buttonFormActionBudget: {
    fontSize: 15,
    textAlign: 'center',
  },

  buttonContainerFormAction: {
    elevation: 5,
    borderRadius: 5,
    borderWidth: 2,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },
  buttonContainerFormActionCustom: {
    elevation: 5,
    borderRadius: 5,
    borderWidth: 2,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 100,
    maxWidth: 200,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  buttonPopupOk: {
    fontSize: 18,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  bottonAlignLeft: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    paddingBottom: 10,
  },

  buttonContainerTopBorder: {
    borderTopColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    borderTopWidth: 2,
    marginBottom: 45,
  },

  buttonContainer_chooseParticipantOrCrew: {
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: '15%',
    padding: 0,
  },

  button_more: {
    ...Platform.select({
      ios: {
        marginTop: 3,
      },
    }),
  },

  button_moreImage: {
    ...Platform.select({
      ios: {
        marginTop: 10,
      },
    }),
  },

  button_more_width: {
    maxWidth: 200,
  },

  button_backContainer: {
    ...Platform.select({
      ios: {
        marginTop: 5,
      },
    }),
  },

  button_backContainerSmall: {
    ...Platform.select({
      ios: {
        marginTop: 15,
      },
    }),
  },

  buttonBudgetAction: {
    fontSize: 15,
    textAlign: 'center',
  },

  buttonContainerBudget: {
    elevation: 5,
    borderRadius: 5,
    borderWidth: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 20,
    paddingRight: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  buttonCommon: {
    fontSize: 18,
    textAlign: 'center',
  },

  buttonContainerCommon: {
    elevation: 5,
    borderRadius: 5,
    borderWidth: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 20,
    paddingRight: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  buttonContainerBudgetPlanReady: {
    elevation: 5,
    borderRadius: 10,
    borderWidth: 2,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        margin: 2,
      },
    }),
  },

  buttonAddGoal: {
    borderRadius: 8,
    paddingLeft: 30,
    paddingRight: 40,
    marginBottom: 10,
    elevation: 5,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  buttonGoalActions: {
    borderRadius: 8,
    paddingLeft: moderateScale(25, 2),
    paddingRight: 40,
    marginBottom: 10,
    width: moderateScale(250),
    shadowOffset: {width: 0, height: 2},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  floatingButton: {
    position: 'fixed',
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    right: 10,
    bottom: 10,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    borderRadius: 100 / 2,
    shadowOffset: {width: 3, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },

  //* *************************
  //*   RADIO BUTTON START
  //* *************************

  radioButtonPM: {
    flexWrap: 'wrap',
    marginTop: 8,
    width: moderateScale(40, 0.2),
    height: moderateScale(40, 0.2),
    fontSize: moderateScale(39, 0.2),
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  radioButtonPMWeb: {
    flexWrap: 'wrap',
    marginTop: 8,
    width: 26,
    height: 26,
    fontSize: 25,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  radioButtonPMMedium: {
    width: 26,
    height: 26,
    fontSize: 25,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  PlanSummaryActionTileIconView: {
    fontSize: 25,
  },
  PlanSummaryActionTileIconPrint: {
    fontSize: 35,
  },
  PlanSummaryActionTileIconShare: {
    fontSize: 25,
  },
  PlanSummaryWebTileMargin: {
    marginRight: '15%',
    marginLeft: '15%',
  },
  radioButtonPMSmall: {
    width: 26,
    height: 26,
    fontSize: 25,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  radioButtonPMSmallChanged: {
    width: 25,
    height: 25,
    fontSize: 25,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  radioButtonPM_Container: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingBottom: 10,
    paddingTop: 10,
  },

  radioButtonPMSmall_Container: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingBottom: 30,
  },

  radioButtonSupport_Container: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingBottom: 10,
    marginTop: 10,
  },

  radioButtonPMSmall_ContainerMultiselect: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 15,
    paddingBottom: 15,
  },

  radioButtonPM_DividerStyleMultiselect: {
    paddingTop: 20,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
  },

  radioButtonLS_DividerStyleMultiselect: {
    paddingTop: 20,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
    paddingBottom: 10,
  },

  radioButtonLSSmall_ContainerMultiselect: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 15,
    justifyContent: 'center',
  },

  radioButtonPM_DividerStyle: {
    paddingTop: 35,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
  },

  radioButtonPM_DividerStyleBorderless: {
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  //* *************************
  //*   RADIO BUTTON END
  //* *************************

  //* *************************
  //*   FORM
  //* *************************

  formTitleText: {
    fontSize: 15,
    flex: 1,
    flexWrap: 'wrap',
  },

  formTitleContainer: {
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },

  formTitlePadding: {
    ...Platform.select({
      ios: {
        marginRight: 10,
      },
      android: {
        marginRight: 30,
      },
    }),
  },

  formSubtitleText: {
    fontSize: 15,
  },

  formSubTitleContainer: {
    paddingBottom: 15,
  },

  formImportantNotification: {
    flexDirection: 'row',
    paddingBottom: 20,
  },

  formRequiredContainer: {
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },

  formRequiredText: {
    fontSize: 15,
    paddingRight: 5,
  },

  //* *************************
  //*   FORM END
  //* *************************

  //* *************************
  //*   PICKER  START
  //* *************************
  standardPicker: {
    height: 50,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },

  standardPickerBackgroundColorGray: {
    ...Platform.select({
      ios: {
        backgroundColor: BrandColors.secondaryBgColor3,
      },
    }),
  },
  ModalPickerInputGrayContainer: {
    marginBottom: 20,
    borderRadius: 10,
    backgroundColor: 'white',
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  pickerColorGray: {
    ...Platform.select({
      ios: {
        backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
      },
    }),
  },

  SelectAddproviderTopBottom: {
    marginTop: 10,
    marginBottom: 10,
  },

  changePasswordPopUpMobile: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: '10%',
    width: 300,
    borderRadius: 20,
  },

  //* *************************
  //*   PICKER  END
  //* *************************

  searchContainer: {
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 5,
  },

  searchBoxIcon: {
    marginLeft: 5,
    height: 25,
    width: 25,
    fontSize: 24,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  searchBoxIconSmall: {
    marginLeft: 5,
    height: 20,
    width: 20,
    fontSize: 20,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
  },

  searchInputField: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    fontSize: 15,
    borderRadius: 10,
  },

  profileTilesContainer: {
    flex: 1,
    flexDirection: 'column',
    marginBottom: 5,
  },

  budgetsTileWrapper: {
    flex: 1,
    padding: 10,
    marginTop: 10,
    borderRadius: 5,
    minHeight: 85,
    elevation: 5,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 5,
    flexWrap: 'wrap',
  },

  budgetsSupportTileWrapper: {
    flex: 1,
    padding: 10,
    marginTop: 10,
    borderRadius: 5,
    minHeight: 85,
    elevation: 5,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 5,
    flexWrap: 'wrap',
  },

  budgetsTilesContainer: {
    flexDirection: 'column',
    margin: 5,
  },

  oneProfileTileContainer: {
    minHeight: 90,
  },

  yellowStar: {
    height: 18,
    width: 18,
    resizeMode: 'contain',
    marginRight: 10,
  },

  placeholderText: {
    fontSize: 15,
  },

  dataSharingConsentModal: {
    flex: 1,
    backgroundColor: 'white',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: '30%',
    bottom: '10%',
    borderRadius: 10,
  },

  personOrganization: {
    flex: 1,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    justifyContent: 'center',
    marginLeft: 5,
    alignItems: 'center',
    borderRadius: 10,
    marginRight: 5,
  },
  crewaAccessWarning: {
    flex: 1,
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    marginLeft: 5,
    borderRadius: 10,
    marginRight: 5,
  },
  personOrganizationColumnView: {
    paddingLeft: 5,
    height: 140,
    flexDirection: 'column',
  },
  personOrganizationRowView: {
    height: 60,
    flexDirection: 'row',
    flex: 1,
  },
  dataSharingConsentModalMobile: {
    width: 350,
    height: 400,
  },

  dataSharingConsentModalTablet: {
    left: '12.5%',
    right: '12.5%',
  },

  dataSharingConsentModal_content: {
    top: '20%',
    bottom: '20%',
    left: '12.5%',
    right: '12.5%',
  },

  dataSharingConsentModal_dividerStyle: {
    paddingTop: 10,
    borderBottomColor: 'grey',
    borderBottomWidth: 1.7,
    flex: 1,
  },

  dataSharingConsentModal_dividerWidthTablet: {
    landscape: {
      width: Dimensions.get('window').height * 0.7,
    },
    portrait: {
      width: Dimensions.get('window').width * 0.7,
    },
  },

  dataSharingConsentModal_dividerWidthMobile: {
    landscape: {
      width: Dimensions.get('window').height * 0.8,
    },
    portrait: {
      width: Dimensions.get('window').width * 0.8,
    },
  },

  pmRegisteredConfirmationModal_dividerWidthTablet: {
    landscape: {
      width: Dimensions.get('window').height * 0.7,
    },
    portrait: {
      width: Dimensions.get('window').width * 0.7,
    },
  },

  pmRegisteredConfirmationModal_dividerWidthMobile: {
    landscape: {
      width: Dimensions.get('window').height * 0.7,
    },
    portrait: {
      width: Dimensions.get('window').width * 0.7,
    },
  },

  dataSharingConsentModal_continueButton: {
    fontSize: 25,
  },

  navigationConfirmModal: {
    flex: 1,
    backgroundColor: 'white',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },

  navigationConfirmModalMobile: {
    width: 330,
    height: 250,
    landscape: {
      top: '35%',
      bottom: '35%',
    },
    portrait: {
      top: '35%',
      bottom: '35%',
    },
  },

  navigationConfirmModalTablet: {
    left: '12.5%',
    right: '12.5%',
    landscape: {
      top: '35%',
      bottom: '35%',
    },
    portrait: {
      top: '40%',
      bottom: '40%',
    },
  },

  pmRegisteredConfirmationModal: {
    flex: 1,
    backgroundColor: 'white',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },

  pmRegisteredConfirmationModalMobile: {
    width: 330,
    height: 360,
    landscape: {
      top: '40%',
      bottom: '10%',
    },
    portrait: {
      top: '40%',
      bottom: '10%',
    },
  },

  pmRegisteredConfirmationModalTablet: {
    left: '12.5%',
    right: '12.5%',
    landscape: {
      top: '40%',
      bottom: '10%',
    },
    portrait: {
      top: '40%',
      bottom: '10%',
    },
  },

  logoutConfirmModalTablet: {
    left: '25%',
    right: '25%',
    landscape: {
      top: '35%',
      bottom: '35%',
    },
    portrait: {
      top: '40%',
      bottom: '40%',
    },
  },

  navigationConfirmModalCancelButtonRightBorder: {
    borderRightColor: 'grey',
    borderRightWidth: 1,
  },

  navigationConfirmModalActionButtons: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  radioButtonPM_PaddingTop20: {
    paddingTop: 20,
  },

  ModalTextInputWrapperAutoHeight: {
    width: '100%',
    backgroundColor: 'white',
    minHeight: 50,
    paddingLeft: 10,
  },

  settingsContainer: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },

  settingsTileWrapper: {
    padding: 15,
    margin: 5,
    borderRadius: 5,
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  loginTileWrapper: {
    padding: 15,
    margin: 5,
    borderRadius: 5,
    elevation: 5,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
        backgroundColor: 'white',
      },
    }),
  },

  previousPlansActiveWapper: {
    padding: 15,
    margin: 5,
    borderRadius: 10,
    paddingBottom: 10,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
        backgroundColor: 'white',
      },
    }),
  },

  invoiceApprovalDetailWrapper: {
    padding: 15,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    paddingBottom: 10,
    elevation: 5,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    backgroundColor: 'white',
    shadowRadius: 10,
    //minWidth: '66%'
  },

  settingsButtonContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 100,
    minWidth: 150,
  },

  settingsButtonImage: {
    width: 50,
    height: 50,
    alignSelf: 'center',
    resizeMode: 'contain',
    fontSize: 50,
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  settingsButtonLogo: {
    flex: 1,
    alignSelf: 'stretch',
    resizeMode: 'contain',
    marginTop: 10,
    marginLeft: 5,
    width: 50,
    height: 50,
  },

  settingsButtonText: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    fontSize: 15,
  },
  loginToText: {
    textAlign: 'center',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
    fontSize: 21,
  },
  myaccountText: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
    fontSize: 21,
  },

  settingsLinkButton: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    fontSize: 15,
    padding: 5,
  },

  changePasswordButtonImage: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    resizeMode: 'contain',
    marginRight: 10,
  },

  basicAlertButtonImage: {
    width: 25,
    height: 25,
    alignSelf: 'center',
    resizeMode: 'contain',
    marginRight: 10,
  },

  playStoreIcon: {
    width: 150,
    height: 70,
    alignSelf: 'center',
    resizeMode: 'contain',
  },

  playStoreIconMobile: {
    width: 100,
    height: 50,
    alignSelf: 'center',
    resizeMode: 'contain',
  },

  planAmountInfoButtonImage: {
    width: 20,
    height: 20,
    alignSelf: 'center',
    resizeMode: 'contain',
    marginRight: 5,
  },

  settingsPopupContainer: {
    marginRight: '5%',
    marginLeft: '5%',
    width: '90%',
  },

  //* *************************
  //*   TEXT
  //* *************************

  textTitle: {
    fontSize: 17,
  },

  textSubTitle: {
    fontSize: 15,
  },

  textLink: {
    textDecorationLine: 'underline',
  },

  textGuideMe: {
    fontSize: 18,
  },

  textGoalButton: {
    fontSize: 17,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
  },

  textResultSummary: {
    backgroundColor: 'transparent',
    paddingTop: 10,
    paddingBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },

  textProvider: {
    fontStyle: 'italic',
    fontSize: 15,
  },

  textSearchProvider: {
    fontFamily: 'arial',
    fontSize: 16,
  },

  //* *************************
  //*   TILE
  //* *************************

  tileGoal: {
    minHeight: 95,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    borderRadius: 10,
  },

  tileCategory: {
    minHeight: 60,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    borderRadius: 10,
  },

  //* *************************
  //*       PADDING START
  //* *************************

  padding0: {
    padding: 0,
  },

  paddingBottom0: {
    paddingBottom: 0,
  },

  paddingBottom3: {
    paddingBottom: 3,
  },
  paddingBottom5: {
    paddingBottom: 5,
  },

  paddingBottom5p: {
    paddingBottom: '5%',
  },
  paddingBottom9p: {
    paddingBottom: '9%',
  },

  paddingBottom10: {
    paddingBottom: 10,
  },

  paddingBottom100: {
    paddingBottom: 100,
  },

  paddingTop10p: {
    paddingTop: '10%',
  },

  paddingBottom22: {
    paddingBottom: 22,
  },

  paddingBottom20: {
    paddingBottom: 20,
  },

  paddingBottom25: {
    paddingBottom: 25,
  },

  paddingBottom30: {
    paddingBottom: 30,
  },

  paddingBottom40: {
    paddingBottom: 40,
  },
  paddingBottom50: {
    paddingBottom: 50,
  },

  paddingLeft20: {
    paddingLeft: 20,
  },
  paddingLeft40: {
    paddingLeft: 40,
  },
  paddingLeft2: {
    paddingLeft: 2,
  },
  paddingLeft10: {
    paddingLeft: 10,
  },
  paddingLeft100: {
    paddingLeft: 100,
  },
  paddingLeft15: {
    paddingLeft: 15,
  },

  paddingRight10: {
    paddingRight: 10,
  },
  paddingLeftRight8: {
    paddingRight: 8,
    paddingLeft: 8,
  },

  paddingRight0: {
    paddingRight: 0,
  },

  paddingRight5: {
    paddingRight: 5,
  },

  paddingTop0: {
    paddingTop: 0,
  },

  paddingTop10: {
    paddingTop: 10,
  },

  paddingTop20: {
    paddingTop: 20,
  },

  paddingTop30: {
    paddingTop: 30,
  },

  paddingTop15: {
    paddingTop: 15,
  },
  paddingTop50: {
    paddingTop: 50,
  },
  paddingTop80: {
    paddingTop: 80,
  },
  paddingBottom15: {
    paddingBottom: 15,
  },

  paddingTop5: {
    paddingTop: 5,
  },

  paddingRight15: {
    paddingRight: 15,
  },

  paddingRight20: {
    paddingRight: 20,
  },

  paddingRight25: {
    paddingRight: 25,
  },
  paddingRight35: {
    paddingRight: 35,
  },
  paddingRight80: {
    paddingRight: 80,
  },
  //* *************************
  //*       PADDING END
  //* *************************

  //* *************************
  //*       MARGIN START
  //* *************************

  margin0: {
    margin: 0,
  },

  marginLeft0: {
    marginLeft: 0,
  },

  marginRight0: {
    marginRight: 0,
  },

  marginRight10: {
    marginRight: 10,
  },

  marginRight20: {
    marginRight: 20,
  },

  marginRight15: {
    marginRight: 15,
  },

  marginRight25: {
    marginRight: 25,
  },

  marginRight40: {
    marginRight: 40,
  },

  marginRight5: {
    marginRight: 5,
  },

  marginBottom0: {
    marginBottom: 0,
  },

  marginBottom5: {
    marginBottom: 5,
  },

  marginBottom10: {
    marginBottom: 10,
  },

  marginBottom20: {
    marginBottom: 20,
  },

  marginLeft5: {
    marginLeft: 5,
  },

  marginLeft10: {
    marginLeft: 10,
  },

  marginLeft24: {
    marginLeft: 24,
  },

  marginLeft25: {
    marginLeft: 25,
  },

  marginRight75: {
    marginRight: 75,
  },

  marginLeft20: {
    marginLeft: 20,
  },

  marginLeft30: {
    marginLeft: 30,
  },

  marginLeft13: {
    marginLeft: 13,
  },

  marginLeft40: {
    marginLeft: 40,
  },

  marginLeftTab: {
    marginLeft: width * 0.1,
  },

  marginRightTab: {
    marginRight: width * 0.1,
  },

  marginTop5: {
    marginTop: 5,
  },

  marginTop10: {
    marginTop: 10,
  },

  marginTop15: {
    marginTop: 15,
  },

  marginTop20: {
    marginTop: 20,
  },

  ToggleButtonImageView: {
    height: 25,
    width: 25,
    marginLeft: 10,
  },

  marginTop25: {
    marginTop: 25,
  },

  marginTop90: {
    marginTop: 90,
  },

  marginRightLeft2p: {
    marginRight: '2%',
    marginLeft: '2%',
  },
  
  minHeight60: {minHeight: 60},

  minHeight85: {minHeight: 85},

  marginRight50: {marginRight: 50},

  marginBottom50: {marginBottom: 20},

  //* *************************
  //*       MARGIN END
  //* *************************

  //* *************************
  //*       HEIGHT START
  //* *************************

  height20: {
    height: 20,
  },

  height40: {
    height: 40,
  },
  height68: {
    height: 68,
  },

  height55: {
    height: 55,
  },

  height230: {
    height: 230,
  },
  height150: {
    height: 150,
  },
  height250: {
    height: 250,
  },
  height300: {
    height: 300,
  },

  height320: {
    height: 320,
  },

  height340: {
    height: 340,
  },
  width340: {
    width: 400,
  },
  width360: {
    width: 360,
  },

  height380: {
    height: 380,
  },

  height400: {
    height: 400,
  },

  height450: {
    height: 450,
  },

  height500: {
    height: 500,
  },

  height550: {
    height: 550,
  },

  maxHeight28: {
    maxHeight: 28,
  },

  maxHeight100: {
    maxHeight: 100,
  },
  minHeight230: {
    minHeight: 230,
  },
  minHeight300: {minHeight: 300},

  //* *************************
  //*       HEIGHT END
  //* *************************

  //* *************************
  //*       WIDTH END
  //* *************************

  maxWidth100: {
    width: 100,
  },

  maxWidth100Percentage: {
    maxWidth: '100%',
  },

  maxWidth93p: {
    maxWidth: '93%',
  },

  //* *************************
  //*       WIDTH END
  //* *************************

  //* *************************
  //*       FLEX START
  //* *************************

  flex01: {
    flex: 0.1,
  },

  flex015: {
    flex: 0.15,
  },

  flex018: {
    flex: 0.18,
  },

  widthStatic50: {
    width: 100,
  },

  flex02: {
    flex: 0.2,
  },

  flex025: {
    flex: 0.25,
  },
  flex045: {
    flex: 0.45,
  },

  flex065: {
    flex: 6.5,
  },

  flex082: {
    flex: 0.82,
  },

  flex09: {
    flex: 0.9,
  },

  flex04: {
    flex: 0.4,
  },

  flex05: {
    flex: 0.5,
  },
  flex55: {
    flex: 0.55,
  },
  flex06: {
    flex: 0.6,
  },

  flex03: {
    flex: 0.3,
  },
  flex033: {
    flex: 0.33,
  },
  flex030: {
    flex: 0.4,
  },
  flex07: {
    flex: 0.7,
  },
  flex075: {
    flex: 0.75,
  },
  flex025: {
    flex: 0.25,
  },

  flex08: {
    flex: 0.8,
  },

  flex085: {
    flex: 0.85,
  },

  flex1: {
    flex: 1,
  },

  flex2: {
    flex: 2,
  },

  flex3: {
    flex: 3,
  },

  flex4: {
    flex: 4,
  },

  flex5: {
    flex: 5,
  },

  //* *************************
  //*       FLEX END
  //* *************************

  //* *************************
  //*   ELEVATION START
  //* *************************
  elevation10: {
    elevation: 10,
    zIndex: 10,
  },

  elevation5: {
    elevation: 3,
    ...Platform.select({
      ios: {
        shadowOffset: {height: 4},
        shadowColor: 'black',
        shadowOpacity: 0.4,
        backgroundColor: 'white',
      },
      android: {
        margin: 2,
      },
    }),
  },

  elevation3: {
    elevation: 3,
    ...Platform.select({
      ios: {
        shadowOffset: {height: 3},
        shadowColor: 'black',
        shadowOpacity: 0.4,
        backgroundColor: 'white',
      },
      android: {
        margin: 3,
      },
    }),
  },
  //* *************************
  //*   ELEVATION END
  //* *************************

  //* *************************
  //*   TEXT START
  //* *************************
  termsOfuseText: {
    ...Platform.select({
      ios: {
        paddingTop: 5,
      },
      android: {
        paddingTop: 5,
      },
    }),
  },

  webRecentMembersText: {
    marginLeft: 10,
    paddingTop: 5,
    fontFamily: 'arial',
    fontSize: 15,
    fontWeight: 'bold',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  webAddMembersText: {
    marginLeft: 10,
    marginTop: 5,
    fontFamily: 'arial',
    fontSize: 15,
    fontWeight: 'bold',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  webSelectedMembersText: {
    marginLeft: 5,
    paddingTop: 5,
    fontFamily: 'arial',
    fontSize: 15,
    fontWeight: 'bold',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  webUnSelectedMembersText: {
    marginLeft: 5,
    paddingTop: 5,
    fontFamily: 'arial',
    fontSize: 15,
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  //* *************************
  //*   TEXT END
  //* *************************

  //* *************************
  //*   IMAGE START
  //* *************************

  phototileCrewContainer1: {
    borderRadius: 50,
    width: 100,
    height: 100,
  },
  phototileCrewContainer2: {
    borderRadius: 20,
    width: 75,
    height: 75,
  },

  passwordShowHideIcon: {
    zIndex: 3,
    position: 'absolute',
    top: 15,
    right: 15,
    width: 30,
    height: 25,
    alignSelf: 'flex-end',
  },

  //* *************************
  //*   IMAGE END
  //* *************************

  //* *************************
  //*   Z-INDEX START
  //* *************************

  autoCompleteZindex: {
    zIndex: 100,
  },

  zIndex0: {
    zIndex: 0,
  },

  zIndexCrew1: {
    ...Platform.select({
      ios: {
        zIndex: 1,
      },
    }),
  },

  zIndexCrew2: {
    ...Platform.select({
      ios: {
        zIndex: 2,
      },
    }),
  },

  zIndex100: {
    zIndex: 100,
  },

  zIndex101: {
    zIndex: 101,
  },

  zIndex102: {
    zIndex: 102,
  },

  zIndex103: {
    zIndex: 103,
  },

  zIndex01: {
    zIndex: 1,
  },

  zIndex02: {
    zIndex: 2,
  },

  zIndex03: {
    zIndex: 3,
  },

  zIndex04: {
    zIndex: 4,
  },

  zIndex05: {
    zIndex: 5,
  },

  zIndex999: {
    zIndex: 999,
  },

  zIndex1000: {
    zIndex: 1000,
  },

  zIndex01Notification: {
    zIndex: 1,
  },

  zIndex02Notification: {
    zIndex: 2,
  },

  zIndex03Notification: {
    zIndex: 3,
  },

  zIndex03NotificationIconBadge: {
    zIndex: 3,
  },

  zIndexN1: {
    zIndex: -1,
  },

  //* *************************
  //*    Z-INDEX END
  //* *************************

  //* *************************
  //*    SEPERATORS/DEVIDER START
  //* *************************

  ApprovalSummaryGreySeperator: {
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
    paddingBottom: 20,
  },

  greySeperator: {
    flex: 1,
    width: '100%',
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
  },

  greySeperator_RadioButton_Budgets: {
    paddingTop: 10,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
  },

  signUpForPlanManagementAvatarIconLarge: {
    width: scale(105, 1),
    height: scale(25, 1),
    resizeMode: 'cover',
  },

  signUpForUploadPlanAvatarIconLarge: {
    width: scale(105, 1),
    height: scale(25, 1),
    resizeMode: 'cover',
  },

  signUpForPlanManagementAvatarIcon: {
    width: scale(250, 1),
    height: scale(60, 1),
    right: '60%',
  },

  top150: {
    top: 150,
  },

  top30p: {
    top: '30%',
  },

  top15p: {
    top: '15%',
  },
  right10: {
    right: 10,
  },
  signUpForPlanManagementAvatarScale: {
    right: scale(35, 1),
  },

  signUpForPlanManagementAvatarOpenScale: {
    right: scale(15, 1),
  },

  signUpForPlanManagementAvatarCloseMarginLC: {
    marginBottom: 30,
  },

  signUpForPlanManagementUploadContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },

  signUpForPlanManagementUploadContainerLarge: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },

  signUpForPlanManagementUploadContainerLargeScalePR: {
    height: scale(120, 1),
  },

  signUpForPlanManagementUploadContainerLargeScaleLC: {
    height: scale(80, 1),
  },

  signUpForPlanManagementUploadAvatarIconLarge: {
    width: scale(185, 1),
    height: scale(45, 1),
    resizeMode: 'cover',
    zIndex: 3,
    position: 'absolute',
  },

  signUpForPMUploadAvatarScalePR: {
    bottom: scale(45, 1),
    right: scale(15, 1),
  },

  signUpForPMUploadAvatarScaleLC: {
    bottom: scale(40, 1),
    right: scale(15, 1),
  },

  signUpForPMUploadAvatarScalePRSmall: {
    bottom: scale(45, 1),
    right: scale(10, 1),
  },

  signUpForPMUploadAvatarScaleLCSmall: {
    bottom: scale(40, 1),
    right: scale(10, 1),
  },

  signUpForPMAvatarScale: {
    right: scale(15, 1),
  },

  signUpForPMAvatarScaleSmall: {
    right: scale(10, 1),
  },

  signUpForPlanManagementTextContainer: {
    position: 'absolute',
    right: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
  },

  signUpForPlanManagementTextContainerWidthScaleOpen: {
    width: scale(210, 1),
  },

  signUpForPlanManagementTextContainerWidthScaleClose: {
    width: scale(65, 1),
  },

  signUpForPlanManagementContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    height: scale(200, 1),
  },

  marginBottomScale2: {
    marginBottom: scale(2),
  },
  marginBottomScale5: {
    marginBottom: scale(5),
  },
  marginBottomScale10: {
    marginBottom: scale(7),
  },

  planMeetingScreenShareIcon: {
    height: moderateScale(35),
    width: moderateScale(35),
  },

  planMeetingScreenPrintIcon: {
    height: moderateScale(45),
    width: moderateScale(45),
  },

  planMeetingScreenViewIcon: {
    height: moderateScale(42),
    width: moderateScale(42),
  },

  planMeetingScreenViewIconHorizontal: {
    height: moderateScale(25),
    width: moderateScale(25),
  },

  chatBubble: {
    flex: 1,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
    borderWidth: 1,
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },

  pmSignUpImportantNotificationIcon: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },

  addGoalAvatarCloseScale: {
    left: 0,
  },

  addGoalAvatarOpenScale: {
    right: scale(15, 1),
    left: 0,
  },

  addGoalAvatarCloseMarginLC: {
    marginBottom: 50,
  },

  addGoalAvatarScaleLC: {
    right: scale(75, 1),
  },

  addGoalAvatarIcon: {
    width: scale(100, 0.5),
    height: scale(30, 0.5),
    resizeMode: 'cover',
    zIndex: 3,
    position: 'absolute',
    top: scale(30, 1),
  },

  addGoalAvatarIconLarge: {
    width: scale(100, 0.5),
    height: scale(30, 0.5),
    resizeMode: 'cover',
    zIndex: 3,
    position: 'absolute',
  },

  addGoalAvatarCloseMarginLC: {
    marginBottom: 20,
  },

  addGoalAvatarScaleLC: {
    right: scale(75, 1),
  },

  //* *************************
  //*    SEPERATORS/DEVIDER END
  //* *************************

  //* ****************************
  //*    ADD PROFILE PICTURE START
  //* ****************************
  formActionButtons: {
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },

  avatarImageContainer: {
    width: height * 0.1,
    height: height * 0.1,
    elevation: 8,
    borderRadius: 20,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  avatarBorderRadius20: {
    borderRadius: 20,
  },

  addProfilePicturePickButton: {
    flex: 1,
    width: 30,
    height: 20,
    resizeMode: 'contain',
  },

  scrollViewProfilePic: {
    flex: 1,
  },

  borderRadiusRound: {
    borderRadius: 32,
  },
  //* ****************************
  //*    ADD PROFILE PICTURE END
  //* ****************************

  //* ****************************
  //*    DOCUMENTS START
  //* ****************************
  documentTitle: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingRight: 20,
  },

  documentDate: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  documentTile: {
    elevation: 2,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    padding: 15,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
    }),
  },

  documentTileFontColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  documentLastModified: {
    flex: 1.5,
    fontSize: 15,
    paddingLeft: 10,
  },

  documentActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  documentImageMargin: {
    margin: 10,
  },

  item: {
    fontSize: 18,
    textDecorationLine: 'underline',
  },

  documentName: {
    flex: 2.2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  documentIcon: {
    width: 35,
    height: 35,
    fontSize: 35,
    resizeMode: 'contain',
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  pickerBackgroundColorGray: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  documentTitleMargin: {
    marginBottom: 10,
    marginTop: 10,
  },

  documentTitleColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  documentTileMarginTouchableRight: {
    marginRight: 10,
  },

  //* ****************************
  //*    PIE CHART
  //* ****************************

  pieChartSpending: {
    height: 130,
    width: 130,
  },

  pieChartSupportItem: {
    height: 90,
    width: 90,
  },

  pieChartSupportItemWeb: {
    height: 60,
    width: 60,
  },

  //* ****************************
  //*    BENEFITS OF LEAPIN
  //* ****************************

  benefitsListContainer: {
    paddingLeft: 20,
    marginRight: 20,
    paddingRight: 20,
  },

  topTitleContainer: {
    marginLeft: 20,
    marginRight: 60,
    borderWidth: 4,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  MultiPlanContainer: {
    borderRadius: 20,
    width: 120,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    height: 30,
  },

  BenefitOfLeapInHeroImage: {
    height: ScreenHeight,
    width: '100%',
  },

  BenefitOfLeapInButtonContainers: {
    marginRight: 20,
    padding: 12,
    marginBottom: 20,
    marginTop: 20,
    elevation: 5,
  },

  //* ****************************
  //*    BENEFITS OF LEAPIN END
  //* ****************************

  //* ****************************
  //*    DISABILITY START
  //* ****************************

  DisabilityList: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  DisabilitySelectedPadding: {
    padding: 0,
    marginTop: 3,
    marginBottom: 3,
    marginLeft: 10,
  },

  DisabilitySelectedContainer: {
    borderRadius: 18,
    borderWidth: 1,
    margin: 5,
  },

  flexShrink1: {
    flexShrink: 1,
  },

  DisabilityClosePadding: {
    paddingLeft: 10,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 5,
  },

  DisabilityCloseIcon: {
    width: 20,
    height: 20,
  },

  //* ****************************
  //*    DISABILITY END
  //* ****************************

  //* ****************************
  //*    BORDER START
  //* ****************************

  borderWidth1: {
    borderWidth: 1,
  },

  borderWidth2: {
    borderWidth: 2,
  },

  borderWidth4: {
    borderWidth: 4,
  },

  borderSecondaryMenu: {
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
  },

  borderPrimaryMenu: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  borderMenuContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
  },

  //* ****************************
  //*    BORDER END
  //* ****************************

  //* *************************
  //*    OPACITY START
  //* *************************

  opacity06: {
    opacity: 0.6,
  },

  opacity05: {
    opacity: 0.5,
  },

  //* *************************
  //*    OPACITY END
  //* *************************

  //* *************************
  //*    STAR_RATING START
  //* *************************
  starRatingDevider: {
    paddingTop: 10,
    borderBottomColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    borderBottomWidth: 1,
  },

  starRatingCenter: {
    alignItems: 'center',
    paddingBottom: 20,
  },
  //* *************************
  //*    STAR_RATING END
  //* *************************

  //* *****************************
  //*    SA_ATTACHMENT_MODAL START
  //* *****************************
  viewAttachments: {
    width: 40,
    height: 25,
    textAlign: 'center',
  },

  downloadAttachments: {
    width: 25,
    height: 25,
    textAlign: 'center',
  },

  attachmentContainer: {
    minHeight: 200,
  },

  //* *****************************
  //*    SA_ATTACHMENT_MODAL END
  //* *****************************

  billerNameStyle: {
    width: 150,
  },

  //* *****************************
  //*    INPUT STYLES
  //* *****************************

  searchInput: {
    flex: 1,
    minHeight: 40,
    width: 300,
  },

  //* ****************************
  //*    DEEPLINK BANNER
  //* ****************************

  bannerText: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: 15,
  },

  bannerContainer: {
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 0,
    marginLeft: 0,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'space-between',
  },

  bannerLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  bannerRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  bannerIcon: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },

  bannerButtonContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 20,
    backgroundColor: '#FFFFFF',
    shadowColor: 'rgba(0,0,0, .2)',
    shadowOpacity: 0.6,
    elevation: 6,
    shadowRadius: 15,
    shadowOffset: {width: 1, height: 13},
  },

  height35: {height: 35},

  //* *****************************
  //*    DEMO_BAR_STYLING START
  //* *****************************

  width350: {
    width: 350,
  },

  //* *****************************
  //*    DEMO_BAR_STYLING END
  //* *****************************

  textInputLoginSignUp: {
    width: 300,
  },
  //* *****************************
  //*    PROVIDER_MAP START
  //* *****************************
  map: {
    ...StyleSheet.absoluteFillObject,
  },

  //* *****************************
  //*    PROVIDER_MAP END
  //* *****************************

  //* *****************************
  //*    PROVIDER_REFERRAL START
  //* *****************************
  referralBtnTitle: {
    width: '70%',
  },
  referralTitle: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  referralDescription: {
    fontSize: 13,
    flex: 1,
    flexWrap: 'wrap',
    paddingRight: 10,
  },
  referralButton: {
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        elevation: 5,
      },
    }),
    marginBottom: 7,
    borderRadius: 15,
    height: 100,
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  referralItemView: {
    flex: 1,
    ...Platform.select({
      ios: {
        shadowOffset: {width: 0, height: 2},
        shadowColor: 'black',
        shadowOpacity: 0.5,
      },
      android: {
        elevation: 5,
      },
    }),
    width: '100%',
    marginBottom: 10,
    borderRadius: 15,
    height: 200,
    borderWidth: 1,
    backgroundColor: 'white',
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  referralItemMorePadding10: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  referralItemLessPadding10: {
    padding: 10,
  },
  updateAddressBtn: {
    elevation: 5,
    borderRadius: 5,
    borderWidth: 2,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 8,
    paddingRight: 8,
  },
  updateAddressBtnTitle: {
    textAlign: 'center',
  },
  referralBtnView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
  },
  referralBtn: {},
  referralView: {
    flex: 1,
  },
  referralBtnMainView: {
    flex: 1,
  },
  referralItemMoreInfoView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: 20,
    margin: 10,
  },
  referralItemLessInfoView: {
    zIndex: 1,
    position: 'absolute',
    right: 0,
    width: '30%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  referralEnquireView: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 10,
    marginBottom: 10,
  },
  referralItemAccordianButtonIcon: {
    height: 15,
    width: 15,
    resizeMode: 'contain',
    marginRight: 5,
    marginLeft: 5,
  },
  referralViewFlex1: {
    flex: 1,
  },
  referralInfoPadding: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  referralMoreInfoUnderLine: {
    textDecorationLine: 'underline',
    paddingRight: 10,
  },
  referralEnquireBtn: {
    width: '100%',
  },
  ndisImage: {width: 60, height: 30, marginRight: 10},
  referralItemImage: {
    height: 60,
    width: 60,
    marginTop: 5,
  },

  /************************ */
  // Provider Enquiry Screen
  /************************ */

  textbox: {
    height: 150,
  },
  providerEnquiryPrePopulate: {
    paddingLeft: 20,
    paddingTop: 4,
  },
  providerEnquiryPrePopulateItem: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 30,
    paddingRight: 20,
  },
  ellipsizeMode: {
    whiteSpace: 'nowrap',
    //overflow: 'hidden',
    //textOverflow: 'ellipsis'
  },
  enquiryCheckbox: {
    flexWrap: 'wrap',
    marginTop: 8,
    width: moderateScale(30, 0.2),
    height: moderateScale(30, 0.2),
    fontSize: moderateScale(32, 0.2),
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  referralButtonSearchIcon: {
    tintColor: 'grey',
    width: 35,
    height: 35,
    marginLeft: 10,
  },
  referralButtonMarkerIcon: {
    tintColor: 'grey',
    width: 50,
    height: 50,
    marginRight: 5,
  },
  width95: {
    width: '95%',
  },
  invalidAddressView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  invalidAddressMessage: {
    marginTop: 10,
    paddingLeft: 5,
  },
  ndisBadge: {
    width: 30,
    height: 30,
  },
  autoSuggestionItem: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'white',
  },
  emptyProviderPopup: {
    height: 250,
    paddingTop: 30,
    paddingBottom: 20,
  },
  enquiryPopup: {
    height: 220,
    paddingTop: 30,
    maxWidth: 400,
    paddingBottom: 20,
  },
  emptyProviderPopupTablet: {
    height: 250,
    paddingTop: 30,
    paddingBottom: 20,
    top: '50%',
  },
  enquiryPopupTablet: {
    height: 150,
    paddingTop: 30,
    paddingBottom: 20,
    top: '50%',
    width: 450,
  },
  showManualEntryAddressPopupAlert: {
    height: 250,
    paddingTop: 10,
    paddingBottom: 10,
    top: '50%',
  },
  loadingModal: {
    height: 120,
    width: 250,
    borderRadius: 10,
    borderWidth: 1,
  },
  preAuthMessageBox: {
    marginTop: 15,
    marginRight: 15,
  },
  copyrightsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  optionTileImageContainer: {
    height: 65,
    width: 65,
  },
  optionTileAvatarContainer: {
    height: 72,
    width: 72,
  },
  optionTileImagePadding: {
    padding: 8,
  },
  optionTileImageBorder: {
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 4,
  },
  borderRadius2: {
    borderRadius: 2,
  },
  headerWarningBanner: {
    textAlign: 'center',
    margin: 5,
  },
  headerWarningBannerBackground: {
    backgroundColor: BrandColors.UNDER_MAINTENANCE_YELLOW,
  },
  colorBlack: {
    color: BrandColors.BLACK,
  },
  approvePopup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    margin: 10,
  },
  buttonMaxWidth: {
    maxWidth: 100,
  },
  overlayContainer: {
    display: 'grid',
    overflow: 'overlay',
  },
  brandBackgroundColor: {
    backgroundColor: BrandColors.TAB_BAR_COLORS.TAB_BOTTOM_INACTIVE,
  },
  closeMyAccountIcon: {
    height: 60,
    width: 60,
    tintColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
    resizeMode: 'contain',
    paddingTop: 10,
    paddingVertical: 10,
  },
  headerLine: {
    borderBottomWidth: 2,
  },
  headerTextV2: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: -30,
  },
  borderRadius20: {
    borderRadius: 20,
  },
  customSettingModalContainer: {
    position: 'absolute',
    bottom: '10%',
    width: 520,
    borderWidth: 0,
    width: '90%',
  },
});
