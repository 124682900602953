/*
 * Author: Tharindu Seneviratne
 * Date: 17/01/2020
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a details screen that will be loaded when the user is attempting to access the details of
 * an HH invoice that requires approval.
 * Currently will only support invoices only.
 */

import React, {Component} from 'react';
import {View, Image, Platform} from 'react-native';
import {connect} from 'react-redux';
import {CommonActions} from '@react-navigation/native';
import {forEach, isEmpty} from 'lodash';
import StarRating from '../Components/Atoms/StarRating';
import logger from 'helpers/Logger';
import * as mime from 'react-native-mime-types-enhanced';
import {
  StandardText,
  StandardTouchableOpacity,
  StandardInput,
} from '../Components/Atoms';
import {EmptyStar, FilledOutStar} from '../assets/images';
import {requestWritePermission} from '../Helpers/FileHelper';
import {BasicForm} from '../Components/Organisms';
import {
  AccordianInvoiceTile,
  InvoiceListHH,
  Container,
  SummaryTile,
  CurrencyText,
  BasicOverlayPopupModal,
  RadioButton,
  GridView,
  InvoiceDetailsTile,
  InvoiceNotes,
} from '../Components/Molecules';
import {
  MESSAGES,
  STAR_RATING,
  APPROVALS_INVOICE_DETAILS as messages,
  APPROVALS_SUMMARY as summaryMessages,
  COMMON_BUTTONS as buttonMessages,
  PAYMENTS_DETAILS_SCREEN,
  INVOICE_DETAILS_SCREEN,
} from '../Constants/Messages';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';
import * as Images from '../assets/images';
import {InvoiceDollarPurple3x} from '../assets/images/vector-icons';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {callAPIs} from '../API/APICaller';
import {
  getInvoiceDetails,
  putInvoiceApproval,
  putInvoice,
} from '../API/PlanDataAPI';
import {updateNotifications} from '../API/NotificationAPI';
import {
  postRating,
  putRating,
  createRatingObject,
  createRatingUpdateObject,
} from '../API/StarRatingAPI';

import {formatDDMMMYYDate} from '../Helpers/Utils';
import DeviceInfo from 'react-native-device-info';
import {OfflineNotice} from '../Components/Organisms';

import {RNFetchBlobSingleton} from '../Helpers/PlatformSynchronizer';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {bindActionCreators} from 'redux';

const LEAPIN_PATH = '/LeapIn';
const JEPG = '.jpeg';

class ApprovalsDetailsInvoiceHH extends Component {
  _closeAlert: any;
  _showAlert: any;
  refreshSummary: any;
  selectedItem: any;
  state = {
    loading: true,
    invoiceDetails: {},
    approveModalVisible: false,
    messageHeader: '',
    messageBody: '',
    errorPopup: false,
    modalCloseLabel: types2.STAR_RATING.DISMISS,
    starCount: 0,
  };

  constructor(props: any) {
    super(props);
    this.selectedItem = this.props.navigationParams.params.selectedItem;
    this.refreshSummary = this.props.navigationParams.params.refreshSummary;

    this._renderInvoiceDetails.bind(this);
    this._invoiceApprovalValue.bind(this);
    this._invoiceTotalValue.bind(this);
    this._renderMain.bind(this);
    this._renderApprovePopUp.bind(this);
    this._submitRatingAction.bind(this);
    this._renderNote.bind(this);
    this._updateNote.bind(this);
    this._updateInvoice.bind(this);
    this._determineNumColumns.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const {loadedMemberId} = this.props;

    const dataCallback = (data: any) => {
      const invoiceDetails = data[0].content;
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        loading: false,
        invoiceDetails,
        starCount: invoiceDetails.ratingInfo.rating
          ? invoiceDetails.ratingInfo.rating
          : 0,
      });
    };

    callAPIs(
      [
        getInvoiceDetails(
          loadedMemberId.loadedMemberId,
          this.selectedItem.uri,
          this.props.user.demoProfile,
        ),
      ],
      dataCallback,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
      },
    );
  }

  render() {
    if (types.isWeb) {
      return this._renderMain();
    }
    return (
      <Container
        contents={this._renderMain}
        loading={this.state.loading}
        ignorePadding={true}
        customStyle={CommonStyles.containerInvoiceDetails}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.APPROVAL_INVOICE}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createAllModals = () => {
    let modalJSX = [this._renderApprovePopUp(DeviceInfo.isTablet())];
    return modalJSX;
  };

  _renderMain = () => {
    const {invoiceDetails} = this.state;

    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <View>
          <BasicForm
            hideTitle={true}
            save={this._approveItem}
            saveLabel={buttonMessages.APPROVE}
            saveStyle={this._renderSaveStyles()}
            delete={this._rejectItem}
            deleteText={buttonMessages.DECLINE}
            deleteStyle={this._renderDeleteStyles()}
            buttonContainerStyle={CommonStyles.containerInvoiceDetails_Buttons}
            disableMore={true}
            readOnly={this.props.member?.access?.finances}
            saveApiError={this.state.saveApiError}
            saveApiErrorCode={this.state.saveApiErrorCode}
            buttonsPositionBottom={true}
            contentWidthFull={true}
          >
            <View style={[CommonStyles.containerInvoice_Summary]}>
              <View>
                <SummaryTile
                  headerDisplayString={messages.TITLE}
                  headerIconImage={InvoiceDollarPurple3x}
                  HeaderTextColor={BrandStyles.TextColor4}
                  HeaderBoarderColor={BrandStyles.borderColor4}
                  hideYellowStar={true}
                  showButton={this.props.user.isEmployee}
                  fontSize={CommonStyles.rpfont20}
                  disableMore={true}
                  showButtonTitle={types.ACTION_BUTTON.UPDATE_NOTES}
                  containsData={true}
                  modal={this._updateInvoice}
                />
              </View>
              {/* Invoice totals */}
              {this._renderInvoiceDetails()}
              <View style={types.isWeb ? CommonStyles.alignItemsCenter : null}>
                {types.isWeb && !this.state.loading && (
                  <InvoiceDetailsTile
                    title={messages.ADDITIONAL_DETAILS_TITLE}
                    contents={this._renderAdditionalDetails()}
                  />
                )}

                <GridView
                  viewWidth={this.props.viewWidth - 150}
                  itemDimension={types2.ITEM_DIMENSION}
                  isMobile={this.props.isMobile}
                  maximumColumns={this._determineNumColumns(
                    invoiceDetails.memberNote,
                  )}
                  style={[
                    CommonStyles.marginBottom15,
                    CommonStyles.marginTop10,
                  ]}
                >
                  {!types.isWeb ? (
                    <InvoiceDetailsTile
                      title={messages.ADDITIONAL_DETAILS_TITLE}
                      contents={this._renderAdditionalDetails()}
                    />
                  ) : null}
                  {(invoiceDetails.memberNote || this.props.user.isEmployee) &&
                  invoiceDetails.memberNote ? (
                    <InvoiceDetailsTile
                      title={
                        this.props.user.isEmployee
                          ? messages.NOTES_FOR_MEMBER_STAFF
                          : messages.NOTES_FOR_MEMBER
                      }
                      contents={this._renderNote(
                        invoiceDetails.memberNote,
                        types2.INVOICE_NOTES.MEMBER_NOTE,
                      )}
                    />
                  ) : null}
                  {this.props.user.isEmployee && invoiceDetails.workNote ? (
                    <InvoiceDetailsTile
                      title={messages.WORK_NOTES}
                      contents={this._renderNote(
                        invoiceDetails.workNote,
                        types2.INVOICE_NOTES.WORK_NOTE,
                      )}
                    />
                  ) : null}
                </GridView>
              </View>
            </View>
            {/* Rendering the acordion sections */}
            {this._renderAccordians(invoiceDetails.groupedInvoiceClaims)}
          </BasicForm>
          {types.isWeb
            ? this._renderApprovePopUp(false)
            : this._renderApprovePopUp(DeviceInfo.isTablet())}
        </View>
      </View>
    );
  };

  _determinSubText = (key: any) => {
    let subText = '';
    switch (key) {
      case messages.SECTIONS.NEEDS_APPROVAL:
        subText = messages.SECTIONS_SUBTEXT.NEEDS_APPROVAL;
        break;
      case messages.SECTIONS.APPROVED || messages.PARTIALLY_APPROVED:
        subText = messages.SECTIONS_SUBTEXT.APPROVED;
        break;
      case messages.SECTIONS.NOT_CLAIMABLE:
        subText = messages.SECTIONS_SUBTEXT.NOT_CLAIMABLE;
        break;
      default:
        subText = messages.SECTIONS_SUBTEXT.NOT_CLAIMABLE;
        break;
    }
    return subText;
  };

  _renderAccordians = (groupedInvoiceClaims: any) => {
    if (groupedInvoiceClaims) {
      return Object.keys(groupedInvoiceClaims).map(key => {
        return this._renderAccordian(
          key,
          this._determinSubText(key),
          groupedInvoiceClaims[key],
          key === messages.SECTIONS.NEEDS_APPROVAL ? true : false,
          BrandStyles[types2.INVOICE_ACCORDIAN_SECTIONS[key].TextColor],
          {
            expand: Images[types2.INVOICE_ACCORDIAN_SECTIONS[key].Expand],
            collapse: Images[types2.INVOICE_ACCORDIAN_SECTIONS[key].Collapse],
          },
        );
      });
    }
  };

  _renderVerifiedName = (friendlyName: any, billerName: any) => {
    if (friendlyName && friendlyName !== billerName) {
      return (
        <View style={[CommonStyles.paddingBottom10]}>
          <StandardText
            style={[CommonStyles.textProvider, BrandStyles.TextColor10]}
          >
            {PAYMENTS_DETAILS_SCREEN.BILL_TO + billerName}
          </StandardText>
        </View>
      );
    }
  };

  _determineNumColumns = (note: any) => {
    if (this.props.user.isEmployee) {
      return types2.THREE_COLUMN;
    } else if (note) {
      return types2.TWO_COLUMN;
    }
    return types2.ONE_COLUMN;
  };

  _renderInvoiceDetails = () => {
    if (!isEmpty(this.state.invoiceDetails)) {
      const billerName = this.state.invoiceDetails.biller.name;
      const providerFriendlyName =
        this.state.invoiceDetails.providerFriendlyName;
      return (
        <View>
          <StandardText
            style={[
              CommonStyles.customFontSemiBold,
              CommonStyles.font22,
              BrandStyles.TextColor4,
            ]}
          >
            {providerFriendlyName ? providerFriendlyName : billerName}
          </StandardText>
          {this._renderVerifiedName(providerFriendlyName, billerName)}
          <View style={[CommonStyles.justifyFlexRowStart]}>
            <View
              style={[CommonStyles.justifyFlexColumnStart, CommonStyles.flex06]}
            >
              <View
                style={[
                  CommonStyles.justifyFlexColumnStart,
                  CommonStyles.marginTop10,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFontSemiBold,
                    types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                    BrandStyles.TextColor5,
                  ]}
                >
                  {messages.INVOICE_NUMBER}
                </StandardText>
                <View
                  style={[
                    CommonStyles.justifyFlexRowStart,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  <StandardText
                    style={[
                      types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                      CommonStyles.customFont,
                      BrandStyles.TextColor5,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    {this.selectedItem.number}
                  </StandardText>
                  {this._renderInvoiceDocumentLink()}
                </View>
              </View>
              <View
                style={[
                  CommonStyles.justifyFlexRowStart,
                  CommonStyles.alignItemsCenter,
                ]}
              >
                <Image
                  style={CommonStyles.containerBudgetApprovalCard_ImageDate}
                  source={Images.GreyClockIcon3x}
                />
                <StandardText
                  style={[
                    CommonStyles.font15,
                    CommonStyles.customFont,
                    BrandStyles.TextColor10,
                  ]}
                >
                  {formatDDMMMYYDate(this.selectedItem.invoiceDate)}
                </StandardText>
              </View>
            </View>
            <View
              style={[
                CommonStyles.justifyFlexColumnStart,
                CommonStyles.flex04,
                CommonStyles.marginBottom10,
              ]}
            >
              <View
                style={[CommonStyles.flex05, CommonStyles.flexDirectionColumn]}
              >
                <CurrencyText
                  value={this._invoiceTotalValue()}
                  isPositive={true}
                  integerStyle={[
                    types.isWeb ? CommonStyles.font30 : CommonStyles.font40,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                />
                <View
                  style={[
                    CommonStyles.justifyFlexRowEnd,
                    types.isWeb ? CommonStyles.marginTop20 : '',
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.title_ListContainer,
                      CommonStyles.marginRight0,
                      CommonStyles.marginBottom20,
                      CommonStyles.customFont,
                    ]}
                  >
                    {messages.TOTAL_INVOICE_TITLE}
                  </StandardText>
                </View>
              </View>
            </View>
          </View>

          {this.state.invoiceDetails.notClaimable !== undefined &&
            this.state.invoiceDetails.notClaimable !== null &&
            this.state.invoiceDetails.notClaimable > 0 && (
              <View
                style={[
                  CommonStyles.justifyFlexRowStart,
                  CommonStyles.marginTop10,
                ]}
              >
                <View
                  style={[
                    CommonStyles.flex05,
                    CommonStyles.flexDirectionColumn,
                    CommonStyles.alignItemsFlexStart,
                  ]}
                >
                  <CurrencyText
                    value={this.state.invoiceDetails.claimable}
                    isPositive={true}
                    integerStyle={[
                      CommonStyles.font30,
                      CommonStyles.customFont,
                      BrandStyles.TextColor5,
                    ]}
                  />
                  <View
                    style={[
                      CommonStyles.justifyFlexRowEnd,
                      types.isWeb ? CommonStyles.marginTop5 : '',
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.title_ListContainer,
                        CommonStyles.marginRight0,
                        CommonStyles.customFont,
                      ]}
                    >
                      {messages.CLAIMABLE_FOR_APPROVAL}
                    </StandardText>
                  </View>
                </View>
                <View
                  style={[
                    CommonStyles.flex05,
                    CommonStyles.flexDirectionColumn,
                  ]}
                >
                  <View style={CommonStyles.justifyFlexRowEnd}>
                    <Image
                      style={[CommonStyles.changePasswordButtonImage]}
                      source={Images.InformationIconx3}
                      accessibilityLabel={
                        MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                      }
                      accessible={true}
                    />
                    <View>
                      <CurrencyText
                        value={this.state.invoiceDetails.notClaimable}
                        isPositive={true}
                        integerStyle={[
                          CommonStyles.font30,
                          CommonStyles.customFont,
                          BrandStyles.TextColor5,
                        ]}
                      />
                    </View>
                  </View>

                  <View
                    style={[
                      CommonStyles.justifyFlexRowEnd,
                      types.isWeb ? CommonStyles.marginTop5 : '',
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.title_ListContainer,
                        CommonStyles.marginRight0,
                        CommonStyles.customFont,
                      ]}
                    >
                      {messages.NOT_CLAIMABLE}
                    </StandardText>
                  </View>
                </View>
              </View>
            )}
        </View>
      );
    }
  };

  _renderAdditionalDetails = () => {
    const {approvalCheckMessages} = this.state.invoiceDetails;
    let finalMessages = [];

    if (approvalCheckMessages && approvalCheckMessages.length > 0) {
      finalMessages[0] = messages.ADDITIONAL_DETAILS[0];
      finalMessages = [...finalMessages, ...approvalCheckMessages];
      return finalMessages.map((item, index) => {
        return (
          <RadioButton
            style={[CommonStyles.paddingBottom5]}
            isSelected={true}
            buttonStyle={CommonStyles.radioButtonPMSmallChanged}
            onChange={(checkBoxObj: any) => {}}
            customImage={index !== 0 ? Images.InformationIconx3 : null}
          >
            <View
              style={[CommonStyles.radioButtonPMSmall_ContainerMultiselect]}
            >
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.justifyContentSpaceBetween,
                ]}
              >
                <StandardText
                  style={[
                    types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                    BrandStyles.TextColor10,
                    CommonStyles.customFont,
                  ]}
                >
                  {item}
                </StandardText>
              </View>
            </View>
          </RadioButton>
        );
      });
    }
    return messages.ADDITIONAL_DETAILS.map(item => {
      return (
        <RadioButton
          style={[CommonStyles.paddingBottom5]}
          isSelected={true}
          buttonStyle={CommonStyles.radioButtonPMSmallChanged}
          onChange={(checkBoxObj: any) => {}}
        >
          <View style={[CommonStyles.radioButtonPMSmall_ContainerMultiselect]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.justifyContentSpaceBetween,
              ]}
            >
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                  BrandStyles.TextColor10,
                  CommonStyles.customFont,
                ]}
              >
                {item}
              </StandardText>
            </View>
          </View>
        </RadioButton>
      );
    });
  };

  _renderNote = (note: any, type: any) => {
    return (
      <InvoiceNotes
        note={note}
        readOnly={!this.props.user.isEmployee}
        noteType={type}
        editInvoiceNotes={this._updateNote}
      />
    );
  };

  _updateNote = (note: any, type: any) => {
    const {invoiceDetails} = this.state;
    if (type === types2.INVOICE_NOTES.MEMBER_NOTE) {
      invoiceDetails.memberNote = note;
    } else if (type === types2.INVOICE_NOTES.WORK_NOTE) {
      invoiceDetails.workNote = note;
    }
    this.setState({
      invoiceDetails,
    });
  };

  _renderDeleteStyles = () => {
    let styles = {};
    return (styles = {
      style: [
        BrandStyles.brandBlockTxtColor6,
        CommonStyles.buttonBudgetAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    });
  };

  _renderSaveStyles = () => {
    let styles = {};
    return (styles = {
      style: [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonBudgetAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    });
  };

  _invoiceTotalValue = () => {
    let totalValue = 0.0;
    if (this.state.invoiceDetails.total) {
      totalValue = this.state.invoiceDetails.total;
    }
    return totalValue;
  };

  _invoiceApprovalValue = () => {
    let totalValue = 0.0;
    if (
      Array.isArray(this.state.invoiceDetails.pendingApproval) &&
      this.state.invoiceDetails.pendingApproval.length > 0
    ) {
      forEach(this.state.invoiceDetails.pendingApproval, item => {
        totalValue += item.total;
      });
    }
    return totalValue;
  };

  _renderAccordian = (
    title: any,
    subtitle: any,
    data: any,
    expandedDefault: any,
    buttonColor: any,
    icons: any,
  ) => {
    if (data && data.length > 0) {
      return (
        <View>
          <AccordianInvoiceTile
            title={title}
            openByDefault={expandedDefault}
            buttonColor={buttonColor}
            icons={icons}
          >
            <View style={CommonStyles.containerInvoice_Cards}>
              <InvoiceListHH
                actualInvoiced={messages.ACTUAL_INVOICED.show}
                actualInvoicedHide={messages.ACTUAL_INVOICED.hide}
                itemClaimedQty={messages.COLUMNS_HH.CLAIMED_QUANTITY}
                itemClaimedPrice={messages.COLUMNS_HH.CLAIMED_PRICE}
                itemClaimedTotal={messages.COLUMNS_HH.CLAIMED_TOTAL}
                itemInvoicedQuantity={messages.COLUMNS_HH.INVOICED_QUANTITY}
                itemInvoicedPrice={messages.COLUMNS_HH.INVOICED_PRICE}
                itemInvoicedTotal={messages.COLUMNS_HH.INVOICED_TOTAL}
                itemCode={messages.COLUMNS.ITEM_CODE}
                itemDesc={messages.COLUMNS.DESCRIPTION}
                itemCategory={messages.COLUMNS.CATEGORY}
                itemQty={messages.COLUMNS.QUANTITY}
                itemUnitPrice={messages.COLUMNS.UNIT_PRICE}
                itemTotalPrice={messages.COLUMNS.TOTAL_PRICE}
                itemDate={messages.COLUMNS.DATE}
                data={data}
                section={title}
              />
            </View>
          </AccordianInvoiceTile>
        </View>
      );
    }

    return null;
  };

  // Invoice attachement view eye icon
  _renderInvoiceDocumentLink = () => {
    const invoiceDocument = this.state.invoiceDetails.invoiceDocument;
    if (invoiceDocument) {
      return (
        <View style={[CommonStyles.contentWrapper, CommonStyles.marginLeft5]}>
          <View style={[CommonStyles.flex1, CommonStyles.flexRow]}>
            <StandardTouchableOpacity
              accessibilityLabel={
                PAYMENTS_DETAILS_SCREEN.OPEN_DOC +
                invoiceDocument.fileName +
                this._getFileExtension(invoiceDocument.contentType)
              }
              style={CommonStyles.InvoiceHeaderDocumentName}
              onPress={() => this._openDocument(invoiceDocument)}
            >
              <Image
                style={[CommonStyles.spendingSupportIconView]}
                source={Images.viewIconPurple2x}
              />
            </StandardTouchableOpacity>
          </View>
        </View>
      );
    }
  };

  _getFileExtension(contentType: any) {
    const extension = mime.extension(contentType);
    if (extension) {
      return '.' + extension;
    }
    return JEPG;
  }

  // Open invoice attachment document
  _openDocument(document: any) {
    if (types.isWeb) {
      window.open(document.url);
    }
    const fileType = document.contentType;
    if (Platform.OS === types2.IOS) {
      this.setState({loading: true});
      const fileNameModified =
        document.fileName + this._getFileExtension(document.contentType);
      const ios = RNFetchBlobSingleton.ios;
      const dirs = RNFetchBlobSingleton.fs.dirs;
      RNFetchBlobSingleton.config({
        path: dirs.DocumentDir + LEAPIN_PATH + fileNameModified,
        fileCache: true,
      })
        .fetch('GET', document.url, {})
        .then(res => {
          this.setState({loading: false});
          ios
            .openDocument(res.path())
            .catch((err: any) =>
              this._showAlert(
                types2.ERROR,
                types2.DOWNLOAD_ERROR,
                types2.OK,
                this._closeAlert,
                true,
              ),
            );
        })
        .catch(err => {
          this.setState({loading: false});
          this._showAlert(
            types2.ERROR,
            types2.DOWNLOAD_ERROR,
            types2.OK,
            this._closeAlert,
            true,
          );
        });
    }

    if (Platform.OS === types2.ANDROID) {
      if (fileType === types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.url,
          fileName: document.fileName,
        });
      } else if (types2.IMAGE_FILE_TYPES.includes(fileType)) {
        this.props.navigation.navigate('WebViewer', {
          url: document.url,
          fileName: document.fileName,
        });
      } else {
        // Download the file locally if it is not supported
        const url = document.url;
        const {config, fs} = RNFetchBlobSingleton;
        const downloadDir = fs.dirs.DownloadDir;
        const options = {
          fileCache: true,
          addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            mime: document.contentType,
            title: document.fileName + types2.DOCUMENT_DOWNLOAD,
            description: types2.DOCUMENT_DOWNLOAD_DESCRIPTION,
            mediaScannable: true,
            path: downloadDir + LEAPIN_PATH + document.fileName,
          },
        };
        config(options)
          .fetch('GET', url)
          .then(res => {});
      }
    }
  }

  /**
   * When user approve the invoice mark the notification as actioned
   */
  _notificationActioned = () => {
    let notificationData = this.props.navigationParams.params.notificationData;

    let notification = {
      id: notificationData.id,
      status: notificationData.status,
      isActioned: true,
    };

    const actionedCallback = () => {
      this.refreshSummary();
    };

    const actionedErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [
        updateNotifications(
          this.props.loadedMemberId.loadedMemberId,
          notification,
        ),
      ],
      actionedCallback,
      actionedErrorCallback,
    );
  };

  _approveItem = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});

    const approveCallback = (data: any) => {
      let responseMessage = '';

      if (data.length > 0) {
        responseMessage = data[0].content.message;
      }
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      // Show success popup for invoices
      this.setState({
        errorPopup: false,
        loading: false,
        messageHeader: summaryMessages.APPROVED_INVOICE,
        messageBody: responseMessage,
        approveModalVisible: true,
      });
    };

    const approveErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
      if (this.props.user.demoProfile) {
        this.setState({
          errorPopup: true,
          messageHeader: types2.DEMO_APPROVAL_FAIL.TITLE,
          messageBody: types2.DEMO_APPROVAL_FAIL.BODY,
          approveModalVisible: true,
        });
      } else {
        // Show error popup for invoices
        this.setState({
          errorPopup: true,
          messageHeader: messages.APPROVAL_FAILED,
          messageBody: err.message,
          approveModalVisible: true,
        });
      }
    };

    // Call the API to approve the invoice
    callAPIs(
      [
        putInvoiceApproval(
          this.props.loadedMemberId.loadedMemberId,
          this.selectedItem.uri,
          this.selectedItem.id,
          types2.APPROVAL.APPROVE,
        ),
      ],
      approveCallback,
      approveErrorCallback,
    );
  };

  _closeForm = () => {
    this.props.navigation.goBack();
  };

  _rejectItem = () => {
    let notificationData = null;

    if (
      this.props.navigationParams.params &&
      this.props.navigationParams.params.notificationData
    ) {
      notificationData = this.props.navigationParams.params.notificationData;
    }
    this.props.actions.navigationParamActions.setParam({
      invoiceDetails: this.state.invoiceDetails,
      selectedItem: this.selectedItem,
      refreshSummary: this.refreshSummary,
      notificationData,
    });
    this.props.navigation.navigate('ApprovalsDetailsInvoiceReject');
  };

  _renderApprovePopUp = (isTablet: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.approveModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closeApprovePopUp}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={this.state.modalCloseLabel}
      >
        {this.state.errorPopup
          ? this._renderErrorPopup(isTablet)
          : this._renderSuccessPopup(isTablet)}
      </BasicOverlayPopupModal>
    );
  };
  _renderErrorPopup = (isTablet: any) => {
    return (
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom20,
          ]}
        >
          <View>
            <Image
              visible={this.state.errorPopup}
              style={[CommonStyles.changePasswordButtonImage]}
              source={Images.InformationIconx3}
              accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
              accessible={true}
            />
          </View>

          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                isTablet ? CommonStyles.font25 : CommonStyles.font20,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {this.state.messageHeader}
            </StandardText>
          </View>
        </View>
        <View style={[CommonStyles.alignItemsCenter]}>
          <StandardText
            style={[
              CommonStyles.font18,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
            ]}
          >
            {this.state.messageBody}
          </StandardText>
        </View>
        <View style={[CommonStyles.containerInstructionDevider]} />
      </View>
    );
  };

  _renderSuccessPopup = (isTablet: any) => {
    let messageFontSize = CommonStyles.font18;
    let headerFontSize = CommonStyles.font20;
    if (isTablet) {
      messageFontSize = CommonStyles.font20;
      headerFontSize = CommonStyles.font25;
    } else if (types.isWeb) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    }
    return (
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom20,
          ]}
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {STAR_RATING.APPROVED}
            </StandardText>
          </View>
        </View>
        <View style={[CommonStyles.alignItemsCenter]}>
          <StandardText
            style={[
              messageFontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
              CommonStyles.paddingBottom20,
            ]}
          >
            {STAR_RATING.YOUR_INVOICE_APPROVED}
          </StandardText>
        </View>

        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom20,
          ]}
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {STAR_RATING.YOUR_RATING}
            </StandardText>
          </View>
        </View>
        <View style={[CommonStyles.alignItemsCenter]}>
          <StandardText
            style={[
              messageFontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
              CommonStyles.paddingBottom20,
            ]}
          >
            {STAR_RATING.HOW_DID_YOU_FIND}
          </StandardText>
        </View>
        <View
          style={[
            types.isWeb
              ? CommonStyles.starRatingCenter
              : CommonStyles.paddingBottom20,
          ]}
        >
          <StarRating
            maxStars={5}
            rating={this.state.starCount}
            disabled={false}
            onStarClick={(rating: any) => this.onStarRatingPress(rating)}
          />
        </View>

        <View style={[CommonStyles.alignItemsCenter]}>
          <StandardText
            style={[
              messageFontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
            ]}
          >
            {STAR_RATING.YOU_CAN_SKIP}
          </StandardText>
        </View>
        <View style={[CommonStyles.containerInstructionDevider]} />
      </View>
    );
  };

  onStarRatingPress(rating: any) {
    let toggle = 0;
    if (this.state.starCount !== rating) {
      toggle = rating;
      this.setState({
        modalCloseLabel: types2.STAR_RATING.SAVE,
      });
    } else if (this.state.starCount == rating && this.state.starCount !== 0) {
      toggle = 0;
      this.setState({
        modalCloseLabel: types2.STAR_RATING.DISMISS,
      });
    }
    this.setState({
      starCount: toggle,
    });
  }

  _getRatingType = () => {
    let ratingTypeId;
    const itemTpye = this.props.navigationParams.params.selectedItem.type;
    if (this.state.invoiceDetails['ratingTypes']) {
      this.state.invoiceDetails['ratingTypes'].forEach(function (obj: any) {
        if (obj.RatingType == itemTpye) {
          ratingTypeId = obj.Id;
        }
      });
      return ratingTypeId;
    }
  };

  _submitRatingAction = () => {
    const type = this._getRatingType();
    const ratingId = this.state.invoiceDetails.ratingInfo.id;
    const ratingValue = this.state.starCount;
    const comment = '';
    const providerKey = this.state.invoiceDetails.biller.billerKey;
    const providerName = this.state.invoiceDetails.biller.name;
    const itemKey = this.props.navigationParams.params.selectedItem.invoiceKey;
    const itemNumber = this.props.navigationParams.params.selectedItem.number;
    const ratingObject = createRatingObject(
      type,
      ratingValue,
      comment,
      providerKey,
      providerName,
      itemKey,
      itemNumber,
    );

    const ratingUpdateObject = createRatingUpdateObject(
      ratingId,
      ratingValue,
      comment,
    );

    const ratingCallBack = () => {
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({
          loading: false,
        });
      }
    };

    const ratingErrorCallBack = (err: any) => {
      if (err.code !== undefined) {
        logger.log('Rating error: ', err);
      }
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({
          loading: false,
        });
      }
    };
    if (this.state.invoiceDetails.ratingInfo == '') {
      callAPIs(
        [postRating(ratingObject, false)],
        ratingCallBack,
        ratingErrorCallBack,
      );
    } else {
      callAPIs(
        [putRating(ratingUpdateObject, false)],
        ratingCallBack,
        ratingErrorCallBack,
      );
    }
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height320,
      ]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height300,
        CommonStyles.width340,
      );
    }
    return styles;
  };

  _showApprovalPopUp = () => {
    this.setState({approveModalVisible: true});
  };

  _closeApprovePopUp = () => {
    if (this.state.modalCloseLabel === types2.STAR_RATING.SAVE) {
      this._submitRatingAction();
    }
    this._closeForm();
    if (
      this.props.navigation.state &&
      this.props.navigation.state.params &&
      this.props.navigation.state.params.notificationData
    ) {
      this._notificationActioned();
    } else {
      this.refreshSummary();
    }

    this.setState({approveModalVisible: false});
  };

  _closeErrorPopup = () => {
    this.setState({
      errorPopUpVisible: false,
      loading: false,
    });
    this.refreshSummary();
    this.props.navigation.dispatch(CommonActions.goBack());
  };

  _updateInvoice = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }
    const {invoiceDetails} = this.state;
    const {loadedMemberId} = this.props;
    const invoiceKey = this.selectedItem.invoiceKey;
    const putInvoiceCallbackFunction = (data: any) => {
      if (parseInt(data[0].statusCode) == 200) {
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
      }
    };

    const putInvoiceErrorCallbackFunction = (err: any, reject: any) => {
      if (err) {
        this._showAlert(
          types2.ERROR,
          INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
          types2.OK,
          this._closeAlert,
          true,
        );
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
        this.setState({
          saveApiErrorCode: err.code,
          saveApiError: true,
        });
      }
    };

    callAPIs(
      [
        putInvoice(
          loadedMemberId.loadedMemberId,
          invoiceKey,
          invoiceDetails,
          false,
        ),
      ],
      putInvoiceCallbackFunction,
      putInvoiceErrorCallbackFunction,
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    navigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovalsDetailsInvoiceHH);
