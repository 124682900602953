/*
 * Author: Shelan Cooray
 * Date: 02/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Plan Management Options Landing screen.
 */

import React, {Component} from 'react';
import {filter} from 'lodash';
import {StandardText, StandardButton} from '../Components/Atoms';
import {
  Container,
  CrewNoAccess,
  ModalApiError,
  RadioGroup,
  RadioButton,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import {OfflineNotice} from '../Components/Organisms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as planManage from '../Constants/PMLearnMoreConstants';
import {MESSAGES} from '../Constants/Messages';
import {LISignUpPlanLogo3x, InformationIconx3} from '../assets/images';
import {
  ApprovalsRed3x,
  ExplorePlanManagement4x,
  PRPlanMeetingSelectedImage,
  MediumRedTickCircleRed3x,
  MediumRedEmptyCircleRed3x,
} from '../assets/images/vector-icons';
import CustomIcon from '../assets/images/CustomIcon';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isTablet} from '../Helpers/PlatformSynchronizer';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {updateMember} from '../API/MemberAPI';
import * as PlanManagerActions from '../Actions/PlanManagerActions';
import * as MemberActions from '../Actions/MemberActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {PM_SIGN_UP_URL} from '../environments';
import {RootTabContext} from '../context/RootTabContext';

// import Orientation from 'react-native-orientation-enhanced';
import {setValue, removeItem} from '../API/WebPersistenceStore';
import {View, Image, Linking, TouchableOpacity, Text} from 'react-native';
const PM_OPTIONS = {
  PLAN_MANAGED: 0,
  SELF_MANAGED: 1,
  NDIA_MANAGED: 2,
};

const PM_SUB_OPTIONS = {
  LEAP_IN: 0,
  OTHER: 1,
};

class PlanManagement extends Component {
  static contextType = RootTabContext;

  // state = {
  //   loading: false,
  //   saveAPIError: false,
  //   data: [],
  //   planManagedModalVisible: false,
  //   isOpen: !types.isWeb ? this.props.screenProps.getInitialMenuState() : false,
  // };

  constructor(props: any) {
    super(props);
    //const { screenProps = {}} = this.context; ::ToDO
    this.state = {
      loading: false,
      saveAPIError: false,
      data: [],
      planManagedModalVisible: false,
      isOpen: false, //!types.isWeb ? screenProps?.getInitialMenuState() : false, ::ToDO
    };

    this._createMainContents.bind(this);
    this._createAllModals.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this._createOptionContents.bind(this);
    this._selectedOption = this._selectedOption.bind(this);
    this._deviceOrientationCheck = this._deviceOrientationCheck.bind(this);
  }

  componentDidMount() {
    this._generateManagedData();
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({
  //         orientation: orientation,
  //       });
  //     });
  //   }
  // }

  componentWillUnmount() {
    //const {screenProps} = this.context;
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  onSelect(index: any, value: any) {
    if (index == 0) {
      let checkItem = {
        type: types2.PLAN_MANAGER_DATA.PLAN_MANAGED,
        selected: value ? true : false,
        displayText: value
          ? MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED
          : MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED,
      };
      if (value) {
        this._onChangePlanManagement(checkItem);
        this._showPlanManagedModal();
      } else {
        this._onChangePlanManagement(checkItem);
      }
    } else if (index == 1) {
      let checkItem = {
        type: types2.PLAN_MANAGER_DATA.SELF_MANAGED,
        selected: value ? true : false,
        displayText: value
          ? MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED
          : MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED,
      };
      this._onChangePlanManagement(checkItem);
    } else if (index == 2) {
      let checkItem = {
        type: types2.PLAN_MANAGER_DATA.AGENCY,
        selected: value ? true : false,
        displayText: value
          ? MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED
          : MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED,
      };
      this._onChangePlanManagement(checkItem);
    }
  }

  onSelectSubOption(index: any, value: any) {
    if (index == 0) {
      let checkItem = {
        type: types2.PLAN_MANAGER_DATA.LEAP_IN,
        selected: value ? true : false,
        displayText: value
          ? MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED
          : MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED,
      };
      this._onChangePlanManagement(checkItem);
    } else if (index == 1) {
      let checkItem = {
        type: types2.PLAN_MANAGER_DATA.OTHER,
        selected: value ? true : false,
        displayText: value
          ? MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED
          : MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED,
      };
      this._onChangePlanManagement(checkItem);
    }
  }

  _generateManagedData = () => {
    let tempArray = [];
    let managedTypes = types2.PLAN_MANAGER_DATA;
    for (let i in managedTypes) {
      if (managedTypes.hasOwnProperty(i)) {
        let tempObj = {
          type: managedTypes[i],
          selected: false,
          displayText: MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED,
        };
        tempArray.push(tempObj);
      }
    }

    //Set the selected one to true if it exists
    if (this.props.member.planManager) {
      //if this.props.memeber.planManager exists, update the tempArray with the true value
      let checkItem = this.props.member.planManager;
      for (let i = 0; i < tempArray.length; i++) {
        if (
          checkItem == types2.PLAN_MANAGER_DATA.LEAP_IN ||
          checkItem == types2.PLAN_MANAGER_DATA.OTHER
        ) {
          //Leap in! was selected so also check plan managed
          if (tempArray[i].type == types2.PLAN_MANAGER_DATA.PLAN_MANAGED) {
            tempArray[i].selected = true;
            tempArray[i].displayText =
              MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED;
          }
        }
        if (checkItem == tempArray[i].type) {
          //found the one to set to true!
          tempArray[i].selected = true;
          tempArray[i].displayText =
            MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED;
        }
      }
    }
    this.props.actions.PlanManagerActions.actionSetManagement(tempArray);
  };

  _selectedOption = (option: any) => {
    if (
      this.props.member.planManager === types2.PLAN_MANAGER_DATA.LEAP_IN ||
      this.props.member.planManager === types2.PLAN_MANAGER_DATA.OTHER
    ) {
      if (option.OPTION_KEY === types2.PLAN_MANAGER_DATA.PLAN_MANAGED) {
        return true;
      }
    } else if (option.OPTION_KEY === this.props.member.planManager) {
      return true;
    } else {
      return false;
    }
  };

  // This function is to set 'selectedIndex' props initially in Radio Button Group
  _selectedOptionKey = (options: any) => {
    let selectedIndex = -1;
    let indexArray = options.map((option: any, index: any) => {
      if (
        this.props.member.planManager === types2.PLAN_MANAGER_DATA.LEAP_IN ||
        this.props.member.planManager === types2.PLAN_MANAGER_DATA.OTHER
      ) {
        if (option.OPTION_KEY === types2.PLAN_MANAGER_DATA.PLAN_MANAGED) {
          return index;
        }
      } else if (option.OPTION_KEY === this.props.member.planManager) {
        return index;
      } else {
        return -1;
      }
    });

    let filteredIndex = indexArray.findIndex((index: any) => {
      return index > -1;
    });

    selectedIndex = filteredIndex;
    return selectedIndex;
  };

  _selectedSubOption = (option: any) => {
    if (option.SUB_OPTION_KEY === this.props.member.planManager) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    if (!this.props.member.id && this.props.user.isCrew) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }

    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        modals={this._createAllModals}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.PLAN_MANAGEMENT_OPTIONS}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
        byPassOnLayout={true}
      />
    );
  }

  _createAllModals = () => {
    let modalJSX = [this._createPlanManagedPopUp(isTablet())];
    return modalJSX;
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerPMPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPMPopUpWebMobile]);
      }
    } else if (isTablet) {
      styles.push(CommonStyles.containerPMPopUpTablet);
      if (this.state.orientation === types2.ORIENTATION.PORTRAIT) {
        styles.push({width: planManage.POPUP_WIDTH_TABLET_PORTRAIT});
      } else {
        styles.push({width: planManage.POPUP_WIDTH_TABLET_LANDSCAPE});
      }
    } else {
      styles.push(CommonStyles.containerPMPopUpMobile);
    }
    return styles;
  };

  _createPlanManagedPopUp = (isTablet: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.planManagedModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closePlanManagedModal}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}>
        <View style={[CommonStyles.flexDirectionColumn]}>
          <View>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
                BrandStyles.TextColor4,
                CommonStyles.customFontSemiBold,
              ]}>
              {planManage.PLAN_MANAGED_POP_UP.POP_UP_TITLE}
            </StandardText>
          </View>
          <View style={[CommonStyles.containerPMPopUpRadioGroup]}>
            <RadioGroup
              onSelect={(index: any, value: any) =>
                this.onSelectSubOption(index, value)
              }>
              <RadioButton
                key={PM_SUB_OPTIONS.LEAP_IN}
                value={
                  planManage.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED]
                    .SUB_OPTIONS[PM_SUB_OPTIONS.LEAP_IN].SUB_OPTION_KEY
                }
                isSelected={this._selectedSubOption(
                  planManage.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED]
                    .SUB_OPTIONS[PM_SUB_OPTIONS.LEAP_IN],
                )}
                style={[
                  CommonStyles.radioButtonPM_DividerStyle,
                  CommonStyles.paddingTop10,
                ]}
                buttonStyle={CommonStyles.radioButtonPMSmall}
                buttonTick={MediumRedTickCircleRed3x}
                buttonEmpty={MediumRedEmptyCircleRed3x}
                disabled={
                  this.props.member.access.profile != types.ACCESS_FULL
                }>
                <View style={[CommonStyles.radioButtonPMSmall_Container]}>
                  <View
                    style={[
                      CommonStyles.flexDirectionRow,
                      CommonStyles.justifyContentSpaceBetween,
                    ]}>
                    <StandardText
                      style={[
                        types.isWeb
                          ? [CommonStyles.font15, CommonStyles.marginRight20]
                          : CommonStyles.font17,
                        BrandStyles.TextColor5,
                        CommonStyles.customFont,
                        CommonStyles.textAlignVerticalCenter,
                        this.setTextPaddingPopUpOptionOne(isTablet),
                      ]}>
                      {
                        planManage.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED]
                          .SUB_OPTIONS[PM_SUB_OPTIONS.LEAP_IN].SUB_OPTION_TITLE
                      }
                    </StandardText>
                    {this._renderLearnMoreButtonTablet(
                      isTablet,
                      PM_SUB_OPTIONS.LEAP_IN,
                      true,
                    )}
                  </View>
                  {this._renderLearnMoreButtonMobile(
                    isTablet,
                    PM_SUB_OPTIONS.LEAP_IN,
                    true,
                  )}
                </View>
              </RadioButton>
              <RadioButton
                key={PM_SUB_OPTIONS.OTHER}
                value={
                  planManage.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED]
                    .SUB_OPTIONS[PM_SUB_OPTIONS.OTHER].SUB_OPTION_KEY
                }
                isSelected={this._selectedSubOption(
                  planManage.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED]
                    .SUB_OPTIONS[PM_SUB_OPTIONS.OTHER],
                )}
                style={[
                  CommonStyles.radioButtonPM_DividerStyle,
                  CommonStyles.paddingTop10,
                ]}
                buttonStyle={CommonStyles.radioButtonPMSmall}
                buttonTick={MediumRedTickCircleRed3x}
                buttonEmpty={MediumRedEmptyCircleRed3x}
                disabled={
                  this.props.member.access.profile != types.ACCESS_FULL
                }>
                <View style={[CommonStyles.radioButtonPMSmall_Container]}>
                  <View
                    style={[
                      CommonStyles.flexDirectionRow,
                      CommonStyles.justifyContentSpaceBetween,
                    ]}>
                    <StandardText
                      style={[
                        types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                        BrandStyles.TextColor5,
                        CommonStyles.customFont,
                        CommonStyles.textAlignVerticalCenter,
                        this.setTextPaddingPopUpOptionTwo(isTablet),
                      ]}>
                      {
                        planManage.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED]
                          .SUB_OPTIONS[PM_SUB_OPTIONS.OTHER].SUB_OPTION_TITLE
                      }
                    </StandardText>
                  </View>
                </View>
              </RadioButton>
            </RadioGroup>
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  setTextPaddingPopUpOptionOne(isTablet: any) {
    if (types.isWeb) {
      return null;
    }

    if (isTablet && this.state.orientation !== types2.ORIENTATION.LANDSCAPE) {
      return CommonStyles.paddingTop10;
    }
  }

  setTextPaddingPopUpOptionTwo(isTablet: any) {
    if (types.isWeb) {
      return null;
    }
    if (isTablet) {
      return CommonStyles.paddingTop10;
    }
  }

  _showPlanManagedModal = () => {
    this.setState({planManagedModalVisible: true});
  };

  _closePlanManagedModal = () => {
    this.setState({planManagedModalVisible: false});
  };

  _deviceOrientationCheck = (isTablet: any) => {
    if (
      isTablet &&
      (this.state.orientation === types2.ORIENTATION.LANDSCAPE ||
        (this.state.orientation !== types2.ORIENTATION.LANDSCAPE &&
          this.state.isOpen == false))
    ) {
      return true;
    } else {
      return false;
    }
  };

  _renderLearnMoreButtonTablet = (
    isTablet: any,
    index: any,
    closePopUp: any,
  ) => {
    if (types.isWeb && this.props.isMobile) {
      return null;
    }

    if (
      types.isWeb ||
      this._deviceOrientationCheck(
        isTablet &&
          (!closePopUp ||
            (closePopUp &&
              this.state.orientation === types2.ORIENTATION.LANDSCAPE)),
      )
    ) {
      return (
        <StandardButton
          style={[
            BrandStyles.brandBlockTxtColor6,
            CommonStyles.buttonLearnMore,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor1,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerLearnMore,
          ]}
          onPress={() =>
            this._navigateLearnMoreOption(
              planManage.MANAGED_OPTIONS[index].OPTION_KEY,
              closePopUp,
            )
          }>
          {types2.LEARN_MORE}
        </StandardButton>
      );
    }
  };

  _renderLearnMoreButtonMobile = (
    isTablet: any,
    index: any,
    closePopUp: any,
  ) => {
    if (types.isWeb && !this.props.isMobile) {
      return null;
    }

    if (
      (types.isWeb && this.props.isMobile) ||
      !isTablet ||
      (isTablet &&
        this.state.orientation !== types2.ORIENTATION.LANDSCAPE &&
        (this.state.isOpen || closePopUp))
    ) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignFlexEnd,
            CommonStyles.marginTop10,
          ]}>
          <StandardButton
            style={[
              BrandStyles.brandBlockTxtColor6,
              CommonStyles.buttonLearnMore,
              CommonStyles.customFontBold,
            ]}
            containerStyle={[
              BrandStyles.primaryBgColor1,
              BrandStyles.brandBorderColor1,
              CommonStyles.buttonContainerLearnMore,
            ]}
            onPress={() =>
              this._navigateLearnMoreOption(
                planManage.MANAGED_OPTIONS[index].OPTION_KEY,
                closePopUp,
              )
            }>
            {types2.LEARN_MORE}
          </StandardButton>
        </View>
      );
    }
  };

  _renderRadioButtons = (options: any, isTablet: any) => {
    return options.map((option: any, index: any) => (
      <RadioButton
        key={index}
        value={option.OPTION_KEY}
        isSelected={this._selectedOption(option)}
        style={[CommonStyles.paddingTop10, CommonStyles.paddingRight10]}
        buttonStyle={
          types.isWeb
            ? CommonStyles.radioButtonPMWeb
            : CommonStyles.radioButtonPM
        }
        buttonTick={MediumRedTickCircleRed3x}
        buttonEmpty={MediumRedEmptyCircleRed3x}
        disabled={this.props.member.access.profile != types.ACCESS_FULL}>
        <View style={[CommonStyles.radioButtonPM_Container]}>
          <View
            style={[
              types.isWeb ? {} : CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.justifyContentSpaceBetween,
              CommonStyles.flexWrap,
            ]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
                BrandStyles.TextColor4,
                CommonStyles.customFontSemiBold,
              ]}>
              {planManage.MANAGED_OPTIONS[index].OPTION_TITLE}
            </StandardText>
            {this._renderLearnMoreButtonTablet(isTablet, index, false)}
          </View>
          <View
            style={[
              types.isWeb ? {} : CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.paddingTop10,
            ]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                CommonStyles.customFont,
                BrandStyles.TextColor5,
                CommonStyles.alignSelfFlexStart,
                CommonStyles.textLineHeight25,
              ]}>
              {planManage.MANAGED_OPTIONS[index].OPTION_DESC}
            </StandardText>
          </View>
          {this._renderLearnMoreButtonMobile(isTablet, index, false)}
        </View>
      </RadioButton>
    ));
  };

  _determineRadioGroupStyle = (isTablet: any) => {
    let styles = [CommonStyles.flex1];
    if (isTablet) {
      styles = styles.push({paddingLeft: 45});
    }
  };

  _createOptionContents = (isTablet: any) => {
    return (
      <View style={this._determineRadioGroupStyle(isTablet)}>
        <RadioGroup
          onSelect={(index: any, value: any) => this.onSelect(index, value)}
          selectedIndex={this._selectedOptionKey(planManage.MANAGED_OPTIONS)}>
          {this._renderRadioButtons(planManage.MANAGED_OPTIONS, isTablet)}
        </RadioGroup>
      </View>
    );
  };

  _renderPMSingUpContent = (isTablet: any) => {
    let buttonDisable = false;
    if (this.props.member.access && !this.props.member.access.decisionMaker) {
      buttonDisable = true;
    }
    return (
      <View
        style={
          this._deviceOrientationCheck(isTablet)
            ? [
                CommonStyles.flexDirectionRow,
                CommonStyles.paddingTop10,
                CommonStyles.paddingRight10,
                CommonStyles.justifyContentSpaceBetween,
              ]
            : [
                CommonStyles.flexDirectionColumn,
                CommonStyles.paddingTop10,
                CommonStyles.paddingRight10,
              ]
        }>
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}>
          <Image
            style={[
              CommonStyles.leapInPlanManagedIcon,
              CommonStyles.titleBoxIconMargin,
            ]}
            source={LISignUpPlanLogo3x}
          />
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.alignFlexStart,
              CommonStyles.alignCenter,
            ]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                CommonStyles.customFont,
                CommonStyles.alignSelfFlexStart,
                BrandStyles.TextColor5,
              ]}>
              {MESSAGES.PLAN_MANAGEMENT_SCREEN.CONTENT_SIGNUP_DESC}
            </StandardText>
          </View>
        </View>
        <View
          style={
            this._deviceOrientationCheck(isTablet)
              ? [CommonStyles.alignFlexEnd]
              : [
                  CommonStyles.flex1,
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignFlexEnd,
                  CommonStyles.paddingTop10,
                ]
          }>
          <StandardButton
            style={this._renderButtonStyle(buttonDisable)}
            containerStyle={this._renderButtonContainerStyle(buttonDisable)}
            onPress={this._transitionToLIManaged}
            disabled={buttonDisable}>
            {types2.SIGN_UP}
          </StandardButton>
        </View>
      </View>
    );
  };

  _renderButtonStyle = (buttonDisable: any) => {
    if (buttonDisable) {
      return [
        BrandStyles.TextColor10,
        CommonStyles.buttonLearnMore,
        CommonStyles.customFontBold,
      ];
    } else {
      return [
        BrandStyles.TextColor4,
        CommonStyles.buttonLearnMore,
        CommonStyles.customFontBold,
      ];
    }
  };

  _renderButtonContainerStyle = (buttonDisable: any) => {
    if (buttonDisable) {
      return [
        BrandStyles.secondaryBgColor3,
        BrandStyles.borderColor8,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      return [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerLearnMore,
      ];
    }
  };

  _createMainContents = () => {
    let headerFont = CommonStyles.rpfont18;
    if (types.isWeb) {
      headerFont = CommonStyles.font20;
    } else if (isTablet) {
      headerFont = CommonStyles.rpfont25;
    }
    return (
      <View style={[CommonStyles.screenWrapper]}>
        <View style={[CommonStyles.containerPMOptions]}>
          {this.state.saveApiError && (
            <ModalApiError errorCode={this.state.saveApiErrorCode} />
          )}
          {this.props.member.memberStatus ===
            types2.MEMBER_STATUS_DATA.PLAN_READY && (
            <View style={[CommonStyles.paddingBottom10]}>
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.PlanManagementHeading,
                  CommonStyles.SummaryTileHeaderContainer,
                  BrandStyles.headerContainsDataBorder,
                  CommonStyles.alignCenter,
                ]}>
                <CustomIcon
                  style={[
                    CommonStyles.leapInPlanManagedIcon,
                    CommonStyles.titleBoxIconMargin,
                    CommonStyles.alignFlexStart,
                    CommonStyles.marginTop5,
                  ]}
                  name={ApprovalsRed3x}
                />
                <View style={[CommonStyles.flex1, CommonStyles.alignFlexStart]}>
                  <StandardText
                    style={[
                      headerFont,
                      CommonStyles.customFontSemiBold,
                      BrandStyles.TextColor3,
                      CommonStyles.textAlignVerticalCenter,
                    ]}>
                    {MESSAGES.PLAN_MANAGEMENT_SCREEN.CONTENT_TITLE1}
                  </StandardText>
                </View>
              </View>
              {this._renderPMSingUpContent(isTablet)}
            </View>
          )}

          <View style={[CommonStyles.paddingBottom10]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.alignItemsCenter,
                CommonStyles.PlanManagementHeading,
                CommonStyles.SummaryTileHeaderContainer,
                BrandStyles.headerContainsDataBorder,
                CommonStyles.alignCenter,
              ]}>
              <CustomIcon
                style={[
                  CommonStyles.leapInPlanManagedIcon,
                  CommonStyles.titleBoxIconMargin,
                ]}
                name={ExplorePlanManagement4x}
              />
              <View style={[CommonStyles.flex1, CommonStyles.alignFlexStart]}>
                <StandardText
                  style={[
                    headerFont,
                    CommonStyles.customFontSemiBold,
                    BrandStyles.TextColor3,
                    CommonStyles.textAlignVerticalCenter,
                  ]}>
                  {this.props.member.memberStatus ===
                  types2.MEMBER_STATUS_DATA.PLAN_READY
                    ? MESSAGES.PLAN_MANAGEMENT_SCREEN.CONTENT_TITLE2_PR
                    : MESSAGES.PLAN_MANAGEMENT_SCREEN
                        .CONTENT_TITLE2_PM_ONBOARDING}
                </StandardText>
              </View>
            </View>
            <View style={[CommonStyles.containerImportantNotification]}>
              <Image
                style={[
                  CommonStyles.titleBoxIcon,
                  CommonStyles.titleBoxIconMargin,
                  CommonStyles.alignSelfCenter,
                ]}
                source={InformationIconx3}
                accessibilityLabel={
                  MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
              <View
                style={[
                  BrandStyles.secondaryBgColor3,
                  CommonStyles.PlanSummaryActionBox,
                  CommonStyles.justifyContentSpaceBetween,
                  CommonStyles.containerRoundCornersBorderless,
                  CommonStyles.marginBottom10,
                ]}>
                <StandardText
                  style={[
                    CommonStyles.SummaryTileText,
                    BrandStyles.TextColor5,
                    CommonStyles.customFontMedium,
                  ]}>
                  {planManage.NOTICE1}
                </StandardText>
                <StandardText
                  style={[
                    CommonStyles.SummaryTileText,
                    BrandStyles.TextColor5,
                    CommonStyles.customFontMedium,
                  ]}>
                  {planManage.NOTICE2}
                </StandardText>
              </View>
            </View>
            {this._createOptionContents(isTablet())}
          </View>

          <View style={[CommonStyles.paddingBottom10]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.alignCenter,
                CommonStyles.PlanManagementHeading,
                CommonStyles.SummaryTileHeaderContainer,
                BrandStyles.headerContainsDataBorder,
              ]}>
              <CustomIcon
                style={[
                  CommonStyles.leapInPlanManagedIcon,
                  CommonStyles.titleBoxIconMargin,
                ]}
                name={PRPlanMeetingSelectedImage}
              />
              <View style={[CommonStyles.flex1, CommonStyles.alignFlexStart]}>
                <StandardText
                  style={[
                    headerFont,
                    CommonStyles.customFontSemiBold,
                    BrandStyles.TextColor3,
                    CommonStyles.textAlignVerticalCenter,
                  ]}>
                  {MESSAGES.PLAN_MANAGEMENT_SCREEN.CONTENT_TITLE3}
                </StandardText>
              </View>
            </View>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.paddingTop10,
                CommonStyles.justifyContentSpaceBetween,
              ]}>
              <View
                style={[
                  CommonStyles.flexDirectionColumn,
                  types.isWeb ? CommonStyles.flex1 : {},
                ]}>
                <StandardText
                  style={[
                    types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                    CommonStyles.alignSelfFlexStart,
                    CommonStyles.paddingBottom10,
                  ]}>
                  {MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_DESC}
                </StandardText>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                  }}>
                  <StandardText
                    style={[
                      types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                      CommonStyles.customFont,
                      CommonStyles.alignCenter,
                      BrandStyles.TextColor5,
                    ]}>
                    {MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_TEL_TEXT}
                  </StandardText>
                  { !types.isWeb ? 
                  <TouchableOpacity
                    onPress={() =>
                      this._makeCall(
                        MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_TEL_NUMBER,
                      )
                    }>
                    <Text
                     style={{color: 'blue', textDecorationLine: 'underline'}}
                      >
                      {MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_TEL_NUMBER}
                    </Text>
                  </TouchableOpacity> : <a href={`tel:${MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_TEL_NUMBER}`}>
                <Text  style={{color: 'blue'}}>{MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_TEL_NUMBER}</Text> 
                  </a> }
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                  }}>
                  <StandardText
                    style={[
                      types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                      CommonStyles.customFont,
                      BrandStyles.TextColor5,
                      CommonStyles.alignCenter,
                    ]}>
                    {MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_EMAIL_TEXT}
                  </StandardText>
                  {!types.isWeb ?
                  <TouchableOpacity onPress={this._handlePress}>
                  <Text 
                    style={{color: 'blue', textDecorationLine: 'underline'}}>
                    
                      {MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_EMAIL}
                    
                  </Text>
                  </TouchableOpacity>
                :
                <a href={`${encodeURI(MESSAGES.PLAN_MANAGEMENT_SCREEN.EMAIL_URL)}`}>
               <Text style={{color: 'blue'}}>{MESSAGES.PLAN_MANAGEMENT_SCREEN.QUESTIONS_EMAIL}</Text>  
                  </a>
                }
                  
                </View>
              </View>
            </View>
          </View>
          {types.isWeb ? this._createPlanManagedPopUp(isTablet()) : null}
        </View>
      </View>
    );
  };
  _handlePress = async () => {
    const url = encodeURI(MESSAGES.PLAN_MANAGEMENT_SCREEN.EMAIL_URL);
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log(`Don't know how to open URL: ${url}`);
    }
  };
  _makeCall = async (phoneNumber: string) => {
    const url = `tel:${phoneNumber}`;
    Linking.canOpenURL(url)
      .then(supported => {
        if (!supported) {
          console.log('Phone number is not available');
        } else {
          return Linking.openURL(url);
        }
      })
      .catch(err => console.error('An error occurred', err));
  };
  _transitionToLIManaged = () => {
    if (this.props.user.demoProfile) {
      if (types.isWeb) {
        window.open(PM_SIGN_UP_URL);
      } else {
        Linking.openURL(PM_SIGN_UP_URL);
      }
    } else {
      this.props.navigation.navigate('UploadPersonalDetailsPM');
    }
  };

  _navigateLearnMoreOption = (option: any, closePopUp: any) => {
    if (closePopUp) {
      this._closePlanManagedModal();
    }
    this.props.actions.NavigationParamActions.setParam({
      lernMoreOption: option,
    });
    if (types.isWeb) {
      removeItem('learnMoreOption');
      setValue('learnMoreOption', option);
    }
    this.props.navigation.navigate('LearnMoreOptions');
  };

  _onChangePlanManagement = (data: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    let tempArray = JSON.parse(
      JSON.stringify(this.props.planManager.management),
    );
    let checkItem = data;

    //only do an update if a value is being selected or if Leap in! is being unselected as a plan manager
    //first ensure everything to false if not the one that was toggled
    for (let i = 0; i < tempArray.length; i++) {
      if (checkItem.type != tempArray[i].type) {
        tempArray[i].selected = false;
        tempArray[i].displayText =
          MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_UNSELECTED;
      } else {
        tempArray[i].selected = checkItem.selected;
        tempArray[i].displayText = checkItem.displayText;
      }
    }

    // If leap In was checked, then check both PLAN_MANAGED & LEAP_IN --note: don't uncheck both if leap in was unchecked
    // note: if you want to have both forced to be unchecked on unchecking LEAP IN, change the if statement to be:
    if (
      checkItem.type == types2.PLAN_MANAGER_DATA.LEAP_IN ||
      checkItem.type == types2.PLAN_MANAGER_DATA.OTHER
    ) {
      for (let i = 0; i < tempArray.length; i++) {
        if (tempArray[i].type == types2.PLAN_MANAGER_DATA.PLAN_MANAGED) {
          tempArray[i].selected = true;
          tempArray[i].displayText =
            MESSAGES.PLAN_MANAGEMENT_SCREEN.CHECKBOX_SELECTED;
        }
      }
    }
    let reduxArray = JSON.parse(JSON.stringify(tempArray));
    // determine new value to submit via API
    let saveObj = this._buildSaveObj(tempArray);
    this._savePlanManagement(saveObj, reduxArray);
  };

  _buildSaveObj = (updateArray: any) => {
    let tempStr = types2.EMPTY_STRING;
    let selectedOptions = filter(updateArray, ['selected', true]);

    if (selectedOptions.length > 1) {
      let selectedOption = filter(selectedOptions, i => {
        return (
          i.type === types2.PLAN_MANAGER_DATA.LEAP_IN ||
          i.type === types2.PLAN_MANAGER_DATA.OTHER
        );
      });
      tempStr = selectedOption[0].type;
    } else if (selectedOptions.length === 1) {
      tempStr = selectedOptions[0].type;
    } else {
      tempStr = types2.PLAN_MANAGER_DATA.NONE;
    }

    let tempObj = {
      planManager: tempStr,
      id: this.props.member.id,
    };
    return tempObj;
  };

  _savePlanManagement = (saveObj: any, reduxArray: any) => {
    let updateMemberCallback = (data: any) => {
      this.props.actions.MemberActions.actionUpdateMember(saveObj);
      this.props.actions.PlanManagerActions.actionSetManagement(reduxArray);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, aboutMe: saveObj});
    };

    const updateMemberErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    // this.props.actions.PlanManagerActions.actionSetManagement(tempArray);
    callAPIs(
      [updateMember(saveObj, this.props.user.demoProfile)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };
}

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  planManager: state.PlanManagerReducer,
  sideMenu: state.SideMenuReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    PlanManagerActions: bindActionCreators(PlanManagerActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanManagement);
