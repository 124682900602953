import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardInput} from '../Atoms';
import {DataSharingConsentModal, FormSubtitle} from '../Molecules';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {connect} from 'react-redux';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

import * as GeneralActions from '../../Actions/GeneralActions';
import {bindActionCreators} from 'redux';

const SignUpFields = (props: any) => {

  let signUpInputField;
  return (
    <View
      key="fNamelNameContainer"
      style={[
        CommonStyles.flex1,
        CommonStyles.width100,
        CommonStyles.alignCenter,
      ]}
    >
      <View
        key="firstnameContainer"
        style={[CommonStyles.textInputLoginSignUp]}
      >
        <FormSubtitle text={['First Name']} />
        <StandardInput
          onFocus={() => {
            if (isTablet()) {
              props.scrollToPosition(0, 280);
            }
          }}
          applyWrapper={false}
          accessibilityLabel="First Name"
          key="firstname"
          value={props.firstname}
          onChangeText={(firstname: any) =>
            props.saveField(types.FIRST_NAME, firstname)
          }
          onSubmitEditing={() => {
            signUpInputField?.focus();
          }}
          
          blurOnSubmit={false}
          style={[
            CommonStyles.LoginSignUpMB10,
            CommonStyles.ModalTextInputWrapper,
            CommonStyles.loginSignUpFieldWidth,
          ]}
          noMargin={true}
          underlineColorAndroid="transparent"
          returnKeyType={'next'}
          errorMessage={props.firstnameError}
        />
      </View>
      <View key="lastnameContainer" style={CommonStyles.textInputLoginSignUp}>
        <FormSubtitle text={['Last Name']} />
        <StandardInput
          onFocus={() => {
            if (isTablet()) {
              props.scrollToPosition(0, 280);
            }
          }}
          applyWrapper={false}
          accessibilityLabel="Last Name"
          key="lastname"
          value={props.lastname}
          onChangeText={(lastname: any) =>
            props.saveField(types.LAST_NAME, lastname)
          }
          onRef={(ref: any) => {
            signUpInputField = ref;
          }}
          onSubmitEditing={() => {
            props?.general?.nextRef['email']?.focus();
          }}
          
          blurOnSubmit={false}
          style={[
            CommonStyles.LoginSignUpMB10,
            CommonStyles.ModalTextInputWrapper,
            CommonStyles.loginSignUpFieldWidth,
          ]}
          noMargin={true}
          underlineColorAndroid="transparent"
          returnKeyType={'next'}
          errorMessage={props.lastnameError}
        />
      </View>
    </View>
  );
};

const mapStateToProps = (state: any) => ({
  general: state.GeneralReducer,
});


const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(GeneralActions, dispatch),
  
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFields);

