/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Supports API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';
import {isWeb} from '../Constants/Constants';
import {NO_PLANS_ERROR_CODE} from '../Constants/Constants2';

//* ********************************
//*       PLANDATA
//* ********************************

/*
 * Returns the Supports for a given Member ID
 * @async
 * @param {string} planId The plan ID of the user you want to return
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The living arrangements object
 */
export const getSupports = (
  planId: any,
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSupportsById =  await configuredAWSLamda();
      const requestBody = {planId, memberId, isDemoProfile};
      const functionName = 'getCurrentSupportsByPlanId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      getSupportsById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the Supports for a given Member ID for a given NDIS budget category
 * @async
 * @param {string} planId The plan ID of the user you want to return
 * @param {string} memberId The member ID of the user you want to return
 * @param {string} budgetCategoryId The budgetCategoryId of the supports
 * @param {string[] | undefined} budgetCategories The budgetCategories if it is Core Flexible
 * @returns {Promise<object>} The current supports list
 */
export const getSupportsForBudgetCategory = (
  planId: any,
  memberId: any,
  budgetCategoryId: any,
  planKey: any,
  isDemoProfile = false,
  autoHandleError = true,
  budgetCategories?: string[],
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSupportsForBudgetCategory =  await configuredAWSLamda();
      const requestBody = {
        planId,
        memberId,
        budgetCategoryId,
        planKey,
        isDemoProfile,
        budgetCategories,
      };
      const functionName = 'getCurrentSupportsForBudgetCategory';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      getSupportsForBudgetCategory.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Updates the Supports for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {object} request The object containing the updated details
 * @returns {Promise<object>} The living arrangements object if successful, otherwise an error object
 */
export const createSupport = (
  requestObj: any,
  demoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putCurrentSupportByPlanId =  await configuredAWSLamda();
      const requestBody = requestObj;
      requestBody.isDemoProfile = demoProfile;
      const functionName = 'putCurrentSupportByPlanId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      putCurrentSupportByPlanId.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 *  Creates or edits an existing goal given a goal ID
 * @async
 * @param {string} planId The plan ID of the user you want to return
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The result of the getGoals API call
 */
export const putGoalForPlan = (
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSupportsById =  await configuredAWSLamda();
      const requestBody = requestObj;
      requestBody.isDemoProfile = isDemoProfile;
      const functionName = 'putCurrentGoalByPlanId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      getSupportsById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Returns an array of goals against a given plan
 * @async
 * @param {string}  The goal object to send to the API
 * @returns {Promise<object>} The result of the putGoal API call
 */
export const getGoalsForPlan = (
  planId: any,
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSupportsById =  await configuredAWSLamda();
      const requestBody = {planId, memberId, isDemoProfile};
      const functionName = 'getCurrentGoalsByPlanId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getSupportsById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Returns an array of goal progress against a given goalId
 * @async
 * @param {integer}  memberId The ID of the member you are retrieving goal progress
 * @param {integer}  goalId The ID of the goal you are retrieving goal progress
 * @returns {Promise<object>} The goal progress result of the getGoalProgress API call
 */
export const getGoalProgress = (
  memberId: any,
  goalId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      const getGoalProgressById =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        goalId: goalId,
        isDemoProfile,
      };
      const functionName = 'getGoalProgress';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getGoalProgressById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Returns an array of goal progress against a given goalId
 * @async
 * @param {object}  requestBody The object of the goal progress you sending to store
 */
export const postGoalProgress = (
  requestBody: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      requestBody.isDemoProfile = isDemoProfile;
      const postGoalProgressById =  await configuredAWSLamda();
      const functionName = 'postGoalProgress';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      postGoalProgressById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Deletes a goal for a given plan
 * @async
 * @param {string} planId The ID of the plan you are deleting a goal from
 * @param {string} memberId The ID of the member you are deleting a goal from
 * @param {string} goalId The ID of the goal you are deletingh
 * @returns {Promise<object>} The result of the putGoal API call
 */
export const deleteGoalForPlan = (
  planId: any,
  memberId: any,
  goalId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const deleteCurrentGoalById =  await configuredAWSLamda();
      const requestBody = {
        planId,
        id: memberId,
        currentGoalId: goalId,
        isDemoProfile,
      };
      const functionName = 'deleteCurrentGoalById';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      deleteCurrentGoalById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
/**
 * Deletes a support for a given plan
 * @async
 * @param {string} planId The ID of the plan you are deleting a goal from
 * @param {string} memberId The ID of the member you are deleting a goal from
 * @param {string} goalId The ID of the goal you are deletingh
 * @returns {Promise<object>} The result of the putGoal API call
 */
export const deleteSupportForPlan = (
  planId: any,
  memberId: any,
  supportId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const deleteCurrentSupportById =  await configuredAWSLamda();
      const requestBody = {
        planId,
        id: memberId,
        currentSupportId: supportId,
        isDemoProfile,
      };
      const functionName = 'deleteCurrentSupportById';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      deleteCurrentSupportById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Returns an a list of budgets for a member
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The result of the getBudget API call
 */
export const getBudget = (
  memberId: any,
  isDemoProfile = false,
  planKey: any,
  autoHandleError = true,
  requestMultiTranche = false,
  requestFlexCore = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getBudget =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        planKey,
        isDemoProfile,
        requestMultiTranche,
        requestFlexCore,
      };
      const functionName = 'getBudget';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getBudget.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          logger.log('PlanDataAPI errorObject: ', errorObject);
          // If error is because of no plans, need to resolve and show
          // meaningful message for budgets
          if (errorObject && errorObject.code != NO_PLANS_ERROR_CODE) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getPlans = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getPlans =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        isDemoProfile,
      };
      const functionName = 'getPlans';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getPlans.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getPlan = (
  plankey: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getPlan =  await configuredAWSLamda();
      const requestBody = {
        id: plankey,
        isDemoProfile,
      };
      const functionName = 'getPlan';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getPlan.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getInvoiceHistory = (
  memberId: any,
  searchQuery = null,
  pageNumber = null,
  pageSize = null,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getInvoiceHistory =  await configuredAWSLamda();
      let requestBody;
      if (isWeb) {
        requestBody = {
          id: memberId,
          searchQuery: searchQuery,
        };
      } else {
        requestBody = {
          id: memberId,
          searchQuery: searchQuery,
          pageNumber: pageNumber,
          pageSize: pageSize,
        };
      }
      requestBody.isDemoProfile = isDemoProfile;

      const functionName = 'getInvoiceHistory';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getInvoiceHistory.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

/**
 * Get draft invoices to diplay on draft invoice landing page
 * @async
 * @param {string} memberId The ID of the member you are deleting a goal from
 * @param {string} searchQuery The query string for search the draft invoice
 * @param {integer} pageNumber Default Page Number to Display
 * @param {integer} pageSize How mange items for page to display
 */
// TODO: Here few more code comments because pagingation to be implement
export const getDraftInvoices = (
  memberId: any,
  isDemoProfile = false,
  // searchQuery = null,
  // pageNumber = null,
  // pageSize = null,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getDraftInvoices =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        isDemoProfile,
        // searchQuery: searchQuery,
        // pageNumber: pageNumber,
        // pageSize: pageSize
      };
      const functionName = 'getDraftInvoices';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getDraftInvoices.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

/**
 * Save Draft Invoice for the First Time
 * @async
 * @param {string} memberId The ID of the member you are saving draft invoice
 * @param {object} invoiceDetails The invoice detail object to save as draft
 */
export const postDraftInvoice = (
  memberId: any,
  invoiceDetails: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const postDraftInvoice =  await configuredAWSLamda(false);
      const requestBody = {id: memberId, invoiceDetails};
      const functionName = 'postDraftInvoice';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      postDraftInvoice.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

/**
 * Update Existing Draft Invoice
 * @async
 * @param {string} memberId The ID of the member you are updating draft invoice
 * @param {integer} invoiceId The draft invoice id which is updating
 * @param {boolean} isActiveDraft The status of draft invoice
 * @param {object} invoiceDetails The invoice detail object to update as draft
 */
export const putDraftInvoice = (
  memberId: any,
  invoiceId: any,
  isActiveDraft: any,
  invoiceDetails = {},
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putDraftInvoice =  await configuredAWSLamda(false);
      const requestBody = {
        id: memberId,
        invoiceId: invoiceId,
        isActiveDraft: isActiveDraft,
        invoiceDetails,
      };
      const functionName = 'putDraftInvoice';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      putDraftInvoice.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

/**
 * Update Existing Invoice (Example: Update Invoice Attachement)
 * @async
 * @param {string} memberId The ID of the member you are updating draft invoice
 * @param {integer} invoiceKey The invoice key which is updating
 * @param {object} invoiceDetails The invoice detail object to update
 */
export const putInvoice = (
  memberId: any,
  invoiceKey: any,
  invoiceDetails = {},
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putInvoice =  await configuredAWSLamda(false);
      const requestBody = {
        id: memberId,
        invoiceKey: invoiceKey,
        invoiceDetails,
      };
      const functionName = 'putInvoice';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      putInvoice.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

/**
 * Returns an a array of transaction histroy for the user
 * @async
 * @param {string} memberId The member ID for the transaction history you want to return
 * @returns {Promise<object>} The result of the getBudget API call
 */
export const getTransactionHistory = (
  memberId: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getTransactionHistory =  await configuredAWSLamda();
      const requestBody = {id: memberId};
      const functionName = 'getTransactionHistory';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getTransactionHistory.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getServiceAgreementsList = (
  memberId: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getServiceAgreementsList =  await configuredAWSLamda();
      const requestBody = {id: memberId};
      const functionName = 'getServiceAgreementsList';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);
      getServiceAgreementsList.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
/**
 * This will fetch the list of items that require the member to approve.
 * @param {string} memberId The member id for the items requiring approval.
 * @param {boolean} autoHandleError Wether the API will auto handle the error or not.
 * @returns {Promise<object>}
 */
export const getApprovalRequired = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from AWS sdk
      const getApprovalsRequired =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        isDemoProfile,
      };
      const functionName = 'getApprovalRequired';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      getApprovalsRequired.invoke(params, (err, data) => {
        if (err) {
          logger.log('getApprovalRequired error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getInvoiceDetails = (
  memberId: any,
  uri: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from AWS sdk
      const getApprovalsRequired =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        invoiceURI: uri,
        isDemoProfile,
      };
      const functionName = 'getInvoice';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      getApprovalsRequired.invoke(params, (err, data) => {
        if (err) {
          logger.log('getInvoice error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const putInvoiceApproval = (
  memberId: any,
  uri: any,
  invoiceId: any,
  approval: any,
  rejectReasons: any,
  sendEmailNotification: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from AWS sdk
      const putInvoiceApproval =  await configuredAWSLamda();
      const requestBody = {
        id: memberId,
        invoiceURI: uri,
        invoiceId,
        type: approval,
        isDemoProfile,
      };

      if (rejectReasons) {
        requestBody.rejectReason = rejectReasons;
      }
      if (sendEmailNotification) {
        requestBody.sendEmail = sendEmailNotification;
      }

      const functionName = 'putInvoiceApproval';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      putInvoiceApproval.invoke(params, (err, data) => {
        if (err) {
          logger.log('putInvoiceApproval error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

export const putAgreementApproval = (
  memberId: any,
  agreementId: any,
  approval: any,
  rejectReasons: any,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from AWS sdk
      const putAgreementApproval =  await configuredAWSLamda();
      const requestBody = {id: memberId, agreementId, type: approval};

      if (rejectReasons) {
        requestBody.rejectReason = rejectReasons;
      }

      const functionName = 'putAgreementApproval';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      putAgreementApproval.invoke(params, (err, data) => {
        if (err) {
          logger.log('putAgreementApproval error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getServiceAgreementApproval = (
  memberId: any,
  agreementId: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from AWS sdk
      const getServiceAgreementApproval =  await configuredAWSLamda();
      const requestBody = {id: memberId, agreementId};
      const functionName = 'getServiceAgreementApproval';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      getServiceAgreementApproval.invoke(params, (err, data) => {
        if (err) {
          logger.log('getServiceAgreementApproval error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const putBulkInvoiceApproval = (
  memberId: any,
  invoices: any,
  approvalType: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from AWS sdk
      const putBulkInvoiceApproval =  await configuredAWSLamda();
      const requestBody = {memberId, invoices, approvalType};
      const functionName = 'putBulkInvoiceApproval';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(requestBody, functionName, serviceName);

      putBulkInvoiceApproval.invoke(params, (err, data) => {
        if (err) {
          logger.log('putBulkInvoiceApproval error: ', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

//* *************************
//* UPDATE PROVIDER DATA
//* *************************

export const putMemberProvider = (
  personId: any,
  memberProviderDetails: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putProvider =  await configuredAWSLamda();
      const requestBody = {
        personId,
        memberProviderId: memberProviderDetails.memberProviderId,
        providerDetails: memberProviderDetails.providerDetails,
      };

      const functionName = 'putMemberProvider';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PLANDATA;
      const params = buildLambda(
        requestBody,
        functionName,
        serviceName,
        personId,
      );
      putProvider.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
